import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react';
import styled from 'styled-components';

export const Summary = observer(()=> {
  const {reportBuilder: {details: {
    isConfig,
    singleProjectData,
    loadingAiSummary,
  }}} = useStore('');

  return <div style={{width: '100%', marginTop: isConfig ? '' : '-20px'}} id='ottoSummaryDiv'>
    <Wrapper isConfig={isConfig} loadingAiSummary={loadingAiSummary}>
      {loadingAiSummary ? <LoadingOutlined style={{color: 'white', fontSize: '15px'}} spin/> : <><img src='/img/summaryStar.svg' alt='' />
        <div style={{marginLeft: '20px'}} id='ottoSummary'>
          <h1>AI Summary Overview</h1>
          <div dangerouslySetInnerHTML={{__html: singleProjectData?.aiSummary['reportSummary']}}></div>
        </div>
      </>}
    </Wrapper>
  </div>;
});

const Wrapper = styled.div<{isConfig?: boolean; loadingAiSummary?: any}>`
    position: absolute;
    color: white;
    top: 0;
    display: flex;
    padding: 20px;
    justify-content: ${p=> p.loadingAiSummary ? 'center' : ''};
    width: ${p=> p.loadingAiSummary ? '100%' : ''};
    height: ${p=> p.loadingAiSummary ? '100%' : ''};
    align-items: ${p=> p.loadingAiSummary ? 'center' : 'flex-start'};
    div{
    h1{margin: 0; color: white; font-size: 24px;};
    p{margin: 0;
    span{font-weight: 700;
    text-decoration: underline;}
    };
    }
`;
