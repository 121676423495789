import React, {useEffect, useState, ReactNode} from 'react';
import {Tooltip} from 'antd';
import {useRouter} from 'next/router';
import {observer} from 'mobx-react-lite';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import styles from './style.module.scss';

import LoginModal from '@/components/common-components/components/authentication-modal';
import useWindowSize from '@/utils/hooks/useWindowSize';
import {NoSsr, Typography} from '@/components/common-components';
import moment from 'moment';
// import {getDateDifferenceForLocalStorage} from '@/components/dashboard/gsc/gsc/utils';
import {
  getLastPropertyFromCookies,
  getPeriodFromLocalStorage,
  setCountryCodeToLocalStorage,
  setLastPropertyIntoCookies,
} from '@/api/common-utils';
import {RenderSaProjectSelect} from '../publicHeader/headerComponents/renderSaProjectSelect';
import {getSingleUrlParam, isNavbarRemoveOnPublicPages} from '@/utils/url';
import {useTranslation} from 'next-i18next';
import {HOME_UPGRADE_CTA_CLICKED} from '@/constants/events';

import {MixPanel} from '@/utils/mixpanel';
import {getAbsoluteLegacyUrl} from '@/utils/legacy';
import ButtonComponent from '@/components/common-components/components/button-component';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBullseyePointer,
  faCubes,
  faLayerPlus,
  faUsdCircle,
} from '@fortawesome/pro-duotone-svg-icons';
import {SiteHealthWithProgressPublic} from '../publicHeader/headerComponents/siteHealthWithProgressPublic';
import {RenderDateRangePickerSA} from '../publicHeader/headerComponents/rangePickerSA';
import Link from 'next/link';
import classnames from 'classnames';
import {Button} from '@/components/common-components/v2';
import {faArrowRight, faXmark} from '@fortawesome/pro-regular-svg-icons';
import classNames from 'classnames';
import {PostOnboardingModal} from '@/components/dashboard/pages/competitor-researcher/domain-analyzer-public/post-onboarding-modal';
import {LoadingOutlined} from '@ant-design/icons';
import {GscConnectModal} from '@/components/dashboard/pages/site-audit-v2/common/gscConnectModal';
import {AiContentToolsDrawer} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/Drawers/AiContentToolsDrawer';
import {toJS} from 'mobx';
import {linkgraphDomain} from '@/utils/const';
import {AiWriterSideDrawer} from '@/components/dashboard/pages/landing-page-optimizer/page-editor/Drawers/AiWriterSideDrawer';
import {LargeBadge} from '@/components/common-components/components/badge';

type SideBarProps = {
  commingSoon?: boolean;
  color?: string;
  key: string;
  title: string;
  disabled?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  external?: boolean;
  newTab?: boolean;
  url?: string;
  path?: string;
  isAlpha?:boolean;
  isBeta?: boolean;
  isNew?: boolean;
};

type SiderProps = {
  sideBarItems?: SideBarProps[];
  publicPage?: boolean;
};

export const PublicSidebar: React.FC<SiderProps> = observer(({sideBarItems, publicPage}) => {
  // const backlinkDisabled = planName !== 'PRO' && planName !== 'Custom' && planName !== 'GROWTH';
  const [isLoader, setIsLoader]= useState(false);
  const [isDropDownSa, setDropDownSa] = useState(false);
  const currentCustomerId = localStorage.getItem('currentCustomerId');

  const getPercentage = (val1, val2) => {
    if (val1 && val2) {
      return val1/val2 * 100;
    } else {
      return 0;
    }
  };

  const {width} = useWindowSize();
  const {
    keywordResearcherV2: {loadSideBarKEList, sideBarKEListParams, setSideBarParam, getSideBarKEList, sideBarKeywordListPages, keywordDetails: {keSelectedId}},
    settings: {
      customer: {setAuthModal, profile, isInitialExtendedDetailsFetched},
    },
  } = useStore('');
  const {
    settings, navBar, gsc,
  } = useStore('');
  const {
    navBar: {isDockedSider, setIsDockedSider, loadSiteProperties},
    home: {isChild},
  } = useStore('');

  const {settings: {customer: {profile: {setShowPostOnboardingModal, setShowCreateProjectBanner, showCreateProjectBanner, isVendastaClient, isVerified}}}} = useStore('');

  const router = useRouter();

  let htmlToDraft = null;
  if (typeof window === 'object') {
    htmlToDraft = require('html-to-draftjs').default;
  }

  const isKEDetailsPage = router.pathname.match(/\/research\/keyword-researcher\/.*/g);
  const isSiteAuditV2 = router.pathname.match(/\/site-audit\/.*/g);

  const {
    plans: {showSidebarPaymentDrawer},
    settings: {customer: {remainingExpiryDays, profile: {plan}}},
    contentOptimizer: {
      currentPage: {content},
      aiOutline: {
        isOpenAiWriterDrawer,
        aiArticleAlreadyReplaced,
      },
    },
  } = useStore('');
  const {siteAuditorV2: {gscLoading, openGscModal, callGscModalApi, setGscOpenModalValue, auditedSitesList}} = useStore('');

  const loading = content.aiInProgress;
  const [contentLength] = useState<'short' | 'medium' | 'long'>('medium');
  const [elapsedTime, setElapsedTime] = useState(0);


  const {t} = useTranslation('common');

  useEffect(() => {
    if (!publicPage && !isKEDetailsPage) {
      loadSiteProperties();
    }
  }, []);

  useEffect(() => {
    if (isOpenAiWriterDrawer) {
      let totalTime = 100 * 1000;

      switch (contentLength) {
        case 'short':
          totalTime = 100 * 1000;
          break;
        case 'medium':
          totalTime = 180 * 1000;
          break;
        case 'long':
          totalTime = 300 * 1000;
          break;
        default:
          break;
      }

      if (!loading) return;

      const intervalId = setInterval(() => {
        if (elapsedTime < totalTime) {
          setElapsedTime(prevElapsedTime => prevElapsedTime + 1000);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId); // Clean up the interval on component unmount
      };
    } else if (aiArticleAlreadyReplaced) {
      setElapsedTime(0);
    }
  }, [elapsedTime, loading, isOpenAiWriterDrawer]);


  const openLoginModal = () => {
    setAuthModal(true, true);
  };

  const defaultPreset = [
    moment().subtract(1, 'years').subtract(1, 'days'),
    moment().subtract(1, 'days'),
  ];

  const {criteria} = gsc;
  const googleProfiels = settings.integration.googleProfiles;
  const siteProperties = navBar.propertiesData;

  const updateDefaultCriteria = (url: string, lastPeriod?: any, countryCode?: string) => {
    const [currentPeriodStart, currentPeriodEnd] = defaultPreset;
    const diff = moment(currentPeriodEnd).diff(moment(currentPeriodStart), 'days') + 1;
    const previousPeriodStart = moment(currentPeriodStart).subtract(1, 'days').subtract(diff, 'days');
    const previousPeriodEnd = moment(currentPeriodStart).subtract(2, 'days');

    criteria.updateCriteria({
      property: url,
      previousPeriodStart: lastPeriod?.p1Start ? lastPeriod.p1Start : previousPeriodStart.format('YYYY-MM-DD'),
      previousPeriodEnd: lastPeriod?.p1End ? lastPeriod.p1End : previousPeriodEnd.format('YYYY-MM-DD'),
      currentPeriodStart: lastPeriod?.p2Start ? lastPeriod.p2Start : currentPeriodStart.format('YYYY-MM-DD'),
      currentPeriodEnd: lastPeriod?.p2End ? lastPeriod.p2End : currentPeriodEnd.format('YYYY-MM-DD'),
      periodStatus: lastPeriod?.periodStatus ? lastPeriod.periodStatus : '1 year',
      countryCode: countryCode ? countryCode : '',
    });

    gsc.sitePropertyEvents.siteEventCriteriaUpdate({
      property: url,
      previousPeriodStart: lastPeriod?.p1Start ? lastPeriod.p1Start : previousPeriodStart.format('YYYY-MM-DD'),
      previousPeriodEnd: lastPeriod?.p1End ? lastPeriod.p1End : previousPeriodEnd.format('YYYY-MM-DD'),
      currentPeriodStart: lastPeriod?.p2Start ? lastPeriod.p2Start : currentPeriodStart.format('YYYY-MM-DD'),
      currentPeriodEnd: lastPeriod?.p2End ? lastPeriod.p2End : currentPeriodEnd.format('YYYY-MM-DD'),
      periodStatus: lastPeriod?.periodStatus ? lastPeriod.periodStatus : '1 year',
      countryCode: countryCode ? countryCode : '',
    });
  };
  const closeGscModal = ()=>{
    setGscOpenModalValue(false);
  };

  const loadGSCSiteProperties = async () => {
    // Load G Profiles.
    const {integration} = settings;
    integration.loadGoogleProfiles();
    // Got G profiles, try to load site properties.
    const adminCode = getSingleUrlParam('gsc_admin_code');
    if (adminCode) {
      await navBar.loadSiteProperties(adminCode);
    } else {
      await navBar.loadSiteProperties();
    }
    if (navBar.hasSiteProperties) {
      const {propertiesData} = navBar;
      const lastProperty = getLastPropertyFromCookies();
      const lastPeriod = JSON.parse(getPeriodFromLocalStorage());

      const property = propertiesData.filter(el => el.url === lastProperty).length > 0 ? propertiesData.filter(el => el.url === lastProperty) : propertiesData.filter(el => el.activeForCurrentCustomer);
      const urlProperty = router.query.sp?.includes('?') ? router.query.sp?.slice(0, -1) : router.query.sp;
      const selectedSiteProperty = navBar.getSiteProperty(urlProperty || (property.length ? property[0].url : null));
      // update query parameter for GSC dashboard.
      // const {kw} = router.query;
      // const {url} = router.query;
      const countryCodeParam = getSingleUrlParam('country_code');
      const matchCountryFromParam = selectedSiteProperty?.activeCountryCodes?.length ? selectedSiteProperty?.activeCountryCodes?.find(item => item == countryCodeParam) : '';
      const countryCode = matchCountryFromParam ? matchCountryFromParam : (selectedSiteProperty?.activeCountryCodes?.length ? selectedSiteProperty?.activeCountryCodes[0] : '');
      // if (isGSCDashboard && !kw && !url) {
      //   // shallow update the query param, so page's state is not lost.
      //   router.push(
      //     `${router.pathname}${appendParams(selectedSiteProperty?.url, countryCode)}`,
      //     undefined,
      //     {shallow: true},
      //   );
      // }
      // Set default presets and update criteria.
      // updateDefaultCriteria(selectedSiteProperty?.url, lastPeriod, selectedSiteProperty?.activeCountryCodes?.find(item => item == store?.gsc?.criteria?.countryCode));
      updateDefaultCriteria(selectedSiteProperty?.url, lastPeriod, countryCode);
      setCountryCodeToLocalStorage(countryCode);
      // setCountry(countryCode);
      setLastPropertyIntoCookies(selectedSiteProperty?.url);
    } else {
      // In this case we will create a demo array of site properties to use
      const demoProperties = [`sc-domain:${linkgraphDomain}`];
      const selectedDemoProperty = demoProperties[0];
      // if (isGSCDashboard) {
      //   router.push(
      //     `${router.pathname}${appendParams(selectedDemoProperty, '')}`,
      //     undefined,
      //     {shallow: true},
      //   );
      // }
      updateDefaultCriteria(selectedDemoProperty);
    }
  };

  useEffect(() => {
    // necessary for settings sidedrawer to get GSC list
    if (!publicPage) {
      loadGSCSiteProperties(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [
    googleProfiels,
    siteProperties,
  ]);

  useEffect(() => {
    if (width < 1300) {
      setIsDockedSider(false);
    } else {
      setIsDockedSider(true);
    }
  }, [width]);

  let renderFreeConsultation = null;
  if (router.pathname !== '/checkout' && renderFreeConsultation == null ) {
    renderFreeConsultation= (
      <div className={styles.sidebarCTA}>
        <Typography tag='p'>
          {t('ready-most-powerful')}
        </Typography >
        <ButtonComponent
          style='solid'
          color='green'
          onClick={() => {
            showSidebarPaymentDrawer();

            MixPanel.track(HOME_UPGRADE_CTA_CLICKED);
          }}
          /*   buttonType='transparent' */
          className={ styles.freeConsultation }>
          {t('upgrade-to-pro')}
        </ButtonComponent>
        <div className={styles.borderCTA} />
      </div>
    );
  }

  const isPricingPage = router.pathname.match(/\/pricing.*/g);
  const isOrderBuilderPage = router.pathname.match(/\/order-builder.*/g);
  const isLinkBuildingPage = router.pathname.match(/\/orders\/link-building.*/g);
  const tableName = getSingleUrlParam('table');
  const isMagicTool = router.pathname?.includes('/keyword-magic-tool');
  const pagesToShowKEList = [
    '/research/keyword-researcher/[...keyword]',
    '/research/keyword-researcher-magic/[...keyword]',
  ];

  let remainingTrial = null;
  remainingTrial = (
    <div>
      <p style={{marginTop: '10px', color: '#A3A4A4'}}>Your trial ends in {remainingExpiryDays} days</p>
      <TrialBar>
        <div style={{background: '#8860D4', height: 5, borderRadius: 3,
          width: `${getPercentage(7- remainingExpiryDays, 7)/100}%`}}/>
      </TrialBar>
    </div>
  );

  const handleKEList = async () => {
    setIsLoader(true);
    await loadSideBarKEList();
    setIsLoader(false);
  };

  const handleScroll = e => {
    if (sideBarKEListParams.page < sideBarKeywordListPages && !isLoader) {
      if ([e.target.scrollHeight + 1, e.target.scrollHeight, e.target.scrollHeight - 1].includes(Math.round(e.target.offsetHeight + e.target.scrollTop))) {
        setSideBarParam(sideBarKEListParams.page + 1);
        handleKEList();
      }
    }
  };

  useEffect(() => {
    if (pagesToShowKEList.includes(router?.pathname)) {
      setSideBarParam(1);
      handleKEList();
    }
    // if (!publicPage && router?.pathname?.includes('gsc/sites-list') && !navBar?.getSiteProperties?.length) {
    //   loadGSCSiteProperties();
    // }
  }, [router?.pathname]);
  let getSidebarItemHeight = null;
  const getSidebarItem = document.getElementById('sidebar_upper_items_div');
  if (getSidebarItem) {
    getSidebarItemHeight = getSidebarItem.clientHeight + 65 + 200;
  }
  const antIcon = <div style={{width: '100%', textAlign: 'center'}}><LoadingOutlined style={{fontSize: 20, color: '#A3A4A4'}} spin /></div>;
  const handleKERedirection = item => {
    localStorage.setItem('listname', item?.name);
    const selectedLocation = item?.locationId ? `locationId=${item.locationId}` : `countryCode=${item?.countryCode ? item.countryCode : ''}`;
    const checkPrevPage = isMagicTool && !tableName ? 'keyword-magic-tool' : 'keyword-researcher-magic';
    if (item?.keywords?.length == 1 && item?.id) {
      router.push(`/research/keyword-researcher/${item.id}/${item.keywords ? item.keywords[0] : ''}?${selectedLocation}`);
    } else {
      router.push(`/research/${checkPrevPage}/${item?.id}/${item?.keywords ? item?.keywords[0] : ''}?${selectedLocation}`);
    }
  };

  return <NoSsr>
    <SideBar isDockedSider={isDockedSider}>
      <AiContentToolsDrawer htmlToDraft={htmlToDraft}/>
      <AiWriterSideDrawer htmlToDraft={htmlToDraft} elapsedTime={elapsedTime}/>
      <PostOnboardingModal />
      <div id={'sidebar_upper_items_div'}>
        {isSiteAuditV2 && !isSiteAuditV2?.includes('/site-audit/list-public') && <div style={{height: 'fit-content', marginBottom: '20px'}}>
          <RenderSaProjectSelect setDropDownSa={setDropDownSa} isDropDownSa={isDropDownSa} isDockedSider={isDockedSider} />
          {isSiteAuditV2?.includes('/site-audit/overview-public') ?
            <DateRangePublic> <RenderDateRangePickerSA isDockedSider={isDockedSider}/></DateRangePublic> : ''}
          <SiteHealthWithProgressPublic isDockedSider={isDockedSider}/>
        </div>}
        <div>
          {sideBarItems?.filter(z => !z?.hidden)?.map(item => {
            if (item?.path?.includes('/site-audit/') && item?.path !== '/site-audit/pruning-page') {
              const getUrlId = getSingleUrlParam('id') || getSingleUrlParam('audit') || toJS(auditedSitesList)[0]?.id;
              const getHash = getSingleUrlParam('public_hash') || toJS(auditedSitesList)[0]?.publicShareHash;
              const makePath = item?.path + `?id=${getUrlId || ''}&public_hash=${getHash || ''}`;
              return <Link href={makePath} passHref><a>
                <Tooltip placement='right' title={isDockedSider ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <PopoverItem itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)}>
                    <IconSpan>{item?.icon}</IconSpan> {isDockedSider ? item?.title : ''}
                  </PopoverItem>
                </Tooltip>
              </a></Link>;
            } else {
              if (item?.path?.includes('public')) {
                return <Link href={item.path} passHref><a>
                  <Tooltip placement='right' title={isDockedSider ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                    <PopoverItem itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (item.path !== '/' && item.key.startsWith(router?.asPath)) || router.pathname?.includes(item?.path)}>
                      <IconSpan>{item?.icon}</IconSpan> {isDockedSider ?<span> {item?.title}</span> : ''} {item?.isNew && <LargeBadge isNew/>}
                    </PopoverItem>
                  </Tooltip>
                </a></Link>;
              } else {
                return <Tooltip placement='right' title={isDockedSider ? '' : `${item.title}`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <PopoverItem onClick={() => {
                    setAuthModal(true, true);
                  }} itemColor={item?.color} key={item.key} highlight={router.pathname == item.path || (item.path !== '/' && router.asPath.startsWith(item.key)) || router.pathname?.includes(item?.path)}>
                    <IconSpan>{item?.icon}</IconSpan> {isDockedSider ?<span> {item?.title}</span> : ''}
                  </PopoverItem>
                </Tooltip>;
              }
            }
          })}
        </div>
      </div>
      <div >
        {pagesToShowKEList.includes(router?.pathname) && getSideBarKEList?.length ? <WrapperList style={{height: `calc(100vh - ${getSidebarItemHeight}px)`}} onScroll={handleScroll}>
          <KeywordList>KEYWORD LISTS</KeywordList>
          {getSideBarKEList?.map((item, idx) => {
            return <KeywordItem key={`KeywordItem_${idx}`} selected={`${item?.id}` == keSelectedId} onClick={() => handleKERedirection(item)}>
              <div>{item?.name}</div>
              <div>{item?.keywords?.length ?? '0'}</div>
            </KeywordItem>;
          })}
          {isLoader ? antIcon : ''}
        </WrapperList> : ''}
      </div>
      <div style={{width: '100%'}}>
        <div className={styles.opacity} />

        <div className={styles.trailSec} style={{width: isDockedSider ? '230px' : '65px'}} onClick={() => {
          if (publicPage && !isVerified) {
            openLoginModal();
          }
        }}>
          {showCreateProjectBanner && !location?.href?.includes('home') && !isNavbarRemoveOnPublicPages() ? <Tooltip placement='right' title={isDockedSider ? '' : `Create Project`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
            <div className={classNames(styles.postOnboardingBanner, !isDockedSider && styles.dockedSidebar)}>
              <Tooltip title={ !isDockedSider ? '': 'Hide Create Project banner'} placement='right'>
                <div className={styles.postOnboardingCloseIcon} onClick={() => setShowCreateProjectBanner(false)}>
                  {!isDockedSider ? '' : <FontAwesomeIcon icon={faXmark} />}
                </div>
              </Tooltip>
              <FontAwesomeIcon icon={faLayerPlus}
                color='#86838e'
                fontSize={25}
                style={!isDockedSider ? {cursor: 'pointer'} : {}}
                onClick={() => {
                  if (!isDockedSider) setShowPostOnboardingModal(true);
                }}/>
              {isDockedSider ? <><div style={{fontSize: 13, marginTop: 5, marginBottom: 10}}>Create a Project and instantly generate 11 powerful reports</div>
                <Button style={{marginLeft: 0, width: '100%'}} onClick={publicPage ? ()=>{} :() => setShowPostOnboardingModal(true)}>
                  Create Project <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: 20, height: 14}}/>
                </Button></> : null}
            </div>
          </Tooltip> : null}
          {!isInitialExtendedDetailsFetched || isVendastaClient ? '':
            <>
              {isDockedSider && remainingExpiryDays && Number(remainingExpiryDays) > -1 ? remainingTrial : ''}
              {/* Hide link building form for ALL agency children */}
              {profile.isWhitelabel && !profile.isParentAccount ? '' : <>
                <Tooltip placement='right' title={isDockedSider ? '' : `Link Building Form`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <a href={(!publicPage || isVerified) && getAbsoluteLegacyUrl('orders/link-building')} className={isLinkBuildingPage ? styles.active : ''}><span style={{minWidth: '36px'}}><Icon onClick={() => {}} icon={faBullseyePointer} /></span>{isDockedSider ? <span>Link Building Form</span> : ''}</a>
                </Tooltip></>}

              {/* Hide link building form for ALL agency children */}
              {/* We will enable it soon after we add toggle in settings menu */}
              {profile.isWhitelabel && !profile.isParentAccount ? '' : <>
                <Tooltip placement='right' title={isDockedSider ? '' : `Order Builder`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <div
                    onClick={()=>{
                      (!publicPage || isVerified) && router.push('/order-builder');
                    }}
                    className={isOrderBuilderPage ? classnames(styles.pricing, styles.active) : styles.pricing}>
                    {/* <a href={!publicPage && '/order-builder'} className={isOrderBuilderPage ? styles.active : ''}> */}
                    <span style={{minWidth: '36px'}}><Icon onClick={() => {}} icon={faCubes} /></span>{isDockedSider ? <span>Order Builder</span> : ''}
                  </div>
                </Tooltip></>}

              {profile.isWhitelabel && !profile.isParentAccount && !profile.shouldShowPricingPage ? '' :
                <Tooltip placement='right' title={isDockedSider ? '' : `Pricing`} overlayInnerStyle={{borderRadius: '4px'}} color='#121212'>
                  <div
                    onClick={()=>{
                      (!publicPage || isVerified) && router.push(`/pricing${profile.isWhitelabel?'-agency':''}${isChild ? `&customerId=${currentCustomerId ? currentCustomerId: ''}` : ''}`);
                    }}
                    className={isPricingPage ? classnames(styles.pricing, styles.active) : styles.pricing}>
                    <span style={{minWidth: '36px'}}><Icon onClick={() => {}} icon={faUsdCircle} /></span>{isDockedSider ? <span>Pricing</span> : ''}</div>
                </Tooltip>}
            </>}
        </div>
      </div>


      {!plan && isDockedSider && !publicPage ? renderFreeConsultation : ''}
    </SideBar>
    <LoginModal />
    {openGscModal && <GscConnectModal loading= {gscLoading} openGscModal={openGscModal} closeGscModal={closeGscModal} callGscModalApi={callGscModalApi}/>}
  </NoSsr>;
});

const SideBar = styled.div<{isDockedSider: boolean}>`
  background-color: #24262A;
  z-index: 3;
  overflow-x: ${p => p.isDockedSider ? 'hidden' : 'unset'};
  width: 230px;
  height: 100%;
  width: ${p => p.isDockedSider ? '230px' : '65px'};
  padding-bottom: 30px;
  transition: width 0.2s;
  padding-left: 13px;
  padding-right: 8px;
  padding-top: 40px;
  min-height: calc(100vh - 66px);
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 20px !important;
  margin-right: 5px !important;
  /* padding-right: 17px; */
 
`;

const IconSpan = styled.span`
  /* margin-right: 5px; */
  display: flex;
  align-items: center;
  height: fit-content;
  min-width:36px;
  svg{
    width: 20px;
    height: 20px;
  }
`;
const WrapperList = styled.div`
  overflow: auto;
  margin-top: 30px;
  /* height: 200px; */
  &::-webkit-scrollbar{
    height: 5px;
      width: 5px;
      }
  &::-webkit-scrollbar-thumb{
    background: rgba(138,138,138,0.32);
  }
  &::-webkit-slider-runnable-track{
    background: rgba(138,138,138,0.12);
  }

`;

const PopoverItem = styled.div<{highlight: boolean; itemColor: string}>`
  background: ${p => p.highlight ? `${p.itemColor}33` : 'transparent'};
  border-radius: 4px;
  color: ${p => p.highlight ? '#fff' : '#E8E8E8'};
  padding: 5px 13px;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.01em;
  height: 36px;
  display: flex;
  align-items: center;
  svg {
    color: ${p => p.highlight ? p.itemColor : '#E8E8E8'} !important;
    fill: ${p => p.highlight ? p.itemColor : '#E8E8E8'} !important;
  }

  &:hover {
    background: #34363B;
    color: #FFF;
  }
`;

const DateRangePublic=styled.div`
  pointer-events: none;
`;

const TrialBar = styled.div`
  width: 200px;
  background: #4E5156;
  margin: -10px 0 15px 0;
  height: 5px;
  border-radius:3px;
`;

const KeywordList = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;
  padding-left: 12px;
  margin-bottom: 10px;
`;

const KeywordItem = styled.div<{selected?: boolean}>`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: ${p => p.selected ? '#FFFFFF' : '#A3A4A4'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 12px;
  background: ${p => p.selected ? '#4E515666' : 'transparent'};
  border-radius: ${p => p.selected ? '5px' : '0'};
  cursor: pointer;
  &:hover {
    background: #4E515666;
    border-radius: 5px;
    color: #FFFFFF;
  }
`;
