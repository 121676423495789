import {Button, CountryDropdown, Input} from '@/components/common-components/v2';
import {getDomain, validateDomain} from '@/utils/url';
// import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import {faChartSimple, faKey} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {CustomTextarea} from '@/components/common-components/components/form/input/customTextarea';
import React, {useEffect, useState} from 'react';
import {PostOnboardingType} from '@/store/settings/customer';
import {faCircleCheck, faPen, faSquareCheck, faSquareXmark} from '@fortawesome/pro-solid-svg-icons';
import {Tooltip} from 'antd';
import {countriesList} from '@/utils/countries';

interface Props {
  domain: string;
  onEditUrlClick: () => void;
  kwInputValue: string[];
  rawKwInputValue: string;
  onKeywordUpdate: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  data: PostOnboardingType;
  fullData: PostOnboardingType;
  onSuggestmoreKw: (value: boolean) => void;
  showAllKw: boolean;
  onKwClick: (kw: string) => void;
  selectedCompetitors: string[];
  onCompetitorClick: (competitor: string) => void;
  addCompetitorHandler: (competitor: string) => void;
  onGscToggle: (value: boolean) => void;
  selectedLocation?: string;
  selectedCountry: string;
  setSelectedCountry: (value: string) => void;
}

export const StepTwo = (
  {
    domain,
    onEditUrlClick,
    onKeywordUpdate,
    kwInputValue,
    rawKwInputValue,
    data,
    fullData,
    onSuggestmoreKw,
    showAllKw,
    onKwClick,
    selectedCompetitors,
    onCompetitorClick,
    addCompetitorHandler,
    // onGscToggle,
    selectedLocation,
    selectedCountry,
    setSelectedCountry,
  }: Props) => {
  const [showCompetitorsInput, setShowCompetitorsInput] = useState(false);
  const [newCompetitor, setNewCompetitor] = useState('');
  const [isDomainInValid, setIsDomainInValid] = useState(false);

  const handleAdd = () => {
    if (newCompetitor && validateDomain(newCompetitor)) {
      addCompetitorHandler(newCompetitor);
      setNewCompetitor('');
    } else setIsDomainInValid(true);
  };

  const addDefaultSrc = e => {
    e.target.src = '/img/project-no-screenshot.png';
  };

  const formatDomain = domain.startsWith('http://') || domain.startsWith('https://') ? domain : `http://${domain}`;

  const onCountryChange = value => {
    setSelectedCountry(value);
  };

  useEffect(() => {
    setSelectedCountry(selectedLocation);
    setIsDomainInValid(false);
  }, []);

  return <Container>
    {/* HEADER SECTION START */}
    <SingleSectionContainer>
      <IconContainer>
        <IconStyled onError={e => addDefaultSrc(e)} src={`https://www.google.com/s2/favicons?sz=64&domain_url=https://www.${getDomain(domain)}`} alt={domain}/>
      </IconContainer>
      <SectionContent>
        <MainTitle>Create a new Project</MainTitle>
        <DomainInfoContainer>
          <DomainInfo>
            <Domain onClick={() => window.open(formatDomain, '_blank')}>{domain}</Domain>
          </DomainInfo>
          <CountryDropdownStyled
            showSearch
            aligned={true}
            isProjectCreation={true}
            variant={'light'}
            onChange={onCountryChange}
            showWorldWide={true}
            value={selectedCountry}
            countriesToInclude={countriesList}/>
          <ButtonStyled variant='ghost' background='transparent' onClick={onEditUrlClick}>
            <FontAwesomeIcon icon={faPen} color='#A3A4A4' style={{marginRight: 5}}/>
            Edit URL
          </ButtonStyled>
        </DomainInfoContainer>
      </SectionContent>
    </SingleSectionContainer>
    {/* HEADER SECTION END */}
    <ScrollableContainer>
      {/* KEYWORDS SECTION START */}
      <SingleSectionContainer>
        <IconContainer>
          <FontAwesomeIcon icon={faKey} color='#121212' style={{height: 20}}/>
        </IconContainer>
        <SectionContent>
          <SectionTitle>Track Keywords</SectionTitle>
          <SectionSubtitle>Deep analysis and ranking tips for the keywords your site ranks for.</SectionSubtitle>

          <CustomTextarea
            variant='light'
            minHeight='125px'
            maxHeight='240px'
            width='100%'
            autoSizeCheck={true}
            autoSize={true}
            onChange={onKeywordUpdate}
            value={rawKwInputValue}
            // onKeyPress={e=>handleValidation(e)}
          ></CustomTextarea>
          <KeywordsListSection>
            {data?.keywords?.map((kw, idx) => {
              return <SingleKeyword key={idx} isSelected={kwInputValue?.some(item => item.trim() == kw)} onClick={() => onKwClick(kw)}>{kw}</SingleKeyword>;
            })}
            {fullData?.keywords?.length > 7 ? <SuggestMoreBtn onClick={() => onSuggestmoreKw(!showAllKw)}>{!showAllKw ? 'View more' : 'View less'}</SuggestMoreBtn> : null}
          </KeywordsListSection>
        </SectionContent>
      </SingleSectionContainer>
      {/* KEYWORDS SECTION END */}

      {/* COMPETITORS SECTION START */}
      <SingleSectionContainer>
        <IconContainer>
          <FontAwesomeIcon icon={faChartSimple} color='#121212' style={{height: 20}}/>
        </IconContainer>
        <SectionContent>
          <SectionTitle>Competitors</SectionTitle>
          <SectionSubtitle>Keep an eye on your competition and learn how to beat them.</SectionSubtitle>
          {showCompetitorsInput ? <AddCompetitorsContainer>
            <div style={{width: '100%'}}>
              <InputStyled variant='gray' onChange={e => {
                setNewCompetitor(e.target.value);
                setIsDomainInValid(false);
              }} value={newCompetitor} placeholder='Add new competitor...'/>
              {isDomainInValid && <span style={{color: 'red'}}>Invalid domain format!</span>}
            </div>
            <Tooltip title='Add Competitor'>
              <FontAwesomeIcon icon={faSquareCheck} color='#1aceb3' style={{cursor: 'pointer', marginLeft: 5}} fontSize={40} onClick={handleAdd}/>
            </Tooltip>
            <Tooltip title='Close editor'>
              <FontAwesomeIcon icon={faSquareXmark} color='#ff5959' style={{cursor: 'pointer', marginLeft: 5}} fontSize={40} onClick={() => {
                setShowCompetitorsInput(false);
                setNewCompetitor('');
                setIsDomainInValid(false);
              }}/>
            </Tooltip>

          </AddCompetitorsContainer> : null}
          <CompetitorsListSection>
            {data?.competitors?.map((competitor, idx) => {
              return <SingleCompetitor key={idx} isSelected={selectedCompetitors?.filter(item => item == competitor).length > 0} onClick={() => onCompetitorClick(competitor)}>
                <CompetitorIconStyled onError={e => addDefaultSrc(e)} src={`https://www.google.com/s2/favicons?sz=64&domain_url=https://www.${getDomain(competitor)}`} alt={competitor}/>
                {competitor}
                <FontAwesomeIcon icon={faCircleCheck} className='competitorCheckIcon' color='#E8E8E8'/>
              </SingleCompetitor>;
            })}
            <AddMoreCompetitorsMoreBtn onClick={() => setShowCompetitorsInput(!showCompetitorsInput)}>+ Add manually</AddMoreCompetitorsMoreBtn>
          </CompetitorsListSection>
        </SectionContent>
      </SingleSectionContainer>
      {/* <SingleSectionContainer style={{marginBottom: 0}}>
        <IconContainer>
          <FontAwesomeIcon icon={faGoogle} color='#121212' style={{height: 20}}/>
        </IconContainer>
        <SectionContent>
          <SectionTitle>Analyze GSC Data</SectionTitle>
          <SectionSubtitle style={{lineHeight: '18px', width: '80%'}}>Connect your Google Search Console account to  get more detailed reports and recommendations on improving your site.</SectionSubtitle>
        </SectionContent>
        <GscToggle>
          <Switch onChange={onGscToggle}/>
        </GscToggle>
      </SingleSectionContainer> */}
      {/* COMPETITORS SECTION END */}
    </ScrollableContainer>
  </Container>;
};
const CountryDropdownStyled = styled(CountryDropdown)`
  margin-right: auto;
  margin-left: 20px;
  margin-top: 0px;

  .ant-select-selector {
    border: 1px solid transparent !important;
    padding: 0px !important;
    min-width: 140px !important;
    width: 140px !important;
    height: 20px !important;
  }

  .ant-select-selection-item {
    div {
      line-height: 20px;
    }
  }

  .ant-select-selection-search {
    top: -6px;
    input {
      height: 20px;
    }
  }
`;
const Container = styled.div`

`;
const ScrollableContainer = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: auto;

  // For Internet Explorer
    scrollbar-face-color: rgba(138,138,138,0.32);
    scrollbar-track-color: rgba(138,138,138,0.12);

    // For Google Chrome
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
`;
const SingleSectionContainer = styled.div`
  display: flex;
  margin-bottom: 25px;

  @media screen and (max-height: 800px) {
    margin-bottom: 18px;
  }
`;
const SectionContent = styled.div`
  flex: 1;
`;
const DomainInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Domain = styled.div`
  color: #2D6CCA;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const DomainInfo = styled.div`
`;

const ButtonStyled = styled(Button)`
  margin: 0px !important;
  padding: 0px !important;
  color: #A3A4A4;

  &:hover {
    background-color: transparent !important;
    color: #A3A4A4;
  }
`;

const MainTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  height: 25px;
`;
const SectionTitle = styled.p`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
`;
const SectionSubtitle = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #4E5156;
  margin-bottom: 8px;
`;
const IconContainer = styled.div`
  margin-right: 16px;
  margin-top: 10px;
`;
const IconStyled = styled.img`
  width: 26px;
  border-radius: 4px;
`;
const CompetitorIconStyled = styled.img`
  width: 20px;
  margin-right: 5px;
  margin-left: 5px;
`;

const KeywordsListSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
`;

const SingleKeyword = styled.div<{isSelected?: boolean}>`
  color: ${p => p.isSelected ? '#54297D' : '#4E5156'};
  border: ${p => p.isSelected ? '1px solid transparent': '1px solid #E8E8E8'};
  background-color: ${p => p.isSelected ? 'rgba(127, 78, 173, 0.15)' : 'transparent'};
  border-radius: 38px;
  padding: 3px 10px;
  margin: 3px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .2s ease;

  &:hover {
    color: #54297D;
    border: 1px solid transparent;
    background-color: rgba(127, 78, 173, 0.15);
  }
`;


const SuggestMoreBtn = styled.div`
  color: #2D6CCA;
  font-size: 12px;
  cursor: pointer;
  margin: 3px;
  display: flex;
  align-items: center;
`;
const AddMoreCompetitorsMoreBtn = styled.div`
  color: #2D6CCA;
  font-size: 12px;
  cursor: pointer;
  margin: 3px;
  display: flex;
  align-items: center;
`;

const CompetitorsListSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SingleCompetitor = styled.div<{isSelected?: boolean}>`
  color: ${p => p.isSelected ? '#54297D' : '#4E5156'};
  border: ${p => p.isSelected ? '1px solid transparent': '1px solid #E8E8E8'};
  background-color: ${p => p.isSelected ? 'rgba(127, 78, 173, 0.15)' : '#F9F9FB'};
  border-radius: 38px;
  padding: 5px 6px;
  margin: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all .2s ease;


  .competitorCheckIcon {
    color: ${p => p.isSelected ? '#7F4EAD' : 'transparent' };
    font-size: 18px;
    margin-left: 5px;
  }

  &:hover {
    color: #54297D;
    border: 1px solid transparent;
    background-color: rgba(127, 78, 173, 0.15);

    .competitorCheckIcon {
      color: #7F4EAD;
    }
  }
`;

const AddCompetitorsContainer = styled.div`
  display: flex;
`;

const InputStyled = styled(Input)`
  border-radius: 5px !important;
  // border: 1px solid #E8E8E8 !important;
  margin-bottom: 5px;
  background-color: #f2f2f5 !important;

  &:focus {
  // border-color: transparent !important;
  box-shadow: none !important;
  }
`;

// const GscToggle = styled.div`
//   padding-top: 10px;
//   // .ant-switch {
//   //   height: 30px;
//   //   width: 57px;
//   // }
//   // .ant-switch-handle {
//   //   height: 27px;
//   //   width: 27px;
//   //   border-radius: 100%;
//   // }
//   .ant-switch-checked {
//     background: #1FAC47;
//   }
// `;
