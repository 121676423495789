import {Modal} from '@/components/common-components';
import stylesGlobal from '@/components/dashboard/gsc/styles.module.scss';
import {toJS} from 'mobx';
import {FilterInput, Typography, Table} from '@/components/common-components/components';
import {CommonInput} from '@/components/common-components/components/input';
import {Textarea} from '@/components/common-components/components/form/input/input';
import ButtonComponent from '@/components/common-components/components/button-component';
import {RingLoaderSmall} from '@/components/common-components/components/skeleton/ring-loader-small';
import {useStore} from '@/store/root-store';
import {LoadingOutlined, MoreOutlined} from '@ant-design/icons';
import {Divider, Dropdown, Menu, Select} from 'antd';
import stylesLocal from './styles.module.scss';
import {SETTINGS_ADDING_NEW_TEAM_MEMBER} from '@/constants/events';
import {observer} from 'mobx-react';
import moment from 'moment';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import styles from './styles.module.scss';
import {MixPanel} from '@/utils/mixpanel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/pro-solid-svg-icons';
import {useTranslation} from 'next-i18next';
import {planApi, profileApi} from '@/api/account';
import {notification, useErrorNotification} from '@/utils/notification-v2';
import classnames from 'classnames';
import {removeParameterFromUrl} from '@/utils/url';
import {useRouter} from 'next/router';
import {getTokenFromCookies} from '@/api/common-utils';
import {numberWithCommas} from '@/utils/number';
import {Button} from '@/components/common-components/v2/Button';
import {apiError} from '@/utils/api';

interface Props {
  showCustomerModal?: boolean;
  setShowCustomerModal?: any;
}

type InviteMemberDTO = {
  emails: string;
  message: string;
  role?: string;
  customer?: string;
};

export const CustomerSwitcherModal = observer(({showCustomerModal, setShowCustomerModal}: Props) => {
  const {t} = useTranslation('common');
  const {
    settings: {
      customer: {
        customerPlanOptions,
        customerData,
        deleteCustomer,
        getCustomersData,
        downgradeCustomer,
        customerSwitcherLoading,
        customerSwitcherJwtLoading,
      },
    },
  } = useStore('');
  const [id, setId] = useState(null);
  const [deleteAccountId, setDeleteAccountId] = useState(null);
  // const [errorMsg, setErrorMsg] = useState<string>('');
  const [message, setMessage] = useState('');
  const [inviteLoading, setInviteLoading] = useState(false);
  const [invitationEmail, setInvitationEmail] = useState('');
  const [stepOne, setStepOne] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [emailError, setEmailError] = useState('');
  const [tableData, setTableData] = useState(toJS(customerData));

  const [query, setQuery] = useState('');
  useEffect(() => {
    setTableData(toJS(customerData));
  }, [JSON.stringify(customerData)]);

  const [payloadObj, setPayloadObj]=useState({
    emails: '',
    message: '',
    role: '',
    customer: '',

  });
  const router=useRouter();

  useEffect(() => {
    const token =getTokenFromCookies();
    if (token) {
      getCustomersData(true);
    }
  }, []);

  useEffect(() => {
    setTableData(toJS(customerData));
  }, [JSON.stringify(customerData)]);

  useEffect(() => {
    const token = getTokenFromCookies();
    if (token) {
      getCustomersData(false);
      setQuery('');
    }
    setInvitationEmail('');
    setMessage('');
  }, [stepOne]);

  const {
    settings: {teamMembers: {setFromResponse,
      getFilteredData}, customer: {profile: {getMaxNumberOfSeats}}},
  } = useStore('');


  const inviteTeamMember = async (email: string, message: string, role?: string, customerId?: string) => {
    setInviteLoading(true);
    const payload:InviteMemberDTO = {
      emails: email,
      message,
      role: role,
      customer: customerId,
    };

    try {
      await profileApi.inviteMember(payload);
      setFromResponse();
      // setErrorMsg('');
      notification.success('Invite Successfully Sent', 'Invited team members will be marked as Active when they confirm the invitation.', 'Close notification');
      setStepOne(true);
    } catch (e) {
      const errorMessage = apiError(e) as string;
      if (e.response?.status === 400 && e.response?.data?.emails) {
        setEmailError(errorMessage);
        // setErrorMsg(e.response?.data?.emails[0]);
      } else {
        // setErrorMsg('');
        notification.error('Invitation Failed', 'An error occured when sending the invitation. Please try again.', false, 'Close notification');
      }
    } finally {
      setInviteLoading(false);
    }
  };

  const changeValue = value => {
    setInvitationEmail(value);
  };
  useEffect(()=>{
    const filteredData=toJS(customerData)?.filter(d => d?.emailAddress?.includes(query));
    setTableData(filteredData as any);
  }, [query]);
  const handleSearchKeyword =value=>{
    setQuery(value);
  };
  const setCloseModal = () => {
    setShowCustomerModal(false);
    const {style} = document.body;
    style.cssText = `width: calc(100% - 0px); overflow: visible;`;
    const tempUrl = removeParameterFromUrl(location.href, 'customerSwitcherModal');
    router.push(
      tempUrl,
      undefined,
      {shallow: true},
    );
  };

  const openShareModal = () => {
    return (

      <div style={{paddingTop: '25px'}}>
        <div style={{display: 'flex', alignItems: 'baseline', paddingBottom: '15px', paddingLeft: '30px'}}>
          <img style={{marginRight: 10, marginTop: -2, cursor: 'pointer'}} src='/img/Vector2.svg' onClick={() => {
            setStepOne(true);
            // setErrorMsg('');
            setEmailError('');
          }} />
          <div>
            <span style={{fontSize: '20px', fontWeight: 600}}>{modalTitle}</span>
            <p style={{fontWeight: '500px'}}>
              to &nbsp;
              {typeof window !== 'undefined' && <a href={location?.origin} target='_blank' rel='noreferrer'>
                {location?.origin}
              </a>}
            </p>
          </div>
        </div>
        {/* <div className={stylesLocal.errorBannerContainer}>
          {errorMsg && <div className={stylesLocal.errorBanner}>
            <span>{errorMsg}</span>
          </div>}
        </div> */}
        <div className={stylesLocal.ContentDiv}>
          <Typography className={stylesLocal.Title} tag='h5'>{t('recipients')} <span>{t('user-seats')} {getFilteredData.length} / {getMaxNumberOfSeats}</span></Typography>
          <CommonInput className={stylesLocal.CommonInput} placeholder={t('email')} value={payloadObj?.emails || invitationEmail} onChange={e => {
            changeValue(e.target.value);
            setEmailError('');
          }} iconClasses={stylesLocal.InputIcon}
          />
          <ErrorText>{emailError && emailError}</ErrorText>
        </div>
        <div className={stylesLocal.FooterDiv}>
          <Typography className={stylesLocal.Title} tag='h5'>{t('message-optional')}</Typography>
          <div className={stylesLocal.FooterFlex}>
            <Textarea className={stylesLocal.Textarea} placeholder={t('enter-your-message')} value={message} onChange={e => setMessage(e.target.value)} />
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', borderTop: '1px solid #E8E8E8', paddingTop: '20px'}}>
            <ButtonComponent
              style='light-transparent'
              onClick={() => {
                // setErrorMsg('');
                setCloseModal();
                setEmailError('');
              }} className={stylesLocal.BtnCancel} disabled={false} >
              <a style={{color: '#4E5156'}}>{t('cancel')}</a>
            </ButtonComponent>

            <ButtonComponent disabled={inviteLoading} className={stylesLocal.Btn} style='solid' color='primary' >
              <a style={{color: '#fff'}} onClick={() => {
                inviteTeamMember(payloadObj?.emails || invitationEmail, payloadObj?.message || message, payloadObj?.role, payloadObj?.customer);

                MixPanel.track(SETTINGS_ADDING_NEW_TEAM_MEMBER, {
                  'addingNewTeamMember': `${invitationEmail}`,
                });
              }}>{inviteLoading ? <FontAwesomeIcon className={'fa-spin'} icon={faCircleNotch} /> : t('invite')}</a>
            </ButtonComponent>
          </div>
        </div>
      </div>
    );
  };
  const onView = async id => {
    window.open(`${location.origin}/home/?customerId=${id}`, '_blank');
  };
  const [showDropDown, setShowDropDown] = useState(false);
  const columns = [
    {
      title: () => <ColumnTitle>USER</ColumnTitle>,
      key: 'emailAddress',
      dataIndex: 'emailAddress',
      // width: 250,
      fixed: 'left' as 'left',
      render: (_, data) => {
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span>{data?.emailAddress}</span> <span>{deleteAccountId == data?.id && <RingLoaderSmall height='1px' />}</span>
          </div>
        );
      },
    },
    {
      title: () => <ColumnTitle style={{textAlign: 'center'}}>PLAN</ColumnTitle>,
      key: 'plan',
      dataIndex: 'plan',
      // width: 150,
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.plan - b.plan,
      render: (_, data) => {
        return (
          <div style={{display: 'flex', marginRight: 'auto'}}>
            <SelectStyled
              dropdownMatchSelectWidth={false}
              dropdownStyle={{textTransform: 'capitalize'}}
              defaultValue={data?.isPlanActive ? data?.plan : 'Disabled'}
              disabled={!data?.isPlanActive}
              options={customerPlanOptions?.map(plan => {
                return {value: plan.id, label: plan.name?.toLowerCase()};
              })}
              onChange={value => {
                try {
                  planApi.updateCustomerPlan(data.id, value);
                } catch (e) {
                  useErrorNotification({e, msg: 'Customer plan change failed', desc: 'Please try again later.', permanent: false});
                }
              }}/>
          </div>
        );
      },
    },
    {
      title: () => <ColumnTitle style={{textAlign: 'center'}}>TIER</ColumnTitle>,
      key: 'serviceTier',
      dataIndex: 'serviceTier',
      // width: 150,
      align: 'center' as 'center',
      sorter: (a: any, b: any) => a.serviceTier - b.serviceTier,
      render: (_, data) => {
        return (
          <div>{data?.serviceTier ?? ''}</div>);
      },
    },
    {
      title: () => <ColumnTitle style={{textAlign: 'right', paddingLeft: '15px'}}>REGISTERED</ColumnTitle>,
      key: 'created',
      // width: 160,
      dataIndex: 'created',
      align: 'center' as 'center',
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      render: (_, data) => {
        return (<div>{moment(data?.created).format('MMM DD,YYYY')}</div>);
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      // width: 100,
      align: 'right' as 'right',
      render: (_, data) => {
        return <div style={{display: 'flex', alignItems: 'center', marginLeft: 6}}>
          <StyledButton color='gray' onClick={() => {
            onView(data?.id);
            setId(data?.id);
          }} >{(customerSwitcherJwtLoading && id == data?.id) && <LoadingOutlined style={{fontSize: '14px', marginRight: '10px', color: 'e5e5e5'}} spin />}Switch</StyledButton>
        </div>;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      // width: 100,
      align: 'right' as 'right',
      render: (_, data) => {
        return <div className={styles.dropdownStyles}>
          <Dropdown trigger={['click']} overlay={
            <Menu style={{display: showDropDown ? 'block' : 'none', borderRadius: 10}}>
              <div style={{display: 'flex', flexDirection: 'column', cursor: 'pointer'}}>
                {/* <span style={{padding: 8}}
                  onClick={async e => {
                    e.stopPropagation();
                    setErrorMsg('');
                    setPayloadObj(
                      {
                        emails: data?.emailAddress,
                        message: '',
                        role: data?.whitelabelDomain ? 'team_member' : 'agency',
                        customer: String(data?.id),
                      },
                    );
                    setStepOne(false);
                    setModalTitle(`Invite ${(data?.whitelabelDomain) ? 'team member' : 'new agency'}`);
                  }}
                >
                  {data?.whitelabelDomain ? 'Invite a team member' : 'Invite user to agency'}
                </span> */}
                <span style={{padding: '8px 15px'}}
                  onClick={async e => {
                    e.stopPropagation();
                    // setErrorMsg('');
                    await downgradeCustomer(data?.id);
                    getCustomersData(false);
                    setTimeout(() => {
                      setShowDropDown(false);
                    }, 800);
                  }}>
                  {'Downgrade to Free'}
                </span>
                <Divider style={{marginTop: 0, marginBottom: 0}}/>
                <span style={{padding: '8px 15px', color: '#F44343'}}
                  onClick={async e => {
                    e.stopPropagation();
                    // setErrorMsg('');
                    await deleteCustomer(data?.id);
                    getCustomersData(false);
                    setDeleteAccountId(data?.id);
                    setTimeout(() => {
                      setShowDropDown(false);
                    }, 800);
                  }}
                >
                  {'Remove account'}
                </span>
              </div>

            </Menu>} placement='bottomRight'
          ><span style={{fontSize: '16px', cursor: 'pointer'}}
              onClick={() => {
                setShowDropDown(true);
              }}
            ><span className={styles.removeStyle}>
                <MoreOutlined />
              </span>
            </span>
          </Dropdown>
        </div>;
      },
    },

  ];
  return <ModalStyled
    visible={showCustomerModal}
    onClose={() => setCloseModal()}
  // closeIcon={<FontAwesomeIcon icon={faTimes} color={'#ffffff'}  style={{fontSize: '16px', color: '#fff', right:-42, top:-28, position:"absolute"}}/>}
  >
    <Content>
      {stepOne ? <>
        <ModalHeader>
          <div>
            <ModalTitleText>{toJS(customerData)?.length > 1 ? 'Customers' : 'Customer'} : {numberWithCommas(toJS(customerData)?.length > 0 ? tableData?.length : 0)}</ModalTitleText>
            <p style={{fontWeight: '500px'}}>
              registered with &nbsp;
              {typeof window !== 'undefined' && <a href={location?.origin} target='_blank' rel='noreferrer'>
                {location?.origin}
              </a>}
            </p>
          </div>
          <Button
            onClick={async e => {
              e.stopPropagation();
              setPayloadObj(
                {
                  emails: '',
                  message: '',
                  role: 'agency',
                  customer: '',
                },
              );
              setStepOne(false);
              setModalTitle('Invite new customer');
            }}
            color={'purple'}
            mode={'light'}
            variant={'ghost'}
            style={{height: '32px', cursor: 'default'}}
          >
            + Invite new customer
          </Button>
        </ModalHeader>
        <div className={classnames(stylesGlobal.tableOuterPages, styles.tableContainer)} >
          <FilterInputStyled>
            <FilterInput
              newFilterUI={true}
              searchPlaceHolder='Search by email...'
              onKeywordChange={handleSearchKeyword}
            // initialList={initialList}
            // mainFilters={activatedFilters ? activatedFilters : initialList}
            // onChange={onFiltersChange}
            // keywordEmpty={query?.length == 0 ? true : false}
            />
          </FilterInputStyled>
          <Table
            columns={columns}
            loading={customerSwitcherLoading}
            pagination={false}
            dataSource={tableData}
            loadingRows={30}
          />
        </div></> : openShareModal()}

    </Content>

  </ModalStyled>;
});
const SelectStyled = styled(Select)`
  .ant-select-selector  {
    background: #f7f7f8 !important;
    border-radius: 5px !important;
    color: #4e5156;
    text-transform: capitalize !important;
  }
`;

const ModalStyled = styled(Modal)`
   margin-top: auto;
   margin-bottom: auto;
   width: 800px !important;
   padding-bottom: 20px;
`;

const Content = styled.div`
  
`;

const ColumnTitle = styled.div`
  color: #121212;
  font-size: 12px;
  font-weight: bold;
  line-height: 14px;
  text-transform: uppercase;
`;


const StyledButton = styled(ButtonComponent)`
height: 32px !important;
`;

const ModalTitleText = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #121212;
  margin-top: 15px;
`;


const FilterInputStyled = styled.div`
  height: auto !important;  
  .common-component-input {
    background-color: #fff !important;
    &::placeholder {
      background-color: #fff !important;
    }
  }`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ErrorText = styled.p`
color:#f44343;
font-size: 12px;
margin-top: 5px;
`;
