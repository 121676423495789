import {getAuthorizationHeader} from '@/api/common-utils';
import axios from 'axios';
import {notification as notify} from '../notification-v2';
import {notification} from '../notifications';
import {startCase} from 'lodash';

export const apiError = (error, message = '', showFieldName = false) => {
  try {
    if (!Array.isArray(error.response?.data) && typeof error?.response?.data == 'object') {
      let apiError;
      if (showFieldName) {
        apiError = Object.entries(error.response?.data).map(item => (startCase(item[0])+ ' : ' + item[1]));
      } else {
        apiError = Object.values(error.response?.data);
      }
      apiError = apiError.flat();
      const fieldError = apiError[0];
      if (typeof fieldError == 'object') {
        let secondObj = Object.values(fieldError);
        secondObj = secondObj.flat();
        const secondObjFieldError = secondObj[0];
        return secondObjFieldError;
      } else {
        return fieldError;
      }
    }
    if (Array.isArray(error.response?.data) && typeof error?.response?.data == 'object') {
      const apiError = error.response?.data.flat();
      const fieldError = apiError[0];
      return fieldError;
    }
    if (error?.message) {
      return error?.message;
    }
    if (error?.response?.status === 401) {
      return false;
    }
    return message || 'Something went wrong';
  } catch (error) {
    return message || 'Something went wrong';
  }
};

export const downloadExportFile = async (exportUrl, exportName?: string, googleSheet?:boolean, googleSheetParam?:string) => {
  if (exportUrl) {
    await axios({
      method: 'get',
      url: `${exportUrl}`,
      responseType: !googleSheet? 'blob': 'json',
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
      .then(response => {
        if (!googleSheet) {
          // eslint-disable-next-line no-useless-escape
          const filename = exportName || `${response?.headers ? response?.headers['content-disposition']?.split('filename=')[1]?.replaceAll('\"', '') : ''}` || 'export';
          const blob = new File([response.data], filename);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          if (response?.status == 200 && response?.data) {
            window.open(`${response?.data[googleSheetParam? googleSheetParam: 'export_url']}`);
          }
        }
      });
  }
};

export const downloadExportFileV2 = async (exportUrl, exportName?: string, googleSheet?:boolean, googleSheetParam?:string) => {
  if (exportUrl) {
    await axios({
      method: 'get',
      url: `${exportUrl}`,
      responseType: !googleSheet? 'blob': 'json',
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
      .then(response => {
        if (!googleSheet) {
          // eslint-disable-next-line no-useless-escape
          const filename = exportName || `${response?.headers ? response?.headers['content-disposition']?.split('filename=')[1]?.replaceAll('\"', '') : ''}` || 'export';
          const blob = new File([response.data], filename);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          if (response?.status == 200 && response?.data) {
            window.open(`${response?.data[googleSheetParam? googleSheetParam: 'export_url']}`);
          }
        }
      });
  }
};

export const downloadExportFileByPostAPI = async (exportUrl, data, exportName?: string) => {
  if (exportUrl) {
    await axios({
      method: 'post',
      url: `${exportUrl}`,
      data: data,
      responseType: 'blob',
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    })
      .then(response => {
        // const currentDate = new Date();
        const filename = exportName || `${response?.headers ? response?.headers['content-disposition']?.split('filename=')[1] : ''}` || 'export';
        const blob = new File([response.data], filename);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
};

export const genericError = error => {
  if (error?.networkError) {
    console.error('Network error');
  } else {
    if (error?.response?.status == 429) {
      notify.error('Quota Exceeded', 'Quota limit exceeded');
    } else if (error?.response?.status >= 500 && error?.response?.status < 600) {
      notify.error('Sorry', 'There was a server error. Please refresh the page');
    } else {
      const errorMessage = apiError(error) as string;
      notify.error('', errorMessage || 'Something went wrong please try again', false, 'OK');
    }
  }
};

export const isNetworkError = (error, message, returnPromise = false) => {
  if (error?.code === 'ERR_NETWORK') {
    notify.info('Network Connectivity Problem Detected:', 'You may be experiencing a network connectivity problem or no network connection is available.');
  } else if (returnPromise) {
    Promise.reject(error);
  } else {
    notification.info(message || 'Something went wrong please try again');
  }
};

const getErrorMessage = errorData => {
  // eslint-disable-next-line guard-for-in
  for (const key in errorData) {
    const value = errorData[key];
    if (Array.isArray(value) && value.length > 0) {
      return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value[0]}`;
    }
    if (typeof value === 'object') {
      const nestedError = getErrorMessage(value);
      if (nestedError) {
        return nestedError;
      }
    }
  }
  return null;
};

export const customapiError = (error, message = '') => {
  try {
    if (typeof error === 'string') {
      return error;
    }
    if (error.response && error.response.data) {
      const responseData = error.response.data;
      const errorMessage = getErrorMessage(responseData);
      if (errorMessage) {
        return errorMessage;
      }
      if (error.response.status === 400) {
        return responseData;
      }
    }
    if (error.message) {
      return error.message;
    }
    if (error.response && error.response.status === 401) {
      return false;
    }
    return message || 'Something went wrong';
  } catch (error) {
    return message || 'Something went wrong';
  }
};
