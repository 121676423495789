
import {useStore} from '@/store/root-store';
import {Drawer} from 'antd';
import {observer} from 'mobx-react';
import {useRef} from 'react';
import styles from './style.module.scss';
import {ToolsSection} from './toolsView';
import {FormsView} from './formViews';
import {toJS} from 'mobx';

interface Props {
  htmlToDraft: any;
  elapsedTime: number;
  setElapsedTime?: (value: number) => void;
}
export const AiWriterSideDrawer = observer(({htmlToDraft, elapsedTime, setElapsedTime}: Props) => {
  const {contentOptimizer: {
    aiOutline: {
      isOpenAiWriterDrawer,
      setIsOpenAiWriterDrawer,
      setAiTemplate,
      aiTemplatesController,
      getAiTemplatesList,
      // getUserAiSettings,
      aiTemplates,
      aiWriterController,
      setAiArticleAlreadyReplaced,
      setCreatingAiArticleSuccessStatus,
      setCreatingAiArticleFailureStatus,
      setShouldOverwriteContent,
      setShouldOverwriteContentSuccessMessage,
      setAiWriter,
      clearOneClickOutlines,
    },
  },
  } = useStore('');
  const drawerRef = useRef(null);

  const handleModalVisibiltyChange = async visible => {
    if (visible) {
      if (aiTemplatesController?.isStandaloneTool) {
        // adding close on backdrop click
        const getClientCoord = e => {
          return {
            x: e.clientX,
            y: e.clientY,
          };
        };

        document.querySelector('.ant-drawer-mask')?.addEventListener('click', event => {
          const {x, y} = getClientCoord(event);
          const elementMouseIsOver = document.elementFromPoint(x, y);

          if (drawerRef?.current && !elementMouseIsOver?.closest('.ant-drawer-content-wrapper')) {
            setIsOpenAiWriterDrawer(false);
          }
        });

        // returning so the rest of code doesnt execute
        return;
      }


      // every time drawer is closed it should set standalone boolean to false,
      // so it doesnt interfere when drawer is used inside sca editor
      // every tempalte click in standalone tool should set this boolean to true again
      setAiTemplate({
        ...aiTemplatesController,
        isStandaloneTool: false,
      });

      if (!aiTemplates?.templates?.length) await getAiTemplatesList();
      // get ai settings so we can prepopulate tone of voice in templates forms
      // getUserAiSettings();
    } else {
      setAiTemplate({
        ...aiTemplatesController,
        isUseMask: false,
      });
    }
  };

  const onClose = () => {
    setAiWriter({
      ...toJS(aiWriterController ?? []),
      showForm: false,
      templateIcon: '',
      templateDesc: '',
      templateName: '',
      isStandaloneTool: false,
    });
    clearOneClickOutlines();
    setIsOpenAiWriterDrawer(false);
    setAiArticleAlreadyReplaced(false);
    setCreatingAiArticleSuccessStatus(false);
    setCreatingAiArticleFailureStatus(false);
    setShouldOverwriteContent(true);
    setShouldOverwriteContentSuccessMessage(true);
  };

  return <Drawer
    ref={drawerRef}
    visible={isOpenAiWriterDrawer}
    afterVisibleChange={handleModalVisibiltyChange}
    onClose={onClose}
    // placement='right'
    destroyOnClose={true}
    mask={true}
    maskClosable={true}
    style={{maxWidth: '100vw'}}
    // when form is not visible we are not showing results, so drawer is narrower
    width={aiWriterController.showForm ? '1100px' : '540px'}
    closable={false}
    // bodyStyle={{backgroundColor: '#F2F2F5', paddingLeft: '0', paddingRight: '0'}}
    className={styles.AiWriterDrawer}
  >

    {!aiWriterController.showForm ? <ToolsSection /> : <FormsView htmlToDraft={htmlToDraft} elapsedTime={elapsedTime} setElapsedTime={setElapsedTime}/>}

  </Drawer>;
});
