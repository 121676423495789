export const languages = [
  {language: 'Automatic Detection', code: 'None'},
  {language: 'English (US)', code: 'en'},
  {language: 'English (UK)', code: 'en-gb'},
  {language: 'English (CA)', code: 'en-ca'},
  {language: 'Afrikaans', code: 'af'},
  {language: 'Albanian', code: 'sq'},
  {language: 'Amharic', code: 'am'},
  {language: 'Arabic', code: 'ar'},
  {language: 'Armenian', code: 'hy'},
  {language: 'Azerbaijani', code: 'az'},
  {language: 'Basque', code: 'eu'},
  {language: 'Belarusian', code: 'be'},
  {language: 'Bengali', code: 'bn'},
  {language: 'Bulgarian', code: 'bg'},
  {language: 'Catalan', code: 'ca'},
  {language: 'Chinese', code: 'zh-cn'},
  {language: 'Corsican', code: 'co'},
  {language: 'Croatian', code: 'hr'},
  {language: 'Czech', code: 'cs'},
  {language: 'Danish', code: 'da'},
  {language: 'Dutch', code: 'nl'},
  {language: 'Esperanto', code: 'eo'},
  {language: 'Estonian', code: 'et'},
  {language: 'Finnish', code: 'fi'},
  {language: 'French', code: 'fr'},
  {language: 'Galician', code: 'gl'},
  {language: 'Georgian', code: 'ka'},
  {language: 'German', code: 'de'},
  {language: 'Gujarati', code: 'gu'},
  {language: 'Haitian', code: 'ht'},
  {language: 'Hausa', code: 'ha'},
  {language: 'Hebrew', code: 'he'},
  {language: 'Hindi', code: 'hi'},
  {language: 'Hungarian', code: 'hu'},
  {language: 'Icelandic', code: 'is'},
  {language: 'Igbo', code: 'ig'},
  {language: 'Indonesian', code: 'id'},
  {language: 'Irish', code: 'ga'},
  {language: 'Italian', code: 'it'},
  {language: 'Japanese', code: 'ja'},
  {language: 'Javanese', code: 'jv'},
  {language: 'Kannada', code: 'kn'},
  {language: 'Kazakh', code: 'kk'},
  {language: 'Kinyarwanda', code: 'rw'},
  {language: 'Korean', code: 'ko'},
  {language: 'Kurdish', code: 'ku'},
  {language: 'Lao', code: 'lo'},
  {language: 'Latin', code: 'la'},
  {language: 'Latvian', code: 'lv'},
  {language: 'Lithuanian', code: 'lt'},
  {language: 'Luxembourgish', code: 'lb'},
  {language: 'Macedonian', code: 'mk'},
  {language: 'Malagasy', code: 'mg'},
  {language: 'Malayalam', code: 'ml'},
  {language: 'Maltese', code: 'mt'},
  {language: 'Maori', code: 'mi'},
  {language: 'Marathi', code: 'mr'},
  {language: 'Mongolian', code: 'mn'},
  {language: 'Norwegian', code: 'no'},
  {language: 'Persian', code: 'fa'},
  {language: 'Polish', code: 'pl'},
  {language: 'Portuguese', code: 'pt'},
  {language: 'Romanian', code: 'ro'},
  {language: 'Russian', code: 'ru'},
  {language: 'Samoan', code: 'sm'},
  {language: 'Serbian', code: 'sr'},
  {language: 'Shona', code: 'sn'},
  {language: 'Sindhi', code: 'sd'},
  {language: 'Sinhala', code: 'si'},
  {language: 'Slovak', code: 'sk'},
  {language: 'Slovenian', code: 'sl'},
  {language: 'Somali', code: 'so'},
  {language: 'Spanish', code: 'es'},
  {language: 'Sundanese', code: 'su'},
  {language: 'Swedish', code: 'sv'},
  {language: 'Taiwan', code: 'zh-tw'},
  {language: 'Tajik', code: 'tg'},
  {language: 'Tamil', code: 'ta'},
  {language: 'Tatar', code: 'tt'},
  {language: 'Telugu', code: 'te'},
  {language: 'Thai', code: 'th'},
  {language: 'Turkish', code: 'tr'},
  {language: 'Turkmen', code: 'tk'},
  {language: 'Ukrainian', code: 'uk'},
  {language: 'Urdu', code: 'ur'},
  {language: 'Uzbek', code: 'uz'},
  {language: 'Vietnamese', code: 'vi'},
  {language: 'Welsh', code: 'cy'},
  {language: 'Xhosa', code: 'xh'},
  {language: 'Yiddish', code: 'yi'},
  {language: 'Yoruba', code: 'yo'},
  {language: 'Zulu', code: 'zu'},
];

