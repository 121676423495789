import React, {useState, useEffect} from 'react';
import styles from '@/components/dashboard/billing/pricing-sidebar/style.module.scss';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {Drawer, Switch, Tooltip} from 'antd';
import {Button, ButtonLink} from '@/components/common-components';
import classnames from 'classnames';
import {useTranslation} from 'next-i18next';
import {getPricingSidebarArray} from '@/store/pricing/data';
import {routes} from '@/utils/const';
import {getChromeAppLink, getGrowBusinessLink} from '@/utils/legacy';
import {CloseOutlined} from '@ant-design/icons';
import {useRouter} from 'next/router';
import {CA_BASIC_PLAN_ID} from '@/constants';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faPlus} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import * as proRegular from '@fortawesome/pro-regular-svg-icons';
import {faInfo} from '@fortawesome/pro-solid-svg-icons';
// import {faBadgeCheck} from '@fortawesome/pro-duotone-svg-icons';
import {getLocation} from '@/utils/string';


export interface CheckoutFormProp {
  setStripe: any;
  setElements: any;
  setSelectedPlan: any;
}

export const PricingSidebar: React.FC = observer(() => {
  const {t: tPricingPlans} = useTranslation('common');
  const {t: tCommon} = useTranslation('common');
  const {t} = useTranslation('common');
  const router = useRouter();
  const {plans: {getPlansAndPrices, loadPlans}} = useStore('');
  const {settings: {customer: {profile: {isWhitelabel, priceStarterPlan, priceGrowthPlan, priceProPlan}}}, plans: {handlePayAnnual, payAnnualValue}} = useStore('');

  const plansList = JSON.parse(JSON.stringify(getPricingSidebarArray(tPricingPlans, isWhitelabel)));

  useEffect(() => {
    if (!getPlansAndPrices?.length) {
      loadPlans(tCommon, isWhitelabel);
    }
  }, [getPlansAndPrices?.length]);

  const {
    plans: {
      getSidebarPayment,
      hideSidebarPaymentDrawer,
      sideBarPaymentContent,
    }, settings: {customer: {profile}},
  } = useStore('');

  const [plansObj, setPlansObj] = useState({});

  useEffect(() => {
    const planObj = {};
    plansList.forEach((item, index) => {
      planObj[item.id] = index;
    });
    setPlansObj(planObj);
  }, []);


  const onClose = () => {
    hideSidebarPaymentDrawer();
  };

  const subscribePlan = () => {
    router.push(`/${routes.checkout}?plan_id=${CA_BASIC_PLAN_ID}${payAnnualValue ? '&pay_annually=1' : ''}`);
    hideSidebarPaymentDrawer();
  };
  // const getPromoCode = planId => {
  //   if (planId == 1) {
  //     return 'Growth50';
  //   } else if (planId == 8) {
  //     return 'Pro50';
  //   } else if (planId == 10) {
  //     return 'Starter50';
  //   } else {
  //     return '';
  //   }
  // };
  const onPricingBtnClick = planId => {
    router.push(`/${routes.checkout}?plan_id=${planId}${payAnnualValue ? '&pay_annually=1' : ''}`);

    hideSidebarPaymentDrawer();
  };

  const redirectToPlanComparison = () => {
    router.push({pathname: `/${routes.pricing}`, query: {plan_comparison: true}});
    hideSidebarPaymentDrawer();
  };

  const planPrice = (name: string) => {
    if (name === 'Starter') {
      return priceStarterPlan ? `$${priceStarterPlan}` : 'Call us';
    } else if (name === 'Growth') {
      return priceGrowthPlan ? `$${priceGrowthPlan}` : 'Call us';
    } else if (name === 'Pro') {
      return priceProPlan ? `$${priceProPlan}` : 'Call us';
    } else if (name === 'Enterprise') {
      return 'Call us';
    }
  };

  function replaceAll(inputString, searchValue, replaceValue) {
    if (inputString) {
      const escapedSearch = searchValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(escapedSearch, 'g');
      return inputString.replace(regex, replaceValue);
    } else {
      return '';
    }
  }

  return (
    <Drawer
      // style={{overflow: 'auto'}}
      placement='right'
      closable={true}
      onClose={onClose}
      visible={getSidebarPayment}
      width={1100}
      headerStyle={{background: '#fff'}}
      className={styles.upgradeDrawer + ' drawerCloseIcon'}
      closeIcon={<CloseOutlined className={'closeIcon'} style={getSidebarPayment ? {display: 'block'} : {display: 'none'}}/>}
      bodyStyle={{backgroundColor: '#F2F2F5', paddingTop: '11px'}}
    >
      <>
        <div style={{paddingTop: '20px', textAlign: 'center'}} >
          <div style={{alignItems: 'center'}}>
            <Switch
              checked={payAnnualValue}
              style={{background: payAnnualValue ? '#F1AA3E' : ''}}
              onChange={value => handlePayAnnual(value)}
            />&nbsp;&nbsp;Pay annually and <OfferText>GET 20% OFF
              <img src={'/icons/annual-offer.svg'} />
            </OfferText>
            <h2 style={{display: 'flex', justifyContent: 'center', paddingTop: '13px'}}>{sideBarPaymentContent?.title || 'Multiple cutting-edge tools. One simple price.'}</h2>
            <Paragraph >{!isWhitelabel && sideBarPaymentContent?.subtitle.includes('SearchAtlas') ? sideBarPaymentContent?.subtitle?.replace('SearchAtlas', getLocation(location)) :
              sideBarPaymentContent?.subtitle || `We’ve been developing the most advanced enterprise SEO software platform to give digital marketers every tool they need to execute SEO campaigns at scale. Get access to our full suite of tools with a ${!isWhitelabel ? 'SearchAtlas' : getLocation(location)} subscription.`}</Paragraph>
          </div>
          <p style={{display: 'flex', justifyContent: 'center', paddingTop: '9px'}}>{sideBarPaymentContent?.text}</p>
        </div>
        {/* <GuaranteeBanner>
          <FontAwesomeIcon icon={faBadgeCheck} style={{fontSize: 20, color: '#1FAC47', marginRight: 10}}/>
          <div>100% Money Back Guarantee if you’re not satisfied. No questions asked</div>
        </GuaranteeBanner> */}
        <div className={styles.content}>

          <div className={styles.planArea}>
            <div className={styles.planDetail}>
              { plansList?.length > 0 && (
                plansList?.map((item, index) =>{
                  const planCurrentPrice = getPlansAndPrices?.find(x => x?.planId == item?.id)?.price || '';
                  const planAnnualPrice = getPlansAndPrices?.find(x => x?.planId == item?.id)?.yearlyCost || '';
                  return <div key={index} className={classnames(index==1 ? styles.planCardSecond : styles.planCard)}>
                    <div className={styles.planBox}>
                      <div className={index==1 ? '' : styles.cardIcon} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <FontAwesomeIcon icon={proRegular[`${item?.icon}`]} style={{color: `${index==1 ? '#fff' : ' #4E5156'}`}}/>
                        {item?.name?.toLowerCase() == 'pro' && <BestValue className={styles.popular} >Best Value</BestValue>}

                      </div>
                      <div className={styles.planName}>
                        {item.name}
                      </div>
                      <p className={styles.planDescription}>{item.description}</p>
                      {item?.price == 'Custom'? <p style={{marginTop: '15px'}} className={styles.planPrice}> Call Us </p> :
                        <>
                          <DelText>{item?.oldPrice}</DelText>
                          <p className={styles.planPrice} style={{display: 'flex', alignItems: payAnnualValue ? 'center' : 'baseline', gap: '6px'}}>
                            {!isWhitelabel ? payAnnualValue ? planAnnualPrice : planCurrentPrice: planPrice(item.name)}
                            {!item.customPlan && <StyledSpan
                              style={{
                                display: item.name === 'Enterprise' || planPrice(item.name) === 'Call us' ? 'none':'inline-block',
                                width: payAnnualValue ? '100px' : 'auto'}}>
                              {` `}
                              {payAnnualValue ? 'per month / billed annually' : t('month')}
                            </StyledSpan>} </p>
                        </>
                      }
                      <div className={styles.planUpgradeButtonSection}>
                        {!item.customPlan ? (
                          <Button onClick={() => {
                            item.id !== profile.plan && onPricingBtnClick(item.id);
                          }}
                          disabled={item.id === profile.plan}
                          className={classnames(styles.planUpgradeButton, item.id === profile.plan ? styles.disabledButton : '')}>
                            {item.id === profile.plan ? 'Current plan' : plansObj[item.id] < plansObj[profile.plan] ? item.previousButtonText : item.buttonText }
                          </Button>
                        ) : (
                          <ButtonLink
                            disabled={isWhitelabel}
                            href={getGrowBusinessLink()}
                            target='_blank'
                            className={classnames(styles.planUpgradeButton, item.activeButton ? styles.activeBtn : '')}
                            rel='noreferrer'>
                            {item.buttonText}
                          </ButtonLink>

                        )}


                      </div>
                      {!isWhitelabel && <div className={styles.planIconArea}>
                        <span style={{marginRight: '5px'}}>
                          <img
                            style={{width: '10px', height: '8px'}}
                            src='/icons/checkSign.svg' alt=''/>
                        </span>
                        <span className={styles.planIconArea}>
                          <div className={styles.planProviderIcon}>
                            <a href={getChromeAppLink()} rel='noreferrer' target='_blank'>
                              <img
                                style={{width: '14px', height: '14px'}}
                                src='/icons/chrome.svg' alt=''/></a>
                          </div>
                          <IconsInfo>
                            {item?.iconsTooltipText ? <Tooltip zIndex={99991} title={isWhitelabel ? replaceAll(item?.iconsTooltipText, 'SearchAtlas', getLocation(location)) : item?.iconsTooltipText} color='#121212' overlayInnerStyle={{borderRadius: '6px', maxWidth: '250px', fontSize: '12px'}}>
                              <FontAwesomeIcon icon={faInfo} style={{cursor: 'pointer', fontSize: '13px'}} color={index==1 ? '' : '#4e5156'}/>
                            </Tooltip> : ''}
                          </IconsInfo>
                        </span>
                      </div>}

                      {item?.checkList.map((value, idx) => {
                        if (value?.name) {
                          return (
                            <div className={styles.planIconArea}>
                              <span style={{marginRight: '5px'}}>
                                {value?.iconName == 'faCheck' ? <img
                                  style={{width: '10px', height: '8px'}}
                                  src='/icons/checkSign.svg' alt=''/> :
                                  <FontAwesomeIcon icon={faPlus} color={index == 1 ? '#F1AA3E' : value?.iconColor}/>}
                              </span>
                              <span className={styles.planDescriptionText} style={{paddingRight: '10px', padding: value?.background ? 8 : 0, background: value?.background && '#431d66', color: value?.background && '#fff', borderRadius: value?.background ? 8 : 0}}>
                                <span style={idx == 0 && item?.name !== 'Enterprise' ? {textDecorationLine: 'underline', fontWeight: 700} : {}}>{value?.name}</span>
                                {value?.infoTooltip ? <Tooltip zIndex={99991} title={isWhitelabel ? replaceAll(value?.infoTooltip, 'SearchAtlas', getLocation(location)) : value?.infoTooltip} color='#121212' overlayInnerStyle={{borderRadius: '6px', maxWidth: '250px', fontSize: '12px'}}>
                                  <FontAwesomeIcon icon={faInfo} style={{marginLeft: '5px', cursor: 'pointer'}} />
                                </Tooltip> : ''}
                              </span>
                              {/* {value.comingSoon && <span className={styles.comingSoon}>Coming Soon</span>} */}
                            </div>
                          );
                        }
                      })}
                    </div>

                  </div>;
                })
              )}

            </div>
          </div>

          <div className={styles.additionalOption}>
            <div className={styles.heading}>
              <h2>Additional options</h2>
              {!isWhitelabel && <span>See <a onClick={() => {
                redirectToPlanComparison();
              }}>full plan comparison</a> or request a <ButtonLink href={getGrowBusinessLink()} target='_blank'>Customized plan</ButtonLink></span>}
            </div>
            <div className={styles.footerSection}>
              <div className={styles.sectionBox1}>
                <h2>{tCommon('seo-content-assistant-plan')}</h2>
                <p style={{marginBottom: 0}}>{tCommon('only-need-access-to-writing-tools')}</p>
                <p>{tCommon('our-sca-only-plan-includes')}</p>
                <div style={{display: 'flex'}}>
                  <div className={styles.scaPlanIconArea}>
                    <span style={{marginRight: '5px'}}>
                      <FontAwesomeIcon icon={faCheck} style={{color: '#1FAC47'}}/>
                    </span>
                    <span className={styles.scaPlanDescriptionText}>
                      {tCommon('sca-only-pages-articles')}
                    </span>
                  </div>
                  <div className={styles.scaPlanIconArea} style={{marginLeft: 32}}>
                    <span style={{marginRight: '5px'}}>
                      <FontAwesomeIcon icon={faCheck} style={{color: '#1FAC47'}}/>
                    </span>
                    <span className={styles.scaPlanDescriptionText}>
                      {tCommon('sca-only-ai-quota')}
                    </span>
                  </div>
                </div>
                <div className={styles.scaPlanIconArea} style={{marginBottom: 10}}>
                  <span style={{marginRight: '5px'}}>
                    <FontAwesomeIcon icon={faCheck} style={{color: '#1FAC47'}}/>
                  </span>
                  <span className={styles.scaPlanDescriptionText}>
                    {tCommon('sca-only-content-assistant-keywords')}
                  </span>
                </div>
                {!isWhitelabel &&
                  <Button
                    onClick={() => subscribePlan()}
                  >
                    {tCommon('subscribe-for-49-month')}
                  </Button>}
                <img src='/img/dashboard/landing-page-optimizer/writing.svg'/>
              </div>
              <div className={styles.sectionBox2}>
                <h2>{tCommon('schedule-a-demo-with-us')}</h2>
                <p>{tCommon('not-sure-where-to-start')}</p>
                {!isWhitelabel && <ButtonLink href={getGrowBusinessLink()}>{tCommon('schedule-a-demo')}</ButtonLink>}
                <img src='/img/dashboard/gsc/insights-tool.svg'/>
              </div>
            </div>
          </div>
        </div>
      </>
    </Drawer>
  );
});

const Paragraph = styled.p`
  display: flex;
  text-align: center;
  margin-right: 35px;
  margin-left: 35px;
  justify-content: center;
`;

const StyledSpan = styled.span`
  font-weight: 300;
  line-height: 18px;
  width: 100;
  font-size: 14px;
  color: #A3A4A4;
`;

const IconsInfo = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e8e8e8;
`;

const DelText = styled.del`
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-decoration-line: line-through;
  color: #A3A4A4;
`;

const BestValue = styled.span`
  float: right;
  margin-right: 20px;
  width: 92px;
  height: 40px;
  background: #7F4EAD26;
  border-radius: 5px;
  text-align: center;
  padding: 7px 3px;
  margin-top: -10px;
  color: #fff;
`;
// const GuaranteeBanner = styled.div`
//   width: 473px;
//   height: 32px;
//   background: rgba(31, 172, 71, 0.04);
//   border: 1px solid rgba(31, 172, 71, 0.5);
//   box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
//   border-radius: 21px;
//   margin: auto;
//   margin-top: 10px;
//   margin-bottom: 18px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-weight: 400;
//   font-size: 12px;
//   color: #121212;
//   `;
const OfferText = styled.span`
color: #F1AA3E;
font-weight: 500;
position: relative;
img {
  position: absolute;
  bottom: -12px;
  left: 0;
}
`;
