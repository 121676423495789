import {useState} from 'react';
import {
  Table as AntdTable,
  TableProps as AntdTableProps,
} from 'antd';
import styled from 'styled-components';
import {faMagnifyingGlass} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Input} from './Input';
import {COLORS} from './colors';


interface TableProps extends AntdTableProps<any> {
  searchEnabled?: boolean;
  searchKey?: string;
  searchPlaceholder?: string;
}

export const Table = ({dataSource, searchKey, searchEnabled = true, searchPlaceholder, ...props}: TableProps) => {
  const [search, setSearch] = useState('');

  return (
    <Wrapper>
      {searchEnabled && <Input value={search} prefix={<FontAwesomeIcon icon={faMagnifyingGlass} style={{color: '#A3A4A4'}}/>} placeholder={searchPlaceholder} onChange={e => setSearch(e.target.value)}/> }
      <TableStyled {...props} dataSource={searchEnabled ? dataSource?.filter(item => item[searchKey]?.toLowerCase().includes(search?.toLowerCase())) : dataSource}>

      </TableStyled>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #fff;
`;

const TableStyled = styled(AntdTable)`
  .ant-table-thead > tr > th {
    text-transform: uppercase;
    border-bottom: unset !important;
    font-size: 12px;
    font-weight: 600;
    color: ${COLORS.black}
  }

  .ant-table-thead > tr > th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .ant-table-thead > tr:last-child th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

