import {useStore} from '@/store/root-store';

interface FlagType {
  code: string;
  width?: number | string;
  height?: number | string;
  style?: object;
}
const Flag = ({code, width = 'auto', height = 'auto', style = {}} : FlagType) => {
  const {countries: {getSpecificCountryByCode}} = useStore('');
  const country = code ? getSpecificCountryByCode(code.toUpperCase()) : null;
  return country ? <img width={width} height={height} style={style} src={country?.emoji} /> : null;
};
export default Flag;
