import {faCircleInfo, faSliders} from '@fortawesome/pro-duotone-svg-icons';
import {faAngleDown, faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Collapse, Divider, Popover, Select, Spin, Switch, Tooltip} from 'antd';
import {observer} from 'mobx-react';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {languages} from '../../../AiContentToolsDrawer/languages';
import {useStore} from '@/store/root-store';
import {LoadingOutlined} from '@ant-design/icons';
import {Input} from '@/components/common-components/v2';
import {PAGES_API} from '@/api/content-optimizer';
import {notification} from '@/utils/notification-v2';
import {CommonInput} from '@/components/common-components';
import {opaqueColor} from '@/utils/colors';
import {TermsToExclude} from '../new-ai-writer/Components/termsToExclude';
import {LargeBadge} from '@/components/common-components/components/badge';
import {readingLevel, searchIntents} from '@/components/dashboard/pages/landing-page-optimizer/blocks/hero/folder-settings/commonFolderSettingsUtils';
import {Form} from 'antd';

type settingsOptionsType = 'content-length'
| 'tone-of-voice'
| 'language'
| 'point-of-view'
| 'use_table'
| 'use_list'
| 'use_tease'
| 'boost-dettection-chance'
| 'use_global_background'
// | 'enable_literary_devices'
| 'key-takeaways'
| 'disable_skinny_paragraph'
| 'provide-details'
| 'h2-section-length'
| 'use_faq'
| 'number-of-pieces'
| 'disable_conclusion'
| 'use_image_section'
| 'generate_metadata'
| 'hero_disable_conclusion'
| 'use_hero_global_background'
| 'show_lang_in_settings_modal'
| 'ai-model'
| 'bold_focus_terms'
| 'table_of_contents'
| 'act_as_who'
| 'reading_level'
| 'writing_style'
| 'niche'
| 'default_audience'
| 'intent'
| 'search_intent'
| 'terms_to_exclude';

interface globalBackgroundErrorProp {
  message: string;
  error: boolean;
}

interface Props {
  isDropdown?: boolean;
  title?: string;
  settingsArray?: settingsOptionsType[];
  selectedContentLength?: string;
  selectedToneOfVoice?: string;
  selectedLanguage?: string;
  selectedPointOfView?: string;
  imagesCheck?: boolean;
  tableCheck?: boolean;
  listCheck?: boolean;
  teaseCheck?: boolean;
  conclusionCheck?: boolean;
  generateMetadata?: boolean;
  boostDettectionCheck?: boolean;
  addGlobalBackgroundCheck?: boolean;
  literaryDevicesCheck?: boolean;
  keyTakawaysCheck?: boolean;
  disableSkinnyParagraphCheck?: boolean;
  generateHeroImageCheck?: boolean;
  globalBackgroundError?: globalBackgroundErrorProp;
  shortFaq?: boolean;
  longFaq?: boolean;
  customToneOfVoice?: string;
  passedTitle?: string;
  passedHeadings?: string[];
  globalBackgroundDesc?: string;
  selectedFaq?: string;
  setSelectedContentLength?: (value: string) => void;
  setSelectedToneOfVoice?: (value: string) => void;
  setSelectedPointOfView?: (value: string) => void;
  setSelectedLanguage?: (value: string) => void;
  setTableCheck?: (value: boolean) => void;
  setListCheck?: (value: boolean) => void;
  setTeaseCheck?: (value: boolean) => void;
  setBoostDettectionCheck?: (value: boolean) => void;
  setAddGlobalBackgroundCheck?: (value: boolean) => void;
  setConclusionCheck?: (value: boolean) => void;
  setGenerateMetadata?: (value: boolean) => void;
  setImagesCheck?: (value: boolean) => void;
  setLiteraryDevicesCheck?: (value: boolean) => void;
  setKeyTakeawaysCheck?: (value: boolean) => void;
  setDisableSkinnyParagraphCheck?: (value: boolean) => void;
  setGenerateHeroImageCheck?: (value: boolean) => void;
  setShortFaq?: (value: boolean) => void;
  setLongFaq?: (value: boolean) => void;
  setNoFaq?: (value: boolean) => void;
  setGlobalBackgroundError?: (value: {message: string; error: boolean}) => void;
  setCustomToneOfVoice?: (value: string) => void;
  setLastUsedToneOfVoice?: (value: string) => void;
  setGlobalBackgroundDesc?: (value: string) => void;
  setSelectedFaq?: any;
  calledFromHero?:boolean;
  cancelBtn?:boolean;
  setShowImageSettings?:any;
  showImageSettings?: boolean;
  setGenerateHeroImage?:any;
  generateHeroImage?:boolean;
  setGenerateOneImage?:any;
  generateOneImage?:boolean;
  setAspectRatio?:any;
  aspectRatio?:any;
  setModel?:any;
  model?:any;
  selectedAiModel?:any;
  setSelectedAiModel?: (value: string) => void;
  setBolFocusTerms?: (value: boolean) => void;
  bolFocusTerms?: boolean;
  setShowTermsToExcludeSetting?: (value: boolean) => void;
  showTermsToExcludeSetting?: boolean;
  setExcludeTerm?: (value?: string) => void;
  excludeTerm?: string;
  setExcludeTermsArray?: (value?: string[]) => void;
  excludeTermsArray?: string[];
  setTableOfContents?: (value: boolean) => void;
  tableOfContents?: boolean;
  contentType?: number;
  actAsWho?: string;
  setActAsWho?: (value: string) => void;
  readingLevelValue?: string;
  setReadingLevel?: (value: string) => void;
  writingStyle?: string;
  setWritingStyle?: (value: string) => void;
  niche?: string;
  setNiche?: (value: string) => void;
  defaultAudience?: string;
  setDefaultAudience?: (value: string) => void;
  intent?: string;
  setIntent?: (value: string) => void;
  searchIntent?: any;
  setSearchIntent?: (value: any) => void;
}

export const Settings = observer(({
  isDropdown = true,
  title,
  settingsArray = [],
  selectedContentLength,
  selectedToneOfVoice,
  selectedLanguage,
  selectedPointOfView,
  tableCheck,
  listCheck,
  teaseCheck,
  conclusionCheck,
  generateMetadata,
  boostDettectionCheck,
  addGlobalBackgroundCheck,
  // literaryDevicesCheck,
  keyTakawaysCheck,
  disableSkinnyParagraphCheck,
  passedTitle,
  passedHeadings,
  globalBackgroundDesc,
  globalBackgroundError,
  setSelectedContentLength,
  setSelectedToneOfVoice,
  setSelectedLanguage,
  setSelectedPointOfView,
  setTableCheck,
  setListCheck,
  setTeaseCheck,
  setBoostDettectionCheck,
  setAddGlobalBackgroundCheck,
  // setLiteraryDevicesCheck,
  setKeyTakeawaysCheck,
  setDisableSkinnyParagraphCheck,
  setCustomToneOfVoice,
  setLastUsedToneOfVoice,
  setGlobalBackgroundDesc,
  setGlobalBackgroundError,
  setConclusionCheck,
  setGenerateMetadata,
  calledFromHero = false,
  cancelBtn,
  setShowImageSettings,
  showImageSettings,
  setGenerateHeroImage,
  generateHeroImage,
  setGenerateOneImage,
  generateOneImage,
  setAspectRatio,
  aspectRatio,
  setModel,
  // model,
  selectedAiModel,
  setSelectedAiModel,
  setBolFocusTerms,
  bolFocusTerms,
  setShowTermsToExcludeSetting,
  showTermsToExcludeSetting,
  excludeTerm,
  setExcludeTerm,
  setExcludeTermsArray,
  excludeTermsArray,
  tableOfContents,
  setTableOfContents,
  contentType,
  actAsWho,
  setActAsWho,
  setReadingLevel,
  readingLevelValue,
  setWritingStyle,
  writingStyle,
  setNiche,
  niche,
  setDefaultAudience,
  defaultAudience,
  setIntent,
  intent,
  setSearchIntent,
  searchIntent,
  selectedFaq,
  setSelectedFaq,
}: Props) => {
  const {
    contentOptimizer: {
      aiOutline: {
        userAiSettings,
        getUserAiSettings,
        loadingUserAiSettings,
        aiWriterController,
      },
    },
  } = useStore('');
  const {settings: {customer: {profile: {isLinkgraph}}}} = useStore('');
  const [useCustomToneOfVoice, setUseCustomToneOfVoice] = useState<boolean>(false);
  const [loadingAiVoice, setLoadingAiVoice] = useState<boolean>(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const {Option} = Select;
  const selectSufixIcon = <FontAwesomeIcon icon={faCaretDown} color={'#121212'}/>;
  const antIcon = <LoadingOutlined style={{fontSize: 16, marginLeft: 7, color: '#7f4ead'}} spin />;

  const faqOptions = [
    {value: '', label: 'No FAQ'},
    {value: 'short', label: 'Short FAQ'},
    {value: 'long', label: 'Long FAQ'},
  ];

  useEffect(() => {
    getUserAiSettings();
  }, []);

  useEffect(() => {
    if (selectedToneOfVoice || userAiSettings?.toneOfVoice) {
      setSelectedToneOfVoice(selectedToneOfVoice || userAiSettings?.toneOfVoice);
      !calledFromHero && setLastUsedToneOfVoice(selectedToneOfVoice || userAiSettings?.toneOfVoice);
    }
    if (selectedPointOfView || userAiSettings?.pointOfView) {
      setSelectedPointOfView(selectedPointOfView || userAiSettings.pointOfView);
    }
    if (!calledFromHero && userAiSettings?.defaultLanguage) {
      setSelectedLanguage(languages.find(item => item.code == userAiSettings?.defaultLanguage)?.code ?? '');
    }
  }, [userAiSettings]);


  const getVoiceFromAi = async () => {
    if (loadingAiVoice) return;

    setLoadingAiVoice(true);
    try {
      const resp = await PAGES_API.getToneOfVoiceSuggestions(passedTitle, passedHeadings);
      if (resp) setSelectedToneOfVoice(resp);
      setUseCustomToneOfVoice(false);
      setCustomToneOfVoice('');
    } catch (e) {
      notification.error('Error auto matching voice.', 'Could not generate AI voice. To fix the issue:');
    } finally {
      setLoadingAiVoice(false);
    }
  };

  const selectSuffixIcon = <FontAwesomeIcon icon={faCaretDown} color={'#121212'}/>;

  const maxTagPlaceholder = () => {
    return (
      <Tooltip
        title={searchIntent?.map((item, index) => (
          <div key={index}>
            {item}{index < searchIntent?.length - 1 ? ', ' : ''}
          </div>
        ))}
      >
        <div>+{searchIntent?.length - 1}</div>
      </Tooltip>
    );
  };

  const updateCheckBox = (type: string, checked: boolean) => {
    switch (type) {
      case 'use_table':
        setTableCheck(checked);
        break;
      case 'use_list':
        setListCheck(checked);
        break;
      case 'use_tease':
        setTeaseCheck(checked);
        break;
      case 'disable_conclusion':
        setConclusionCheck(checked);
        break;
      case 'generate_metadata':
        setGenerateMetadata(checked);
        break;
      case 'boost-dettection-chance':
        setBoostDettectionCheck(checked);
        break;
      case 'use_global_background':
        setAddGlobalBackgroundCheck(checked);
        break;
      // case 'enable_literary_devices':
      //   setLiteraryDevicesCheck(checked);
      //   break;
      case 'key-takeaways':
        setKeyTakeawaysCheck(checked);
        break;
      case 'disable_skinny_paragraph':
        setDisableSkinnyParagraphCheck(checked);
        break;
      case 'generate_hero_image':
        setGenerateHeroImage(checked);
        break;
      case 'generate_one_image':
        setGenerateOneImage(checked);
        break;
      case 'bold_focus_terms':
        setBolFocusTerms(checked);
        break;
      case 'terms_to_exclude':
        setShowTermsToExcludeSetting(checked);
        break;
      case 'table_of_contents':
        setTableOfContents(checked);
        break;
    }
  };


  const contentLengthOptions = ['Short', 'Medium', 'Long'];
  const onContentLengthChange = value => {
    setSelectedContentLength(value);
  };


  // const toneOfVoiceOptions = ['Excited', 'Witty', 'Sarcastic', 'Feminine', 'Masculine', 'Bold', 'Dramatic', 'Grumpy', 'Secretive', 'Casual', 'Professional', 'Funny', 'Angry', 'Yoda', 'Tonhy Stark', 'Shakespeare'];
  const shortToneOfVoiceOptions = ['Casual', 'Professional', 'Funny', 'Angry', 'Baby Yoda'];
  const onToneOfVoiceChange = value => {
    setSelectedToneOfVoice(value);
  };

  const pointOfViewOptions = ['First person', 'Second person', 'Third person'];
  const onPointOfViewChange = value => {
    setSelectedPointOfView(value.toLowerCase());
  };

  const languageOptions = languages;
  const onLanguageChange = value => {
    setSelectedLanguage(value);
  };

  const aiModelOptions = [{label: 'ChatGPT (Open AI)', value: 'gpt'}, {label: 'Claude', value: 'claude'}, {...(isLinkgraph && {label: 'Groq', value: 'groq'})}];

  useEffect(() => {
    if (cancelBtn) {
      setGlobalBackgroundError({message: 'Missing global background', error: false});
    }
  }, [cancelBtn]);

  useEffect(() => {
    if (!addGlobalBackgroundCheck && globalBackgroundError.error) {
      setGlobalBackgroundError({message: 'Missing global background', error: false});
    }
    if (!addGlobalBackgroundCheck) setGlobalBackgroundDesc('');
  }, [addGlobalBackgroundCheck]);

  // const handleModelValue = item => {
  //   if (item.toLowerCase() === 'search atlas' ) return 'searchatlas';
  //   return item.toLowerCase();
  // };
  const clearTermsToExclude = () => {
    setExcludeTerm('');
    setExcludeTermsArray([]);
  };

  const aspectRatioOptions = [{
    name: 'Article image (8:3)',
    value: '8:3',
  }, {
    name: 'Wide screen image (16:9)',
    value: '16:9',
  }, {
    name: 'Square image (1:1)',
    value: '1:1',
  }, {
    name: 'Vertical image (2:3)',
    value: '2:3',
  }];

  // const modelOptions = ['Search Atlas', 'Midjourney'];
  const settingsLoader = loadingUserAiSettings ? <Spin indicator={antIcon} /> : null;
  const settingsLoaderWithloadingAi = loadingUserAiSettings || loadingAiVoice ? <Spin indicator={antIcon} /> : null;

  const aiModelOption = settingsArray.includes('ai-model') &&
  <SelectContainer>
    <OptionLabel>AI Model</OptionLabel>
    <SelectStyled
      grayBackground={!isDropdown}
      suffixIcon={selectSufixIcon}
      value={selectedAiModel}
      onChange={setSelectedAiModel}>
      {aiModelOptions.map((item, idx) =>
        <Option
          style={{fontSize: 12}}
          key={idx}
          value={item.value}
        >
          <div style={{display: 'flex'}}>{item.label}{item.value == 'groq' && <LargeBadge alpha/>}</div>
        </Option>,
      )}
    </SelectStyled>
  </SelectContainer>;

  const contentLengthOption = settingsArray.includes('content-length') &&
  <SelectContainer>
    <OptionLabel>Content Length</OptionLabel>
    <SelectStyled
      grayBackground={!isDropdown}
      suffixIcon={selectSufixIcon}
      value={selectedContentLength}
      onChange={value => onContentLengthChange(value)}>
      {contentLengthOptions.map((item, idx) => {
        return <Option style={{fontSize: 12}} key={idx} value={item.toLowerCase()}>{item}</Option>;
      })}
    </SelectStyled>
  </SelectContainer>;

  const toneOfVoiceWithAi = settingsArray.includes('tone-of-voice') &&
  <SelectContainer>
    <OptionLabel>{aiWriterController?.templateUsed == '1-click-draft' ? 'Tone of voice' : 'Voice'}</OptionLabel>
    <Popover
      overlayInnerStyle={{
        width: 190,
      }}
      trigger='click'
      placement='rightTop'
      visible={isPopoverVisible}
      onVisibleChange={setIsPopoverVisible}
      content={<div>
        <div style={{fontSize: 12, color: '#4E5156'}}>Input any voice or auto-match based on your topic.</div>
        <Divider style={{marginTop: 10, marginBottom: 10}} />
        <InputCustomVoiceBtn onClick={() => {
          setUseCustomToneOfVoice(true);
          setLastUsedToneOfVoice(selectedToneOfVoice);
          setSelectedToneOfVoice('custom');
          setIsPopoverVisible(false);
        }}>+ Input a Custom Voice</InputCustomVoiceBtn>
        {shortToneOfVoiceOptions.map((item, idx) => {
          return <ToneOfVoiceSingleOption style={{cursor: 'pointer', fontSize: 12}} key={idx} onClick={() => {
            setUseCustomToneOfVoice(false);
            onToneOfVoiceChange(item);
            setIsPopoverVisible(false);
          }}>{item}</ToneOfVoiceSingleOption>;
        })}
      </div>}>
      <div style={{width: 160, backgroundColor: isDropdown ? '#fff' : '#F9F9FB', borderRadius: 6, padding: '4px 11px', cursor: 'pointer', display: 'flex', border: isDropdown ? '0px solid transparent' : '1px solid #E8E8E8'}}>
        {selectedToneOfVoice == 'custom' ? 'Custom (input below)' : selectedToneOfVoice}
        <FontAwesomeIcon icon={faCaretDown} style={{marginLeft: 'auto', marginTop: 3}}/>
      </div>
    </Popover>
    {settingsLoaderWithloadingAi}
    {(aiWriterController?.templateUsed !== '1-click-draft' && !loadingAiVoice && !calledFromHero) && <Tooltip title={!passedTitle ? 'Main Heading (H1) required to find the best match.' : null}>
      <div style={{marginLeft: 5, color: '#2D6CCA', cursor: 'pointer'}} onClick={() => {
        if (!passedTitle) return;
        getVoiceFromAi();
      }}>Auto match</div>
    </Tooltip>}
  </SelectContainer>;


  const customToneOfVoiceOption = useCustomToneOfVoice &&
  <SelectContainer>
    <OptionLabel>{'Custom voice'}</OptionLabel>
    <InputStyled onChange={e => setCustomToneOfVoice(e.target.value)}/>
    {settingsLoader}
  </SelectContainer>;

  const languageOption = settingsArray.includes('language') &&
  <><SelectContainer>
    <OptionLabel>Language</OptionLabel>
    <SelectStyled
      grayBackground={!isDropdown}
      suffixIcon={selectSufixIcon}
      value={selectedLanguage}
      onChange={value => onLanguageChange(value)}>
      {languageOptions.map((item, idx) => {
        return <Option key={idx} value={item.code}>{item.language}</Option>;
      })}
    </SelectStyled>
    {settingsLoader}
  </SelectContainer></>;


  const pointOfViewOption = settingsArray.includes('point-of-view') &&
  <SelectContainer>
    <OptionLabel>Point of view</OptionLabel>
    <SelectStyled
      grayBackground={!isDropdown}
      suffixIcon={selectSufixIcon}
      value={selectedPointOfView}
      onChange={value => onPointOfViewChange(value)}>
      {pointOfViewOptions.map((item, idx) => {
        return <Option style={{fontSize: 12}} key={idx} value={item.toLowerCase()}>{item}</Option>;
      })}
    </SelectStyled>
    {settingsLoader}
  </SelectContainer>;

  const faqOption = settingsArray.includes('use_faq') &&
  <SelectContainer>
    <OptionLabel>Enable FAQ</OptionLabel>
    <SelectStyled
      grayBackground={!isDropdown}
      suffixIcon={selectSufixIcon}
      value={selectedFaq}
      onChange={value => setSelectedFaq(value)}>
      {faqOptions.map((item, index)=> {
        return <Option key={index} value={item.value} >{item.label}</Option>;
      })}
    </SelectStyled>
    {settingsLoader}
  </SelectContainer>;


  const h2SectionLengthOption = settingsArray.includes('h2-section-length') &&
  <SelectContainer>
    <OptionLabel>H2 Section length</OptionLabel>
    <SelectStyled
      grayBackground={!isDropdown}
      suffixIcon={selectSufixIcon}
      value={selectedContentLength}
      onChange={value => onContentLengthChange(value)}>
      {contentLengthOptions.map((item, idx) => {
        return <Option style={{fontSize: 12}} key={idx} value={item.toLowerCase()}>{item}</Option>;
      })}
    </SelectStyled>
    {settingsLoader}
  </SelectContainer>;

  const provideDetailsOption = (settingsArray.includes('use_hero_global_background') || settingsArray.includes('use_global_background')) &&
    <>
      {settingsArray.includes('use_hero_global_background') && <GlobalBackgroundHeading>
        <p className='global-background-heading-txt'>
          <StyledCheckbox checked={addGlobalBackgroundCheck} onChange={e => updateCheckBox('use_global_background', e.target.checked)}>Add global background
            <Tooltip title='Provide additional details about the common subject of all articles in this project. Increase quality of content only if all articles will have a common subject.' overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6, fontSize: 12}}>
              <FontAwesomeIcon icon={faCircleInfo} style={{marginLeft: 5}}/>
            </Tooltip>
          </StyledCheckbox>
        </p>
      </GlobalBackgroundHeading>
      }
      { addGlobalBackgroundCheck && (
        <CommonInputStyled
          grayBackground={!isDropdown}
          calledFromHero={calledFromHero}
          border={false}
          type='text'
          size={1}
          variant='white'
          inputType='textarea'
          value={globalBackgroundDesc}
          placeholder='Provide details about the subject of the article'
          onChange={e => {
            setGlobalBackgroundDesc(e.target.value);
            if (e.target.value) {
              setGlobalBackgroundError({message: 'Missing global background', error: false});
            }
          }}
        />
      )
      }
      {globalBackgroundError.error && <div style={{fontSize: 12, color: '#ff5959', marginTop: -20}}>{globalBackgroundError.message}</div>}
    </>
  ;

  const termsToExcludeSection = (settingsArray.includes('terms_to_exclude') || settingsArray.includes('terms_to_exclude')) && <>
    {settingsArray.includes('terms_to_exclude') && <GlobalBackgroundHeading>
      <p className='global-background-heading-txt'>
        <StyledCheckbox checked={showTermsToExcludeSetting} onChange={e => updateCheckBox('terms_to_exclude', e.target.checked)}>Terms to exclude
          {/* <Tooltip title='' overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6, fontSize: 12}}>
            <FontAwesomeIcon icon={faCircleInfo} style={{marginLeft: 5}}/>
          </Tooltip> */}
        </StyledCheckbox>
      </p>
      <p className='description-text'>The AI Writer will aim to write content without the specified terms. </p>
    </GlobalBackgroundHeading>}
    {showTermsToExcludeSetting && <TermsToExclude
      excludeTerm={excludeTerm}
      setExcludeTerm={setExcludeTerm}
      excludeTermsArray={excludeTermsArray}
      setExcludeTermsArray={setExcludeTermsArray}
      clearTermsToExclude={clearTermsToExclude}
      inputBorder='none'
      wrapperBackgroundColor='#F9F9FB'
      inputBackgroundColor='#F9F9FB'
      inputPlaceholder='Enter term(s) and phrase(s), separated by commas, and hit enter.'
    />}
  </>;
  const addImage = settingsArray.includes('use_image_section') &&
  <>
    <div style={{marginTop: '15px'}}>
      <StyledCheckbox checked={showImageSettings} onChange={e => {
        setShowImageSettings(e.target.checked);
        if (!e.target.value) {
          setGenerateHeroImage(true);
          setGenerateOneImage(true);
          setAspectRatio('16:9');
          setModel('searchatlas');
        }
      }}>Add images
      </StyledCheckbox>
    </div>
    {showImageSettings && (
      <AddImageCard>
        <div style={{margin: '16px 0px 0px 12px', width: '100%', paddingRight: '10px'}}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flexWrap: 'wrap'}}>
            <SwitchContainer>
              <SwitchStyled checked={generateHeroImage} onChange={checked => updateCheckBox('generate_hero_image', checked)}/>
              Generate an article hero image
            </SwitchContainer>
            <SelectContainer>
              <OptionLabel>Aspect ratio:</OptionLabel>
              <SelectStyled
                grayBackground={false}
                suffixIcon={selectSufixIcon}
                value={aspectRatio}
                onChange={value => {
                  setAspectRatio(value);
                }}>
                {aspectRatioOptions.map((item, idx) => {
                  return <Option style={{fontSize: 12}} key={idx} value={item.value}>{item.name}</Option>;
                })}
              </SelectStyled>
              {settingsLoader}
            </SelectContainer>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', flexWrap: 'wrap'}}>
            <SwitchContainer style={{marginBottom: '0px'}}>
              <SwitchStyled checked={generateOneImage} onChange={checked => updateCheckBox('generate_one_image', checked)}/>
              Generate one image per H2 section
            </SwitchContainer>
            {/* <SelectContainer>
              <OptionLabel>Model:</OptionLabel>
              <SelectStyled
                grayBackground={false}
                suffixIcon={selectSufixIcon}
                value={model}
                onChange={value => setModel(value)}>
                {modelOptions.map((item, idx) => {
                  return <Option style={{fontSize: 12}} key={idx} value={handleModelValue(item)}>{item}</Option>;
                })}
              </SelectStyled>
              {settingsLoader}
            </SelectContainer> */}
          </div>
        </div>
      </AddImageCard>
    )}

  </>;

  const disabeSkinnyParagraph = calledFromHero && settingsArray.includes('disable_skinny_paragraph') && (<>
    <SwitchContainer>
      <SwitchStyled checked={disableSkinnyParagraphCheck} onChange={checked => updateCheckBox('disable_skinny_paragraph', checked)}/>
      Disable short paragraphs
    </SwitchContainer></>);

  const disableConclusion = calledFromHero &&
settingsArray.includes('disable_conclusion') && (
    <SwitchContainer style={{width: '100%'}}>
      <SwitchStyled
        checked={conclusionCheck}
        onChange={checked => updateCheckBox('disable_conclusion', checked)}
      />
    Disable conclusion
    </SwitchContainer>
  );

  const boldFocusTerms = calledFromHero && settingsArray.includes('bold_focus_terms') && (<>
    <SwitchContainer>
      <SwitchStyled checked={bolFocusTerms} onChange={checked => updateCheckBox('bold_focus_terms', checked)}/>
      Bold Focus Terms in AI Content
    </SwitchContainer></>);

  // const tableOfContents = calledFromHero && settingsArray.includes('table_of_contents') && (<>
  //   <SwitchContainer>
  //     <SwitchStyled checked={tableOfContents} onChange={checked => updateCheckBox('table_of_contents', checked)}/>
  //   Include table of contents
  //   </SwitchContainer></>);

  const oneClickOptions = (
    <div>
      {/* Content length */}
      {contentLengthOption}

      {/* Tone of voice with AI Suggestions option */}
      {toneOfVoiceWithAi}

      {/* Tone of voice with AI Suggestions option */}
      {customToneOfVoiceOption}

      {/* Language */}
      {languageOption}

      {/* Point of View */}
      {pointOfViewOption}
    </div>
  );


  const oneClickCheckboxes = (<>

    {!calledFromHero && settingsArray.includes('bold_focus_terms') && <>
      <SwitchContainer style={{width: '100%'}}>
        <SwitchStyled checked={bolFocusTerms} onChange={checked => updateCheckBox('bold_focus_terms', checked)}/>
        Bold Focus Terms in AI Content
      </SwitchContainer></>
    }

    {!calledFromHero && settingsArray.includes('generate_metadata') && <SwitchContainer style={{width: '100%'}}>
      <SwitchStyled checked={generateMetadata} onChange={checked => updateCheckBox('generate_metadata', checked)}/>
      Generate meta title & description
    </SwitchContainer>}

    {!calledFromHero && settingsArray.includes('disable_conclusion') && <SwitchContainer style={{width: '100%'}}>
      <SwitchStyled checked={conclusionCheck} onChange={checked => updateCheckBox('disable_conclusion', checked)}/>
      Disable conclusion
    </SwitchContainer>}

    <CheckboxContainer
      calledFromHero={calledFromHero}
    >
      {calledFromHero && settingsArray.includes('generate_metadata') && <SwitchContainer style={{width: '100%'}}>
        <SwitchStyled checked={generateMetadata} onChange={checked => updateCheckBox('generate_metadata', checked)}/>
      Generate meta title & description
      </SwitchContainer>}

      <div className='left' style={{minWidth: 130}}>
        {settingsArray.includes('use_list') && <SwitchContainer>
          <SwitchStyled checked={listCheck} onChange={checked => updateCheckBox('use_list', checked)}/>
          Lists
          <Tooltip title='40% chance to include markdown bullet and numeric lists' overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6, fontSize: 12}}>
            <FontAwesomeIcon icon={faCircleInfo} style={{marginLeft: 5}}/>
          </Tooltip>
        </SwitchContainer>}

        {settingsArray.includes('use_table') && <SwitchContainer>
          <SwitchStyled checked={tableCheck} onChange={checked => updateCheckBox('use_table', checked)}/>
          Tables
          <Tooltip title='30% chance to include tables' overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6, fontSize: 12}}>
            <FontAwesomeIcon icon={faCircleInfo} style={{marginLeft: 5}}/>
          </Tooltip>
        </SwitchContainer>}

        {settingsArray.includes('use_tease') && <SwitchContainer>
          <SwitchStyled checked={teaseCheck} onChange={checked => updateCheckBox('use_tease', checked)}/>
          Tease
          <Tooltip title='Include a transition paragraph at the end of h2 sections (adds ~35 words per H2 Section)' overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6, fontSize: 12}}>
            <FontAwesomeIcon icon={faCircleInfo} style={{marginLeft: 5}}/>
          </Tooltip>
        </SwitchContainer>}

        {settingsArray.includes('boost-dettection-chance') && <SwitchContainer style={{width: '100%'}}>
          <SwitchStyled checked={boostDettectionCheck} onChange={checked => updateCheckBox('boost-dettection-chance', checked)}/>
          Boost chance of detection as real
        </SwitchContainer>}

        {contentType===3 && settingsArray.includes('table_of_contents') && <SwitchContainer>
          <SwitchStyled checked={tableOfContents} onChange={checked => updateCheckBox('table_of_contents', checked)}/>
        Include table of contents
        </SwitchContainer>}

      </div>
      <div className='right'>
        {/* {settingsArray.includes('enable_literary_devices') && <SwitchContainer>
          <SwitchStyled checked={literaryDevicesCheck} onChange={checked => updateCheckBox('enable_literary_devices', checked)}/>
        Literary devices
          <Tooltip title='Use over 500 literary devices such as metaphors, similes, etc/' overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6, fontSize: 12}}>
            <FontAwesomeIcon icon={faCircleInfo} style={{marginLeft: 5}}/>
          </Tooltip>
        </SwitchContainer>} */}
        {settingsArray.includes('key-takeaways') && <SwitchContainer>
          <SwitchStyled checked={keyTakawaysCheck} onChange={checked => updateCheckBox('key-takeaways', checked)}/>
        Key takeaways
          <Tooltip title='Add a key takeaway section after the introduction' overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6, fontSize: 12}}>
            <FontAwesomeIcon icon={faCircleInfo} style={{marginLeft: 5}}/>
          </Tooltip>
        </SwitchContainer>}
        {!calledFromHero && settingsArray.includes('disable_skinny_paragraph') && <SwitchContainer>
          <SwitchStyled checked={disableSkinnyParagraphCheck} onChange={checked => updateCheckBox('disable_skinny_paragraph', checked)}/>
          Disable short paragraphs
        </SwitchContainer>}
        {contentType!==3 && settingsArray.includes('table_of_contents') && <SwitchContainer>
          <SwitchStyled checked={tableOfContents} onChange={checked => updateCheckBox('table_of_contents', checked)}/>
        Include table of contents
        </SwitchContainer>}
      </div>


    </CheckboxContainer>
    {!calledFromHero && settingsArray.includes('use_global_background') && <SwitchContainer style={{width: '100%'}}>
      <SwitchStyled checked={addGlobalBackgroundCheck} onChange={checked => updateCheckBox('use_global_background', checked)}/>
          Add global background
    </SwitchContainer>}
  </>
  );


  const completeArticleOptions = (
    <>
      <StyledArticlesContainer
        calledFromHero={calledFromHero}
      >
        {/* Checkboxes */}

        {oneClickCheckboxes}

        {/* Global background of the article */}

        {!calledFromHero && provideDetailsOption}

        <div>
          {disableConclusion}

          {/* Disable short paragraphs switch for hero component */}
          {disabeSkinnyParagraph}

          {/* bold focus terms */}
          {boldFocusTerms}

          {/* AI Model */}
          {aiModelOption}

          {/* H2 content length */}
          {h2SectionLengthOption}

          {/* FAQ */}
          {faqOption}

          {/* Language */}
          {!settingsArray.includes('show_lang_in_settings_modal') && languageOption}

          {/* Point of View */}
          {pointOfViewOption}

          {/* Tone of voice with AI Suggestions option */}
          {toneOfVoiceWithAi}

          {settingsArray.includes('act_as_who') &&
          <SelectContainer>
            <OptionLabel>Act as who?</OptionLabel>
            <StyledInput value={actAsWho} onChange={e => setActAsWho(e?.target?.value)} placeholder='Act as who?' />
          </SelectContainer>}
          {settingsArray.includes('reading_level') && <SelectContainer>
            <OptionLabel>Reading Level:</OptionLabel>
            <SelectStyled
              suffixIcon={selectSuffixIcon}
              value={readingLevelValue}
              onChange={(value: any) => setReadingLevel(value)}
            >
              {readingLevel.map((item, idx) => (
                <Option key={idx} style={{fontSize: 12}} value={item}>{item}</Option>
              ))}
            </SelectStyled>
          </SelectContainer>}
          {settingsArray.includes('writing_style') && <SelectContainer>
            <OptionLabel>Writing style:</OptionLabel>
            <StyledInput value={writingStyle} onChange={e => setWritingStyle(e?.target?.value)} placeholder='Writing style' />
          </SelectContainer>}
          {settingsArray.includes('niche') && <SelectContainer>
            <OptionLabel>Niche:</OptionLabel>
            <StyledInput value={niche} onChange={e => setNiche(e?.target?.value)} placeholder='Niche' />
          </SelectContainer>}
          {settingsArray.includes('default_audience') && <SelectContainer>
            <OptionLabel>Default audience</OptionLabel>
            <StyledInput value={defaultAudience} onChange={e => setDefaultAudience(e?.target?.value)} placeholder='Default audience' />
          </SelectContainer>}
          {settingsArray.includes('intent') && <SelectContainer>
            <OptionLabel>Business intent:</OptionLabel>
            <StyledInput value={intent} onChange={e => setIntent(e?.target?.value)} placeholder='Business intent' />
          </SelectContainer>}
          {settingsArray.includes('search_intent') && <SelectContainer>
            <OptionLabel>Search intent:</OptionLabel>
            <Form.Item style={{marginBottom: 0}}>
              <SelectStyled
                value={searchIntent}
                mode='multiple'
                suffixIcon={selectSuffixIcon}
                maxTagCount='responsive'
                maxTagPlaceholder={maxTagPlaceholder}
                onChange={(value: any) => setSearchIntent(value)}
                defaultValue={searchIntent || []}
              >
                {searchIntents.map(item => (
                  <Option key={item.value} style={{fontSize: 12}} value={item.value}>{item.label}</Option>
                ))}
              </SelectStyled>
              <FontAwesomeIcon icon={faCaretDown} color='#4E5156' style={{position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)'}} />
            </Form.Item>
          </SelectContainer>}

          {/* Tone of voice with AI Suggestions option */}
          {customToneOfVoiceOption}

          {/* Language in settings modal on sca  */}

          {settingsArray.includes('show_lang_in_settings_modal') && languageOption}

        </div>

      </StyledArticlesContainer>
      {calledFromHero && provideDetailsOption}
      {termsToExcludeSection}
      {addImage}
    </>
  );

  const settingsToShow = aiWriterController?.templateUsed == '1-click-draft' ? oneClickOptions : completeArticleOptions;

  return isDropdown ? <AccordionPanelStyled onChange={() => {}}
    expandIcon={({isActive}) => <FontAwesomeIcon icon={faAngleDown} rotation={isActive ? 90 : null} />}
    expandIconPosition={'right'}>
    <Collapse.Panel header={<div>
      <FontAwesomeIcon icon={faSliders} style={{marginRight: 12}}/>
      <span style={{fontSize: 13, fontWeight: 500, color: '#101F36'}}>
        {title}
      </span>

    </div>} key='1'>
      {aiWriterController?.templateUsed == '1-click-draft' ? oneClickOptions : completeArticleOptions}
    </Collapse.Panel>
  </AccordionPanelStyled> : <SettingsContainer calledFromHero={calledFromHero}>{settingsToShow}</SettingsContainer>;
});

const SettingsContainer= styled.div<{calledFromHero:boolean}>`
  padding: ${props => props.calledFromHero ? '' : '10px'} ;
  margin-top: ${props => props.calledFromHero ? '' : '10px'};
  background-color: #fff;
  border-radius: 6px;
`;

const AccordionPanelStyled = styled(Collapse)`
  border-radius: 8px;
  
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02);


  .ant-collapse-header {
    background: rgba(36, 63, 104, 0.12);
    border-radius: 8px 8px 0 0 !important;
    padding: 8px 14px !important;
  }

  .ant-collapse-content { 
    background: rgba(36, 63, 104, 0.12);
    border-top: 1px solid transparent;
    border-radius: 0 0 8px 8px !important;
  }

  .ant-collapse-content-box {
    padding: 5px 14px !important;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  font-size: 12px;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
`;
const CheckboxContainer = styled.div<{calledFromHero:boolean}>`
  display: flex;
  flex-direction: ${props => props.calledFromHero ? 'column' : ''} ;
  
`;

const InputCustomVoiceBtn = styled.div`
cursor: pointer;
font-size: 12px;
color: #2D6CCA;

&:hover {
  text-decoration: underline;
}
`;
const ToneOfVoiceSingleOption = styled.div`
cursor: pointer;
font-size: 12px;

&:hover {
  color: #2D6CCA;
  text-decoration: underline;
}
`;

const SwitchStyled = styled(Switch)`
  width: 30px;
  min-width: 30px;
  height: 12px;
  margin-right: 10px;
  background: #A7B2C3;

  .ant-switch-handle {
    height: 15px;
    width: 15px;
    margin-top: -4px;
  }

  &.ant-switch-checked {
    background: ${opaqueColor('#7F4EAD', 30)} !important;
    .ant-switch-handle {
      &::before {
        background: #7F4EAD;
      }
     
    }
  }
 


`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const OptionLabel = styled.div`
  margin-right: 6px;
  font-size: 12px;
  display: flex;
`;
const SelectStyled = styled(Select)<{grayBackground?: boolean}>`
  .ant-select-selector {
    border-radius: 6px !important;
    border: 1px solid #E8E8E8 !important;
    background-color: ${p => p.grayBackground ? '#F9F9FB': '#fff'} !important;
    box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02) !important;
    max-width: 160px;
    min-width: 160px;
    font-size: 12px;
  }
  .ant-select-item {
    font-size: 12px !important;
  }
`;
const InputStyled = styled(Input)`
  border-radius: 6px !important;
  border: 1px solid #E8E8E8 !important;
  background: #fff !important;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02) !important;
  width: 160px;
  padding: 4px 11px;
  font-size: 12px;

  &:focus {
    padding: 4px 11px;
  }
`;

const CommonInputStyled = styled(CommonInput)<{border?: boolean; boxShadow?: boolean; grayBackground?: boolean; calledFromHero?:boolean}>`
  color: #121212 !important;
  background-color: ${p => p.calledFromHero ? '#F9F9FB' : p.grayBackground ? '#F9F9FB': 'transparent'} !important;
  border-radius: ${props => props.calledFromHero ? '8px' : '6px'};
  border: ${props => props.calledFromHero ? '1px solid #E8E8E8' : ''}; 
  ${p => p.boxShadow && 'box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02)'};
  ${p => !p.border && 'border: 1px solid transparent !important'};
  padding-right: 8px !important;
  margin-bottom: 20px !important;

  &:focus {
    box-shadow: none !important;
  }
 
`;

const StyledArticlesContainer = styled.div<{calledFromHero:boolean}>`
display: ${props => props.calledFromHero ? 'flex' : 'block'} ;
justify-content: ${props => props.calledFromHero ? 'space-between' : ''};
margin-top: ${props => props.calledFromHero ? '' : '10px'};
background-color: ${props => props.calledFromHero ? '#F9F9FB' : '#fff'};
border-radius: ${props => props.calledFromHero ? '8px' : '6px'};
border: ${props => props.calledFromHero ? '1px solid #E8E8E8' : ''}; 
padding: ${props => props.calledFromHero ? '10px' : ''};
@media screen and (max-width: 580px) {
  flex-wrap: wrap;
}
`;

const GlobalBackgroundHeading = styled.div`
  margin-top: 15px;
  .global-background-heading-txt{
    color: #121212;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0px;
  }
  .description-text {
    font-family: 'Inter', sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;
    color: #4E5156;
    margin-bottom: 1em;
    padding-left: 25px;
  }
`;

const StyledCheckbox = styled(Checkbox)`
.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #7F4EAD;
  border-color: #7F4EAD;
}
`;

const AddImageCard = styled.div`
    width: 660px;
    min-height: 68px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #E8E8E8;
    background: #F9F9FB;
    margin-top: 6px;
    margin-bottom: 18px;
    display: flex;
    @media screen and (max-width: 768px) {
      width: 100% !important;
      flex-wrap: wrap;
    }
`;

export const StyledInput = styled(Input)`
  width: 160px;
  height: 32px;
  border-radius: 6px;
  border: 1px solid #E8E8E8 !important;
`;
