export const filterNullValues = obj => {
  // Create a new object to store filtered data
  const filteredData = {};

  // Iterate through the object's entries
  Object.entries(obj).forEach(([key, value]) => {
    // Check if the value is not null
    if (value !== null) {
      filteredData[key] = value;
    }
  });

  // Return the filtered data
  return filteredData;
};


export const heatmapHeightLogic = heatmapData => {
  const totalHeatmapLength = heatmapData?.length;
  const mapCount = heatmapData?.map(data => Math.ceil(data?.maps?.length ? (data?.maps?.length / 3) : 1)).reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
  return (totalHeatmapLength > 1 ? (totalHeatmapLength - 1) * 5.62 : 0) + ((mapCount - heatmapData?.length) * 4.3);
};
