// import {useState, useEffect} from 'react';
import styles from './style.module.scss';
// import {faDownload} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Button, GrayButton,
} from '@/components/common-components/v2/Button';
// import {CopyOutlined} from '@ant-design/icons';

import {Divider, Tooltip} from 'antd';
import {faCheck, faClockRotateLeft, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {
  SCA_CREATE_PAGE,
  // SCA_PAGE_CONTENT_IDEA_COPY,
  // SCA_PAGE_AI_OUTLINE_DOWNLOAD
} from '@/constants/events';

import {MixPanel} from '@/utils/mixpanel';
import {useStore} from '@/store/root-store';
import {CONTENT_ASSISTANT_API} from '@/api/ContentAssistant';
// import {useTranslation} from 'next-i18next';
// import {useStore} from '@/store/root-store';
// import parse from 'html-react-parser';
// import {faArrowsRotate} from '@fortawesome/pro-solid-svg-icons';
import {useRouter} from 'next/router';
import {routes} from '@/utils/const';
import {AtomicBlockUtils, convertToRaw, EditorState} from 'draft-js';
import moment from 'moment';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  aiOutline: any;
  createdAt?: string;
  insertToEditor: any;
  outlineId?: number;
  // rewriteOutline?: (value: string, idx: number) => any;
  // rewritingOutline?: boolean;
  // rewritingOutlineId?: number;
}

export const SingleAiOutline = ({
  aiOutline,
  insertToEditor,
  outlineId,
  // rewriteOutline,
  // rewritingOutline,
  // rewritingOutlineId
}: Props) => {
  // const [copySuccess, setCopySuccess] = useState(false);
  // const [textForInjection, setTextForInjection] = useState(null);

  // const {t} = useTranslation('landing-page-optimizer');

  const {contentOptimizer: {aiOutline: {aiTemplatesController, setIsOpenAiContentToolsDrawer}}} = useStore('');
  const router = useRouter();


  // const downloadImage = async (url: string) => {
  //   if (!url) return false;
  //   const image = await fetch(url, {
  //     mode: 'no-cors',
  //   });
  //   const imageBlog = await image.blob();
  //   const imageURL = URL.createObjectURL(imageBlog);

  //   const link = document.createElement('a');
  //   link.href = imageURL;
  //   link.download = 'Generated art';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const formatImageIntoBlock = (editorState, base64) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'image',
      'IMMUTABLE',
      {src: base64},
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  const handleCreateContent = async () => {
    const pagePayload = {};
    pagePayload['title'] = aiOutline?.result?.heading ?? '';

    // eslint-disable-next-line
    const cleanedHtml = aiOutline?.result ? `<p></p><img src=\"${aiOutline?.result}\" width=\"40%\" alt=\"img\" /><p></p>` : '';
    pagePayload['cleaned_html'] = cleanedHtml;
    const editorState = EditorState.createEmpty();
    const newEditorState = formatImageIntoBlock(editorState, aiOutline?.result);
    const contentState = newEditorState.getCurrentContent();
    const rawJson = JSON.stringify(convertToRaw(contentState));
    pagePayload['editor_state'] = rawJson;

    const {uuid} = await CONTENT_ASSISTANT_API.createPage(pagePayload);

    MixPanel.track(SCA_CREATE_PAGE, {
      'pageId': uuid,
    });

    setIsOpenAiContentToolsDrawer(false);

    router.push(`/${routes.landingPageOptimizer}/${uuid}`, null, {shallow: true});
  };
  return (
    <div className={styles.ContentIdeaContainer}>
      <p className={styles.ContentIdea}>
        <img src={aiOutline?.result} className={styles.ContentIdeaImage}/>
        {/* {convertedText} */}
        {/* {convertedText && parse(aiOutline)} */}

      </p>
      <div className={styles.ideaOrder}>{outlineId + 1}</div>
      <Divider className={styles.popoverDivider} />
      <div style={{display: 'flex', justifyContent: 'space-between', cursor: 'default'}}>

        <div className={styles.controlButtons}>
          {!aiTemplatesController?.isStandaloneTool ? <Button
            color={aiOutline?.isAdded ? 'green' : 'purple'}
            variant='ghost'
            className={styles.addToArticleBtn}
            onClick={() => {
              insertToEditor(aiOutline?.result);
            }}>
            {aiOutline?.isAdded ? <span>
              <FontAwesomeIcon icon={faCheck} style={{marginRight: 10}}/>
                Added
            </span> :
              <span>
                <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}}/>
                Add to article
              </span>}
          </Button> : null}
          {/* <Tooltip
            title={'Download'}
            trigger='hover'
            placement='bottom'
            overlayClassName={styles.copyTooltip}
          >
            <FontAwesomeIcon icon={faDownload} className={styles.downloadButton} onClick={() => {
              downloadImage(aiOutline?.result);

              MixPanel.track(SCA_PAGE_AI_OUTLINE_DOWNLOAD, {
                'description': 'Content idea downloaded',
              });
            }}
            />
          </Tooltip> */}
          <Tooltip
            title={'Open image in new tab'}
            trigger='hover'
            placement='bottom'
            overlayClassName={styles.copyTooltip}
          >
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginLeft: 10}} className={styles.downloadButton} onClick={() => {
              window.open(aiOutline?.result, '_blank');
            }}
            />
          </Tooltip>
          {aiTemplatesController?.isStandaloneTool ?
            <GrayButton onClick={() => handleCreateContent()}>
              <FontAwesomeIcon icon={faPlus} style={{marginRight: 10}}/>
                Create article
            </GrayButton> :
            null}
        </div>
        <Tooltip
          placement='bottomLeft'
          overlayInnerStyle={{
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), #2D2F34',
            boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
            borderRadius: 5,
            padding: 10,
            width: 'max-content',
            maxWidth: 400,
          }}
          title={<div>
            {aiOutline?.createdAt ?
              <div style={{display: 'flex', marginBottom: 5}}>
                <span style={{color: '#E8E8E8', fontSize: 12, marginRight: 20, width: 150}}>Generated on: </span>
                <span style={{color: '#fff', fontSize: 12, fontWeight: 500, maxWidth: 150, whiteSpace: 'pre-wrap'}}>{`${moment(aiOutline?.createdAt)?.format('ll')} - ${moment(aiOutline?.createdAt)?.format('h:mm a')}`}</span>
              </div> :
              null}
            {aiOutline?.label ?
              <div style={{display: 'flex', marginBottom: 5}}>
                <span style={{color: '#E8E8E8', fontSize: 12, marginRight: 20, width: 150}}>Used template: </span>
                <span style={{color: '#fff', fontSize: 12, fontWeight: 500, maxWidth: 150, whiteSpace: 'pre-wrap'}}>{aiOutline?.label}</span>
              </div> :
              null}
            {aiOutline?.inputs?.length ?
              aiOutline.inputs.map((input, idx) => {
                return <div style={{display: 'flex', marginBottom: 5}} key={idx}>
                  <span style={{color: '#E8E8E8', fontSize: 12, marginRight: 20, width: 150}}>{input.fieldLabel}:</span>
                  <span style={{color: '#fff', fontSize: 12, fontWeight: 500, maxWidth: 150, whiteSpace: 'pre-wrap', overflow: 'hidden', display: 'inline-block'}}>{input.fieldValue}</span>
                </div>;
              }) :
              null}
          </div>}>
          <FontAwesomeIcon icon={faClockRotateLeft} style={{margin: 'auto 0 auto auto'}} />
        </Tooltip>
      </div>
    </div>
  );
};
