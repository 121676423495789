import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class SitePropertyEventsApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');

  public async getEvents(params) {
    try {
      const response = await this.axios.get(`${SitePropertyEventsApi.baseUrl}/site-property-events/`, {
        params: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getSiteEvents(params) {
    const getToken = localStorage.getItem('token');

    try {
      const response = await this.axios.get(`${SitePropertyEventsApi.baseUrl}/site-property-events/overview/`, {
        params: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      if (getToken) {
        return Promise.reject(e);
      }
    }
  }

  public async createEvent({property, name, page, date, desc, isSitewide, icon, pageGroup, countryCode}) {
    try {
      const response = await this.axios.post(`${SitePropertyEventsApi.baseUrl}/site-property-events/`,
        {
          site_property: property,
          name: name,
          page: page,
          date: date,
          desc: desc,
          is_sitewide: isSitewide,
          icon: icon,
          page_group: pageGroup,
          country_code: countryCode,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async deleteEvent({id}) {
    try {
      const response = await this.axios.delete(`${SitePropertyEventsApi.baseUrl}/site-property-events/${id}/`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getEventDetail({property, periodStart, periodEnd, relativeDays, id, countryCode}) {
    const params = {
      site_property: property,
      country_code: countryCode,
    };
    if (relativeDays) {
      params['relative_days'] = relativeDays;
    } else {
      params['period_start'] = periodStart;
      params['period_end'] = periodEnd;
    }
    try {
      const response = await this.axios.get(`${SitePropertyEventsApi.baseUrl}/site-property-events/${id}/`, {
        params: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async editEvent({countryCode, id, name, page, date, desc, isSitewide, icon, pageGroup, siteProperty}) {
    try {
      const response = await this.axios.put(`${SitePropertyEventsApi.baseUrl}/site-property-events/${id}/`,
        {
          country_code: countryCode,
          name: name,
          page: page,
          date: date,
          desc: desc,
          is_sitewide: isSitewide,
          icon: icon,
          page_group: pageGroup,
          site_property: siteProperty,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getKeywordTableData(params) {
    try {
      const response = await this.axios.get(`${SitePropertyEventsApi.baseUrl}/keywords/`, {
        params: params,
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default SitePropertyEventsApi;
