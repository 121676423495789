import {types, flow, cast, Instance, getParent} from 'mobx-state-tree';
import {AUDITED_SITES_API_V2} from '@/api/SiteAuditorV2';
import {notification} from '@/utils/notification-v2';
import {EmailSettings, initEmailSettings} from './emailSettings';
import {toJS} from 'mobx';
import moment from 'moment';
import {getSingleUrlParam, isPublicAuditOverview, isPublicAuditPageExplorer} from '@/utils/url';
import {getTokenFromCookies} from '@/api/common-utils';
import Router from 'next/router';
import {AlertsPage, initAlertsPage} from './alertsPage';
import {apiError} from '@/utils/api';
export const segmentModel = types.model({
  actual: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
});
export const totalActualModel = types.model({
  actual: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  total: types.maybeNull(types.number),
  hasData: types.maybeNull(types.boolean),
  segments: types.maybeNull(types.array(segmentModel)),
});

const gscContextModel = types.model({
  propertyUrl: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  gaPropertyId: types.maybeNull(types.string),
});

export const auditedSitesListModel = types.model({
  auditStartedAt: types.maybeNull(types.string),
  crawlErrorMessage: types.maybeNull(types.string),
  crawledPagesValues: types.maybeNull(totalActualModel),
  coreWebVitalsValues: types.maybeNull(totalActualModel),
  favicon: types.maybeNull(types.string),
  gscConnected: types.maybeNull(types.boolean),
  gaConnected: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  postprocessingStatus: types.maybeNull(types.string),
  processingStatus: types.maybeNull(types.string),
  lastCrawled: types.maybeNull(types.string),
  propertyUrl: types.maybeNull(types.string),
  siteHealthValues: types.maybeNull(totalActualModel),
  speedIndexValues: types.maybeNull(totalActualModel),
  indexablePagesValues: types.maybeNull(totalActualModel),
  totalPages: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  isFirstCrawl: types.maybeNull(types.boolean),
  estimatedTimeleft: types.maybeNull(types.string),
  progressBar: types.maybeNull(types.number),
  crawlAgent: types.maybeNull(types.string),
  crawlBudget: types.maybeNull(types.number),
  crawlConcurrency: types.maybeNull(types.number),
  crawlAgentFull: types.maybeNull(types.string),
  isDeletingAudit: types.optional(types.boolean, false),
  currentCrawlDepth: types.union(types.string, types.number),
  averageCrawlSpeed: types.maybeNull(types.string),
  nextProcessingDeltaDays: types.maybeNull(types.number),
  gscContext: types.maybeNull(gscContextModel),
  ottoProjectUuid: types.maybeNull(types.string),
});

export const auditedSitesListBasicModel = types.model({
  crawlErrorMessage: types.maybeNull(types.string),
  favicon: types.maybeNull(types.string),
  gscConnected: types.maybeNull(types.boolean),
  gaConnected: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  processingStatus: types.maybeNull(types.string),
  lastCrawled: types.maybeNull(types.string),
  propertyUrl: types.maybeNull(types.string),
  siteHealthValues: types.maybeNull(totalActualModel),
  status: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  isFirstCrawl: types.maybeNull(types.boolean),
  estimatedTimeleft: types.maybeNull(types.string),
  progressBar: types.maybeNull(types.number),
  gscContext: types.maybeNull(gscContextModel),
});

export const labelValueModel = types.model({
  label: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
});

export const labelValueDetailModel = types.model({
  label: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
  displayValue: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  numericUnit: types.maybeNull(types.string),
  numericValue: types.maybeNull(types.number),
  metric: types.maybeNull(types.string),
});

export const currentChangeModel = types.model({
  current: types.maybeNull(types.number),
  change: types.maybeNull(types.number),
});

export const dateValueModel = types.model({
  date: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  barColor: types.maybeNull(types.string),
});


export const addedChangedRedirectedRemovedModel = types.model({
  added: types.maybeNull(types.number),
  changed: types.maybeNull(types.number),
  redirected: types.maybeNull(types.number),
  removed: types.maybeNull(types.number),
});

export const resolvedOpenedModel = types.model({
  resolved: types.maybeNull(types.number),
  opened: types.maybeNull(types.number),

});

export const dateValuesForPageChangeModel = types.model({
  crawlId: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  values: types.maybeNull(addedChangedRedirectedRemovedModel),
});

export const dateValuesForIssueChangeModel = types.model({
  crawlId: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  values: types.maybeNull(resolvedOpenedModel),
});

export const colorStatusModel = types.model({
  color: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
});

export const desktopMobileValues = types.model({
  desktop: types.maybeNull(types.array(labelValueDetailModel)),
  desktopWebVitals: types.maybeNull(colorStatusModel),
  mobile: types.maybeNull(types.array(labelValueDetailModel)),
  mobileWebVitals: types.maybeNull(colorStatusModel),
});

export const failuresModel = types.model({
  key: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

export const coreWebVitalsModel = types.model({
  color: types.maybeNull(types.string),
  failures: types.maybeNull(types.array(failuresModel)),
  passed: types.maybeNull(types.boolean),
});

export const histogramModel = types.model({
  density: types.maybeNull(types.number),
  end: types.maybeNull(types.union(types.string, types.number)),
  start: types.maybeNull(types.union(types.string, types.number)),
});

export const colorHistogramModel = types.model({
  color: types.maybeNull(types.string),
  histogram: types.maybeNull(types.array(histogramModel)),
  label: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  value: types.maybeNull(types.string),
});

export const webVitalsV2Model = types.model({
  coreWebVitals: types.maybeNull(coreWebVitalsModel),
  cumulativeLayoutShift: types.maybeNull(colorHistogramModel),
  firstContentfulPaint: types.maybeNull(colorHistogramModel),
  firstInputDelay: types.maybeNull(colorHistogramModel),
  largestContentfulPaint: types.maybeNull(colorHistogramModel),
  timestamp: types.maybeNull(types.string),
});

export const siteAuditModel = types.model({
  allPageTypes: types.maybeNull(types.array(labelValueModel)),
  historicalSiteHealth: types.maybeNull(types.array(dateValueModel)),
  historicalPageChanges: types.maybeNull(types.array(dateValuesForPageChangeModel)),
  historicalIssueChanges: types.maybeNull(types.array(dateValuesForIssueChangeModel)),
  indexability: types.maybeNull(types.array(labelValueModel)),
  siteCoreWebVitals: types.maybeNull(desktopMobileValues),
  siteHealth: types.maybeNull(totalActualModel),
  totalIssues: types.maybeNull(currentChangeModel),
  totalPages: types.maybeNull(currentChangeModel),
  webVitalsV2: types.maybeNull(webVitalsV2Model),
});

export const auditedSiteDetailModel = types.model({
  auditDuration: types.maybeNull(types.string),
  auditStartedAt: types.maybeNull(types.string),
  auditCompletedAt: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  postprocessingStatus: types.maybeNull(types.string),
  processingStatus: types.maybeNull(types.string),
  propertyUrl: types.maybeNull(types.string),
  siteAudit: types.maybeNull(siteAuditModel),
  shouldRepoll: types.maybeNull(types.boolean),
});
const releventLinksModel = types.model({
  link: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
});
export const explorerResultsModel = types.model({
  // fundamentals
  url: types.maybeNull(types.union(types.string, types.number)),
  depth: types.maybeNull(types.union(types.string, types.number)),
  type: types.maybeNull(types.union(types.string, types.number)),
  segmentNames: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  importance: types.maybeNull(types.union(types.string, types.number)),
  health: types.maybeNull(types.union(types.string, types.number)),
  https: types.maybeNull(types.boolean),
  status: types.maybeNull(types.union(types.string, types.number)),
  domain: types.maybeNull(types.union(types.string, types.number)),
  redirect: types.maybeNull(types.union(types.string, types.number)),
  downloadTime: types.maybeNull(types.union(types.string, types.number)),
  discovered: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),

  // gsc_insights
  totalKeywords: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  rankChange: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  impressions: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  clicks: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),

  // content
  title: types.maybeNull(types.union(types.string, types.number)),
  h1: types.maybeNull(types.array(types.string)),
  metaDescription: types.maybeNull(types.union(types.string, types.number)),
  // social
  openGraphType: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  openGraphTitle: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  openGraphUrl: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  openGraphDescription: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  openGraphImage: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  twitterCard: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  twitterDescription: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  twitterImage: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  twitterSite: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  twitterTitle: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),

  // indexability
  indexable: types.maybeNull(types.boolean),
  robotsTxt: types.maybeNull(types.boolean),
  xRobotTags: types.maybeNull(types.boolean),
  robotsMeta: types.maybeNull(types.boolean),
  canonicalUrl: types.maybeNull(types.union(types.string, types.number)),
  canonicalType: types.maybeNull(types.union(types.string, types.number)),
  inXmlSitemap: types.maybeNull(types.boolean),

  // relations
  linked: types.maybeNull(types.boolean),
  incomingInternalLinks: types.maybeNull(types.union(types.string, types.number)),
  hreflang: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  previous: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  next: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  outgoingInternalLinks: types.maybeNull(types.union(types.string, types.number)),
  outgoingExternalLinks: types.maybeNull(types.union(types.string, types.number)),
  incomingCanonicalLinks: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  incomingRedirects: types.maybeNull(types.union(types.string, types.number)),
  hreflangVariant: types.maybeNull(types.union(types.string, types.number)),
  mobileVariant: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  ampVariant: types.maybeNull(types.union(types.string, types.number)),

  // lighthouse_web_vitals
  performanceScore: types.maybeNull(types.union(types.string, types.number)),
  coreWebVitals: types.maybeNull(types.boolean),
  speedIndex: types.maybeNull(types.union(types.string, types.number)),
  firstContentfulPaint: types.maybeNull(types.union(types.string, types.number)),
  largestContentfulPaint: types.maybeNull(types.union(types.string, types.number)),
  timeToInteractive: types.maybeNull(types.union(types.string, types.number)),
  totalClockingTime: types.maybeNull(types.union(types.string, types.number)),
  cumulativeLayoutShift: types.maybeNull(types.union(types.string, types.number)),

  // google_search_console
  // clicks: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  clickThroughRate: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  // impressions: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  position: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),

  // google_analytics
  avgTimeOnPage: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  bounceRate: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  pageValue: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  pageViews: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  uniquePageViews: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  conversions: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  revenue: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  avgSessionDuration: types.maybeNull(types.number),
  bounces: types.maybeNull(types.number),
  goalCompletionsAll: types.maybeNull(types.number),
  sessionDuration: types.maybeNull(types.number),
  sessions: types.maybeNull(types.number),
  uniquePurchases: types.maybeNull(types.number),
  users: types.maybeNull(types.number),
  totalUsers: types.maybeNull(types.number),
  userEngagementDuration: types.maybeNull(types.number),
  engagedSessions: types.maybeNull(types.number),
  ecommercePurchases: types.maybeNull(types.number),
  newUsers: types.maybeNull(types.number),
  eventCount: types.maybeNull(types.number),
  screenPageViews: types.maybeNull(types.number),
  totalRevenue: types.maybeNull(types.number),

  // conversions
  analytics: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  visualAnalytics: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  tagManager: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),

  // schema_org
  schemaOrgTypes: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),
  schemaOrgItems: types.maybeNull(types.union(types.string, types.number, types.array(types.string))),

  id: types.maybeNull(types.union(types.string, types.number)),


});
export const filterTypes = types.model({
  field: types.maybeNull(types.string),
  filterType: types.maybeNull(types.string),
});
export const pageExplorerListModel = types.model({
  count: types.maybeNull(types.number),
  crawledPages: types.maybeNull(types.string),
  results: types.maybeNull(types.array(explorerResultsModel)),
  next: types.maybeNull(types.string),
  previous: types.maybeNull(types.string),
  siteHealth: types.maybeNull(totalActualModel),
  totalPages: types.maybeNull(types.number),
  shouldRepoll: types.maybeNull(types.boolean),
  filterTypes: types.maybeNull(types.array(filterTypes)),
  propertyUrl: types.maybeNull(types.string),

});
const contentHeadersModel = types.model({
  count: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  values: types.maybeNull(types.array(types.string)),
});

const explorerDetailContentModel = types.model({
  headers: types.maybeNull(types.array(contentHeadersModel)),
  metaDescription: types.maybeNull(types.string),
  metaDescriptions: types.maybeNull(types.array(types.string)),
  title: types.maybeNull(types.string),
  titles: types.maybeNull(types.array(types.string)),
});

const explorerDetailConversionsModel = types.model({
  // analytics: null
  // tagManager: null
  // visualAnalytics: null
});

const explorerGoogleAnalyticsModel = types.model({
  // avgTimeOnPage: null
  // bounceRate: null
  // conversions: null
  // pageValue: null
  // pageViews: types.maybeNull(types.number),
  // revenue: null
  // uniquePageViews: null
  avgSessionDuration: types.maybeNull(types.number),
  bounceRate: types.maybeNull(types.number),
  totalUsers: types.maybeNull(types.number),
  userEngagementDuration: types.maybeNull(types.number),
  engagedSessions: types.maybeNull(types.number),
  ecommercePurchases: types.maybeNull(types.number),
  newUsers: types.maybeNull(types.number),
  eventCount: types.maybeNull(types.number),
  screenPageViews: types.maybeNull(types.number),
  totalRevenue: types.maybeNull(types.number),
});

export const complaintListModel = types.model({
  description: types.maybeNull(types.string),
  isIssue: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  issueNameSnakecase: types.maybeNull(types.string),
  isIgnored: types.maybeNull(types.boolean),
  severity: types.maybeNull(types.number),
  severityType: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  affectedResources: types.maybeNull(types.frozen({})),
  learnWhy: types.maybeNull(types.string),
  relevantLinks: types.maybeNull(types.array(releventLinksModel)),
});

export const complaintDetailModel = types.model({
  compliantList: types.maybeNull(types.array(complaintListModel)),
  healthToGain: types.maybeNull(types.number),
  issueGroup: types.maybeNull(types.string),
  groupIcon: types.maybeNull(types.string),
  isIgnored: types.maybeNull(types.boolean),
  issueGroupSnakecase: types.maybeNull(types.string),
  issueList: types.maybeNull(types.array(complaintListModel)),
});

export const explorerIssuesModel = types.model({
  compliant: types.maybeNull(types.array(complaintDetailModel)),
  issues: types.maybeNull(types.array(complaintDetailModel)),
});

const googleSearchConsoleModel = types.model({
  clickThroughRate: types.maybeNull(types.number),
  clicks: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  position: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  rankChange: types.maybeNull(types.number),
});

const incomingInternalLinksTableModel = types.model({
  anchorText: types.maybeNull(types.string),
  foundIn: types.maybeNull(types.string),
  rel: types.maybeNull(types.union(types.maybeNull(types.string), types.array(types.string))),
  statusCode: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const outgoingInternalLinksTableModel = types.model({
  anchor: types.maybeNull(types.string),
  foundIn: types.maybeNull(types.string),
  isRedirect: types.maybeNull(types.boolean),
  ordinal: types.maybeNull(types.number),
  redirectsTo: types.maybeNull(types.string),
  rel: types.maybeNull(types.union(types.maybeNull(types.string), types.array(types.string))),
  statusCode: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
});

const gscInsightsModel = types.model({
  organicTraffic: types.maybeNull(types.number),
  rankChange: types.maybeNull(types.number),
  searchImpressions: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
});

const indexabilityModel = types.model({
  canonicalUrl: types.maybeNull(types.string),
  downloadTime: types.maybeNull(types.string),
  https: types.maybeNull(types.boolean),
  inXmlSitemap: types.maybeNull(types.boolean),
  indexable: types.maybeNull(types.boolean),
  metaGooglebot: types.maybeNull(types.array(types.string)),
  metaRobots: types.maybeNull(types.array(types.string)),
  robotsTxt: types.maybeNull(types.string),
  xmlSitemap: types.maybeNull(types.string),
  indexabilityFailReason: types.maybeNull(types.string),
});

const lighthouseWebVitalsModel = types.model({
  performanceScore: types.maybeNull(types.model({
    label: types.maybeNull(types.string),
    color: types.maybeNull(types.string),
    status: types.maybeNull(types.union(types.string, types.number)),
    value: types.maybeNull(types.union(types.number)),
  })),
  values: types.maybeNull(types.array(types.model({
    color: types.maybeNull(types.string),
    label: types.maybeNull(types.string),
    status: types.maybeNull(types.union(types.string, types.number)),
    value: types.maybeNull(types.union(types.string, types.number)),
  }))),
  timestamp: types.maybeNull(types.string),
});

const relationsModel = types.model({
  ampVariant: types.maybeNull(types.string),
  canonical: types.maybeNull(types.string),
  // incomingCanonicalLinks: null
  incomingInternalLinksTable: types.maybeNull(types.array(incomingInternalLinksTableModel)),
  outgoingInternalLinksTable: types.maybeNull(types.array(outgoingInternalLinksTableModel)),
  outgoingExternalLinksTable: types.maybeNull(types.array(outgoingInternalLinksTableModel)),
  incomingInternalLinks: types.maybeNull(types.number),
  // incomingRedirects: null
  outgoingExternalLinks: types.maybeNull(types.number),
  outgoingInternalLinks: types.maybeNull(types.number),
});

// const potentialActionModel = types.model({
//   queryInput: types.maybeNull(types.string),
//   // target: types.maybeNull(types.model({
//   //   type: types.maybeNull(types.string),
//   //   urlTemplate: types.maybeNull(types.string),
//   // })),
//   type: types.maybeNull(types.string),
// });

// const schemaDetailGraphModel = types.model({
//   description: types.maybeNull(types.string),
//   id: types.maybeNull(types.string),
//   inLanguage: types.maybeNull(types.string),
//   name: types.maybeNull(types.string),
//   potentialAction: types.maybeNull(types.array(potentialActionModel)),
//   type: types.maybeNull(types.string),
//   url: types.maybeNull(types.string),
// });

// const schemaDetailModel = types.model({
//   context: types.maybeNull(types.string),
//   graph: types.maybeNull(types.array(schemaDetailGraphModel)),
// });

// const schemaOrgModel = types.model({
//   schema: types.maybeNull(schemaDetailModel),
//   // schemaOrgItems: null
//   // schemaOrgTypes: null
// });

const ogMetaModel = types.model({
  label: types.maybeNull(types.string),
  values: types.maybeNull(types.array(types.string)),
});
const webVitalsModel = types.model({
  color: types.maybeNull(types.string),
  score: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  numericUnit: types.maybeNull(types.string),
  displayValue: types.maybeNull(types.string),
  numericValue: types.maybeNull(types.number),
});

const issuesListModel = types.model({
  description: types.maybeNull(types.string),
  issueName: types.maybeNull(types.string),
  severity: types.maybeNull(types.number),
  isIssue: types.maybeNull(types.boolean),
  isIgnored: types.maybeNull(types.boolean),
  value: types.maybeNull(types.union(types.frozen({}), types.string, types.number, types.boolean)),
  label: types.maybeNull(types.string),
  severityType: types.maybeNull(types.string),
  learnWhy: types.maybeNull(types.string),
  relevantLinks: types.maybeNull(types.array(releventLinksModel)),
});

const sitemapsTableModel = types.model({
  sitemap: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  items: types.maybeNull(types.string),
  references: types.maybeNull(types.array(types.string)),
  lastChecked: types.maybeNull(types.string),
});


const gscData = types.model({
  averagePos: types.maybeNull(types.number),
  ctr: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  impressions: types.maybeNull(types.number),
  kwCount: types.maybeNull(types.number),
  traffic: types.maybeNull(types.number),
});

const gscHistoricalData = types.model({
  daily: types.maybeNull(types.array(gscData)),
});

export type gscHistoricalDataType = Instance<typeof gscHistoricalData>;

export const pageExplorerDetailModel = types.model({
  content: types.maybeNull(explorerDetailContentModel),
  conversions: types.maybeNull(explorerDetailConversionsModel),
  googleAnalytics: types.maybeNull(explorerGoogleAnalyticsModel),
  googleSearchConsole: types.maybeNull(googleSearchConsoleModel),
  gscInsights: types.maybeNull(gscInsightsModel),
  gscHistorical: types.maybeNull(types.union(gscHistoricalData, types.array(types.string))),
  indexability: types.maybeNull(indexabilityModel),
  statusCode: types.maybeNull(types.number),
  issues: types.maybeNull(explorerIssuesModel),
  lighthouseWebVitals: types.maybeNull(lighthouseWebVitalsModel),
  metaDesc: types.maybeNull(types.string),
  relations: types.maybeNull(relationsModel),
  schemaOrg: types.maybeNull(types.string),
  shouldRepoll: types.maybeNull(types.boolean),
  social: types.maybeNull(types.model({
    ogMeta: types.maybeNull(types.array(ogMetaModel)),
    twitterMeta: types.maybeNull(types.array(ogMetaModel)),
  })),
  redirectsTo: types.maybeNull(types.string),
  technicalScore: types.maybeNull(types.number),
  statusCodeColor: types.maybeNull(types.string),
  pageHealth: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  screenshotImage: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  lastCrawl: types.maybeNull(types.string),
  propertyUrl: types.maybeNull(types.string),
  siteHealth: types.maybeNull(totalActualModel),
});

const complaintsModel = types.model({
  affectedPages: types.maybeNull(types.number),
  affectedPagesPct: types.maybeNull(types.number),
  description: types.maybeNull(types.string),
  groupAffected: types.maybeNull(types.number),
  groupAffectedPct: types.maybeNull(types.number),
  healthToGain: types.maybeNull(types.number),
  isCompliant: types.maybeNull(types.union(types.boolean, types.number)),
  issueName: types.maybeNull(types.string),
  value: types.maybeNull(types.union(types.frozen({}), types.string, types.number, types.boolean)),
  isIgnored: types.maybeNull(types.boolean),
  label: types.maybeNull(types.string),
  learnWhy: types.maybeNull(types.string),
  relevantLinks: types.maybeNull(types.array(releventLinksModel)),
  severityType: types.maybeNull(types.string),
});

const siteAuditIssuesListModel = types.model({
  compliantList: types.maybeNull(types.array(complaintsModel)),
  group: types.maybeNull(types.string),
  groupAffected: types.maybeNull(types.number),
  errorCount: types.maybeNull(types.number),
  compliantCount: types.maybeNull(types.number),
  noticeCount: types.maybeNull(types.number),
  warningCount: types.maybeNull(types.number),
  groupAffectedPct: types.maybeNull(types.number),
  groupIcon: types.maybeNull(types.string),
  healthToGain: types.maybeNull(types.number),
  isCompliant: types.maybeNull(types.union(types.boolean, types.number, types.array(types.string))),
  isDomainLevel: types.maybeNull(types.boolean),
  issuesList: types.maybeNull(types.array(complaintsModel)),
  groupSnakecase: types.maybeNull(types.string),
  isIgnored: types.maybeNull(types.boolean),
  isSkipped: types.maybeNull(types.boolean),
});

const robotsTxtTableModel = types.model({
  contents: types.maybeNull(types.array(types.maybeNull(types.string))),
  lastCrawl: types.maybeNull(types.string),
  responseHeaders: types.maybeNull(types.frozen({})),
  timestamp: types.maybeNull(types.string),
});

const siteWideDetailModel = types.model({
  shouldRepoll: types.maybeNull(types.boolean),
  domain: types.maybeNull(types.model({
    canonicalDomain: types.maybeNull(types.string),
    https: types.maybeNull(types.boolean),
  })),
  propertyUrl: types.maybeNull(types.string),
  siteHealth: types.maybeNull(totalActualModel),
  robotsTxt: types.maybeNull(types.model({
    canonicalUrls: types.maybeNull(types.array(types.union(types.string, types.model({url: types.maybeNull(types.string)})))),
    xmlSitemapReferences: types.maybeNull(types.array(types.string)),
    lastChange: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
  })),

  webVitals: types.maybeNull(types.model({
    speedIndex: types.maybeNull(webVitalsModel),
    firstInputDelay: types.maybeNull(webVitalsModel),
    cumulativeLayoutShift: types.maybeNull(webVitalsModel),
    largestContentfulPaint: types.maybeNull(webVitalsModel),
  })),

  webVitalsV2: types.maybeNull(webVitalsV2Model),

  issues: types.maybeNull(types.array(
    types.model({
      type: types.maybeNull(types.string),
      groupIcon: types.maybeNull(types.string),
      groupSnakecase: types.maybeNull(types.string),
      issuesList: types.maybeNull(types.array(issuesListModel)),
      compliantList: types.maybeNull(types.array(issuesListModel)),
      healthToGain: types.maybeNull(types.number),
      isIgnored: types.maybeNull(types.boolean),
    }),
  )),
  compliant: types.maybeNull(types.array(
    types.model({
      type: types.maybeNull(types.string),
      groupIcon: types.maybeNull(types.string),
      groupSnakecase: types.maybeNull(types.string),
      issuesList: types.maybeNull(types.array(issuesListModel)),
      compliantList: types.maybeNull(types.array(issuesListModel)),
      healthToGain: types.maybeNull(types.number),
      isIgnored: types.maybeNull(types.boolean),
    }),
  )),
  sitemapsTable: types.maybeNull(types.array(sitemapsTableModel)),
  robotsTxtContents: types.maybeNull(types.array(types.string)),
  robotsTxtTable: types.maybeNull(types.array(robotsTxtTableModel)),
  xmlSitemaps: types.maybeNull(types.model({
    indexes: types.maybeNull(types.number),
    sitemapUrls: types.maybeNull(types.number),
    sitemaps: types.maybeNull(types.number),
  })),
});

export const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  search: types.maybeNull(types.string),
  ordering: types.maybeNull(types.string),
});

export const filterModel = types.model({
  filterType: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  other: types.maybeNull(types.union(types.string, types.boolean)),

});
export const affectedPageData = types.model({
  label: types.maybeNull(types.string),
  issueLabel: types.maybeNull(types.string),
  data: types.maybeNull(types.string),
});

export const affectedPagesDrawerModel = types.model({
  healthScore: types.maybeNull(types.number),
  issueCount: types.maybeNull(types.number),
  issueType: types.maybeNull(types.string),
  datatype: types.maybeNull(types.string),
  isTableType: types.maybeNull(types.boolean),
  pagerank: types.maybeNull(types.number),
  pk: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  affectedResources: types.maybeNull(types.union(types.array(affectedPageData), types.model({
    label: types?.maybeNull(types.string),
    data: types.maybeNull(types.string),
    issueLabel: types.maybeNull(types.string),
  }))),
});
type AffectedPagesDrawerModel = Instance<typeof affectedPagesDrawerModel>;

const detailIgnoreLoaderModel = types.model({
  key: types.string,
  check: types.boolean,
});

const pageExplorerColumnsListModel = types.model({
  name: types.string,
  color: types.maybeNull(types.string),
  filterType: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});

const IssueListModel = types.model({
  issueName: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  pages: types.maybeNull(types.array(types.string)),
});

const IssueChartDetailDataModel = types.model({
  group: types.maybeNull(types.string),
  issues: types.maybeNull(types.array(IssueListModel)),
});

const ChartDetailDataModel = types.model({
  url: types.maybeNull(types.string),
  changeType: types.maybeNull(types.string),
});
const filterHeader = types.model({
  field: types.maybeNull(types.string),
  headerValue: types.maybeNull(types.string),
});
const CrawelSettingDetailModal = types.model({
  selectedUserAgent: types.maybeNull(types.string),
  crawlBudget: types.maybeNull(types.number),
  crawlConcurrency: types.maybeNull(types.number),
  nextProcessingDeltaDays: types.maybeNull(types.string),
  updatedSetting: types.maybeNull(types.boolean),
});
const siteHealthAndPropertyUrlModel = types.model({
  propertyUrl: types.maybeNull(types.string),
  actual: types.maybeNull(types.number),
  total: types.maybeNull(types.number),
  color: types.maybeNull(types.string),
});

const segmentsModal = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  icon: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  sizeLimit: types.maybeNull(types.string),
  ordering: types.maybeNull(types.string),
  requestQueryParams: types.maybeNull(types.frozen({})),
});
const siteAuditSpacificDataModel = types.model({
  favicon: types.maybeNull(types.string),
  propertyUrl: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  gaConnected: types.maybeNull(types.boolean),
  estimatedTimeleft: types.maybeNull(types.string),
  // progressBar: types.maybeNull(types.string),
  lastCrawled: types.maybeNull(types.string),
  isFirstCrawl: types.maybeNull(types.boolean),
  processingStatus: types.maybeNull(types.string),
  gscConnected: types.maybeNull(types.boolean),
  segments: types.maybeNull(types.array(segmentsModal)),
  id: types.maybeNull(types.number),
  siteHealthValues: types.maybeNull(totalActualModel),
  gscContext: types.maybeNull(gscContextModel),
});
const affectedFilterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  header: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  equal: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  operator: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  active: types.maybeNull(types.boolean),
  filterField: types.maybeNull(types.string),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
});
const dataArray = types.model({
  url: types.maybeNull(types.string),
  contentType: types.maybeNull(types.string),
  statusCode: types.maybeNull(types.number),
});
const childrenModel = types.model({
  children: types.maybeNull(types.array(types.frozen({}))),
  depth: types.maybeNull(types.number),
  h1Header: types.maybeNull(types.string),
  h2Header: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  issueCount: types.maybeNull(types.number),
  itemStyle: types.maybeNull(types.model({
    color: types.maybeNull(types.string),
  })),
  keywords: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  pageHealth: types.maybeNull(types.number),
  pagerank: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});
const depthNodesModel = types.model({
  children: types.maybeNull(types.array(childrenModel)),
  depth: types.maybeNull(types.number),
  h1Header: types.maybeNull(types.string),
  h2Header: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  impressions: types.maybeNull(types.number),
  issueCount: types.maybeNull(types.number),
  itemStyle: types.maybeNull(types.model({
    color: types.maybeNull(types.string),
  })),
  keywords: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  pageHealth: types.maybeNull(types.number),
  pagerank: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  wordCount: types.maybeNull(types.number),
});

export const AuditedSitesV2Store = types.model({
  openGscModal: types.maybeNull(types.boolean),
  gscModalHeading: types.maybeNull(types.string),
  loadingList: types.boolean,
  loadMoreLoading: types.boolean,
  isSiteAudit: types.boolean,
  saIdForGscSettings: types.maybeNull(types.union(types.number, types.string)),
  loadingSiteAuditIssuesList: types.boolean,
  loadingAuditSiteWideData: types.boolean,
  loadingChartDetailData: types.boolean,
  loading: types.boolean,
  loadingAuditDetail: types.boolean,
  loadingPageExplorerList: types.boolean,
  loadingAffectedPagesDrawerData: types.boolean,
  loadingCrawlUpdate: types.boolean,
  loadingBulkUpload: types.boolean,
  loadingPageExplorerDetail: types.boolean,
  detailIgnoreLoader: types.array(detailIgnoreLoaderModel),
  auditedSitesList: types.maybeNull(types.array(auditedSitesListModel)),
  auditedSitesListBasic: types.maybeNull(types.array(auditedSitesListBasicModel)),
  sitesListBasicLoading: types.boolean,
  siteAuditIssuesList: types.maybeNull(types.array(siteAuditIssuesListModel)),
  chartDetailData: types.maybeNull(types.array(ChartDetailDataModel)),
  IssueChartDetailData: types.maybeNull(types.array(IssueChartDetailDataModel)),
  auditedSiteDetail: types.maybeNull(auditedSiteDetailModel),
  auditedSiteSegmentDetail: types.maybeNull(types.array(types.model({
    segmentId: types.maybeNull(types.string),
    segmentData: types.maybeNull(auditedSiteDetailModel),
  }))),
  siteWideDetail: types.maybeNull(siteWideDetailModel),
  pageExplorerList: types.maybeNull(pageExplorerListModel),
  urlWeb: types.maybeNull(types.string),
  pageExplorerDetail: types.maybeNull(pageExplorerDetailModel),
  newAuditedSiteId: types.maybeNull(types.number),
  siteAuditIssuesAffected: types.maybeNull(types.number),
  siteAuditIssuesCrawled: types.maybeNull(types.number),
  siteAuditIssuesTotal: types.maybeNull(types.number),
  siteAuditPropertyUrl: types.maybeNull(types.string),
  issuesPageSiteHealth: types.maybeNull(totalActualModel),
  segmentAffectedPages: types.maybeNull(types.number),
  segmentTotalPages: types.maybeNull(types.number),
  loadingIsuePage: types.maybeNull(types.boolean),
  params: types.maybeNull(paramsModel),
  paramsBasic: types.maybeNull(paramsModel),
  loadingSiteHealthData: types.optional(types.boolean, true),
  loadingSaDropdownList: types.optional(types.boolean, true),
  affectedPagesDrawerData: types.maybeNull(types.array(affectedPagesDrawerModel)),
  pageExplorerParams: types.maybeNull(paramsModel),
  pageExplorerFilter: types.maybeNull(types.array(filterModel)),
  auditedSitesListCount: types.maybeNull(types.number),
  maxTotalPageBudget: types.maybeNull(types.number),
  siteAuditSelectedProperty: types.maybeNull(types.number),
  pageExplorerDetailRepolling: types.maybeNull(types.boolean),
  pageExplorerListRepolling: types.maybeNull(types.boolean),
  auditedSiteDetailRepolling: types.maybeNull(types.boolean),
  auditedSitesListRepolling: types.maybeNull(types.boolean),
  auditedSitesListRepollingBasic: types.maybeNull(types.boolean),
  showEmptyState: types.maybeNull(types.boolean),
  showIgnoreIssuesModal: types.maybeNull(types.boolean),
  pageExplorerProperty: types.maybeNull(types.string),
  pageExplorerDetailId: types.maybeNull(types.string),
  pageExplorerColumnsList: types.maybeNull(types.array(pageExplorerColumnsListModel)),
  previousListInStore: types.maybeNull(types.string),
  auditPeriodStart: types.maybeNull(types.string),
  stopFunctionCall: types.optional(types.boolean, false),
  auditPeriodEnd: types.maybeNull(types.string),
  filterHeader: types.maybeNull(types.array(filterHeader)),
  affectedUrl: types.maybeNull(types.string),
  loadingDepthNodes: types.maybeNull(types.boolean),
  clicked: types.maybeNull(types.boolean),
  siteAuditSpacificData: types.maybeNull(siteAuditSpacificDataModel),
  siteAuditSpacificDataLoading: types.maybeNull(types.boolean),
  crawelSetting: types.maybeNull(CrawelSettingDetailModal),
  depthNodes: types.maybeNull(depthNodesModel),
  lastDepthNode: types.maybeNull(types.frozen({})),
  depthNodesGraph: types.maybeNull(types.frozen({})),
  importanceSlider: types.maybeNull(types.number),
  perPages: types.maybeNull(types.number),
  sliderValue: types.maybeNull(types.number),
  crawlBudgetError: types.maybeNull(types.string),
  segmentValue: types.maybeNull(types.string),
  healthState: types.maybeNull(types.model({
    actual: types.maybeNull(types.number),
    total: types.maybeNull(types.number),
    color: types.maybeNull(types.string),
  })),

  // affectedFilterMain: types.maybeNull
  affectedFilterList: types.array(affectedFilterListModel),
  filterParams: types.maybeNull(types.string),
  emailSettings: EmailSettings,
  alertsStore: AlertsPage,
  auditSettingModal: types.maybeNull(types.boolean),
  gscLoading: types.maybeNull(types.boolean),
  siteHealthAndPropertyUrl: types.maybeNull(siteHealthAndPropertyUrlModel),
  siteHealthIncrease: types.maybeNull(types.number),
  loadingMoreExplorerPages: types.maybeNull(types.boolean),
  callingApi: types.maybeNull(types.boolean),
  manageColumnsVersionPE: types.maybeNull(types.string),
  recordEnded: types.boolean,
  appliedFiltersInStore: types.maybeNull(types.frozen({})),
  affectedPagesParams: types.maybeNull(types.model({
    ordering: types.string,
    page: types.number,
    page_size: types.number,
  })),
  loadingSegment: types.maybeNull(types.boolean),
  robotsTabsData: types.maybeNull(robotsTxtTableModel),
  loadProjectDetails: types.maybeNull(types.boolean),
  notStandardData: types.maybeNull(types.array(dataArray)),
  isInOtto: types.maybeNull(types.boolean),
  notStandardDataLoading: types.boolean,
  callGscModalApi: types.boolean,
}).views(self => ({
  get getPageExplorerList() {
    return toJS(self.pageExplorerList);
  },
  get getRobotsTabsData() {
    return toJS(self.robotsTabsData);
  },
  get getappliedFiltersInStore() {
    return toJS(self.appliedFiltersInStore);
  },
  get getAffectedUrl() {
    return toJS(self.affectedUrl);
  },
  get getAffectedFilterList() {
    return toJS(self.affectedFilterList);
  },
  get getAuditedSiteSegmentDetail() {
    return toJS(self.auditedSiteSegmentDetail);
  },
  get getPageExplorerListCount() {
    return toJS(self.pageExplorerList?.count);
  },
  get getSiteHealthAndPropertyUrl() {
    return toJS(self.siteHealthAndPropertyUrl);
  },
  get getPageExplorerColumnsList() {
    return toJS(self.pageExplorerColumnsList);
  },
  get getSiteAuditSpacificData() {
    return toJS(self.siteAuditSpacificData);
  },
  get getPageExplorerDetail() {
    return toJS(self.pageExplorerDetail);
  },
  get getUrlWeb() {
    return toJS(self.urlWeb);
  },
  get getSiteAuditIssuesList() {
    return toJS(self.siteAuditIssuesList);
  },
  get getAuditedSiteDetailData() {
    return toJS(self.auditedSiteDetail);
  },
  get getSiteWideDetail() {
    return toJS(self.siteWideDetail);
  },
  get getAuditSitesList() {
    return toJS(self.auditedSitesList);
  },
  get getAuditSitesListBasic() {
    return toJS(self.auditedSitesListBasic);
  },
  get isLoadingSegment() {
    return toJS(self.loadingSegment);
  },
  get getAffectedPagesDrawerData() {
    return toJS(self.affectedPagesDrawerData);
  },
  get getDetailIgnoreLoader() {
    return toJS(self.detailIgnoreLoader);
  },
  get getIssueChartDetailData() {
    return toJS(self.IssueChartDetailData);
  },
  // get getSelectedSiteSA() {
  //   const propertyStore = self.siteAuditSelectedProperty ?? '';
  //   const propertyStorage = localStorage.getItem('lastPropertySiteAudit');
  //   if (propertyStorage) {
  //     return propertyStorage?.toString();
  //   } else if (propertyStore) {
  //     return propertyStore?.toString();
  //   } else {
  //     let state = 'wait';
  //     setTimeout(() => {
  //       state = toJS(self.auditedSitesListBasic)?.length || !self.loadingSaDropdownList ? self.siteAuditSelectedProperty?.toString() : '';
  //     }, 1500);
  //     return state;
  //   }
  // },
})).actions(self => {
  const setPropertyUrl = value => {
    if (self.siteAuditSpacificData) {
      self.siteAuditSpacificData.propertyUrl = value;
    }
  };

  const updateSelectedSiteSA = id => {
    self.siteAuditSelectedProperty = Number(id);
    localStorage.setItem('lastPropertySiteAudit', String(id));
  };
  const setLoadProjectDetails = value => {
    self.loadProjectDetails = value;
  };
  const setSelectedSiteAuditInStore = id => {
    self.siteAuditSelectedProperty = Number(id);
  };

  const setIsInOtto = value => {
    self.isInOtto = value;
  };

  const setSpecificIDResponse = flow(function* (id: any) {
    self.siteAuditSpacificDataLoading = true;
    try {
      const data = yield AUDITED_SITES_API_V2.getSpecificIdResponse(id);
      if (data) {
        self.siteAuditSpacificData = cast(data);
      }
      const checkProcessing = (data?.status == 'processing' || data?.status == null);
      if (checkProcessing) {
        yield new Promise(r => setTimeout(r, 10000));
        return setSpecificIDResponse(id);
      }
      // return data;
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.siteAuditSpacificDataLoading = false;
    }
  });
  const checkSpecificIDResponse = flow(function* (id: any) {
    try {
      const data = yield AUDITED_SITES_API_V2.getSpecificIdResponse(id);
      return data;
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    }
  });

  const setFilterHeader = (value: any) => {
    self.filterHeader = value;
  };
  const setRobotsTabsData = data => {
    self.robotsTabsData = data;
  };

  const setAuditedSitesList = data => {
    self.auditedSitesList = data;
  };

  const setCrawlBudgetError = (value: string) => {
    self.crawlBudgetError = value;
  };

  const setHealthSliderValue = (value: number) => {
    self.sliderValue = value;
  };
  const setUrlWeb = (value: string) => {
    self.urlWeb = value;
  };

  const setPerPages = (value: number) => {
    self.perPages = value;
  };


  const setDetailIgnoreLoader = (name, check) => {
    const checkdata = self.detailIgnoreLoader.findIndex(data => data.key == name);
    if (checkdata >= 0) {
      const data = self.getDetailIgnoreLoader;
      data[checkdata].check = check;
      self.detailIgnoreLoader = cast(data);
    } else {
      self.detailIgnoreLoader = cast(self.detailIgnoreLoader.concat([{key: name, check: check}]));
    }
  };

  const createSegment = flow(function* (data: any, projectId:number) {
    self.loadingSegment = true;
    try {
      yield AUDITED_SITES_API_V2.createSegment(data, projectId, self.filterParams);
      notification.success('Created segment', 'Segment created sucessfully' );
      return 'response';
    } catch (err) {
      notification.error('Segment not created ', apiError(err) as string);
      self.loadingSegment = false;
    } finally {
      self.loadingSegment = false;
    }
  });


  const modifySegment = flow(function* (data: any) {
    self.loadingSegment = true;
    try {
      yield AUDITED_SITES_API_V2.modifySegment(data, self.filterParams);
      notification.success('Modify segment', 'Segment Modify sucessfully' );
      return 'response';
    } catch (err) {
      notification.error('Segment not modify ', apiError(err) as string);
      self.loadingSegment = false;
    } finally {
      self.loadingSegment = false;
    }
  });

  const deleteSegment = flow(function* (Id: any, segmentName:any) {
    self.loadingSegment = true;
    try {
      const response = yield AUDITED_SITES_API_V2.deleteSegment(Id, segmentName);
      notification.success('Segment Deleted', 'Segment deleted sucessfully' );
      return response;
    } catch (err) {
      notification.error('Segment not created ', apiError(err) as string);
      self.loadingSegment = false;
    } finally {
      self.loadingSegment = false;
    }
  });


  const setSiteHealthAndPropertyUrl = value => {
    self.siteHealthAndPropertyUrl = value;
  };
  const loadAuditedSitesList = flow(function* (noLoading?: boolean, basicData?: boolean, searchData?: any, isLoadMore?: boolean, setLoadMore?: any) {
    self.showEmptyState = false;
    self.loadingList = true;
    try {
      let data: any = [];
      if (searchData) {
        data = searchData;
      } else {
        data = yield AUDITED_SITES_API_V2.getAuditedSitesList(toJS(self.params), !!basicData);
      }
      if (data.isCancel) return;
      if (data) {
        if (self.params.page == 1) {
          self.auditedSitesList = cast(data?.results);
          self.loadingList = false;
        } else {
          const newSite = [];
          data?.results?.map(item => {
            const siteIndex = self.auditedSitesList?.findIndex(e => e.id == item.id);
            if (isLoadMore) {
              if ((data?.results ?? []).length) {
                self.auditedSitesList = cast([...toJS(self.auditedSitesList ?? []), ...(data?.results || [])]);
              }
            }
            if (siteIndex > 0) {
              self.auditedSitesList[siteIndex] = item;
            } else {
              newSite.push(item);
            }
          });
          if (newSite.length > 0) {
            self.auditedSitesList = cast([...toJS(self.auditedSitesList), ...newSite]);
          }

          self.loadingList = false;
        }
        setLoadMore && setLoadMore(false);
        self.auditedSitesListCount = data?.count;
        self.maxTotalPageBudget = data?.maxTotalPageBudget;
        self.loadMoreLoading = false;
      }
      const checkProcessing = data?.results?.some(item => item?.status == 'processing' || item?.status == null);
      if (checkProcessing && self.auditedSitesListRepolling) {
        yield new Promise(r => setTimeout(r, 10000));
        return loadAuditedSitesList(true);
      }
      self.showEmptyState = true;
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      } else {
        const errorMessage = apiError(err) as string;
        notification.error('Failed to fetch data', errorMessage);
      }
    } finally {
      self.loadingList = false;
    }
  });

  const setAffectedFIlter = filter => {
    self.affectedFilterList = cast(filter);
  };

  const setAppliedFiltersInStore = value => {
    self.appliedFiltersInStore = value;
  };

  const loadAuditedSitesListBasic = flow(function* () {
    self.loadingSiteHealthData = true;
    self.loadingSaDropdownList = true;
    try {
      const data = yield AUDITED_SITES_API_V2.getAuditedSitesList(toJS(self.paramsBasic), true);
      if (data.isCancel) return;
      if (data) {
        if (self.paramsBasic.page == 1) {
          self.auditedSitesListBasic = cast(data?.results);
          self.sitesListBasicLoading = false;
        } else {
          const newSite = [];
          data?.results?.map(item => {
            const siteIndex = self.auditedSitesListBasic?.findIndex(e => e.id == item.id);
            if (siteIndex > 0) {
              self.auditedSitesListBasic[siteIndex] = item;
            } else {
              newSite.push(item);
            }
          });
          if (newSite.length > 0) {
            self.auditedSitesListBasic = cast([...toJS(self.auditedSitesListBasic), ...newSite]);
            // self.sitesListBasicLoading = false;
          }
        }
      }
      const checkProcessing = data?.results?.some(item => item?.status == 'processing' || item?.status == null);
      if (checkProcessing && self.auditedSitesListRepollingBasic) {
        yield new Promise(r => setTimeout(r, 10000));
        return loadAuditedSitesListBasic();
      }
      self.showEmptyState = true;
    } catch (err) {
      self.loadingSiteHealthData = false;
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      } else {
        const errorMessage = apiError(err) as string;
        notification.error('Failed to fetch data', errorMessage);
      }
    } finally {
      self.loadingList = false;
      self.loadingSiteHealthData = false;
      self.loadingSaDropdownList = false;
    }
  });

  const setParamsBasic = value => {
    self.sitesListBasicLoading = true;
    if (self.paramsBasic.search != value?.search) {
      value.page = 1;
    }
    self.paramsBasic.search = value?.search;

    if (value?.page) {
      self.paramsBasic.page = value?.page;
    }
    if (value?.page_size) {
      self.paramsBasic.page_size = value?.page_size;
    }
    if (!value?.noApiCall) {
      loadAuditedSitesListBasic();
    }
  };

  const setParams = (value, noApiCall?: boolean, isLoadMore?: boolean) => {
    if (self.params.search != value?.search) {
      value.page = 1;
    }
    self.params.search = value?.search;

    if (value?.page) {
      self.params.page = value?.page;
    }
    if (value?.page_size) {
      self.params.page_size = value?.page_size;
    }
    self.params.ordering = value?.ordering;
    if (!noApiCall) {
      loadAuditedSitesList(true, null, null, isLoadMore);
    }
  };

  const setLoadMoreLoading = value => {
    self.loadMoreLoading = value;
  };

  const resetPageExplorerParams = () => {
    self.pageExplorerProperty = '';
    // self.pageExplorerList = null;
    self.pageExplorerParams = {
      page_size: 50,
      page: 1,
      search: '',
      ordering: '-activated_at',
    };
  };
  const setPageExplorerParams = (value, noApiCall?: boolean) => {
    self.pageExplorerParams.search = value?.search;

    if (value?.page) {
      self.pageExplorerParams.page = value?.page;
    }
    if (value?.pageSize) {
      self.pageExplorerParams.page_size = value?.pageSize;
    }
    if (!noApiCall) {
      loadPageExplorerList(self.pageExplorerProperty, self.pageExplorerColumnsList, false);
    }
  };

  const setPageWithFilter = () => {
    self.pageExplorerParams.page = 1;
  };

  const handleExportIssuesPage = flow(function* (id) {
    try {
      yield AUDITED_SITES_API_V2.exportIssuesPage(id);
      notification.success('', 'You will receive an email with a download link when the export is ready', '');
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingAffectedPagesDrawerData = false;
    }
  });

  const loadSiteAuditIssuesList = flow(function* (id, name='', noLoading?: boolean, noSubLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingSiteAuditIssuesList = true;
    }
    if (!noSubLoading) {
      self.loadingIsuePage = true;
    }
    try {
      const token = getTokenFromCookies();
      if (!token && !publicHash) {
        Router.push('/login');
      } else {
        const data = yield AUDITED_SITES_API_V2.getSiteAuditIssuesList(id, name, publicHash);
        if (data.isCancel) return;
        if (data) {
          self.siteAuditIssuesList = data?.issues;
          self.siteAuditIssuesAffected = data?.affectedPages;
          self.siteAuditIssuesCrawled = data?.crawledPages;
          self.siteAuditIssuesTotal = data?.totalPages;
          self.siteAuditPropertyUrl = data?.propertyUrl;
          self.issuesPageSiteHealth = data?.siteHealth;
          self.segmentAffectedPages = data?.segmentAffectedPages;
          self.segmentTotalPages = data?.segmentTotalPages;
        }
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loadingSiteAuditIssuesList = false;
      self.loadingIsuePage = false;
    }
  });

  const setAffetedPagesFromResponse = data => {
    const mergedData = data?.map(item => {
      const isArray = Array.isArray(item?.affectedResources);
      if (isArray) {
        const dummyArray = [];
        item?.affectedResources?.map(z => {
          dummyArray.push({label: z?.label, data: JSON.stringify(z?.data), issueLabel: z?.issueLabel});
        });
        return {
          ...item,
          affectedResources: dummyArray,
        };
      } else {
        return {
          ...item,
          affectedResources: {
            label: item?.affectedResources?.label,
            data: !item?.affectedResources?.data ? null : JSON.stringify(item?.affectedResources?.data),
            issueLabel: item?.affectedResources?.issueLabel ? JSON.stringify(item?.affectedResources?.issueLabel) : null,
          },
        };
      }
    }) as AffectedPagesDrawerModel[];
    if (self.affectedPagesParams.page == 1) {
      self.affectedPagesDrawerData = cast([...mergedData]);
    } else {
      self.affectedPagesDrawerData = cast([...self.affectedPagesDrawerData, ...mergedData]);
    }
    self.loadingAffectedPagesDrawerData = false;
  };

  const setAffectedPagesParams = data => {
    self.affectedPagesParams.page = data?.page;
    self.affectedPagesParams.ordering = data?.ordering ? data?.ordering : '';
  };

  const setAffectedPagesTableData = data => {
    self.affectedPagesDrawerData = cast(data || []);
  };

  const resetAffectedPagesList = () => {
    self.affectedPagesDrawerData = cast([]);
    setAffectedPagesParams({
      page: 1,
    });
  };

  const loadSiteAuditAffectedPages = flow(function* (id, data) {
    const publicHash = getSingleUrlParam('public_hash');
    self.callingApi = true;
    if (data?.page == 1) {
      self.loadingAffectedPagesDrawerData = true;
      self.recordEnded = false;
    }
    const payload = data;
    try {
      const data = yield AUDITED_SITES_API_V2.loadAuditAffectedPages(id, payload, self.segmentValue, publicHash);
      if (data.isCancel) return;
      if (data) {
        setAffetedPagesFromResponse(data);
      }
      if (data?.length == 0) {
        self.recordEnded = true;
      }
    } catch (err) {
      if (err.response?.status == 400) {
        notification.error('Error', err.response?.data?.issue?.length ? err.response?.data?.issue[0] : (err.response?.data?.group?.length ? err.response?.data?.group[0] : 'Not a valid field name'));
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingAffectedPagesDrawerData = false;
      self.callingApi = false;
    }
  });

  const checkIsSiteAudit = flow(function* (search:string) {
    self.showEmptyState = false;
    try {
      let data: any = [];
      data = yield AUDITED_SITES_API_V2.getAuditedSitesList({
        search,
        page_size: 100,
        page: 1,
      });
      if (data.isCancel) return;
      if (data?.results.length > 0) {
        return toJS(data?.results).some(z => z.propertyUrl !== '' && search.includes(z.propertyUrl));
      }
      self.loadingList = false;
      self.showEmptyState = true;
      return false;
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return false;
    } finally {
      self.loadingList = false;
    }
  });

  const handleIgnoreIssues = flow(function* (id, payload, stopIgnoring?: boolean) {
    self.loadingAffectedPagesDrawerData = true;
    try {
      yield AUDITED_SITES_API_V2.ignoreIssuesApi(id, payload, stopIgnoring);
      setParams({page_size: 100});
    } catch (err) {
      if (err.response?.status == 400) {
        notification.error('Error', err.response?.data?.issue?.length ? err.response?.data?.issue[0] : (err.response?.data?.group?.length ? err.response?.data?.group[0] : 'Not a valid field name'));
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingAffectedPagesDrawerData = false;
    }
  });

  const loadSiteWideDetail = flow(function* (id, noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingAuditSiteWideData = true;
    }
    try {
      const token = getTokenFromCookies();
      if (!token && !publicHash) {
        Router.push('/login');
      } else {
        const data = yield AUDITED_SITES_API_V2.getSiteWideDetail(id, publicHash);
        if (data.isCancel) return;
        if (data) {
          // data.issues = data?.issues?.map(item => {
          //   return {
          //     ...item,
          //     compliantList: item?.compliantList?.map(z => {
          //       return {
          //         ...z,
          //         value: !z?.value ? null : JSON.stringify(z?.value),
          //       };
          //     }),
          //     issuesList: item?.issuesList?.map(z => {
          //       return {
          //         ...z,
          //         value: !z?.value ? null : JSON.stringify(z?.value),
          //       };
          //     }),
          //   };
          // });

          // data.compliant = data?.compliant?.map(item => {
          //   return {
          //     ...item,
          //     compliantList: item?.compliantList?.map(z => {
          //       return {
          //         ...z,
          //         value: !z?.value ? null : JSON.stringify(z?.value),
          //       };
          //     }),
          //     issuesList: item?.issuesList?.map(z => {
          //       return {
          //         ...z,
          //         value: !z?.value ? null : JSON.stringify(z?.value),
          //       };
          //     }),
          //   };
          // });

          self.siteWideDetail = cast(data);
        }
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.loadingAuditSiteWideData = false;
    }
  });
  const onLoadAuditedSiteDetail = id => {
    self.loadingAuditDetail = true;
    self.auditedSiteDetail = null;
    loadAuditedSiteDetail(id);
  };

  const handleSegmentsList = data => {
    self.auditedSiteSegmentDetail = cast(data);
  };

  const loadAuditedSiteDetail = flow(function* (id, segmentId?: string) {
    const publicHash = getSingleUrlParam('public_hash');
    try {
      const token = getTokenFromCookies();
      if (!token && !publicHash) {
        Router.push('/login');
      } else {
        const data = yield AUDITED_SITES_API_V2.getAuditedSiteDetail(id, self.auditPeriodStart, self.auditPeriodEnd, publicHash, segmentId);
        if (data?.isCancel) return;
        if (segmentId) {
          const formateData = self.auditedSiteSegmentDetail || [];
          const checkIdx = formateData?.findIndex(z => z?.segmentId == `${segmentId}`);
          if (checkIdx !== -1) {
            formateData[checkIdx] = {
              segmentId: `${segmentId}`,
              segmentData: data,
            };
          } else {
            formateData.push({
              segmentId: `${segmentId}`,
              segmentData: data,
            });
          }
          self.auditedSiteSegmentDetail = cast(formateData);
        } else {
          if (data) {
            const selectedProperty = self.siteAuditSelectedProperty ? self.siteAuditSelectedProperty : parseInt(localStorage.getItem('lastPropertySiteAudit'));
            if (selectedProperty == id || isPublicAuditOverview()) {
              self.auditedSiteDetail = data;
              self.loadingAuditDetail = false;
            }
          }
          let timeOutId = null;

          if (data?.shouldRepoll && self.auditedSiteDetailRepolling) {
            timeOutId = yield new Promise(r => setTimeout(r, 2000));
            return loadAuditedSiteDetail(id);
          } else {
            const parent = getParent(self) as any;
            const uuid = getSingleUrlParam('uuid');
            clearTimeout(timeOutId);
            setSpecificIDResponse(id);
            if (self.isInOtto) {
              parent.ottoV2Store.loadOttoV2Project(uuid);
              self.isInOtto = false;
            }
            if (self.siteAuditSelectedProperty == id || isPublicAuditOverview()) {
              self.auditedSiteDetail = data;
              self.loadingAuditDetail = false;
            }
          }
        }
      }
    } catch (err) {
      if (err?.response?.status == 404) {
        localStorage.removeItem('lastPropertySiteAudit');
        if (!window?.location?.pathname.includes('otto')) {
          Router.push('/site-audit/list');
        }
      }

      if (err?.response?.status == 400) {
        notification.error('Error loading data', err?.response?.data?.siteproperty?.siteproperty);
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingAuditDetail = false;
    }
  });

  const setAuditDateRange = (start, end) => {
    self.auditPeriodStart = start;
    self.auditPeriodEnd = end;
  };
  const setCrawelSettingDetail = (value: any) => {
    self.crawelSetting = {
      selectedUserAgent: value.selected_user_agent || null,
      crawlBudget: value.crawl_budget,
      crawlConcurrency: value.crawl_concurrency,
      nextProcessingDeltaDays: value.next_processing_delta_days || null,
      updatedSetting: true,
    };
  };
  const postSiteAudit = flow(function* (property) {
    self.params = {
      search: '',
      page: 1,
      page_size: self.params?.page_size ?? 10,
      ordering: self.params?.ordering,
    };
    self.loading = true;
    let response = null;
    let values = {};

    if (self.crawelSetting.updatedSetting) {
      values = {
        'selected_user_agent': self.crawelSetting.selectedUserAgent,
        'crawl_concurrency': self.crawelSetting.crawlConcurrency,
      };
      if (!location?.pathname?.includes('public')) {
        values['crawl_budget']= self.crawelSetting.crawlBudget;
        values['next_processing_delta_days'] = self.crawelSetting.nextProcessingDeltaDays;
      }
    }
    try {
      response = yield AUDITED_SITES_API_V2.postNewSiteAudit(property, values);
      self.newAuditedSiteId = response?.id;
      self.crawlBudgetError = '';
      if (response?.domainStatus == 'completed') {
        notification.info('Info', 'The site already exists.');
      }
      return response;
    } catch (err) {
      const objValue = Object.values(err?.response?.data);
      if (err?.response?.status == 400) {
        if (objValue?.length && Array.isArray(objValue[0])) {
          self.crawlBudgetError = objValue[0]?.join('\n');
        } else {
          self.crawlBudgetError = String(objValue);
        }
      }
      if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', err?.response?.data?.message, false, 'View Pricing');
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loading = false;
    }
  });

  const siteAuditConnectGsc = flow(function* (id, data?: any) {
    self.gscLoading = true;
    let response = null;
    try {
      response = yield AUDITED_SITES_API_V2.siteAuditConnectGsc(id, data);
      const selectedClusterIndex = self.auditedSitesList?.findIndex(e => e.id == id);
      if (self.auditedSitesList?.length && self.auditedSitesList[selectedClusterIndex]?.gscConnected == false && self.auditedSitesListBasic?.length) {
        self.auditedSitesList[selectedClusterIndex].gscConnected = !self.auditedSitesList[selectedClusterIndex]?.gscConnected;
        self.auditedSitesListBasic[selectedClusterIndex].gscConnected = !self.auditedSitesListBasic[selectedClusterIndex]?.gscConnected;
      }

      return response;
    } catch (err) {
      if (err?.response?.status == 400) {
        notification.error('Error loading data', err?.response?.data?.siteproperty?.siteproperty);
      } else if (err?.response?.status == 429) {
        notification.error('Quota Exceeded', err?.response?.data?.message, false, 'View Pricing');
      } else if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      } else if (err?.response?.status == 404) {
        notification.error('', 'Detail not found');
      }
      return Promise.reject(err);
    } finally {
      self.gscLoading = false;
    }
  });
  const updateColumnsList = properties => {
    self.pageExplorerColumnsList = properties;
  };

  // const stopLoadingList = () => {
  //   self.loadingPageExplorerList = false;
  //   self.stopFunctionCall = false;
  // };

  const loadPageExplorerList = flow(function* (id, properties, noLoading?: boolean) {
    if (self.pageExplorerParams?.page !== 1) {
      self.loadingMoreExplorerPages = true;
    }
    const getSegmentName = getSingleUrlParam('segmentName');
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingPageExplorerList = true;
    }
    self.pageExplorerProperty = id;
    const tempProperties = [];
    properties?.map(item => tempProperties.push(item.name));
    if (getSegmentName) {
      tempProperties.push('segment_names');
    }
    try {
      const token = getTokenFromCookies();
      if (!token && !publicHash) {
        Router.push('/login');
      } else {
        const response = yield AUDITED_SITES_API_V2.loadExplorerList(id, tempProperties, self.pageExplorerParams, self.filterParams, publicHash, getSegmentName);
        if (self.siteAuditSelectedProperty == id || id == Number(localStorage.getItem('lastPropertySiteAudit')) || toJS(self.auditedSitesList)[0].id || isPublicAuditPageExplorer()) {
          if (response?.isCancel && self.pageExplorerParams?.page > 1) {
            self.pageExplorerParams.page = self.pageExplorerParams?.page - 1;
          } else {
            if (self.pageExplorerParams?.page == 1) {
              self.pageExplorerList = cast(response);
              self.stopFunctionCall = true;
            } else {
              const newSite = [];
              response?.results?.map(item => {
                const siteIndex = self.pageExplorerList?.results?.findIndex(e => e.id == item.id);
                if (siteIndex !== -1 && self.pageExplorerList?.results?.length) {
                  self.pageExplorerList.results[siteIndex] = item;
                } else {
                  newSite.push(item);
                }
              });
              if (newSite.length > 0) {
                self.pageExplorerList = cast({
                  ...response,
                  results: self.pageExplorerList?.results?.length ? [...toJS(self.pageExplorerList?.results), ...newSite] : newSite?.slice(),
                });
              }
              self.stopFunctionCall = true;
            }
          }
        }
        let timeOutId = null;
        if (response?.shouldRepoll && self.pageExplorerListRepolling && (self.siteAuditSelectedProperty == id || isPublicAuditPageExplorer()) && self.pageExplorerColumnsList.length == properties.length) {
          timeOutId = yield new Promise(r => setTimeout(r, 5000));
          loadPageExplorerList(id, properties, true);
        } else {
          clearTimeout(timeOutId);
          if (self.siteAuditSelectedProperty == id || isPublicAuditPageExplorer()) {
            if (response?.isCancel && self.pageExplorerParams?.page > 1) {
              self.pageExplorerParams.page = self.pageExplorerParams?.page - 1;
            } else if (!response?.isCancel) {
              if (self.pageExplorerParams?.page == 1) {
                self.pageExplorerList = cast(response);
                self.stopFunctionCall = true;
              } else {
                const newSite = [];
                response?.results?.map(item => {
                  const siteIndex = self.pageExplorerList?.results?.findIndex(e => e.id == item.id);
                  if (siteIndex !== -1 && self.pageExplorerList?.results?.length) {
                    self.pageExplorerList.results[siteIndex] = item;
                  } else {
                    newSite.push(item);
                  }
                });
                if (newSite.length > 0) {
                  self.pageExplorerList = cast({
                    ...response,
                    results: self.pageExplorerList?.results?.length ? [...toJS(self.pageExplorerList?.results), ...newSite] : newSite?.slice(),
                  });
                }
                self.stopFunctionCall = true;
              }
            }
          }
        }
      }
    } catch (err) {
      if (err?.response?.status == 404 && err?.response?.data?.detail !== 'Invalid page.') {
        localStorage.removeItem('lastPropertySiteAudit');
        if (!window?.location?.pathname.includes('otto')) {
          Router.push('/site-audit/list');
        }
      } else if (err?.response?.status == 400) {
        notification.error('Error loading data', err?.response?.data?.siteproperty?.siteproperty);
      } else if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      } else {
        const errorMessage = apiError(err) as string;
        notification.error('', errorMessage);
      }
    } finally {
      self.loadingPageExplorerList = false;
      self.loadingMoreExplorerPages = false;
    }
  });

  const handleArchiveSite = flow(function* (id) {
    const getIndex = toJS(self.auditedSitesList)?.findIndex(item => item.id == id);
    self.auditedSitesList[getIndex].isDeletingAudit = true;
    try {
      yield AUDITED_SITES_API_V2.archiveAuditedSite(id);
      self.auditedSitesList[getIndex].isDeletingAudit = true;
      loadAuditedSitesList(true);
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingAuditSiteWideData = false;
    }
  });

  const handleCrwalSettings = flow(function* (id, values) {
    self.loadingCrawlUpdate = true;
    try {
      const response = yield AUDITED_SITES_API_V2.crawlSettings(id, values);
      if (response) {
        self.crawlBudgetError = '';
        notification.success('Crawl Setting', 'Settings successfully updated for ' + response.propertyUrl);
      }
      self.loadingCrawlUpdate = false;
    } catch (err) {
      const objValue = Object.values(err?.response?.data);
      if (err?.response?.status == 400) {
        if (objValue?.length && Array.isArray(objValue[0])) {
          self.crawlBudgetError = objValue[0]?.join('\n');
        } else {
          self.crawlBudgetError = String(objValue);
        }
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingCrawlUpdate = false;
    }
  });

  const handleBulkSiteAudit = flow(function* (list) {
    self.loadingBulkUpload = true;
    try {
      yield AUDITED_SITES_API_V2.bulkSiteAudit(list);
      notification.success('Bulk Site Audit', `${list.length} ${list.length > 1 ? 'sites' : 'site'} added successfully`);
      loadAuditedSitesList();
      loadAuditedSitesListBasic();
      self.crawlBudgetError = '';
      self.loadingBulkUpload = false;
    } catch (err) {
      if (err?.response?.data?.length > 0) {
        const objValue = Object.values(err?.response?.data[0]);
        if (err?.response?.status == 400) {
          if (objValue?.length && Array.isArray(objValue[0])) {
            self.crawlBudgetError = objValue[0]?.join('\n');
          } else {
            self.crawlBudgetError = String(objValue);
          }
        }
      }
      if (err?.response?.status === 429) {
        notification.warning('Site Audit Quota Exceeded', err?.response?.data?.message);
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingBulkUpload = false;
    }
  });

  const recrawlSiteAuditProperty = flow(function* (id) {
    try {
      yield AUDITED_SITES_API_V2.recrawlAuditProperty(id);
      loadAuditedSitesList(true);
    } catch (err) {
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingAuditSiteWideData = false;
    }
  });

  const onLoadPageExplorerDetail = flow(function* (id, noLoading?: boolean) {
    const publicHash = getSingleUrlParam('public_hash');
    if (!noLoading) {
      self.loadingPageExplorerDetail = true;
    }

    self.pageExplorerDetailId = id;
    try {
      const token = getTokenFromCookies();
      if (!token && !publicHash) {
        Router.push('/login');
      } else {
        const response = yield AUDITED_SITES_API_V2.loadExplorerDetail(id, self.siteAuditSelectedProperty, publicHash);
        if (response?.shouldRepoll && self.pageExplorerDetailRepolling) {
          updateExplorerDetailApiResponse(response, true);
          yield new Promise(r => setTimeout(r, 2000));
          return onLoadPageExplorerDetail(id);
        } else {
          updateExplorerDetailApiResponse(response, true);
          self.loadingPageExplorerDetail = false;
        }
      }
    } catch (err) {
      if (err?.response?.status == 400) {
        notification.error('Error loading data', err?.response?.data?.siteproperty?.siteproperty);
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingPageExplorerDetail = false;
    }
  });

  const updateExplorerDetailApiResponse = (response, assignValueToPageExplorerDetail = false) => {
    if (response && response !== '') {
      response.schemaOrg = JSON.stringify(response.schemaOrg) === '{}' ? null : JSON.stringify(response.schemaOrg);

      // response.issues['issues'] = response?.issues?.issues?.map(item => {
      //   return {
      //     ...item,
      //     compliantList: item?.compliantList?.map(z => {
      //       return {
      //         ...z,
      //         affectedResources: !z?.affectedResources ? null : JSON.stringify(z?.affectedResources),
      //       };
      //     }),
      //     issueList: item?.issueList?.map(z => {
      //       return {
      //         ...z,
      //         affectedResources: !z?.affectedResources ? null : JSON.stringify(z?.affectedResources),
      //       };
      //     }),
      //   };
      // });

      // response.issues.compliant = response.issues?.compliant?.map(item => {
      //   return {
      //     ...item,
      //     compliantList: item?.compliantList?.map(z => {
      //       return {
      //         ...z,
      //         affectedResources: !z?.affectedResources ? null : JSON.stringify(z?.affectedResources),
      //       };
      //     }),
      //     issueList: item?.issueList?.map(z => {
      //       return {
      //         ...z,
      //         affectedResources: !z?.affectedResources ? null : JSON.stringify(z?.affectedResources),
      //       };
      //     }),
      //   };
      // });
      if (assignValueToPageExplorerDetail) {
        self.pageExplorerDetail = cast(response);
      }
    }
    return response;
  };

  const recrawlExplorerDetail = flow(function* (id) {
    self.loadingPageExplorerDetail = true;
    try {
      const response = yield AUDITED_SITES_API_V2.recrawlExplorerDetailApi(id, self.siteAuditSelectedProperty);
      if (response?.shouldRepoll && self.pageExplorerDetailRepolling) {
        updateExplorerDetailApiResponse(response, true);
        yield new Promise(r => setTimeout(r, 2000));
        return onLoadPageExplorerDetail(id);
      } else {
        updateExplorerDetailApiResponse(response, false);
        self.loadingPageExplorerDetail = false;
      }
    } catch (err) {
      if (err?.response?.status == 400) {
        notification.error('Page Recrawl', err?.response.data);
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      self.loadingPageExplorerDetail = false;
    }
  });

  const loadPageExplorerDetail = id => {
    self.pageExplorerDetail = null;
    onLoadPageExplorerDetail(id);
  };

  const recrawlOverviewDetail = flow(function* (id) {
    try {
      yield AUDITED_SITES_API_V2.recrawlAuditProperty(id);
      loadAuditedSiteDetail(id);
    } catch (err) {
      if (err?.response?.status == 400) {
        notification.error('Page Recrawl', err?.response?.data);
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingPageExplorerDetail = false;
    }
  });
  const reCrawlSiteListPage = flow(function* (id) {
    try {
      yield AUDITED_SITES_API_V2.recrawlAuditProperty(id);
      loadAuditedSitesList();
    } catch (err) {
      if (err?.response?.status == 400) {
        notification.error('Page Recrawl', err?.response?.data);
      }
      if (err?.response?.status >= 500 && err?.response?.status < 600) {
        notification.error('Sorry', 'There was a server error. Please refresh the page');
      }
      return Promise.reject(err);
    } finally {
      // self.loadingPageExplorerDetail = false;
    }
  });

  const setPageExplorerDetailRepolling = val => {
    self.pageExplorerDetailRepolling = val;
  };

  const setPageExplorerListRepolling = val => {
    self.pageExplorerListRepolling = val;
  };

  const setAuditedSiteDetailRepolling = val => {
    self.auditedSiteDetailRepolling = val;
  };
  const setClicked = val => {
    self.clicked = val;
  };

  const handleUpdateColumns = data => {
    loadPageExplorerList(self.pageExplorerProperty, data);
  };
  const setPreviousListInStore = data => {
    self.previousListInStore = data;
  };

  const setAuditedSitesListRepolling = val => {
    self.auditedSitesListRepolling = val;
  };

  const setAuditedListRepollingBasic = val => {
    self.auditedSitesListRepollingBasic = val;
  };
  const setImportanceValue = value => {
    self.importanceSlider = value;
  };
  const setIgnoreIssuesModal = value => {
    self.showIgnoreIssuesModal = value;
  };

  const getIssuesChartTableData = flow(function* (id, value) {
    const publicHash = getSingleUrlParam('public_hash');
    if (value && id) {
      self.loadingChartDetailData = true;
      self.IssueChartDetailData = null;
      try {
        const data = yield AUDITED_SITES_API_V2.loadIssuesChartTable(id, value, publicHash);
        if (data?.length) {
          self.IssueChartDetailData = data;
        }
      } catch (err) {
        if (err?.response?.status >= 500 && err?.response?.status < 600) {
          notification.error('Sorry', 'There was a server error. Please refresh the page');
        }
        return Promise.reject(err);
      } finally {
        self.loadingChartDetailData = false;
      }
    }
  });

  const getPagesChartTableData = flow(function* (id, value) {
    const publicHash = getSingleUrlParam('public_hash');
    if (value && id) {
      self.loadingChartDetailData = true;
      self.chartDetailData = null;
      try {
        const data = yield AUDITED_SITES_API_V2.loadPagesChartTable(id, value, publicHash);
        if (data?.length) {
          self.chartDetailData = data;
        }
      } catch (err) {
        if (err?.response?.status >= 500 && err?.response?.status < 600) {
          notification.error('Sorry', 'There was a server error. Please refresh the page');
        }
        return Promise.reject(err);
      } finally {
        self.loadingChartDetailData = false;
      }
    }
  });
  const loadDepthNodesV2 = flow(function* (siteProperty: string, nodeSizeBy: string) {
    self.depthNodesGraph = {};
    self.loadingDepthNodes = true;
    try {
      const response = yield AUDITED_SITES_API_V2.getDepthNodes(siteProperty);
      // @ts-ignore
      self.depthNodes = response?.length ? response[0] : {};
      self.lastDepthNode = response?.length ? response[response.length - 1] : {};
      const graph = yield AUDITED_SITES_API_V2.getDepthNodes(siteProperty, 'circle', nodeSizeBy);
      if (graph) {
        self.depthNodesGraph = graph;
      }
    } catch (err) {
      const {response} = err;
      const {data} = response;
      notification.error('Sorry', data?.siteproperty?.length ? data.siteproperty[0] : 'Error loading data');
      self.loadingDepthNodes = false;
    } finally {
      self.loadingDepthNodes = false;
    }
  });
  const loadNotStandardData = flow(function* (id: string) {
    self.notStandardDataLoading = true;
    try {
      const response = yield AUDITED_SITES_API_V2.getNotStandardData(id);
      if (!response?.isCancel) {
        self.notStandardData = cast(response);
      }
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.notStandardDataLoading = false;
    }
  });

  const setHealthState = values => {
    self.healthState = values;
  };
  const setSegementValue = (value?: any) => {
    self.segmentValue = value;
  };
  const setFilterParam = value => {
    self.filterParams = value;
  };

  const setAuditSettingModal = value => {
    self.auditSettingModal = value;
  };

  const setHealthIncrease = value => {
    self.siteHealthIncrease = value;
  };
  const setUrlWebAffectedPage = value => {
    self.affectedUrl = value;
  };
  const setGscOpenModalValue = (value, heading='', isSiteAudit=false, saIdForGscSettings?: string | number, callGscModalApi=true) => {
    self.openGscModal = value;
    self.gscModalHeading = heading;
    self.isSiteAudit = isSiteAudit;
    self.saIdForGscSettings = saIdForGscSettings || '';
    self.callGscModalApi = callGscModalApi;
  };

  return {
    createSegment,
    loadNotStandardData,
    modifySegment,
    setSelectedSiteAuditInStore,
    deleteSegment,
    updateSelectedSiteSA,
    setAffectedFIlter,
    setUrlWebAffectedPage,
    setRobotsTabsData,
    setSpecificIDResponse,
    setHealthSliderValue,
    setImportanceValue,
    setHealthState,
    handleSegmentsList,
    setAuditSettingModal,
    loadAuditedSitesList,
    postSiteAudit,
    setAffectedPagesTableData,
    checkSpecificIDResponse,
    setClicked,
    loadPageExplorerList,
    setLoadProjectDetails,
    loadSiteWideDetail,
    loadSiteAuditIssuesList,
    setSegementValue,
    loadAuditedSiteDetail,
    onLoadAuditedSiteDetail,
    setGscOpenModalValue,
    getIssuesChartTableData,
    getPagesChartTableData,
    handleArchiveSite,
    handleCrwalSettings,
    handleBulkSiteAudit,
    setParams,
    setAuditedSitesList,
    setPageExplorerParams,
    resetAffectedPagesList,
    resetPageExplorerParams,
    setPreviousListInStore,
    // stopLoadingList,
    handleIgnoreIssues,
    setIgnoreIssuesModal,
    setPerPages,
    setDetailIgnoreLoader,
    setAffectedPagesParams,
    onLoadPageExplorerDetail,
    setAuditDateRange,
    reCrawlSiteListPage,
    loadPageExplorerDetail,
    loadAuditedSitesListBasic,
    setParamsBasic,
    setPageExplorerDetailRepolling,
    setAppliedFiltersInStore,
    setAuditedSiteDetailRepolling,
    setPageExplorerListRepolling,
    handleUpdateColumns,
    loadSiteAuditAffectedPages,
    setCrawlBudgetError,
    setAuditedSitesListRepolling,
    setAuditedListRepollingBasic,
    recrawlSiteAuditProperty,
    recrawlOverviewDetail,
    setAffetedPagesFromResponse,
    recrawlExplorerDetail,
    setFilterHeader,
    loadDepthNodesV2,
    setPageWithFilter,
    setCrawelSettingDetail,
    updateColumnsList,
    handleExportIssuesPage,
    setFilterParam,
    setLoadMoreLoading,
    siteAuditConnectGsc,
    setSiteHealthAndPropertyUrl,
    setHealthIncrease,
    setUrlWeb,
    checkIsSiteAudit,
    setPropertyUrl,
    setIsInOtto,
  };
});
export function initAuditedSitesV2Store() {
  return AuditedSitesV2Store.create({
    emailSettings: initEmailSettings(),
    alertsStore: initAlertsPage(),
    loadMoreLoading: false,
    siteAuditSpacificDataLoading: false,
    loadingList: true,
    loading: false,
    loadProjectDetails: false,
    loadingAuditDetail: true,
    loadingPageExplorerList: true,
    loadingSiteAuditIssuesList: true,
    loadingAuditSiteWideData: true,
    notStandardDataLoading: false,
    pageExplorerDetailRepolling: true,
    loadingChartDetailData: false,
    pageExplorerListRepolling: true,
    isSiteAudit: false,
    segmentValue: '',
    auditedSiteDetailRepolling: true,
    auditedSitesListRepolling: false,
    auditedSitesListRepollingBasic: false,
    showIgnoreIssuesModal: false,
    crawlBudgetError: '',
    loadingPageExplorerDetail: true,
    loadingAffectedPagesDrawerData: true,
    loadingCrawlUpdate: false,
    recordEnded: false,
    loadingIsuePage: false,
    affectedPagesDrawerData: [],
    loadingBulkUpload: false,
    loadingMoreExplorerPages: false,
    isInOtto: false,
    auditSettingModal: false,
    openGscModal: false,
    callGscModalApi: false,
    gscModalHeading: '',
    detailIgnoreLoader: [],
    previousListInStore: '',
    auditPeriodStart: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    auditPeriodEnd: moment().format('YYYY-MM-DD'),
    auditedSitesList: [],
    depthNodes: {},
    depthNodesGraph: null,
    loadingDepthNodes: false,
    affectedUrl: '',
    urlWeb: '',
    crawelSetting: {
      selectedUserAgent: '',
      crawlBudget: 0,
      crawlConcurrency: 0,
      updatedSetting: false,
    },
    perPages: 0,
    siteHealthAndPropertyUrl: {
      propertyUrl: '',
      actual: 0,
      total: 0,
      color: '',
    },
    params: {
      page_size: 10,
      page: 1,
      search: '',
      ordering: '-activated_at',
    },
    paramsBasic: {
      page_size: 100,
      page: 1,
      search: '',
    },
    filterHeader: [{
      field: '',
      headerValue: '',
    }],
    pageExplorerParams: {
      page_size: 50,
      page: 1,
      search: '',
    },
    pageExplorerProperty: '',
    clicked: false,
    filterParams: '',
    callingApi: false,
    sitesListBasicLoading: false,
    gscLoading: false,
    siteHealthIncrease: 0,
    manageColumnsVersionPE: '1.4',
    affectedFilterList: [
      {
        id: 3, name: 'exclude', header: 'URL', filterField: 'url', text: '', type: undefined, active: false, customFields: [
          {label: 'Is', operator: 'exact'},
          {label: 'Contains', operator: 'contains'},
          {label: 'Starts With', operator: 'startswith'},
          {label: 'Ends With', operator: 'endswith'},
        ],
      },
      {id: 4, name: 'pagerank', header: 'Page Rank', from: undefined, to: undefined, equal: undefined, type: undefined, active: false},
      {id: 5, name: 'pagehealth', header: 'Page Health', from: undefined, to: undefined, equal: undefined, type: undefined, active: false},
    ],
    affectedPagesParams: {
      ordering: '',
      page: 1,
      page_size: 50,
    },
    auditedSiteSegmentDetail: [],
    siteAuditSpacificData: {
      gscConnected: true,
    },
  });
}
