import {useCallback, useEffect, useRef, useState} from 'react';
import {Divider, Spin} from 'antd';
import {
  CommonInput,
} from '@/components/common-components/components';
import {Button as V2Button, GrayButton} from '@/components/common-components/v2/Button';
import styles from './style.module.scss';
import {observer} from 'mobx-react';
import {LoadingOutlined} from '@ant-design/icons';
import {MixPanel} from '@/utils/mixpanel';
import {SCA_PAGE_AI_ONE_CLICK_GENERATE} from '@/constants/events';
import {useStore} from '@/store/root-store';
import {faArrowLeft, faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faXmark} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import {toJS} from 'mobx';
import {isContentGeneratorPublic} from '@/utils/url';
import {Settings} from '../settings';
import {debounce} from 'lodash';
import {ContentState, EditorState, Modifier, SelectionState} from 'draft-js';
import {Checkbox} from '@/components/common-components/v2';

interface Props {
  pageId: any;
  drawerVisible: boolean;
  selectedContentLength: 'short' | 'medium' | 'long';
  selectedToneOfVoice: string;
  selectedLanguage: string;
  selectedPointOfView: string;
  setSelectedContentLength: (value: 'short' | 'medium' | 'long') => void;
  setSelectedToneOfVoice: (value: string) => void;
  setSelectedLanguage: (value: string) => void;
  setSelectedPointOfView: (value: string) => void;
  htmlToDraft: any;
  setIsContentOverwritten: (value: boolean) => void;
}

export const OneClickDraftForm = observer(({
  pageId,
  drawerVisible,
  selectedContentLength,
  selectedToneOfVoice,
  selectedLanguage,
  selectedPointOfView,
  setSelectedContentLength,
  setSelectedToneOfVoice,
  setSelectedLanguage,
  setSelectedPointOfView,
  setIsContentOverwritten,
  htmlToDraft,
}: Props) => {
  const {contentOptimizer: {currentPage, aiOutline}} = useStore('');
  const {contentOptimizer: {aiOutline: {setShowRemainingTerms, showRemainingTerms}}} = useStore('');
  const {settings: {customer: {getCustomerQuota}}} = useStore('');
  const [showTermsToInclude, setShowTermsToInclude] = useState(true);
  const [usedTerms, setUsedTerms] = useState('');
  const [termsToInclude, setTermsToInclude] = useState([]);
  const [shouldOverwriteContent, setShouldOverwriteContent] = useState(true);
  const [lastUsedToneOfVoice, setLastUsedToneOfVoice] = useState('');
  const [customToneOfVoice, setCustomToneOfVoice] = useState('');
  const [contentIdea, setContentIdea] = useState('');
  const [isContentIdeaError, setIsContentIdeaError] = useState(false);

  const termsRef = useRef(null);
  const antIcon = <LoadingOutlined style={{fontSize: 24, marginLeft: '10px', color: '#fff', marginTop: '5px'}} spin />;


  useEffect(() => {
    if (!drawerVisible) resetFormHandler();
  }, [drawerVisible]);

  useEffect(() => {
    if (!isContentGeneratorPublic()) {
      // methods for standalone tool and regular tool are different
      // in regular editor drawer we send page uuid, not the case with stanalone tool
      if (aiOutline?.aiWriterController?.isStandaloneTool) aiOutline.loadAiOutlineHistoryStandalone();

      if (!aiOutline?.aiWriterController?.isStandaloneTool) aiOutline.loadAiOutlineHistory(currentPage?.content?.uuid);
    }
  }, []);

  const debouncedChange = debounce((state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations = false) => {
    // we are then calling the edit content method which will fire the API call and send new state to the BE
    currentPage.editContent(state, isNewDecorations);
  }, 2000);


  const debouncedUpdate = useCallback(
    (state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations) => debouncedChange(state, focusTerms, focusTermsClassNames, isNewDecorations),
    // eslint-disable-next-line
        []
  );

  const insertText = async (outlineParam: any, id: number, shouldReplace?: boolean) => {
    // NEW FLOW start
    const {contentBlocks, entityMap} = htmlToDraft(outlineParam);

    const selection = currentPage.currentEditorState.getSelection();


    const newSelection = !shouldReplace ?
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: selection.getEndOffset(),
        focusKey: selection.getAnchorKey(),
        focusOffset: selection.getEndOffset(),
      }) :
      selection.merge({
        anchorKey: currentPage.currentEditorState.getCurrentContent().getFirstBlock().getKey(),
        anchorOffset: 0,
        focusOffset: currentPage.currentEditorState.getCurrentContent().getLastBlock().getText().length,
        focusKey: currentPage.currentEditorState.getCurrentContent().getLastBlock().getKey(),
      });

    const fragment = ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap();

    const nonStyledState = Modifier.replaceWithFragment(
      currentPage.currentEditorState.getCurrentContent(),
      newSelection,
      fragment,
    );

    // WE are calculating nodes to toggle blue background on new Ai generated nodes
    let firstNewBlockId: number;
    const currentBlock = selection.getAnchorKey();
    const nextBlock = currentPage.currentEditorState.getCurrentContent().getBlockAfter(selection.getAnchorKey())?.getKey();

    const existingNodes = document.querySelectorAll('[data-block="true"]');
    existingNodes?.forEach((elem, id) => {
      if (elem.getAttribute('data-offset-key') && elem.getAttribute('data-offset-key') == `${currentBlock}-0-0`) {
        firstNewBlockId = id + 1;
      }
    });


    const updatedState = shouldReplace ?
      EditorState.createWithContent(nonStyledState) :
      EditorState.push(currentPage.currentEditorState, nonStyledState, 'insert-fragment');

    currentPage.setCurrentEditorState(updatedState);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('[data-block="true"]');
      if (newTextElems.length) {
        for (let i = firstNewBlockId; i <= newTextElems.length; i++) {
          if (newTextElems[i]?.getAttribute('data-offset-key') && newTextElems[i]?.getAttribute('data-offset-key') != `${nextBlock}-0-0` && newTextElems[i]?.getAttribute('data-offset-key') != `${currentBlock}-0-0`) {
            newTextElems[i].classList.add('newTextBlock');
          } else {
            return;
          }
        }
      }
    }, 500);

    debouncedUpdate(updatedState, currentPage.analytics?.focusTerms, currentPage.focusTermsClassNames, false);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('.newTextBlock');
      if (newTextElems.length) {
        newTextElems.forEach(elem => {
          elem.classList.remove('newTextBlock');
        });
      }
    }, 3500);
    // NEW FLOW END
    aiOutline?.updateSingleOutline(true, id);
  };


  const onFormSubmit = async () => {
    if (!contentIdea) {
      setIsContentIdeaError(true);
      return;
    }
    setIsContentIdeaError(false);
    try {
      setIsContentOverwritten(false);
      await aiOutline.updateUserAiSettings({
        language: selectedLanguage,
        toneOfVoice: selectedToneOfVoice == 'custom' ? customToneOfVoice ? customToneOfVoice : lastUsedToneOfVoice : selectedToneOfVoice,
        pointOfView: selectedPointOfView,
      });


      const response = await aiOutline.loadOneClickDraft(contentIdea, termsToInclude, selectedContentLength);
      if (shouldOverwriteContent) {
        const outlines = await aiOutline.refetchOneClickDraftResult(response.id);
        if (outlines?.results?.length) {
          let textToInject = '';
          const htmlTagRegex = /^<[^>]*>/;
          outlines?.results[0]?.split('\n\n').forEach((str, idx) => {
            if (idx == 0 && htmlTagRegex.test(str)) {
              textToInject += str;
            } else {
              textToInject += `<p>${str}</p>`;
            }
          });
          insertText(textToInject, 0, true);
        }
        setIsContentOverwritten(true);
      } else {
        await aiOutline.refetchOneClickDraftResult(response.id);
      }
    } catch (e) {
      setIsContentOverwritten(false);
      Promise.reject(e);
    } finally {
      getCustomerQuota();
    }

    // aiOutline.loadAiOutline(pageId);

    MixPanel.track(SCA_PAGE_AI_ONE_CLICK_GENERATE, {
      'pageId': pageId,
      'description': 'Generate one click ai writer.',
    });
  };


  const resetFormHandler = () => {
    setTermsToInclude([]);
    setUsedTerms('');
    setSelectedToneOfVoice(lastUsedToneOfVoice ?? 'Professional');
    setContentIdea('');
  };


  // const validationRegex = /^[A-Za-z0-9 ._-]*[A-Za-z0-9][A-Za-z0-9 ._-]*$/;


  // chekcs if focus terms input is in focus or not
  const myFunction = () => {
    if (document.activeElement === termsRef?.current?.input) {
      if (!usedTerms || termsToInclude?.includes(usedTerms.trim())) return;
      const updatedTerms = [...(usedTerms?.split(',') ?? []), ...termsToInclude];
      setTermsToInclude(updatedTerms);
      setUsedTerms('');
    }
  };

  // adds event hendler on enter key press
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();

        myFunction();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [usedTerms]);

  const onImportTermsFromArticleClick = () => {
    const termsFromArticle = currentPage?.analytics?.focusTerms?.map(term => term.name);

    setTermsToInclude([...(termsFromArticle ?? []), ...(termsToInclude ?? [])]);
    setShowRemainingTerms(false);
  };

  const onTermRemove = (term: string) => {
    const updatedTerms = [...termsToInclude].filter(item => item !== term);
    setTermsToInclude(updatedTerms);
  };


  return (
    <>
      <Header>
        <HeaderTop>
          <BackArrowContainer onClick={() => {
            aiOutline?.setAiWriter({
              ...toJS(aiOutline?.aiWriterController ?? []),
              showForm: false,
              templateIcon: '',
              templateDesc: '',
              templateName: '',
              isStandaloneTool: false,
            });
            aiOutline?.clearOneClickOutlines();
            setIsContentOverwritten(false);
          }}>
            <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 10}}/>
            Back to templates
          </BackArrowContainer>


        </HeaderTop>
        <DescContainer>
          <ImgContainer>
            <img src={`/img/icon/${aiOutline.aiWriterController.templateIcon}`} style={{height: 25, margin: 'auto'}}/>
          </ImgContainer>
          <div>
            <TemplateName>{aiOutline.aiWriterController?.templateName}</TemplateName>
            <TemplateDesc>{aiOutline.aiWriterController?.templateDesc}</TemplateDesc>
          </div>

        </DescContainer>
      </Header>
      <Divider style={{width: 50, minWidth: 50, marginTop: 20, marginBottom: 20}}/>
      <div>
        <p style={{fontSize: 12, lineHeight: '20px', color: '#4E5156'}}>Topic</p>

        <div style={{display: 'flex'}}>
          <CommonInputStyled
            boxShadow={true}
            value={contentIdea}
            onChange={e => {
              if (e.target.value) setIsContentIdeaError(false);
              setContentIdea(e.target.value);
            }}
            type='text'
            variant='white'
            placeholder='Enter a simple blog post title...'
            className={styles.input} />
        </div>
        {isContentIdeaError && <p style={{fontSize: 12, color: '#ff5959', marginTop: 5}}>Missing content idea</p>}
        {showTermsToInclude ? <div className={styles.termsToIncludeContainer}>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <p style={{marginBottom: 0, color: '#4E5156', marginTop: 0, fontSize: 12}}>{`Terms to Include`}</p>
            <p
              style={{marginBottom: 0, color: '#13408B', marginLeft: 'auto', marginRight: 10, cursor: 'pointer', fontSize: 12}}
              onClick={() => onImportTermsFromArticleClick()}>
                Import from Article
            </p>
            <p style={{color: '#A3A4A4', marginTop: 0, marginBottom: 0, textDecoration: 'underline', cursor: 'pointer', fontSize: 12}} onClick={() => {
              setShowTermsToInclude(false);
              setTermsToInclude([]);
              setShowRemainingTerms(false);
            }}>
                Cancel
            </p>
          </div>
          <div style={{background: '#fff', borderRadius: 8, marginBottom: 20, border: '1px solid #E8E8E8'}}>
            <CommonInputStyled
              border={false}
              inputRef={termsRef}
              type='text'
              variant='white'
              value={usedTerms}
              placeholder='Enter term(s) or question(s), separated by commas, and hit enter'
              onChange={e => setUsedTerms(e.target.value)}
              className={styles.input} />
            <AddedTermsContainer>
              {termsToInclude?.slice(0, 8).map((item, idx) => {
                return <SingleAddedTerm key={idx}>{item}
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
                    onClick={() => onTermRemove(item)}
                  />
                </SingleAddedTerm>;
              })}
              {(termsToInclude?.length > 9 && !showRemainingTerms) && <SingleAddedTerm style={{cursor: 'pointer'}} onClick={() => setShowRemainingTerms(true)}>
                {`+${termsToInclude.length - 9} releated terms`}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
                />
              </SingleAddedTerm>}
              {showRemainingTerms && termsToInclude?.slice(9).map((item, idx) => {
                return <SingleAddedTerm key={idx}>{item}
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{marginLeft: 5, cursor: 'pointer', color: '#A3A4A4'}}
                    onClick={() => onTermRemove(item)}
                  />
                </SingleAddedTerm>;
              })}
              {termsToInclude?.length > 0 && <SingleAddedTerm style={{cursor: 'pointer', color: '#2D6CCA'}} onClick={() => {
                setTermsToInclude([]);
                setShowRemainingTerms(false);
              }}>
                Clear All
              </SingleAddedTerm>}
            </AddedTermsContainer>
          </div>


        </div> : <div style={{cursor: 'pointer', color: '#2D6CCA', marginTop: 10, marginBottom: 20}} onClick={() => setShowTermsToInclude(true)}>+ Add terms to include</div>}

        <Settings
          title='Fine-tune your results with custom AI Settings'
          selectedContentLength={selectedContentLength}
          setSelectedContentLength={setSelectedContentLength}
          selectedToneOfVoice={selectedToneOfVoice}
          setSelectedToneOfVoice={setSelectedToneOfVoice}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          selectedPointOfView={selectedPointOfView}
          setSelectedPointOfView={setSelectedPointOfView}
          setLastUsedToneOfVoice={setLastUsedToneOfVoice}
          setCustomToneOfVoice={setCustomToneOfVoice}
          customToneOfVoice={customToneOfVoice}
          settingsArray={['content-length', 'tone-of-voice', 'language', 'point-of-view']}/>
        <CheckboxStyled checked={shouldOverwriteContent} onChange={e => setShouldOverwriteContent(e.target.checked)}>Replace current article with AI Generated Content</CheckboxStyled>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 25}}>
          <V2Button
            onClick={() => onFormSubmit()}
            disabled={aiOutline.loading || !contentIdea}
            color='purple'
            variant='solid'
            className={styles.generateIdeasButton}>
              Generate
            {aiOutline.loading ? <Spin indicator={antIcon} /> : <FontAwesomeIcon style={{marginLeft: 10}} icon={faArrowRight} />}
          </V2Button>

          <GrayButton color='transparent' variant='noBorder' style={{marginLeft: 0}} onClick={() => resetFormHandler()}>
            <FontAwesomeIcon style={{marginRight: 10}} icon={faXmark} />
              Clear inputs
          </GrayButton>
        </div>

      </div>
    </>
  );
});

const Header = styled.div`

`;
const AddedTermsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
`;
const SingleAddedTerm = styled.div`
  background-color: #F9F9FB;
  padding: 3px 10px;
  border-radius: 38px;
  border: 1px solid  #e8e8e8;
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
`;
const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ImgContainer = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  margin-right: 13px;
`;
const DescContainer = styled.div`
  max-width: 80%
`;
const TemplateName = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #121212;
  margin-bottom: 4px;
`;
const TemplateDesc = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #4E5156;
`;
const BackArrowContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const CommonInputStyled = styled(CommonInput)<{border?: boolean; boxShadow?: boolean}>`
  color: #121212 !important;
  border-radius: 6px !important;
  ${p => p.boxShadow && 'box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02)'};
  ${p => !p.border && 'border: 1px solid transparent !important'};
  padding-right: 8px !important;
  
  &:focus {
    box-shadow: none !important;
  }
 
`;

const CheckboxStyled = styled(Checkbox)`
  margin-left: 0px !important;
  margin-bottom: 10px !important;
  font-size: 12px;
  margin-top: 22px;

  .ant-checkbox-inner {
    border-radius: 4px;
  }
`;

