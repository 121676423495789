import {getApiUrl, getAuthHeader, getHeaders} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

export class OttoV2Api extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.SA_ENDPOINT, '/api');
  private static readonly baseUrlCa: string = getApiUrl(BaseApi.CA_ENDPOINT, '/api');
  private static readonly baseUrlLinkgraph: string = getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api');
  private static readonly baseUrlKey: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');
  private static readonly baseUrlGsc: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api');

  public async getOttoV2Projects(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/otto-projects/`, {
        params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOttoV2Project(uuid: string) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async setAutoPilot(uuid: string, isEngaged: boolean) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/`, {is_engaged: isEngaged}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async connectGBP(uuid: string, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/connect-google-integration/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getIssueTableData(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/otto-urls/`, {
        params: params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async getSitewideIssueTableData(params) {
    try {
      const search = params.schema ? {search: params?.search} : {search_q: params?.search};
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/otto-projects/${params?.uuid}/sitewide-issues/`, {
        params: {
          issue_type: params?.issue_type,
          page_size: params?.page_size,
          page: params?.page,
          ...search,
          deploy_status: params?.deploy_status,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTopicalMapsTableData(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/otto-projects/${params?.uuid}/topical-maps/`, {
        params: {
          issue_type: params?.issue_type,
          page_size: params?.page_size,
          page: params?.page,
          search: params?.search,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createOttoTopicalMap(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/topical-authority-map/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteOttoTopicalMap(id) {
    try {
      const response = await this.axios.delete(`${OttoV2Api.baseUrl}/topical-authority-map/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateTopicalMapKeyword(uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/update-topical-map-data/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getCloudStacksTableData(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/cg/v1/cloud-stack-contents/`, {
        params: {
          page_size: params?.page_size,
          page: params?.page,
          search: params?.search,
          otto_project: params?.otto_project,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOttoTopicalMapsData(uuid: string) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/topical-map-data/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateCloudStacksTableData(id, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrlCa}/cg/v1/cloud-stack-contents/${id}/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteCloudStackContent(id) {
    try {
      const response = await this.axios.delete(`${OttoV2Api.baseUrl}/cg/v1/cloud-stack-contents/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async deleteQASuggestion(id) {
    try {
      const response = await this.axios.delete(`${OttoV2Api.baseUrl}/gbp/v1/questions/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async getPressReleaseTableData(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/cg/v1/press-release/`, {
        params: {
          page_size: params?.page_size,
          page: params?.page,
          search: params?.search,
          otto_project: params?.otto_project,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSuggestedTargetKeywords() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/target-keywords/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getCloudStacksProviders() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/cg/v1/cloud-stack-providers/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createCloudStackOrPressRelease(type, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/cg/v1/${type}-contents/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deployOttoUrls(payload, uuid) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/deploy/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async buildCloudContent(id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/cg/v1/cloud-stack-contents/${id}/build/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async publishCloudContent(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/cg/v1/cloud-stack-contents/${id}/deploy/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async refreshCloudStackDeployInfo(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/cg/v1/cloud-stack-contents/refresh-deploy-info/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deployProposedFix(payload, id, uuid) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrl}/v2/otto-urls/${id}/?uuid=${uuid}`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deployImageAltText(payload, id, uuid) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/update-sitewide-issues/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async generateAiProposedFix(payload, id, uuid) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-urls/${id}/generate-ai-recommendation/?uuid=${uuid}`, payload, {
        headers: getAuthHeader(),
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createOttoProject(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteOttoProject(id:string) {
    try {
      const response = await this.axios.delete(`${OttoV2Api.baseUrl}/v2/otto-projects/${id}/`, getHeaders());
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }

  public async checkScriptFromUrl(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/check-script-from-url/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async topicalMapUpdateArticle(payload, id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/topical-authority-map/${id}/update-article/`, payload, {
        headers: getAuthHeader(),
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async checkScriptFromInstance(uuid) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/check-script-from-instance/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGSCSiteVerification(uuid) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/v2/otto-projects/${uuid}/gsc-site-verification/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async gscSiteVerificationScriptVerification(uuid) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/v2/otto-projects/${uuid}/gsc-site-verification/verify/`, null, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async refreshGSCSiteVerificationScript(uuid) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/v2/otto-projects/${uuid}/gsc-site-verification/refresh/`, null, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOttoGBPPosts(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/`, {
        params: {
          ...params,
          location: params?.location,
          page_size: params?.page_size,
          search: params?.search,
          page: params?.page,
          status__in: params?.status__in,
          created_at: params?.created_at,
          topic_type__in: params?.topic_type__in,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async approveOttoGBPPosts(payload, id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/${id}/approve/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async unApproveOttoGBPPosts(payload, id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/${id}/unapprove/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async publishOttoGBPPosts(payload, id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/${id}/publish/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async unPublishOttoGBPPosts(payload, id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/${id}/unpublish/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async editOttoGBPPost(payload, id) {
    try {
      const response = await this.axios.put(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/${id}/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOttoGBPQuestionAnswers(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/`, {
        params: {
          location: params?.location,
          page_size: params?.page_size,
          page: params?.page,
          search: params?.search,
          status__in: params?.status__in,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async publishGBPQuestion(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/${id}/publish/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async unPublishGBPQuestion(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/${id}/unpublish/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateGBPOQuestion(id, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/${id}/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async storeSelectedTitles(payload, id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/topical-authority-map/${id}/store-selected-titles/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateGBPOwnersAnswer(id, payload) {
    try {
      const response = await this.axios.put(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/${id}/owners-answer/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async aiEnhanceGBPOQuestion(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/${id}/ai-enhance/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async aiEnhanceGBPOwnersAnswer(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/${id}/owners-answer/ai-enhance/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateOttoV2Project(uuid: string, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/`, payload, {
        headers: {...getAuthHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async updateKnowledgeGraph(id: number, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrl}/v2/knowledge-graphs/${id}/`, payload, {
        headers: {...getAuthHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getGBPLocations() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/gbp/v1/locations/`, {
        headers: {...getAuthHeader()},
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOttoGBPAiGenerateContent(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/posts/ai-generate/content/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOttoGBPAiGenerateImage(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/posts/ai-generate/image/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async generateOttoGBPAiBulkImage(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/posts/ai-generate/bulk-images/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async createOttoGBPPost(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async ottoGBPUploadImage(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/files/upload-image`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOttoGBPReviews(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/reviews/`, {
        params: {
          location: params?.location,
          page_size: params?.page_size,
          page: params?.page,
          search: params?.search,
          star_rating__in: params?.star_rating__in,
          is_replied: params?.is_replied,
        },
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async publishReview(id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/reviews/${id}/reply/publish/`, '', {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async unPublishReview(id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/reviews/${id}/reply/unpublish/`, '', {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async editOttoGBPReview(id, payload) {
    try {
      const response = await this.axios.put(`${OttoV2Api.baseUrlCa}/gbp/v1/reviews/${id}/reply/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async aiGenerateGBPReview(id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/reviews/${id}/reply/ai-generate/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async editFullOttoGBPPost(id: string, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrl}/gbp/v1/posts/${id}/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async addQuestionAnswer(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async ottoQAAiGeneratedAnswer(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/questions/ai-generate/answer-content/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getLocationDetail(id) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async setReviewSettings(id, payload) {
    try {
      const response = await this.axios.put(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/reviews/settings/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async updateOTTOSettings(uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/update-settings/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadReviewSettings(id) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/reviews/settings/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async addPage(uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/add-user-top-page/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async addSourcePage(uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/append-values/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async deleteUrlIssue(id, uuid, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrl}/v2/otto-urls/${id}/?uuid=${uuid}`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getKnowledgeGraphFields() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/knowledge-graphs/meta/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async editKBTAnswer(id, uuid, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrl}/v2/otto-urls/${id}/?uuid=${uuid}`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async questionBulkAiGenerate(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/questions/ai-generate/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async generateQuestionContent(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/questions/ai-generate/question-content/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async generateAnswerContent(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/questions/ai-generate/answer-content/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async ottoBulkImportPostOrQuestion(id, key) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/${key}/bulk-import/`, { }, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async editCustomHtml(id, uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-urls/${id}/custom-content/?uuid=${uuid}`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getKnowledgeGraphData(id) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/v2/knowledge-graphs/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async ottoQuotaTopup(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlLinkgraph}/customer/payment/top-up/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getOttoQuotaAllocations() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlLinkgraph}/customer/payment/quota-allocations/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getTaskResult(taskId) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/core/v1/task-results/${taskId}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getStarRatingCount(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/gbp/v1/reviews/star-rating-count/`, {
        params: params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async updateTargetKeyword(id, uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-urls/${id}/edit-target-keywords/?uuid=${uuid}`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async aiGenerateReviewExample(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${id}/reviews/ai-generate/example/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async deletePost(id) {
    try {
      const response = await this.axios.delete(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async ownersAnswerAiGenerate(id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/${id}/owners-answer/ai-generate/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async buildPressReleaseContent(id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/cg/v1/press-release/${id}/build/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async publishPressReleaseContent(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/cg/v1/press-release/${id}/deploy/signal-genesys/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async createPressRelease(type, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/cg/v1/press-release/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getDistributionChannels() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/cg/v1/press-release/distributions/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getCategories() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrl}/cg/v1/press-release/signal-genesys/categories/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getExportTaskId(uuid) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/v2/otto-projects/${uuid}/export-task-completed-report/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getSummaryData(taskId) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/core/v1/task-results/${taskId}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async postBulkAiGenerate(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/bulk-generate/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async postBulkCreate(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/posts/bulk-create/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getDistributionAgainstUrl(id) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/cg/v1/press-release/${id}/channels/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async createBulkPages(uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/add-nlp-pages/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async createTopicalMapBulkArticles(uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/topical-authority-map/${uuid}/bulk-create-articles/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAggregatorNetwork(id) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlKey}/v1/aggregator-network/?gb_location_id=${id}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getSitemapsIndexingList(id) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/v2/otto-projects/${id}/indexing-sitemaps/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getIndexingUrls(id, params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/v2/indexing-urls/?otto_project_uuid=${id}`, {
        headers: getAuthHeader(),
        params: {
          page_size: params?.page_size,
          page: params?.page,
          search: params?.search,
        },
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async enableSitemap(id, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/v2/otto-projects/${id}/toggle-sitemap/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async enableUrlIndexing(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/v2/indexing-urls/select-for-indexing/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async createIndexingUrl(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/v2/indexing-urls/create-manual-urls/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async activateIndexing(id) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/v2/otto-projects/${id}/activate-instant-indexing/`, {}, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getGscEmailStatus(customerId, gscProperty) {
    try {
      const response = await this.axios.get(`https://gsc.searchatlas.com/search-console/api/v2/google-indexing/verify-service-account-access/?customer_id=${customerId}&gsc_property_url=${gscProperty}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSchemaAttributes(location) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/metadata/schema/attributes?location=${location}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSchemaFields() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/metadata/schema/fields`, {
        headers: getAuthHeader(),
      });
      return JSON.stringify(response.data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSchemaMedias() {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/metadata/schema/medias`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getMetadataCategories(search=null) {
    let params;
    if (search) params = search;
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/metadata/categories`, {
        params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async geMetadataServices(data=null) {
    let params;
    if (data) params = data;
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/metadata/services`, {
        params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getReviewStats(location) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/reviews/replied-count?location=${location}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getQuestionStats(location) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/questions/count?location=${location}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getOptimizations(location, search=null) {
    let params;
    if (search) params = search;
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${location}/optimizations/`, {
        params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateOptimizations(location, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${location}/optimizations/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async generateOptimizations(location) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${location}/optimizations/generate/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deployOptimizations(location, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/gbp/v1/locations/${location}/optimizations/deploy/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async taskResults(taskId) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/core/v1/task-results/${taskId}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getLandingPageBuilderList(id) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/cg/v1/local-landing-page/?otto_project=${id}`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async generateLandingPage(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/cg/v1/local-landing-page/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async editLandingPage(uuid, payload) {
    try {
      const response = await this.axios.patch(`${OttoV2Api.baseUrlCa}/cg/v1/local-landing-page/${uuid}/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteLandingPage(uuid) {
    try {
      const response = await this.axios.delete(`${OttoV2Api.baseUrlCa}/cg/v1/local-landing-page/${uuid}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getLandingPageDetail(uuid) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/cg/v1/local-landing-page/${uuid}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getCitySuggestions(payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/cg/v1/local-landing-page/nearby-cities/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async publishLandingPageToWp(uuid, payload) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrlCa}/cg/v1/local-landing-page/${uuid}/publish-to-wp/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async ottoBulkAiGenerate(payload, uuid) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/bulk-ai-generate/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async freezeOttoProject(uuid) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/freeze/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getIndexingGraphKeys(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/v2/indexing-urls/status-graph/`, {
        params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async unfreezeOttoProject(uuid) {
    try {
      const response = await this.axios.post(`${OttoV2Api.baseUrl}/v2/otto-projects/${uuid}/unfreeze/`, {}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      },
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getSelectedSiteMapUrls(params) {
    try {
      const response = await this.axios.get(`${OttoV2Api.baseUrlCa}/v2/indexing-urls/urls-for-sitemap/`, {
        params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const OTTO_V2_API = new OttoV2Api();
