import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {notification, Row, Col} from 'antd';
import styles from './styles.module.scss';
import {Input} from '@/components/common-components/v2';
import {getLocation} from '@/utils/string';

export const ApiKey= observer(() => {
  const {settings: {customer: {profile}}} = useStore('');

  const handleCopyTxt = entryText => {
    navigator.clipboard.writeText(entryText);
    notification.success({
      message: 'API Key is copied to clipboard',
      placement: 'bottomRight',
    });
  };
  const title = 'Search Atlas API Key';
  return (
    <div className={styles.topHeading}>
      {!profile.isWhitelabel ? title : title?.replace('Search Atlas', getLocation(location))}
      <Row gutter={24}>
        <Col xl={18} md={18} xs={24} className={styles.container}> <Input className={styles.keyContainer} value={profile?.searchatlasApiKey}/></Col>
        <Col xl={6} md={6} xs={24} style= {{marginTop: '24px'}}><div className={styles.footerRight} onClick={() => handleCopyTxt(profile?.searchatlasApiKey)}><img src='/img/copy-white.svg' />Copy API Key</div></Col>
      </Row>
    </div>
  );
});
