import React, {useEffect, useState, PropsWithChildren} from 'react';
import {RootStoreType} from '@/store/root-store';
import {setTokenIntoCookies, getTokenFromCookies, setHasRegisteredIntoStorage, getHasRegisteredFromStorage} from '@/api/common-utils';
import {useRouter} from 'next/router';
import {isProtectedPath} from '@/utils/subscription';
import {MixPanel} from '@/utils/mixpanel';
import {MainLoader} from '@/components/common-components/v2/MainLoader';
import {getSingleUrlParam} from '@/utils/url';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';

type IProps = PropsWithChildren<{
  store: RootStoreType;
}>;

const withAuthentication = (WrappedComponent: React.FC<any>) => {
  const RequiresAuthentication = (props: IProps) => {
    const router = useRouter();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
      if (!router.isReady) return;
      const fetchProfile = async () => {
        // this is used by masquerade feature.
        // eslint-disable-next-line camelcase
        const {jwtToken, impersonate, activationCode, sso_access_token /* redirectTarget */} = router.query;
        if (impersonate && impersonate == '1') {
          localStorage.setItem('impersonate', '1');
        }
        // attempt to activate customer profile.
        if (activationCode && typeof activationCode == 'string') {
          const auth = await props.store.settings.customer.activateProfile(activationCode);
          if (auth?.success) {
            setTokenIntoCookies(auth?.accessToken);
            // const item = localStorage.getItem('domainAnalyzerUrl');
            // if (item) {
            //   router.push(item);
            // } else {
            //   router.push('/onboarding');
            // }
            // if (redirectTarget && typeof redirectTarget == 'string') {
            // }
          }
        }

        if (jwtToken && typeof jwtToken === 'string') {
          setTokenIntoCookies(jwtToken);
        }

        // eslint-disable-next-line camelcase
        if (sso_access_token && typeof sso_access_token === 'string') {
          try {
            const auth = await props.store.settings.customer.getSsoToken(sso_access_token);
            if (auth.bearerToken) {
              setTokenIntoCookies(auth.bearerToken);
              router.push('/home');
            }
          // eslint-disable-next-line no-empty
          } catch (error) {}
        }

        try {
          const token = getTokenFromCookies();
          if (token) {
            localStorage.setItem('signedInFrom', 'default');
            /*
            token needs to be at least in cookies before
            we make profile API call.
            */
            await props.store.settings.customer.loadProfile();

            // identify user at mixpanel and update profile information
            const {
              id, customerId, firstName, emailAddress,
              lastName, isVerified, isSubscriber, planName, subscription,
            } = props.store.settings.customer.profile;

            if (impersonate != '1') {
              MixPanel.identify(`${id}`);
              MixPanel.people.set({
                $name: `${firstName} ${lastName}`,
                $email: emailAddress,
                isVerified: isVerified,
                isSubscriber: isSubscriber,
                customerId: customerId,
                currentPlan: planName,
                monthlyPaymentAmount: subscription?.paymentAmount,
                lastSeen: new Date(),
              });
              MixPanel.register({
                customerId,
                isVerified,
                isSubscriber,
              });
            }
            // const savedUrl = localStorage.getItem('nextUrl');
            // if (savedUrl) {
            //   router.push(savedUrl);
            // }
          } else {
            const hasRegistered = getHasRegisteredFromStorage();
            if (hasRegistered === 'YES') {
              router.push('/login');
            } else {
              const invitationToken = getSingleUrlParam('invitation_token');
              if (invitationToken) {
                typeof window !== 'undefined' && localStorage.setItem('registerEmailUrl', `${router?.asPath}`);
                router.push(`/register?invitation_token=${invitationToken}`);
              } else {
                router.push('/register');
              }
            }
            return;
          }

          // Check for protected paths and if this customer has access to those
          // paths, and redirect to /404 if doesn't have access rights.
          const profile = props.store.settings.customer.profile;
          if (profile?.subscription) {
            const {isProtected, productFlag} = isProtectedPath(location.pathname);
            if (isProtected && !profile?.subscription[productFlag]) {
              router.push('/404');
            }
          }

          // load invoices into the store as soon as we've got the customer.
          // await props.store.invoices.loadInvoices();
          // await props.store.invoices.loadOrderInvoices();
          // await props.store.invoices.getCurrentPayment();
          props.store.invoices.loadOrderInvoices('');
          props.store.invoices.getCurrentPayment();
          if (process.env.NODE_ENV === 'production') {
            if (window?.$crisp) {
              try {
                window?.$crisp?.push(['set', 'user:email', [props.store.settings.customer.profile.emailAddress]]);
                window?.$crisp?.push(['set', 'session:data', [
                  [
                    ['plan_name', props.store.settings.customer.profile?.planName],
                    ['monthly_fee', toJS(props.store.settings.customer.profile?.subscription?.paymentAmount)],
                    ['customer_qp_link', `https://quantumpuppy.com/admin/customer/customer/${props.store.settings.customer.profile?.customerId}/change/`],
                  ],
                ]]);
              } catch (e) {
                console.error('Crisp error - ', e);
              }
            }
          }
          setIsAuthenticated(true);
          setHasRegisteredIntoStorage();
        } catch (error) {
          if (error?.response?.status === 429) {
            notification.error('', 'You’re currently out of quota. Please upgrade your plan to Optimize this Page.');
          }
          // if (error?.response?.status === 401) {
          //   notification.info('Your session has expired. Please login again.');
          // } else {
          //   notification.info('Something went wrong.');
          // }
          // interceptor already pushes to login.
          // Just catching so, its not thrown.
        }
      };
      fetchProfile();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.isReady]);

    return isAuthenticated ? <WrappedComponent {...props} /> : <MainLoader fullPage={true} />;
  };

  return RequiresAuthentication;
};

export default withAuthentication;
