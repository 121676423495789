import {Modal, Typography} from '@/components/common-components/components';
import {useStore} from '@/store/root-store';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import stylesLocal from './styles.module.scss';
import {CommonInput} from '@/components/common-components/components/input';
import {Button, Col, DatePicker, DatePickerProps, Dropdown, InputNumber, Menu, Row} from 'antd';
import {useEffect, useState} from 'react';
import {CreateBoard} from '../createBoard/create-board';
import moment from 'moment';

const statuses = [
  'Paused',
  'Pending Client Approval',
  'Not Started',
  'Rejected Need Revision',
  'Done',
  'In Progress',
];

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const AddTaskModal = observer(() => {
  const {reportBuilder:
    {details:
      {
        setAddTaskBoard,
        addTask,
        addTaskBoard,
        addingNotionBoardTask,
        singleProjectData,
        setModalData,
        modalData,
        loadBoardsList,
        boardsList,
        loadingBoardsList,
      }}} = useStore('');
  const [nameRequiredError, setNameRequiredError] = useState(false);
  const [campaignMonth, setCampaignMonth] = useState(moment().format('MMMM'));

  const onClose = () => {
    setNameRequiredError(false);
    setModalData({
      ...modalData,
      openModal: false,
      modalType: '',
      title: '',
      status: 'In Progress',
      type: '',
      website: '',
      issueFound: '',
      fixed: '',
      dueDate: '',
      rankingFactor: '',
      timeSavedWithOtto: '',
    });
  };

  const onBtnClick = async () => {
    try {
      if (!modalData?.title || !modalData?.status) {
        if (!modalData?.title) {
          setNameRequiredError(true);
          return;
        }
      }
      const payload = {
        'customer_client_id': singleProjectData?.customerClientId,
        'task': {
          'title': modalData?.title,
          'status': modalData?.status,
          'campaign_month': campaignMonth,
          'board_id': modalData?.boardId,
          ...(modalData?.type && {'type': modalData?.type}),
          ...(modalData?.website && {'website': modalData?.website}),
          ...(modalData?.issueFound && {'issues_found': Number(modalData?.issueFound)}),
          ...(modalData?.fixed && {'issues_fixed': Number(modalData?.fixed)}),
          ...(modalData?.dueDate && {'due_date': modalData?.dueDate}),
          ...(modalData?.rankingFactor && {'ranking_factor': modalData?.rankingFactor}),
          ...(modalData?.timeSavedWithOtto && {'time_saved': modalData?.timeSavedWithOtto}),
        },
      };
      await addTask(payload);
      onClose();
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const getBoardsList = async () => {
    const response = await loadBoardsList(singleProjectData?.customerClientId);
    if (response?.length) {
      setModalData({...modalData, boardId: response[0]?.id, boardName: response[0]?.name});
    }
  };

  useEffect(() => {
    getBoardsList();
  }, [addTaskBoard]);

  const onDateChange: DatePickerProps['onChange'] = date => {
    setModalData({...modalData, dueDate: date?.format('MM-DD-YYYY')});
  };

  return <>
    <StyledModal
      visible={modalData?.openModal}
      maskStyle={{zIndex: 9}}
      width={560}
      closable={false}
      className={stylesLocal.Modal}
      destroyOnClose
      zIndex={9}
    >
      {modalData?.modalType == 'create-board' ? <CreateBoard/> : <div style={{padding: '25px 30px 15px 30px', borderRadius: '12px', backgroundColor: '#F9F9FB'}}>
        <div style={{paddingBottom: '15px'}}>
          <span style={{fontSize: '20px', fontWeight: 600}}>Add Task</span><br/>
          {/* <span>to July Tasks board</span> */}
        </div>
        <Row className={stylesLocal.AntdRow}>
          <Col span={24}>
            <div className={stylesLocal.ContentDiv}>
              <Typography className={stylesLocal.Title} tag='h5'><span>Board</span><span style={{color: '#2d6cca', cursor: 'pointer'}} onClick={()=> setAddTaskBoard(true)}>+Add Task Board</span></Typography>
              <Dropdown
                disabled={loadingBoardsList}
                trigger={['click']}
                overlay={<MenuStyled onClick={item => setModalData({...modalData, boardId: item?.key?.split('>>')[0], boardName: item?.key?.split('>>')[1]})}>
                  {boardsList?.map(item => <MenuItemStyled style={{background: item?.id == modalData?.boardId ? 'rgba(45,108,202,0.2)' : ''}} key={`${item?.id}>>${item?.name}`}>{item?.name}</MenuItemStyled>)}
                </MenuStyled>}>
                <DropdownContentWrapper disabled={false}>
                  <DropdownContent>{modalData?.boardName}</DropdownContent>
                  <FontAwesomeIcon icon={faCaretDown} />
                </DropdownContentWrapper>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Row className={stylesLocal.AntdRow}>
          <Col span={24}>
            <div className={stylesLocal.ContentDiv}>
              <Typography className={stylesLocal.Title} tag='h5'>Campaign Month</Typography>
              <Dropdown trigger={['click']} overlay={<Menu onClick={item => setCampaignMonth(item?.key)}>
                {months?.map(item => <Menu.Item style={{background: item == campaignMonth ? 'rgba(45,108,202,0.2)' : ''}} key={item}>{item}</Menu.Item>)}
              </Menu>}>
                <DropdownContentWrapper disabled={false}>
                  <div style={{fontSize: '12px'}}>{campaignMonth}</div>
                  <FontAwesomeIcon icon={faCaretDown} />
                </DropdownContentWrapper>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Row className={stylesLocal.AntdRow}>
          <Col span={18}>
            <div className={stylesLocal.ContentDiv} style={{paddingRight: '13px'}}>
              <Typography className={stylesLocal.Title} tag='h5'>Name</Typography>
              <CommonInput className={stylesLocal.CommonInput} value={modalData?.title} onChange={e => {
                setNameRequiredError(false);
                setModalData({...modalData, title: e.target.value});
              }} iconClasses={stylesLocal.InputIcon}/>
            </div>
            {nameRequiredError && <div style={{color: 'red', fontSize: '12px'}}>Task Name is required</div>}
          </Col>
          <Col span={6}>
            <div className={stylesLocal.ContentDiv}>
              <Typography className={stylesLocal.Title} tag='h5'>Status</Typography>
              <Dropdown trigger={['click']} overlay={<MenuStyled onClick={item => setModalData({...modalData, status: item?.key})}>
                {statuses?.map(item => <Menu.Item style={{borderRadius: '8px', background: item == modalData?.status ? 'rgba(45,108,202,0.2)' : ''}} key={item}>{item}</Menu.Item>)}
              </MenuStyled>}>
                <DropdownContentWrapper disabled={false}>
                  <DropdownContent>{modalData?.status}</DropdownContent>
                  <FontAwesomeIcon icon={faCaretDown} />
                </DropdownContentWrapper>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Row className={stylesLocal.AntdRow}>
          <Col span={24}>
            <div className={stylesLocal.ContentDiv}>
              <Typography className={stylesLocal.Title} tag='h5'>Domain / Page URL (optional)</Typography>
              <CommonInput className={stylesLocal.CommonInput} value={modalData?.website} onChange={e => setModalData({...modalData, website: e.target.value})} iconClasses={stylesLocal.InputIcon}/>
            </div>
          </Col>
        </Row>
        <Row className={stylesLocal.AntdRow}>
          <Col span={9}>
            <div className={stylesLocal.ContentDiv} style={{paddingRight: '13px'}}>
              <Typography className={stylesLocal.Title} tag='h5'>Task type (Optional)</Typography>
              <CommonInput className={stylesLocal.CommonInput} value={modalData?.type} onChange={e => setModalData({...modalData, type: e.target.value})} iconClasses={stylesLocal.InputIcon}/>
            </div>
          </Col>
          <Col span={8}>
            <div className={stylesLocal.ContentDiv} style={{paddingRight: '13px'}}>
              <Typography className={stylesLocal.Title} tag='h5'>Issues found (Optional)</Typography>
              <InputNumber min={'0'} className={stylesLocal.InputNumber} value={modalData?.issueFound} onChange={value => {
                if (Number(value) > 0) {
                  setModalData({...modalData, issueFound: String(value)});
                } else {
                  setModalData({...modalData, issueFound: ''});
                }
              }}/>
            </div>
          </Col>
          <Col span={7}>
            <div className={stylesLocal.ContentDiv}>
              <Typography className={stylesLocal.Title} tag='h5'>Issues fixed (Optional)</Typography>
              <InputNumber min={'0'} className={stylesLocal.InputNumber} value={modalData?.fixed} onChange={value => {
                if (Number(value) > 0) {
                  setModalData({...modalData, fixed: String(value)});
                } else {
                  setModalData({...modalData, fixed: ''});
                }
              }}/>
            </div>
          </Col>
        </Row>
        <Row className={stylesLocal.AntdRow}>
          <Col span={24}>
            <div className={stylesLocal.ContentDiv}>
              <Typography className={stylesLocal.Title} tag='h5'>Due date (optional)</Typography>
              <StyledDatePicker disabledDate={current => {
                const customDate = moment().format('YYYY-MM-DD');
                return current && current < moment(customDate, 'YYYY-MM-DD');
              }} onChange={onDateChange} format='DD / MM / YYYY' />
            </div>
          </Col>
        </Row>
        <Row className={stylesLocal.AntdRow}>
          <Col span={13}>
            <div className={stylesLocal.ContentDiv} style={{paddingRight: '13px'}}>
              <Typography className={stylesLocal.Title} tag='h5'>Ranking Factor (optional)</Typography>
              <CommonInput className={stylesLocal.CommonInput} value={modalData?.rankingFactor} onChange={e => setModalData({...modalData, rankingFactor: e.target.value})} iconClasses={stylesLocal.InputIcon}/>
            </div>
          </Col>
          <Col span={11}>
            <div className={stylesLocal.ContentDiv}>
              <Typography className={stylesLocal.Title} tag='h5'>Time Saved with OTTO (optional)</Typography>
              <CommonInput className={stylesLocal.CommonInput} value={modalData?.timeSavedWithOtto} onChange={e => setModalData({...modalData, timeSavedWithOtto: e.target.value})} iconClasses={stylesLocal.InputIcon}/>
            </div>
          </Col>
        </Row>
        <div className={stylesLocal.FooterDiv}>
          <ButtonsWrapper>
            <div
              onClick={() => onClose()} className={stylesLocal.BtnCancel}>
              <a style={{color: '#4E5156'}}>Cancel</a>
            </div>

            <ButtonStyled loading={addingNotionBoardTask} disabled={addingNotionBoardTask} className={stylesLocal.Btn} color='blue' onClick={() => onBtnClick()}>Add Task</ButtonStyled>
          </ButtonsWrapper>
        </div>
      </div>}
    </StyledModal>
    {addTaskBoard && <CreateBoard />}
  </>;
});

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  button{
      color: white;
    border-radius: 8px;
    background: #1890ff;}
`;

const StyledDatePicker = styled(DatePicker)`
  height: 38px;
  font-size: 12px;
  background: #ffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

const StyledModal = styled(Modal)`
  border-radius: 12px;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px 0px #0000000D;
`;

const MenuStyled = styled(Menu)`
  width: 99%;
  padding: 10px;
  border-radius: 8px;
  max-height: 323px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 5px;
  }
`;

const MenuItemStyled = styled(Menu.Item)`
  border-radius: 8px;
  span {
    width: 450px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const DropdownContentWrapper = styled.div<{disabled: boolean}>`
  height: 38px;
  font-size: 12px;
  background: #ffff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
`;

const DropdownContent = styled.div`
  width: 450px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const ButtonStyled = styled(Button)`
  width: fit-content !important;
  height: 44px !important;
  display: flex !important;
  align-items: center !important;
  padding: 13px 25px 14px 25px !important;
  border-radius: 8px !important;
  background: #2D6CCA !important;
  color: #ffff !important;

  &:hover {
    color: #ffff !important;
    border-color: #2D6CCA !important;
  }
  &:focus {
    color: #ffff !important;
    border-color: #2D6CCA !important;
  }
  &:disabled {
    color: #ffff !important;
    border-color: #2D6CCA !important;
  }
`;
