import {useRef, useEffect} from 'react';
import {Modal} from '@/components/common-components';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {useStore} from '@/store/root-store';
import moment from 'moment';
import {addProtocolToDomainHttps, getDomain} from '@/utils/url';
import {Empty, Spin} from 'antd';
import {observer} from 'mobx-react-lite';
import {LoadingOutlined} from '@ant-design/icons';

interface Props {
  visible: boolean;
  selectedNotificationId?: number | null;
  highlightLatest: boolean;
  setVisible: (visibility: boolean) => void;
}

const WhatsNewModal = observer(({visible, setVisible, highlightLatest, selectedNotificationId}: Props) => {
  const selectedRef = useRef(null);
  const {settings: {customer: {customerNotifications: {results: {data: notificationsData}, next}, loadCustomerNotifications, notificationsLoading}}} = useStore('');

  const addDefaultSrc = e => {
    e.target.src = '/img/icon/se-no-screenshot-placeholder.svg';
  };

  useEffect(() => {
    if (selectedRef?.current) {
      selectedRef.current?.scrollIntoView();
      !highlightLatest && selectedRef.current?.classList?.add('temporary-highlight');
    }
  }, [selectedRef?.current]);

  const handleScroll = e => {
    const atBottom = Math.round(e?.target?.scrollHeight - e?.target?.scrollTop) === e?.target?.clientHeight;
    if (atBottom && next && !notificationsLoading) {
      loadCustomerNotifications();
    }
  };

  const antIcon = <LoadingOutlined style={{fontSize: 36, color: '#7f4ead', marginRight: '10px'}} spin />;

  const getHttpLink = url => {
    const domain = getDomain(url);
    if (!domain) {
      const currentDomain = addProtocolToDomainHttps(
        getDomain(window.location.hostname) === 'localhost' ? 'localhost:3000' : getDomain(window.location.hostname),
      );
      return `${currentDomain}${url}`;
    }
    return addProtocolToDomainHttps(url);
  };

  return (
    <ModalStyled visible={visible} onClose={() => setVisible(false)} destroyOnClose={true} wrapClassName='rc-dialog-wrapModal'>
      <div style={{paddingRight: 15, paddingLeft: 15}}>
        <StyledBoxIcon>
          <img src='/img/icon/newspaper.svg' alt='New Notifications' style={{width: '100%', height: '100%'}} />
        </StyledBoxIcon>
        <Title>What&apos;s new</Title>
      </div>
      <NotificationsWrapper onScroll={handleScroll}>
        {notificationsData?.length > 0 ? (
          <>
            {notificationsData?.map((item, index) => (
              <Notification {...(selectedNotificationId == item?.id ? {ref: selectedRef} : {})} key={item?.id} isHighlighted={index == 0 && highlightLatest}>
                <p className='notification-date'>{item?.releaseDate ? moment(item?.releaseDate).format('MMMM D, YYYY') : '-'}</p>
                <div style={{flexGrow: 1}} className='content-wrapper'>
                  <h3 className='notification-title'>{item?.title}</h3>
                  {(item?.bannerImage && item?.bannerImage?.length > 0) && (
                    <div className='banner-image'>
                      <img src={item?.bannerImage} alt={item?.title} style={{width: '100%'}} onError={e => addDefaultSrc(e)} />
                    </div>
                  )}
                  <div className='description' dangerouslySetInnerHTML={{__html: item?.wysiwygContent}} />
                  {(item?.ctaLink && item?.ctaLink?.length > 0) && (
                    <div><StyledLink href={getHttpLink(item.ctaLink)} target='_blank'>{item?.ctaLabel || 'View'}</StyledLink></div>
                  )}
                </div>
              </Notification>
            ))}
            {notificationsLoading && (
              <div style={{textAlign: 'center'}}>
                <Spin indicator={antIcon} />
              </div>
            )}
          </>
        ) : (
          <EmptyStyled description='No New Notifications' />
        )}
      </NotificationsWrapper>
      <div style={{position: 'absolute', top: -1, right: -31}}>
        <FontAwesomeIcon
          icon={faXmark}
          style={{height: '26px', width: '19.5px', color: '#FFFFFF', cursor: 'pointer'}}
          onClick={() => setVisible(false)}
        />
      </div>
    </ModalStyled>
  );
});

export default WhatsNewModal;

const NotificationsWrapper = styled.div`
  max-height: 70vh;
  min-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 7px;
    height: 100px;
  }

/* Handle portion of the scrollbar */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(78, 81, 86, 0.5);
    border-radius: 12px;
  }
  @keyframes highlightNotification {
    0% {background: rgba(127, 78, 173, 0.2);}
    100% {background: #fff;}
  }

  .temporary-highlight {
    animation: highlightNotification 1.5s forwards;
  }
`;

const StyledLink = styled.a`
  display: inline-block;
  background-color: #7F4EAD !important;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  color: #fff !important;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  height: 38px;
`;

const Notification = styled.div<{isHighlighted?: boolean}>`
  min-height: 100px;
  width: 100%;
  padding: 13px 15px 0;
  border-radius: 12px;
  border-radius: ${p => p?.isHighlighted ? '12px' : '0'};
  display: flex;
  gap: 34px;
  ${p => p?.isHighlighted ? 'background-color: rgba(127, 78, 173, 0.08);' : ''}
  .content-wrapper {
    ${p => p?.isHighlighted ? '' : 'border-bottom: 1px solid #e8eaec !important;'}
    padding-bottom: 13px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    row-gap: 3px;
  }
  .notification-title {
    font-size: 20px;
    line-height: 24.2px;
    font-weight: 600;
    color: #121212;
    margin-bottom: 5px !important;
  }
  .description {
    color: ${p => p?.isHighlighted ? '#121212' : '#4E5156'};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px
  }
  .description p,
  .description h1,
  .description h2,
  .description h3,
  .description h4,
  .description h5,
  .description h6 {
      line-height: normal !important;
      margin-bottom: 0px !important;
  }

  .banner-image {
    width: 60%;
    min-width: 410px;
    margin-top: 12px;
    margin-bottom: 12px;
    border-radius: 12px;
    overflow: hidden;
    min-height: 100px;
    @media screen and (max-width: 860px) {
      width: 95%;
    }
  }
  img:not(.banner-image img) {
    max-width: 100% !important;
    height: auto !important;
  }
  .notification-date {
    margin-top: 3px;
    color: ${p => p?.isHighlighted ? '#7F4EAD' : '#4E5156'};
    font-weight: ${p => p?.isHighlighted ? '600' : '400'};
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
  }
`;

const ModalStyled = styled(Modal)`
  margin-top: auto;
  margin-bottom: auto;
  width: 80vw;
  max-width: 800px !important;
  max-height: 890px !important;
  display: flex;
  align-items: center;
  .rc-dialog-content {
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    margin-right: auto;
    margin-left: auto;
  }
  @media screen and (max-width: 600px) {
    width: 80vw;
  }
  .rc-dialog-body {
    padding: 30px 15px;
    @media screen and (max-width: 600px) {
      padding: 20px 15px;
    }
  }
  .rc-dialog-close {
    display: none;
  }
`;

const StyledBoxIcon = styled.div`
  width: 34px !important;
  height: 34px !important;
  margin-bottom: 8px;
`;

const Title = styled.h2`
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
`;

const EmptyStyled = styled(Empty)`
  color: rgba(0, 0, 0, 0.25);
  height: 200px;
  padding-top: 30px;
`;
