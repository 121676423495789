import React from 'react';
import {Select, Tooltip} from 'antd';
import styled from 'styled-components';
import {useStore} from '@/store/root-store';
import {getDomain} from '@/utils/url';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';

interface Props {
  onProjectChange?: (value)=> void;
  selectedId?: any;
  isReportBuilder?: boolean;
}
export const ProjectSelector: React.FC<Props> = observer(({onProjectChange, selectedId, isReportBuilder}) => {
  const {navBar: {getSiteProperties, setIsActivatePropertiesDrawerOpen}} = useStore('');
  const {Option} = Select;
  const addDefaultSrc = e => {
    e.target.src = '/img/project-no-screenshot.png';
  };
  const getImgUrl = url => {
    return <ImgStyled onError={e => addDefaultSrc(e)} src={`https://www.google.com/s2/favicons?sz=64&domain_url=https://www.${getDomain(url)}`} alt={url}/>;
  };
  const getNewProjectData = id => {
    const findInactive = getSiteProperties?.find(item => item?.id == id)?.activeForCurrentCustomer;
    if (findInactive) {
      onProjectChange(id);
    }
  };

  return (
    <StyledSelect isReportBuilder={isReportBuilder} dropdownMatchSelectWidth={250} getPopupContainer={trigger => trigger.parentNode} defaultValue={selectedId} onChange={getNewProjectData} suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212'/>}>
      {getSiteProperties?.sort((a, b) => Number(b?.activeForCurrentCustomer) - Number(a?.activeForCurrentCustomer)).map((items, index)=> {
        return <Option key={`project-index-${index}`} value={items?.id} disabled={!items?.activeForCurrentCustomer}>
          <Tooltip placement='left' color='#121212'
            title={items?.activeForCurrentCustomer ? '' : <div>Inactive project. You can manage project status <span onClick={() => setIsActivatePropertiesDrawerOpen()} style={{color: '#2D6CCA', cursor: 'pointer'}}>here</span></div>}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{marginLeft: '5px'}}>
                {getImgUrl(items?.url)}
                {getDomain(items?.url)}</span>
            </div>
          </Tooltip>
        </Option>;
      })}
    </StyledSelect>
  );
});


const StyledSelect = styled(Select)<{isReportBuilder?: boolean}>`
    width: 100%;
  .ant-select-selector {
    width: ${p=> p?.isReportBuilder ? '330px' : ''} !important;
    border-radius: 5px !important;
    padding: 6px 6px !important;
    background: #CDCDCD;
    cursor: pointer !important;
    height: ${p=> p?.isReportBuilder ? '38px' : '28px'} !important;
    line-height: unset !important;
    border: 1px solid #CDCDCD;
    display: flex;
    align-items: center;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }
  .ant-select-selection-item {
    display: flex;
  }
`;

const ImgStyled = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 5px;
`;
