import {observer} from 'mobx-react';
import styled from 'styled-components';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {useState} from 'react';
import {SingleLinkSection} from './singleLinkSection';
import {urlRegex} from '@/constants/regex';
import {urlPrefixesWithWww} from '@/utils/url';

interface Props {
  state: any;
  setState: (state: any) => void;
  calledFromHero?:boolean;
  isInternalLink?: boolean;
  isExternalLink?: boolean;
  calledFromCa?: boolean;
}

export const LinksToInclude = observer(({state, setState, calledFromHero, isInternalLink, isExternalLink, calledFromCa}: Props) => {
  const [showError, setShowError] = useState(false);
  // Reorders entire Focus Topic sections
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // Trigers reorder after entire Focus Topic section drag finishes
  const onSectionDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state.items,
      result.source.index,
      result.destination.index,
    );

    setState({
      ...state,
      items: items,
    });
  };
  const getListStyle = () => ({
    width: '100%',
  });

  const getItemStyle = (isDragging, draggableStyle) => {
    return ({
      // some basic styles to make the items look a bit nicer
      userSelect: 'none',
      padding: '0px',
      margin: `0 0 0 0`,

      // styles we need to apply on draggables
      ...draggableStyle,
    });
  };

  // Updates state inside single Focus Topic section after reordering fields inside
  const onSingleFieldDragEnd = (index, newState) => {
    const curState = {...state};
    const items = curState['items'];
    items[index]['fields'] = newState;

    setState({
      ...state,
      items: items,
    });
  };

  const isEmptyState = (currentInputValue?: string) => {
    let isEmpty = true;
    if (currentInputValue) {
      isEmpty = false;
      return false;
    }
    for (let i = 0; i < state?.items?.length; i++) {
      if (state.items[i].value) {
        isEmpty = false;
        return false;
      } else {
        for (let n = 0; n < state.items[i].fields.length; n++) {
          if (state.items[i].fields[n].value) {
            isEmpty = false;
            return false;
          }
        }
      }
    }

    return isEmpty;
  };

  // url validation check section
  const urlRegexWithWww = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  const validateUrl = (value: string) => {
    if (value.length) {
      if (urlPrefixesWithWww.some(url => value?.startsWith(url))) {
        const result = urlRegexWithWww.test(value) && !value.includes(' ');
        setShowError(!result);
      } else {
        const result = urlRegex.test(value) && !value.includes(' ');
        setShowError(!result);
      }
    } else {
      setShowError(false);
    }
  };

  // Updates value for single focus topic on field edit
  const updateLink = (e, index: number) => {
    validateUrl(e.target.value);
    const curState = {...state};
    const items = curState['items'];
    const topic = items[index];

    topic['value'] = e.target.value;

    items[index] = topic;

    setState({
      ...state,
      items: items,
    });
    setState({
      ...state,
      isEmpty: isEmptyState(e.target.value),
    });
  };
  // Updates value for single focus topic on field edit
  const updateAnchorText = (e, index: number) => {
    const curState = {...state};
    const items = curState['items'];
    const topic = items[index];

    topic['anchorText'] = e.target.value;

    items[index] = topic;

    setState({
      ...state,
      items: items,
    });
    setState({
      ...state,
      isEmpty: isEmptyState(e.target.value),
    });
  };

  // Adds single field into single Focus Topic section
  const addNewField = (value?: string) => {
    const curState = {...state};
    const items = curState['items'];

    items.push({
      label: 'Question',
      placeholder: 'Question',
      value: value ? value : '',
      fields: [],
      required: false,
    });

    const newState = {
      ...curState,
      items: items,
    };

    setState(newState);
  };


  // Removes single Focus Topic section
  const removeTopicHandler = (index: number) => {
    const curState = {...state};
    const items = [...curState.items];

    items.splice(index, 1);
    if (items?.length == 1) {
      items[0].required = true;
    }
    setState({
      ...state,
      items: items,
    });
  };

  // Generates new Focus Topic section
  const addNewTopicField = () => {
    const curState = {...state};
    const items = [...curState.items, {
      value: '',
      anchorText: '',
      label: 'question',
      placeholder: 'Question',
      fields: [],
      required: false,
    }];

    setState({
      ...state,
      items: items,
    });
  };

  const usedQuestions = [];
  state?.items?.forEach(item => {
    item.fields?.filter(field => field.type === 'question')?.forEach(field => usedQuestions.push(field.value));
  });


  const onOptionChange = (value:string, index:number) => {
    const isRequired = value == 'optional' ? false : true;

    const updatedItems = state.items.map((item, indx) => {
      if (index == indx) {
        return {...item, required: isRequired};
      }
      return item;
    });

    setState(prevState => ({
      ...prevState,
      items: updatedItems,
    }));
  };


  return <Wrapper calledFromHero={calledFromHero} >
    {calledFromCa && <AddMoreTopics calledFromCa={calledFromCa} onClick={() => addNewTopicField()}>+ {isInternalLink ? 'Add internal link' : isExternalLink ? 'Add external link': 'Add link'}</AddMoreTopics>}
    <DragDropContext onDragEnd={onSectionDragEnd}>
      <Droppable droppableId='droppable'>
        {provided => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle()}
          >
            {state.items.map((item, index) => (
              <Draggable key={index} draggableId={`${item.label}-${index}`} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style,
                    )}
                  >
                    <SingleLinkSection
                      totalItems={state?.items?.length || 0}
                      totalTerms={item?.fields?.filter(item => item?.type == 'term').length}
                      totalQuestions={item?.fields?.filter(item => item?.type == 'question').length}
                      onSingleFieldDragEnd={onSingleFieldDragEnd}
                      updateLink={updateLink}
                      updateAnchorText={updateAnchorText}
                      currentItemState={item}
                      addField={addNewField}
                      removeTopic={removeTopicHandler}
                      index={index}
                      showError={showError}
                      calledFromHero={calledFromHero}
                      onOptionChange={onOptionChange}
                      calledFromCa={calledFromCa}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
      {!calledFromCa && <AddMoreTopics calledFromCa={calledFromCa} onClick={() => addNewTopicField()}>+ {isInternalLink ? 'Add internal link' : isExternalLink ? 'Add external link': 'Add link'}</AddMoreTopics>}
    </div>
  </Wrapper>;
});

const Wrapper = styled.div<{calledFromHero:boolean}>`
  // padding: 18px 30px;
  margin-top: ${props => props.calledFromHero ? '' : '20px'};
`;

const AddMoreTopics = styled.div<{calledFromCa?: boolean}>`
    color: #2D6CCA;
    font-size: 12px;
    cursor: pointer;
    ${p => p?.calledFromCa ? `
    display: flex;
    justify-content: flex-end;
    ` : ''}
`;


