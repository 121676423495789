import {Tooltip} from 'antd';
import styled from 'styled-components';
import {observer} from 'mobx-react';
import {Button} from '@/components/common-components/v2';

interface Props {
  link: any;
  addItem: (value: {link: string; anchorText?: string}) => void;
}
export const SingleExternalLink = observer(({link, addItem}: Props) => {
  const addDefaultSrc = e => {
    e.target.src = '/img/world-wide-web.svg';
  };

  return <SingleLinkContainer>
    <Left>
      <Icon>{link.url && <img onError={e => addDefaultSrc(e)} src={`https://www.google.com/s2/favicons?sz=16&domain_url=${link.url}`} />}</Icon>
    </Left>
    <Tooltip title={link?.url ?? link?.metaDesc} placement='left' overlayInnerStyle={{background: '#000', borderRadius: 8}}>

      <Right>
        <TitleAndStar>
          <Title>
            {link?.title ? link.title : ''}
          </Title>
        </TitleAndStar>

        <Link href={link?.url} target='_blank' rel='noreferrer'>
          {link?.url}
        </Link>
        <MetaDesc>
          { link.metaDesc}
        </MetaDesc>

      </Right>
    </Tooltip>
    <Button variant='ghost' color='blue' style={{fontSize: 12, padding: '5px 10px', marginRight: 0, marginLeft: 'auto', height: 30}} onClick={() => addItem({link: link?.url, anchorText: link?.metaDesc ?? ''})}>+ Add</Button>

  </SingleLinkContainer>;
});

const SingleLinkContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
`;
const Left = styled.div`
`;
const Right = styled.div`
`;
const TitleAndStar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Icon = styled.div`
    img {
      margin-bottom: auto;
      margin-top: 5px;
      width: 30px;
      margin-right: 10px;
    }
`;
const Title = styled.div`
  cursor: pointer;
  color: #121212 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 280px;
`;
const MetaDesc = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #121212;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: 280px;
`;
const Link = styled.a`
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: #34AEF3;
  width: 300px;

  &:hover {
    text-decoration: underline;
  }
`;


