import axios, {AxiosInstance, AxiosRequestConfig, AxiosError, CancelTokenSource, CancelToken, AxiosResponseTransformer} from 'axios';
import {notification} from '@/utils/notifications';
import {camelCase, isArray, isObject, mapKeys, mapValues} from 'lodash';
import {getApiUrl} from '@/api/common-utils';
import Router from 'next/router';
import {flushTokenFromCookies, getTokenFromCookies, setTokenIntoCookies, getHasRegisteredFromStorage} from './common-utils';
import {notification as notify} from '@/utils/notification-v2';
const tokenSources: {
  [key: string]: CancelTokenSource;
} = {};

export type ApiErrorType = {
  statusCode: number;
  errorCode: string;
  message: string;
};

function refreshAuthToken() {
  const token = getTokenFromCookies();
  const response = axios.post(`${getApiUrl(BaseApi.LINKGRAPH_ENDPOINT, '/api/token/refresh/')}`, {
    token,
  });
  return response;
}

function pushToLoginOrRegister() {
  flushTokenFromCookies();
  const hasRegistered = getHasRegisteredFromStorage();
  if (hasRegistered === 'YES') {
    Router.push('/login');
  } else {
    Router.push('/register');
  }
}

async function onCancelRequest(config: AxiosRequestConfig) {
  // if cancelToken is defined, we will cancel previous request and create a new token for new request.
  if (config.cancelToken) {
    const key = config.url;
    let tokenSource = tokenSources[key];
    if (tokenSource && tokenSource.cancel) {
      tokenSource.cancel('Operation canceled due to new request');
    }
    tokenSource = axios.CancelToken.source();
    config.cancelToken = tokenSource.token;
    tokenSources[key] = tokenSource;
  }

  return config;
}

async function onError(error: AxiosError, axiosInstance: AxiosInstance, errorList?: ApiErrorType[]) {
  let throwError = true;
  // return valid/empty response and exit
  if (axios.isCancel(error)) {
    return Promise.resolve({data: {isCancel: true}});
  }
  // Common error handling
  const {response} = error;
  if (!response) {
    notification.info('Something went wrong.');
  } else if (response.status === 401) {
    // Interceptor that retries 401s by refreshing
    // tokens automatically only if token exists.
    try {
      const _response = await refreshAuthToken();
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${_response.data.token}`;
      setTokenIntoCookies(_response.data.token);
      return axiosInstance(error.config);
    } catch (err) {
      notification.info('Your session has expired. Please login again.');
      pushToLoginOrRegister();
    }
  } else if (response.status === 429 && response?.data && response?.data['code'] === 'all_seats_occupied') {
    notification.info(response.data['detail']);
    pushToLoginOrRegister();
  } else if (response.status === 429) {
    if (response?.config?.url !== apiUrls.rankTracker && response?.config?.url !== apiUrls.bulkArticleGenerateCA) {
      notify.error('', 'You’re currently out of quota. Please upgrade your plan to Optimize this Page.');
    }
  // } else if (error.message === 'Network Error') {
  //   notify.info('Network Connectivity Problem Detected', 'You may be experiencing a network connectivity problem or no network connection is available.', 'Reload App', () => location.reload(), 8);
  } else {
    // Any other error.
    const {status, data} = response;
    /* let showGeneralError = true; */
    if (errorList && errorList.length) {
      const errorItem = errorList.filter(err => err.statusCode == status && err.errorCode == data['code']).pop();
      if (errorItem) {
        throwError = false;
        /*  showGeneralError = false; */
        notification.info(errorItem.message);
      }
    }
    /*     if (showGeneralError) {
      notification.error(`API request failed with code ${status}: ${JSON.stringify(data)}`);
    } */
  }
  if (throwError) {
    throw error;
  }
}


function keysToCamelCase<T>(obj: T): T {
  if (isArray(obj)) {
    // @ts-ignore
    return obj.map(keysToCamelCase);
  }
  if (!isObject(obj)) {
    return obj;
  }
  const fixedKeys = mapKeys(obj, (value, key) => camelCase(key));
  // @ts-ignore
  return mapValues(fixedKeys, keysToCamelCase);
}

// tslint:disable-next-line:no-any
function transformResponse(data: any): any {
  return keysToCamelCase(data);
}

function getDefaultTransformResponse(): AxiosResponseTransformer[] {
  const axiosDefault = axios.defaults.transformResponse;
  if (axiosDefault == null) {
    return [transformResponse];
  }
  if (isArray(axiosDefault)) {
    // @ts-ignore
    return axiosDefault?.concat(transformResponse);
  }
  // @ts-ignore
  return [axiosDefault, transformResponse];
}

const CAMEL_CASE_DEFAULT_CONFIG: AxiosRequestConfig = {
  transformResponse: getDefaultTransformResponse(),
};

export class BaseApi {
  public static readonly LINKGRAPH_ENDPOINT: string = 'lg';
  public static readonly GSC_ENDPOINT: string = 'gsc';
  public static readonly BACKLINK_ENDPOINT: string = 'bl';
  public static readonly KEYWORD_ENDPOINT: string = 'kw';
  public static readonly CA_ENDPOINT: string = 'ca';
  public static readonly SA_ENDPOINT: string = 'sa';

  axios: AxiosInstance;
  axiosWithoutAuth: AxiosInstance;
  cancelToken: CancelToken;

  constructor(baseURL?: string, errorList?: ApiErrorType[]) {
    this.axios = axios.create({...CAMEL_CASE_DEFAULT_CONFIG, baseURL});
    this.axios.interceptors.response.use(
      res => res,
      err => onError(err, this.axios, errorList),
    );
    this.axios.interceptors.request.use(
      onCancelRequest,
    );
    this.axiosWithoutAuth = axios.create({...CAMEL_CASE_DEFAULT_CONFIG, baseURL});
    this.cancelToken = axios.CancelToken.source().token;
  }
}

export const apiUrls = {
  rankTracker: `${getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api')}/v1/rank-tracker/`,
  bulkArticleGenerateCA: `content-fragments/full-seo-article-writer-bulk/`,
};

