import {toJS} from 'mobx';
import {flow, types, cast, getRoot} from 'mobx-state-tree';
import {initOverviewStore, OverviewStore} from './overview';
import {COMPETITOR_RESEARCHER_API} from '@/api/competitor-researcher-v2';
import {COMPETITOR_RESEARCHER_API_POLL_INTERVAL} from '@/constants';
import {notification} from '@/utils/notification-v2';
import {getSingleUrlParam} from '@/utils/url';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';
import {PROJECTS_API} from '@/api/projects';
import {apiError, isNetworkError} from '@/utils/api';
import {getTokenFromCookies} from '@/api/common-utils';

const trustCategory = types.model({
  category: types.maybeNull(types.string),
  percent: types.maybeNull(types.string),
});

const backlinksTrends = types.model({
  date: types.maybeNull(types.string),
  ascore: types.maybeNull(types.number),
  lostLinks: types.maybeNull(types.number),
  lostRefDomains: types.maybeNull(types.number),
  newLinks: types.maybeNull(types.number),
  newRefDomains: types.maybeNull(types.number),
  totalLinks: types.maybeNull(types.number),
  totalRefdomains: types.maybeNull(types.number),
});

const organicTrends = types.model({
  date: types.maybeNull(types.string),
  organicKeywordsTop3: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  organicTrafficBranded: types.maybeNull(types.number),
  organicTrafficCost: types.maybeNull(types.number),
  organicTrafficNonBranded: types.maybeNull(types.number),
  paidKeywords: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
});

const matrixSiteData = types.model({
  title: types.maybeNull(types.string),
  metaDesc: types.maybeNull(types.string),
  screenshotUrl: types.maybeNull(types.string),
  domainRating: types.maybeNull(types.number),
  urlRating: types.maybeNull(types.number),
  spamScore: types.maybeNull(types.number),
  organicTrend: types.maybeNull(types.array(organicTrends)),
  backlinksTrend: types.maybeNull(types.array(backlinksTrends)),
  authorityScore: types.maybeNull(types.number),
  refferingDomains: types.maybeNull(types.number),
  backlinkCount: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  organicKeywordsTop3: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  organicTrafficBranded: types.maybeNull(types.number),
  organicTrafficCost: types.maybeNull(types.number),
  organicTrafficNonBranded: types.maybeNull(types.number),
  paidKeywords: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  resultCode: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  externalBacklinks: types.maybeNull(types.number),
  referringDomains: types.maybeNull(types.number),
  indexedUrls: types.maybeNull(types.number),
  referringIps: types.maybeNull(types.number),
  referringSubnets: types.maybeNull(types.number),
  referringDomainsEdu: types.maybeNull(types.number),
  externalBacklinksEdu: types.maybeNull(types.number),
  referringDomainsGov: types.maybeNull(types.number),
  externalBacklinksGov: types.maybeNull(types.number),
  referringDomainsEduExact: types.maybeNull(types.number),
  externalBacklinksEduExact: types.maybeNull(types.number),
  referringDomainsGovExact: types.maybeNull(types.number),
  externalBacklinksGovExact: types.maybeNull(types.number),
  lastCrawledAt: types.maybeNull(types.string),
  lastCrawlResult: types.maybeNull(types.string),
  outdomainsExternal: types.maybeNull(types.string),
  outlinksExternal: types.maybeNull(types.string),
  outlinksInternal: types.maybeNull(types.string),
  outlinksPages: types.maybeNull(types.string),
  redirectTo: types.maybeNull(types.string),
  language: types.maybeNull(types.string),
  langaugeDescription: types.maybeNull(types.string),
  languageConfidence: types.maybeNull(types.string),
  totalNonuniqueLinks: types.maybeNull(types.string),
  nonUniqueLinkTypeDeleted: types.maybeNull(types.string),
  nonUniqueLinkTypeNofollow: types.maybeNull(types.string),
  nonUniqueLinkTypeImagelink: types.maybeNull(types.string),
  nonUniqueTextlink: types.maybeNull(types.string),
  referringDomainTypeFollow: types.maybeNull(types.string),
  referringDomainTypeDirect: types.maybeNull(types.string),
  citationFlow: types.maybeNull(types.number),
  trustFlow: types.maybeNull(types.number),
  trustMetric: types.maybeNull(types.number),
  trustCategories: types.maybeNull(types.array(trustCategory)),
});

const backlinksTrendModel = types.model({
  ascore: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  lostLinks: types.maybeNull(types.number),
  lostRefDomains: types.maybeNull(types.number),
  newLinks: types.maybeNull(types.number),
  newRefDomains: types.maybeNull(types.number),
  totalLinks: types.maybeNull(types.number),
  totalRefdomains: types.maybeNull(types.number),
});
const organicTrendModel = types.model({
  commercialKeywordsCount: types.maybeNull(types.number),
  commercialKeywordsTraffic: types.maybeNull(types.number),
  commercialKeywordsTrafficCost: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
  informationalKeywordsCount: types.maybeNull(types.number),
  informationalKeywordsTraffic: types.maybeNull(types.number),
  informationalKeywordsTrafficCost: types.maybeNull(types.number),
  navigationalKeywordsCount: types.maybeNull(types.number),
  navigationalKeywordsTraffic: types.maybeNull(types.number),
  navigationalKeywordsTrafficCost: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  organicKeywords4To10: types.maybeNull(types.number),
  organicKeywords11To20: types.maybeNull(types.number),
  organicKeywords21To30: types.maybeNull(types.number),
  organicKeywords31To40: types.maybeNull(types.number),
  organicKeywords41To50: types.maybeNull(types.number),
  organicKeywords51To100: types.maybeNull(types.number),
  organicKeywordsTop3: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  organicTrafficBranded: types.maybeNull(types.number),
  organicTrafficCost: types.maybeNull(types.number),
  organicTrafficNonBranded: types.maybeNull(types.number),
  paidKeywords: types.maybeNull(types.number),
  paidKeywords4To10: types.maybeNull(types.number),
  paidKeywords11To20: types.maybeNull(types.number),
  paidKeywords21To30: types.maybeNull(types.number),
  paidKeywords31To40: types.maybeNull(types.number),
  paidKeywords41To50: types.maybeNull(types.number),
  paidKeywords51To100: types.maybeNull(types.number),
  paidKeywordsTop3: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  paidTrafficCost: types.maybeNull(types.number),
  transactionalKeywordsCount: types.maybeNull(types.number),
  transactionalKeywordsTraffic: types.maybeNull(types.number),
  transactionalKeywordsTrafficCost: types.maybeNull(types.number),
});
export const MetricsModel = types.model({
  countryCode: types.maybeNull(types.string),
  processingStatus: types.maybeNull(types.string),
  keywordCount: types.maybeNull(types.number),
  paidKeywordCount: types.maybeNull(types.number),
  paidTraffic: types.maybeNull(types.number),
  authorityScore: types.maybeNull(types.number),
  backlinkCount: types.maybeNull(types.number),
  mode: types.maybeNull(types.string),
  traffic: types.maybeNull(types.number),
  domainRating: types.maybeNull(types.number),
  url: types.maybeNull(types.string),
  screenshotUrl: types.maybeNull(types.string),
  organicTrend: types.maybeNull(types.array(organicTrendModel)),
  backlinksTrend: types.maybeNull(types.array(backlinksTrendModel)),
});

export const ResearcherListModel = types.model({
  customerId: types.maybeNull(types.number),
  userEmail: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  isSaved: types.maybeNull(types.boolean),
  metrics: types.maybeNull(MetricsModel),
  searchedOn: types.maybeNull(types.string),
});

export const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  user_id: types.maybeNull(types.union(types.number, types.string)),
});


export const competitorsArrayModal = types.model({
  rank: types.maybeNull(types.number),
  domain: types.maybeNull(types.string),
  commonTerms: types.maybeNull(types.number),
});

export const competitorsModal = types.model({
  ppcCompetitors: types.maybeNull(types.array(competitorsArrayModal)),
  seoCompetitors: types.maybeNull(types.array(competitorsArrayModal)),
});

export const topKeywordsModal = types.model({
  keyword: types.maybeNull(types.string),
  seoClicks: types.maybeNull(types.number),
  searchVolume: types.maybeNull(types.number),
  topRankedurl: types.maybeNull(types.string),
  rankingDifficulty: types.maybeNull(types.number),
  totalMonthlyclicks: types.maybeNull(types.number),
  rank: types.maybeNull(types.number),

});

export const organicDomainOverviewKeywordStatusModal = types.model({
  lostranks: types.maybeNull(types.number),
  gainedranks: types.maybeNull(types.number),
  newlyranked: types.maybeNull(types.number),
});

export const htmlLabelsModal = types.model({
  hasData: types.maybeNull(types.boolean),
  hasTableData: types.maybeNull(types.union(types.boolean, types.string)),
  heading: types.maybeNull(types.string),
  nextCheck: types.maybeNull(types.string),
  subtext: types.maybeNull(types.string),
});

export const daPublicModel = types.model({
  organicTraffic: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  refdomainCount: types.maybeNull(types.number),
  backlinksCount: types.maybeNull(types.number),
  domainRating: types.maybeNull(types.number),
  competitors: types.maybeNull(competitorsModal),
  totalSeoClicks: types.maybeNull(types.number),
  totalAdwordsClicks: types.maybeNull(types.number),
  topOrganicKeywords: types.maybeNull(types.array(topKeywordsModal)),
  htmlLabels: types.maybeNull(types.array(htmlLabelsModal)),
  // topPaidKeywords: types.maybeNull(types.array(topKeywordsModal)),
  // ppcKeywords: types.maybeNull(types.number),
  ppcBudget: types.maybeNull(types.number),
  // organicDomainOverviewKeywordStatus: types.maybeNull(organicDomainOverviewKeywordStatusModal),
});

// for home v2
export const ottoModel = types.model({
  completedTasks: types.maybeNull(types.number),
  daysLeft: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  currentBatchExpiresAt: types.maybeNull(types.string),
  totalTasks: types.maybeNull(types.number),
});

export const saModel = types.model({
  diff: types.maybeNull(types.number),
  health: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
});

export const organicKeywordsTrendObject = types.model({
  value: types.maybeNull(types.number),
  date: types.maybeNull(types.string),
});

export const seModel = types.model({
  authority: types.maybeNull(types.number),
  backlinks: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  organicKeywords: types.maybeNull(types.number),
  organicTraffic: types.maybeNull(types.number),
  rating: types.maybeNull(types.number),
  refdomains: types.maybeNull(types.number),
  screenshotUrl: types.maybeNull(types.string),
  organicKeywordsTrend: types.maybeNull(types.union(types.array(types.number), types.array(organicKeywordsTrendObject))),
  organicTrafficTrend: types.maybeNull(types.union(types.array(types.number), types.array(organicKeywordsTrendObject))),
});

export const krtModel = types.model({
  id: types.maybeNull(types.number),
  trackedKeywords: types.maybeNull(types.number),
  visibility: types.maybeNull(types.number),
});


export const dataModel = types.model({
  krt: types.maybeNull(krtModel),
  otto: types.maybeNull(ottoModel),
  sa: types.maybeNull(saModel),
  se: types.maybeNull(seModel),
});

export const AppErrorMessageModel = types.model({
  otto: types.maybeNull(types.array(types.string)),
  sa: types.maybeNull(types.array(types.string)),
  krt: types.maybeNull(types.array(types.string)),
  se: types.maybeNull(types.frozen({})),
});

export const customerProjectsModel = types.model({
  appErrorMessages: types.maybeNull(AppErrorMessageModel),
  competitors: types.maybeNull(types.array(types.string)),
  countryCode: types.maybeNull(types.string),
  domainUrl: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  keywords: types.maybeNull(types.array(types.string)),
  status: types.maybeNull(types.string),
  data: types.maybeNull(dataModel),
  krtId: types.maybeNull(types.number),
  ottoId: types.maybeNull(types.number),
  saId: types.maybeNull(types.number),
  seId: types.maybeNull(types.number),
  inProgress: types.maybeNull(types.array(types.string)),
});

export const krtObjects = types.model({
  id: types.maybeNull(types.number),
  hostname: types.maybeNull(types.string),
  preciseLocation: types.maybeNull(types.string),
  trackedKeywords: types.maybeNull(types.array(types.string)),
  countryCode: types.maybeNull(types.string),
});

const FacebookAdsModel = types.model({
  archiveId: types.maybeNull(types.string),
  body: types.maybeNull(types.string),
  caption: types.maybeNull(types.string),
  cards: types.maybeNull(types.frozen()),
  ctaText: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  entityType: types.maybeNull(types.string),
  gatedType: types.maybeNull(types.string),
  instagramActorName: types.maybeNull(types.string),
  instagramProfilePicUrl: types.maybeNull(types.string),
  isActive: types.maybeNull(types.boolean),
  isProfilePage: types.maybeNull(types.boolean),
  linkUrl: types.maybeNull(types.string),
  pageEntityType: types.maybeNull(types.string),
  pageIsDeleted: types.maybeNull(types.boolean),
  pageName: types.maybeNull(types.string),
  pageProfilePictureUrl: types.maybeNull(types.string),
  pageProfileUri: types.maybeNull(types.string),
  publisherPlatform: types.maybeNull(types.array(types.string)),
  startDate: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  videoHdUrl: types.maybeNull(types.string),
  videoSdUrl: types.maybeNull(types.string),
  videoPreviewImageUrl: types.maybeNull(types.string),
  linkDescription: types.maybeNull(types.string),
  originalImageUrl: types.maybeNull(types.string),
});

const competitorResearchUsersModel = types.model({
  id: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
});

export const CompetitorResearcherV2Store = types.model({
  loadingList: types.maybeNull(types.boolean),
  searchBtnLoading: types.maybeNull(types.boolean),
  overview: OverviewStore,
  listApiRepolling: types.boolean,
  params: types.maybeNull(paramsModel),
  historyParams: types.maybeNull(paramsModel),
  total: types.maybeNull(types.number),
  totalHistoryCount: types.maybeNull(types.number),
  // maxLookupReached: types.maybeNull(types.boolean),
  competitorResearcherListSaved: types.maybeNull(types.array(ResearcherListModel)),
  competitorResearcherListHistory: types.maybeNull(types.array(ResearcherListModel)),
  inputFieldValue: types.maybeNull(types.string),
  // daPublic: types.maybeNull((daPublicModel)),
  // daNonNullList: types.maybeNull(types.string),
  // daPublicLoading: types.maybeNull(types.boolean),
  // isError: false,
  competitorResearcherUrl: types.maybeNull(types.string),
  competitorResearcherId: types.maybeNull(types.union(types.string, types.number)),
  isGridView: types.boolean,
  openSiteExplorerBulkModal: types.boolean,
  isBulkLoading: types.boolean,
  uptimeNotificationShown: types.boolean,
  isExportLoading: types.boolean,
  // for home v2
  customerProjectsParams: types.maybeNull(paramsModel),
  customerProjectsDataTotalCount: types.maybeNull(types.number),
  customerProjectsData: types.maybeNull(types.array(customerProjectsModel)),
  isRepollProjects: types.boolean,
  refreshList: types.boolean,
  krtObjectsList: types.maybeNull(types.array(krtObjects)),
  loadingListV2: types.maybeNull(types.boolean),
  facebookAds: types.maybeNull(types.array(FacebookAdsModel)),
  loadingFacebookAds: types.boolean,
  matrixSiteDatas: types.maybeNull(matrixSiteData),
  loadingCompetitorResearchUsers: types.boolean,
  competitorResearchUsers: types.maybeNull(types.array(competitorResearchUsersModel)),
  showCreatorEmail: types.boolean,
  loadingNetworkGraphReport: types.boolean,
  domain: types.maybeNull(types.string),
  isValidUrl: types.maybeNull(types.boolean),
}).views(self => ({
  get getSavedCompetitorResearcherList() {
    return toJS(self.competitorResearcherListSaved);
  },
  get getHistoryCompetitorResearcherList() {
    return toJS(self.competitorResearcherListHistory);
  },
  get getCompetitorResearcherId() {
    // let id = null;
    // if (typeof window !== 'undefined') {
    //   id = self.competitorResearcherId || localStorage.getItem('competitorResearcherId') || '';
    // } else {
    //   id = self.competitorResearcherId || '';
    // }
    // if (!id && toJS(self.competitorResearcherList)?.length) {
    //   id = toJS(self.competitorResearcherList)[0]?.id;
    // }
    return self.competitorResearcherId || localStorage.getItem('competitorResearcherId') || (toJS(self.competitorResearcherListSaved)?.length ? toJS(self.competitorResearcherListSaved)[0]?.id : '') || (toJS(self.competitorResearcherListHistory)?.length ? toJS(self.competitorResearcherListHistory)[0]?.id : '') || '';
  },
  get getCustomerProjectsData() {
    return toJS(self.customerProjectsData);
  },
  get getFacebookAdsDataList() {
    return toJS(self.facebookAds);
  },
})).actions(self => {
  const setRefreshList = value => self.refreshList = value;
  const setIsExportLoading = value => self.isExportLoading = value;

  const siteExplorerUptime = flow(function* () {
    try {
      const response = yield COMPETITOR_RESEARCHER_API.getSiteExplorerUptime();
      return response;
    } catch (e) {
      return e;
    }
  });
  const networkGraphReport = flow(function* (targetDomain) {
    try {
      const response = yield COMPETITOR_RESEARCHER_API.getnetworkGraphReport(targetDomain);
      return response?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const networkGraphReportUpdate = flow(function* (targetDomain, countryCode?: string) {
    self.loadingNetworkGraphReport = true;
    try {
      const response = yield COMPETITOR_RESEARCHER_API.getnetworkGraphReportUpdate(targetDomain, countryCode);
      if (response?.status == 500) {
        notification?.error('Error', 'Server Error 500.');
      } else {
        self.matrixSiteDatas = cast(response.data);
      }
      return response?.data;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
      return Promise.reject(e);
    } finally {
      self.loadingNetworkGraphReport = false;
    }
  });

  const setUptimeNotification = value => {
    self.uptimeNotificationShown = value;
  };
  const postNewCompetitor = flow(function* (payload, search=false, fieldValue='') {
    self.searchBtnLoading = true;
    self.inputFieldValue = fieldValue;
    const publicHash = getSingleUrlParam('public_hash');
    try {
      const response = yield COMPETITOR_RESEARCHER_API.postCompetitorResearcher(payload, publicHash);
      if (response) {
        search ? '' : notification.success('Success', 'Project created successfully');
        return response;
      }
    } catch (e) {
      const rootStore = getRoot(self) as any;

      if (e?.response?.status === 406) {
        notification.warning('Competitor Researcher Quota Exceeded', e?.response?.data?.message, 'Update Plan', rootStore?.plans?.showSidebarPaymentDrawer);
        return false;
      } else if (e?.response?.status === 429) {
        notification.warning('Competitor Researcher Quota Exceeded', e?.response?.data?.message, 'Update Plan', rootStore?.plans?.showSidebarPaymentDrawer);
        return false;
      } else if (e?.response?.status == 500) {
        notification?.error('Error', 'Server Error 500.');
        return false;
      } else {
        const errorMessage = apiError(e) as string;
        notification.error('', errorMessage);
      }
      return {};
    } finally {
      self.loadingList = false;
      self.searchBtnLoading = false;
    }
  });

  const splitHTMLtoMultiPagePDF = flow(function* () {
    const elements = document.getElementsByClassName('single-html-block');
    if (elements.length === 0) {
      notification.error('Error', 'Error exporting page');
      return;
    }

    const element = elements[0] as HTMLElement;
    const width = element.offsetWidth;
    const height = element.offsetHeight;

    const margin = 15;
    const pdfWidth = width + 2 * margin;
    const pdfHeight = (pdfWidth * 1.5) + 2 * margin;

    const doc = new Jspdf('p', 'pt', [pdfWidth, pdfHeight]);

    const pageCount = Math.ceil(height / pdfHeight);
    Array.from(element.querySelectorAll('img'))?.forEach(img => {
      img.setAttribute('loading', 'eager');
    });

    try {
      const canvas = yield html2canvas(element, {allowTaint: true, useCORS: true});
      const image = canvas.toDataURL('image/png', 1.0);

      doc.addImage(image, 'PNG', margin, margin, width, height);

      for (let i = 1; i < pageCount; i++) {
        doc.addPage();
        doc.addImage(image, 'PNG', margin, -(pdfHeight * i) + margin, width, height);
      }

      doc.save('main-overview-page.pdf');
      setIsExportLoading(false);
    } catch (error) {
      setIsExportLoading(false);
    }
  });

  const exportSingleNode = flow(function* () {
    try {
      setIsExportLoading(true);
      yield new Promise(r => setTimeout(r, 500));
      yield splitHTMLtoMultiPagePDF();
    } catch (e) {
      setIsExportLoading(false);
    }
  });

  // const postNewCompetitorPublic = flow(function* (payload) {
  //   try {
  //     const response = yield COMPETITOR_RESEARCHER_API.postCompetitorResearcher(payload);
  //     if (response) {
  //       self.maxLookupReached = false;
  //       return response;
  //     }
  //   } catch (e) {
  //     if (e.response.status === 406) {
  //       notification.error('Maximum number of lookups reached.', 'You cannot make more than 2 lookups as unregistered user.');
  //       self.maxLookupReached = true;
  //     }
  //     return Promise.reject(e);
  //   } finally {
  //     self.loadingList = false;
  //   }
  // });

  // const objectsEqual = (o1, o2) =>
  //   typeof o1 === 'object' && o1 !== null && Object?.keys(o1)?.length > 0 ?
  //     Object?.keys(o1)?.length === Object?.keys(o2)?.length &&
  //     Object?.keys(o1)?.every(p => objectsEqual(o1[p], o2[p])) :
  //     o1 === o2;

  // const arraysEqual = (a1, a2) =>
  //   a1?.length === a2?.length && a1?.every((o, idx) => objectsEqual(o, a2[idx]));

  const loadCompetitorResearcherList = flow(function* (noLoading?: boolean) {
    if (!noLoading) {
      self.loadingList = true;
    }
    const payload = {
      ...self.params,
      saved: 1,
      detail_mode: Number(self.isGridView),
    };
    try {
      const response = yield COMPETITOR_RESEARCHER_API.getCompetitorResearchlist(payload);
      self.total = response?.count;
      updateList(response?.results);
      const metricsStatus = response?.results?.filter(item => !['FAILURE', 'SUCCESS', null].includes(item?.metrics?.processingStatus));
      if (metricsStatus?.length && self.listApiRepolling) {
        yield new Promise(r => setTimeout(r, COMPETITOR_RESEARCHER_API_POLL_INTERVAL));
        if (response.isCancel) {
          self.loadingList = true;
        } else {
          self.loadingList = false;
        }
        return loadCompetitorResearcherList(true);
      } else {
        updateList(response?.results);
        if (response.isCancel) {
          self.loadingList = true;
        } else {
          self.loadingList = false;
        }
      }
      return response;
    } catch (e) {
      self.loadingList = false;
      return Promise.reject(e);
    }
  });

  const loadCRHistoryList = flow(function* (noLoading?: boolean) {
    if (!noLoading) {
      self.loadingList = true;
    }
    const payload = {
      ...self.historyParams,
      detail_mode: Number(self.isGridView),
    };
    try {
      const response = yield COMPETITOR_RESEARCHER_API.getCompetitorResearchlist(payload);
      self.totalHistoryCount = response?.count;
      updateHistoryList(response?.results);
      const metricsStatus = response?.results?.filter(item => !['FAILURE', 'SUCCESS', null].includes(item?.metrics?.processingStatus));
      if (metricsStatus?.length && self.listApiRepolling) {
        yield new Promise(r => setTimeout(r, COMPETITOR_RESEARCHER_API_POLL_INTERVAL));
        if (response.isCancel) {
          self.loadingList = true;
        } else {
          self.loadingList = false;
        }
        return loadCRHistoryList(true);
      } else {
        updateHistoryList(response?.results);
        if (response.isCancel) {
          self.loadingList = true;
        } else {
          self.loadingList = false;
        }
      }
      return response?.results;
    } catch (e) {
      self.loadingList = false;
      return Promise.reject(e);
    }
  });

  // const postNewDomainAnalyzerPublic = flow(function* (url) {
  //   localStorage.setItem('showPostOnboarding', 'true');
  //   self.daPublicLoading = true;
  //   self.isError = false;
  //   try {
  //     const response = yield COMPETITOR_RESEARCHER_API.postDomainAnalyzer(url);
  //     if (response?.response?.status == 406) {
  //       self.isError = true;
  //       self.daPublicLoading = false;
  //       return;
  //     }
  //     if (response?.htmlLabels) {
  //       self.daPublic = response;
  //       return response;
  //     } else {
  //       self.isError = true;
  //     }
  //   } catch (e) {
  //     self.daPublicLoading = false;
  //     self.isError = true;
  //     return Promise.reject(e);
  //   } finally {
  //     self.daPublicLoading = false;
  //   }
  // });

  const createBulk = flow(function* (payload) {
    self.isBulkLoading = true;
    const params = {
      urls: payload?.urls,
      mode: payload?.mode,
      country_code: payload?.countryCode,
      is_saved: payload?.isSaved,
    };
    try {
      const response = yield COMPETITOR_RESEARCHER_API.createBulk(params);
      if (response) {
        if (payload?.isSaved) {
          loadCompetitorResearcherList();
        } else {
          loadCRHistoryList();
        }
      }
      return response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.isBulkLoading = false;
    }
  });
  const removeCompetitor = flow(function* (id) {
    try {
      yield COMPETITOR_RESEARCHER_API.deleteCompetitorResearcher(id);
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const saveCompetitor = flow(function* (id) {
    try {
      const response = yield COMPETITOR_RESEARCHER_API.saveProject(id);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const getKrtObjectsList = flow(function* (params) {
    try {
      const response = yield COMPETITOR_RESEARCHER_API.getKrtObjectsList(params);
      self.krtObjectsList = response?.results;
      return response?.results;
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const connectProjects = flow(function* (projectId, payload, hideSuccessMessage?: boolean) {
    try {
      const response = yield PROJECTS_API.connectProject(projectId, payload);
      if (!hideSuccessMessage) notification.success('', 'Project connected successfully.');
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    }
  });

  const updateList = data => {
    if (!data?.isCancel) {
      self.competitorResearcherListSaved = cast(data);
    }
  };

  const updateHistoryList = data => {
    if (!data?.isCancel) {
      self.competitorResearcherListHistory = cast(data);
    }
  };

  const setListApiCall = value => {
    self.listApiRepolling = value;
  };

  const setParams = (value, noApiCall?: boolean) => {
    if (value?.search !== undefined) {
      self.params.search = value?.search;
    }
    self.params.ordering = value?.ordering;

    if (value?.type) {
      self.params.type = value?.type;
    }
    if (value?.page) {
      self.params.page = value?.page;
    }
    if (value?.pageSize) {
      self.params.page_size = value?.pageSize;
    }
    if (value?.user_id) {
      self.params.user_id = value?.user_id == 'all' ? '' : value?.user_id;
    }
    if (!noApiCall) {
      loadCompetitorResearcherList();
    }
  };
  const setHistoryParams = (value, noApiCall?: boolean) => {
    if (value?.search !== undefined) {
      self.historyParams.search = value?.search;
    }
    self.historyParams.ordering = value?.ordering;

    if (value?.type) {
      self.historyParams.type = value?.type;
    }
    if (value?.page) {
      self.historyParams.page = value?.page;
    }
    if (value?.pageSize) {
      self.historyParams.page_size = value?.pageSize;
    }
    if (value?.user_id) {
      self.historyParams.user_id = value?.user_id == 'all' ? '' : value?.user_id;
    }
    if (!noApiCall) {
      loadCRHistoryList();
    }
  };
  const setCompetitorResearcherUrl = url => {
    self.competitorResearcherUrl = url;
  };
  const setCompetitorResearcherId = (id, publicHash='') => {
    if (self.competitorResearcherId !== id) {
      self.overview.resetAllFilters();
    }
    self.competitorResearcherId = id;
    localStorage.setItem('competitorResearcherId', id);
    localStorage.setItem('siteExplorerPublicHash', ['null', 'undefined', null, undefined].includes(publicHash) ? '' : publicHash);
  };
  const setIsGridView = val => {
    self.isGridView = val;
  };
  const setOpenSiteExplorerBulkModal = val => {
    self.openSiteExplorerBulkModal = val;
  };

  // for home v2
  const loadProjectsData = flow(function* (noLoading?: boolean) {
    if (!noLoading) {
      self.loadingListV2 = true;
    }
    const payload = {
      ...self.customerProjectsParams,
      saved: 1,
      detail_mode: Number(self.isGridView),
    };
    try {
      if (getTokenFromCookies()) {
        const response = yield PROJECTS_API.getProjectsData(payload);
        self.customerProjectsDataTotalCount = response?.count;
        updateCustomerProjectData(response?.results);
        if (response.isCancel) {
          self.loadingListV2 = true;
        } else {
          self.loadingListV2 = false;
        }

        if (response?.results && self.isRepollProjects) {
          const projectExist = response?.results?.filter(p => p.inProgress.length > 0);
          if (projectExist.length > 0) {
            yield new Promise(r => setTimeout(r, 10000));
            return loadProjectsData(true);
          }
        }
      }
    } catch (error) {
      self.loadingListV2 = false;
      isNetworkError(error, 'Failed to get projects', false);
    }
  });

  const setProjectRepoll = (value: boolean) => {
    self.isRepollProjects = value;
  };

  const updateCustomerProjectData = data => {
    if (!data?.isCancel) {
      self.customerProjectsData = cast(data);
    }
  };

  const setCustomerProjectsParams = (value, noApiCall?: boolean) => {
    if (value?.search !== undefined) {
      self.customerProjectsParams.search = value?.search;
    }
    self.customerProjectsParams.ordering = value?.ordering;

    if (value?.type) {
      self.customerProjectsParams.type = value?.type;
    }
    if (value?.page) {
      self.customerProjectsParams.page = value?.page;
    }
    if (value?.pageSize) {
      self.customerProjectsParams.page_size = value?.pageSize;
    }
    if (!noApiCall) {
      loadProjectsData();
    }
  };

  const refreshProjectList = () => {
    self.customerProjectsParams = {...self.customerProjectsParams, page: 1, search: '', ordering: '', type: ''};
    loadProjectsData();
  };

  const deleteProject = flow(function* (id, isLastRecord?: boolean) {
    if (isLastRecord) {
      setCustomerProjectsParams({
        ...self.customerProjectsParams,
        page: self.customerProjectsParams.page !== 1 ? self.customerProjectsParams.page - 1 : self.customerProjectsParams.page,
      }, true);
    }
    try {
      const response = yield PROJECTS_API.deleteProject(id);
      if (!response?.isCancel) {
        loadProjectsData();
      }
    } catch (e) {
      return Promise.reject(e);
    }
  });

  const getFacebookAdsData = flow(function* () {
    self.loadingFacebookAds = true;
    try {
      const publicHash = getSingleUrlParam('public_hash');
      const params = {};
      if (publicHash) {
        params['hash'] = publicHash;
      }
      const response = yield COMPETITOR_RESEARCHER_API.getFacebooksAdsData(self.getCompetitorResearcherId, params);
      if (response.isCancel) return;
      self.facebookAds = response;
    } catch (e) {
      self.facebookAds = cast([]);
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      self.loadingFacebookAds = false;
    }
  });

  const getCompetitorResearchUsers = flow(function* () {
    self.loadingCompetitorResearchUsers = true;
    try {
      const response = yield COMPETITOR_RESEARCHER_API.getCompetitorResearchUsers();
      if (response.isCancel) return;
      self.competitorResearchUsers = response;
    } catch (e) {
      self.facebookAds = cast([]);
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    } finally {
      self.loadingCompetitorResearchUsers = false;
    }
  });

  const setShowCreatorEmail = value => {
    self.showCreatorEmail = value;
  };

  const setDomain = value => {
    self.domain = value;
  };

  const setIsValidUrl = value => {
    self.isValidUrl = value;
  };

  return {
    setParams,
    getCompetitorResearchUsers,
    setCompetitorResearcherId,
    setListApiCall,
    updateList,
    removeCompetitor,
    saveCompetitor,
    setIsGridView,
    postNewCompetitor,
    exportSingleNode,
    setUptimeNotification,
    setOpenSiteExplorerBulkModal,
    createBulk,
    loadCRHistoryList,
    setCompetitorResearcherUrl,
    setHistoryParams,
    // postNewDomainAnalyzerPublic,
    // postNewCompetitorPublic,
    loadCompetitorResearcherList,
    siteExplorerUptime,
    networkGraphReport,
    networkGraphReportUpdate,
    loadProjectsData,
    setProjectRepoll,
    setCustomerProjectsParams,
    refreshProjectList,
    setRefreshList,
    deleteProject,
    getKrtObjectsList,
    connectProjects,
    getFacebookAdsData,
    setShowCreatorEmail,
    setDomain,
    setIsValidUrl,
  };
});

export const initCompetitorResearcherV2Store = () => {
  return CompetitorResearcherV2Store.create({
    loadingList: false,
    listApiRepolling: false,
    openSiteExplorerBulkModal: false,
    uptimeNotificationShown: false,
    isExportLoading: false,
    isBulkLoading: false,
    isGridView: false,
    isRepollProjects: false,
    refreshList: false,
    overview: initOverviewStore(),
    params: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
      user_id: '',
    },
    historyParams: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
      user_id: '',
    },
    customerProjectsParams: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    loadingListV2: false,
    loadingFacebookAds: false,
    loadingCompetitorResearchUsers: false,
    matrixSiteDatas: null,
    showCreatorEmail: false,
    loadingNetworkGraphReport: false,
    domain: '',
    isValidUrl: false,
  });
};
