import {findKeywordRegex, targetPageRegex, urlDomainRegex, urlSubDomainRegex} from '@/constants/regex';
import moment from 'moment';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';
import {flow} from 'mobx-state-tree';

export function maxIndex<T>(arr: T[]): number | undefined {
  if (arr.length === 0) {
    return undefined;
  }
  let maxIdx = 0;
  for (let idx = 1; idx < arr.length; ++idx) {
    if (arr[idx] > arr[maxIdx]) {
      maxIdx = idx;
    }
  }
  return maxIdx;
}

export function stringToArray(stringdata: string, Regexp: any = null, content: string = '') {
  let data = stringdata;
  if (Regexp) {
    data = stringdata?.replace(Regexp, content);
  }
  let updateData = data?.split(findKeywordRegex);
  updateData = updateData?.filter(function(el) {
    return el != '';
  });
  updateData?.forEach((element, key) => {
    updateData[key] = element?.trim()?.toLowerCase();
  });
  return updateData?.filter(Boolean);
}

export function splitNewLineIntoArray(stringdata: string, Regexp: any = null) {
  const data = stringdata;
  let updateData = data?.split(Regexp);
  if (Regexp) {
    updateData = data.split(Regexp);
  }
  updateData = updateData?.filter(function(el) {
    return el != '';
  });
  updateData?.forEach((element, key) => {
    updateData[key] = element?.trim()?.toLowerCase();
  });

  return updateData?.filter(Boolean);
}

export function getUniqueData(objectData, checkKey) {
  return objectData?.filter((data, index) => {
    return index === objectData?.findIndex(obj => {
      return obj[checkKey] === data[checkKey];
    });
  });
}

export function formatDate(date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export const getPrecedingDate = (startDate : string, endDate : string)=>{
  const formatStartDate = moment(startDate).format('DD MMM YYYY');
  const formatEndDate = moment(endDate).format('DD MMM YYYY');
  const diff = moment(formatEndDate).diff(moment(formatStartDate), 'days') + 1;
  const previousStartDate = moment(formatStartDate).subtract(diff, 'days');
  const previousEndDate = moment(formatStartDate).subtract(1, 'days');
  return {previousStartDate: formatDate(previousStartDate), previousEndDate: formatDate(previousEndDate)};
};

export const findNestedObj = (entireObj, keyToFind, valToFind) => {
  let foundObj;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export const checkMode = domainName => {
  let selectedMode = '';
  const value = domainName?.trim();
  const checkDomain = value?.match(urlDomainRegex);
  const checkSubDomain = value?.match(urlSubDomainRegex);
  const checkTargetPage = value?.match(targetPageRegex);
  if (checkDomain) {
    selectedMode = 'domain';
  } else if (checkSubDomain) {
    selectedMode = 'subdomain';
  } else if (checkTargetPage) {
    selectedMode = 'target_page';
  } else {
    selectedMode = 'domain';
  }
  return selectedMode;
};

export const getSortOrder = (key, order) => {
  if ([`${key}`, `-${key}`].includes(order) && order.includes('-')) {
    return {
      value: ``,
      tooltip: `Click to cancel sorting`,
    };
  } else if ([`${key}`, `-${key}`].includes(order) && !order.includes('-')) {
    return {
      value: `-${key}`,
      tooltip: `Click to sort descending`,
    };
  } else if (![`${key}`, `-${key}`].includes(order)) {
    return {
      value: `${key?.replace('-', '')}`,
      tooltip: `Click to sort ascending`,
    };
  }
};

export const captureSection = async (node, y, height) => {
  const canvas = await html2canvas(node, {
    useCORS: true,
    height,
    y,
    windowWidth: node.scrollWidth,
    windowHeight: height,
  });
  return canvas.toDataURL();
};
export const exportSingleNodeMultiplePages = flow(function* (node, exportName) {
  const {scrollWidth, scrollHeight} = node;
  const maxCanvasHeight = 8000;
  const pdf = new Jspdf({
    orientation: 'p',
    unit: 'pt',
    format: [scrollWidth, Math.min(maxCanvasHeight, scrollHeight)],
  });

  for (let y = 0; y < scrollHeight; y += maxCanvasHeight) {
    const height = Math.min(maxCanvasHeight, scrollHeight - y);
    const imageData = yield captureSection(node, y, height);
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgProps= pdf.getImageProperties(imageData);
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    pdf.addImage(imageData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST', 0);
    if (y + height < scrollHeight) {
      pdf.addPage();
    }
  }
  pdf.save(exportName || `document.pdf`);
});

export const convertArrayObjectKeysToSnakeCase = data => {
  if (!Array.isArray(data)) {
    return data;
  }
  return data.map(obj => {
    const newObj = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const snakeCaseKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        newObj[snakeCaseKey] = obj[key];
      }
    }
    return newObj;
  });
};

