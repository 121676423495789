import {toJS} from 'mobx';
import {types, Instance, flow, cast} from 'mobx-state-tree';
import {planApi, profileApi} from '@/api/account';
import moment from 'moment';
import {notification} from '@/utils/notifications';
import {notification as notify} from '@/utils/notification-v2';
import {getCurrentDate} from '@/utils/moment';
import {apiError} from '@/utils/api';
import {isNil} from 'lodash';
import {COMPETITOR_RESEARCHER_API} from '@/api/competitor-researcher-v2';
import {WhiteLabelOrderBuilderModel} from './whitelabelOrderBuilder';
import {getTokenFromCookies} from '@/api/common-utils';

export const CusPlan = types.model({
  name: types.maybeNull(types.string),
});

export const Subscription = types.model({
  id: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
  seats: types.maybeNull(types.number),
  paymentAmount: types.maybeNull(types.string),
  monthlyCost: types.maybeNull(types.string),
  paymentFrequency: types.maybeNull(types.string),
  blEnabled: types.maybeNull(types.boolean),
  caEnabled: types.maybeNull(types.boolean),
  cxEnabled: types.maybeNull(types.boolean),
  gscEnabled: types.maybeNull(types.boolean),
  keEnabled: types.maybeNull(types.boolean),
  nextPaymentAt: types.maybeNull(types.string),
  lastPaidAt: types.maybeNull(types.string),
  activatedAt: types.maybeNull(types.string),
  activeUntil: types.maybeNull(types.string),
  plan: types.maybeNull(CusPlan),
});

export type SubscriptionType = Instance<typeof Subscription>;

export const QuotaValues = types.model({
  total: types.maybeNull(types.number),
  consumed: types.maybeNull(types.number),
});

export const BudgetValues = types.model({
  total: types.maybeNull(types.number),
  consumed: types.maybeNull(types.number),
});

export const useDemoModel = types.model({
  total: types.maybeNull(types.boolean),
  consumed: types.maybeNull(types.number),
});

export const usMaxCompetitorsPerProjectModel = types.model({
  total: types.maybeNull(types.number),
  consumed: types.maybeNull(types.number),
});

export const useMaxNumberOfProjects = types.model({
  total: types.maybeNull(types.number),
  consumed: types.maybeNull(types.number),
});


export const historicalLimitModel = types.model({
  total: types.maybeNull(types.string),
  consumed: types.maybeNull(types.number),
});


export const BlQuota = types.model({
  budget: types.maybeNull(BudgetValues),
  useDemo: types.maybeNull(useDemoModel),
  maxCompetitorsPerProject: types.maybeNull(usMaxCompetitorsPerProjectModel),
  maxNumberOfProjects: types.maybeNull(useMaxNumberOfProjects),
  allowedBacklinkResearches: types.maybeNull(QuotaValues),
});

export const CheckerPerDay = types.model({
  total: types.maybeNull(types.number),
  consumed: types.maybeNull(types.number),
});
export const BulkQuota = types.model({
  daCheckerMaxLookupsPerDay: types.maybeNull(CheckerPerDay),
});

export const CaQuota = types.model({
  allowedFocusTerms: types.maybeNull(QuotaValues),
  allowedPages: types.maybeNull(QuotaValues),
  allowedSiteAuditorPages: types.maybeNull(QuotaValues),
  allowedOttoV2Projects: types.maybeNull(QuotaValues),
  allowedOttoProjects: types.maybeNull(QuotaValues),
  allowedAiContentGeneration: types.maybeNull(QuotaValues),
  allowedAiPremiumContentGeneration: types.maybeNull(QuotaValues),
  allowedSiteAuditorProjects: types.maybeNull(QuotaValues),
  crawledPagesAllowedPerMonth: types.maybeNull(QuotaValues),
  allowedOnpageAudits: types.maybeNull(QuotaValues),
  purchasedPremiumAiQuota: types.maybeNull(QuotaValues),
  allowedOttoAiSuggestions: types.maybeNull(QuotaValues),
  allowedOttoCloudStacks: types.maybeNull(QuotaValues),
  allowedOttoHyperdrive: types.maybeNull(QuotaValues),
  hyperdriveCredits: types.maybeNull(QuotaValues),
  hyperdriveCreditsPurchased: types.maybeNull(QuotaValues),
  allowedOttoCloudStacksPurchased: types.maybeNull(QuotaValues),
});

export const GscQuota = types.model({
  allowedSiteKeywords: types.maybeNull(QuotaValues),
  allowedPages: types.maybeNull(QuotaValues),
  allowedSitesToProcess: types.maybeNull(QuotaValues),
  historicalLimit: types.maybeNull(historicalLimitModel),
  projectDeactivationsAllowedPerMonth: types.maybeNull(QuotaValues),
  allowedActiveProjects: types.maybeNull(QuotaValues),
});

export const KeQuota = types.model({
  maxKeywordLookups: types.maybeNull(QuotaValues),
  maxKeywordLookupsPerWeek: types.maybeNull(QuotaValues),
  allowedCompetitorResearches: types.maybeNull(QuotaValues),
  allowedCompetitorResearchProjects: types.maybeNull(QuotaValues),
  allowedLocalSerpsHeatmapSearches: types.maybeNull(QuotaValues),
  allowedTrackedKeywords: types.maybeNull(QuotaValues),
  allowedTrackedProjects: types.maybeNull(QuotaValues),
  allowedContentPlans: types.maybeNull(QuotaValues),
  allowedBulkAnalysisUrls: types.maybeNull(QuotaValues),
  allowedOutreachCampaigns: types.maybeNull(QuotaValues),
  allowedOutreachSentEmails: types.maybeNull(QuotaValues),
  allowedOutreachConnectedEmails: types.maybeNull(QuotaValues),
});
export const QpQuota = types.model({
  allowedHaroReplies: types.maybeNull(QuotaValues),
});

export const Addons = types.model({
  id: types.maybeNull(types.number),
  isActive: types.maybeNull(types.boolean),
  nextPaymentAt: types.maybeNull(types.string),
  paymentAmount: types.maybeNull(types.number),
  paymentFrequency: types.maybeNull(types.string),
  planId: types.maybeNull(types.number),
  planName: types.maybeNull(types.string),
});

export const QuotaInfo = types.model({
  bl: types.maybeNull(BlQuota),
  bulk: types.maybeNull(BulkQuota),
  ca: types.maybeNull(CaQuota),
  gsc: types.maybeNull(GscQuota),
  ke: types.maybeNull(KeQuota),
  qp: types.maybeNull(QpQuota),
  seats: types.maybeNull(types.number),
});

export const vendastaClientDetailsModel = types.model({
  isVendastaClient: types.maybeNull(types.boolean),
  dataUrl: types.maybeNull(types.string),
  dataAccountId: types.maybeNull(types.string),
  dataAppId: types.maybeNull(types.string),
});
export const teamModel = types.model({
  id: types.maybeNull(types.number),
  email: types.maybeNull(types.string),
});

export const PostOnboardingModel = types.model({
  keywords: types.maybeNull(types.array(types.string)),
  competitors: types.maybeNull(types.array(types.string)),
});

export type PostOnboardingType = Instance<typeof PostOnboardingModel>;

export const PostOnboardingSubmitModel = types.model({
  id: types.maybeNull(types.number),
  domainUrl: types.maybeNull(types.string),
  isCurrent: types.maybeNull(types.boolean),
  assignedUsers: types.maybeNull(types.array(types.string)),
  competitors: types.maybeNull(types.array(types.string)),
  keywords: types.maybeNull(types.array(types.string)),
});

export const Address = types.model({
  city: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  defaultLocation: types.maybeNull(types.string),
  countryName: types.maybeNull(types.string),
  latitude: types.maybeNull(types.string),
  longitude: types.maybeNull(types.string),
  locationId: types.maybeNull(types.number),
});

export const TopupPrices = types.model({
  topupHyperdriveCreditCost: types.maybeNull(types.union(types.number, types.string)),
  topupOttoAiQuotaCost: types.maybeNull(types.union(types.number, types.string)),
  topupOttoPageCost: types.maybeNull(types.union(types.number, types.string)),
  topupOttoProjectCost: types.maybeNull(types.union(types.number, types.string)),
});

export const Profile = types.model({
  paymentFailed: types.maybeNull(types.boolean),
  isVendastaClient: types.maybeNull(types.boolean),
  isWhitelabeled: types.maybeNull(types.boolean),
  logo: types.maybeNull(types.string),
  favicon: types.maybeNull(types.string),
  vendastaClientDetails: types.maybeNull(vendastaClientDetailsModel),
  cardLast4: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  customerId: types.maybeNull(types.number),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  emailAddress: types.maybeNull(types.string),
  occupiedSeats: types.maybeNull(types.number),
  seats: types.maybeNull(types.number),
  roles: types.maybeNull(types.array(types.string)),
  phoneNumber: types.maybeNull(types.string),
  trialExpiresAt: types.maybeNull(types.string),
  nextPaymentAt: types.maybeNull(types.string),
  industry: types.maybeNull(types.string),
  isSubscriber: types.maybeNull(types.boolean),
  searchatlasApiKey: types.maybeNull(types.string),
  isTestAccount: types.maybeNull(types.boolean),
  isAutoTopupEnabled: types.maybeNull(types.boolean),
  useTestStripeKey: types.maybeNull(types.boolean),
  plan: types.maybeNull(types.number),
  planName: types.maybeNull(types.string),
  subscription: types.maybeNull(Subscription),
  team: types.maybeNull(types.array(teamModel)),
  contactName: types.maybeNull(types.string),
  hasCompletedRegistration: types.maybeNull(types.boolean),
  isVerified: types.maybeNull(types.boolean),
  quotaUtilization: types.maybeNull(QuotaInfo),
  addons: types.maybeNull((types.array(Addons))),
  creditsforBuying: types.maybeNull(types.number),
  firebaseKey: types.maybeNull(types.string),
  projectCount: types.maybeNull(types.number),
  showConfettiAnimation: types.boolean,
  productOverview: types.boolean,
  gscProductOverview: types.boolean,
  topUpAiQuotaLoading: types.boolean,
  name: types.maybeNull(types.string),
  hasOnboardingCompleted: types.maybeNull(types.boolean),
  registrationSource: types.maybeNull(types.string),
  postOnboarding: types.maybeNull(PostOnboardingModel),
  postOnboardingLoading: types.boolean,
  updateCompetitorLoading: types.boolean,
  postOnboardingSubmitData: types.maybeNull(PostOnboardingSubmitModel),
  showPostOnboardingModal: types.boolean,
  showCreateProjectBanner: types.boolean,
  serviceTier: types.maybeNull(types.string),
  shouldDisableLivechat: types.maybeNull(types.boolean),
  currentCredits: types.maybeNull(types.number),
  loadHomePageLoading: types.maybeNull(types.boolean),
  getStatus: types.maybeNull(types.string),
  loadingStatus: types.maybeNull(types.boolean),
  isParentAccount: types.maybeNull(types.boolean),
  isShopifyCustomer: types.maybeNull(types.boolean),
  hasWhitelabelSettingsAccess: types.maybeNull(types.boolean),
  isDaFlowCompleted: types.maybeNull(types.boolean),
  shouldShowBillingPage: types.maybeNull(types.boolean),
  shouldShowPricingPage: types.maybeNull(types.boolean),
  shouldShowOrdersPage: types.maybeNull(types.boolean),
  shouldChildrenReceiveEmails: types.maybeNull(types.boolean),
  priceStarterPlan: types.maybeNull(types.number),
  priceGrowthPlan: types.maybeNull(types.number),
  priceProPlan: types.maybeNull(types.number),
  signInLogo: types.maybeNull(types.string),
  signUpLogo: types.maybeNull(types.string),
  companyName: types.maybeNull(types.string),
  apiRecallCount: types.maybeNull(types.number),
  whitelabelDomain: types.maybeNull(types.string),
  canAccessDiagonalGrowth: types.maybeNull(types.boolean),
  canUseFreeTrial: types.maybeNull(types.boolean),
  referralLink: types.maybeNull(types.string),
  totalReferrals: types.maybeNull(types.number),
  referralStatsLink: types.maybeNull(types.string),
  address: types.maybeNull(Address),
  postOnboardingDataLoading: types.boolean,
  topupPrices: types.maybeNull(TopupPrices),
}).views(self => ({
  get fullName() {
    return (self.firstName && self.lastName) ? `${self.firstName} ${self.lastName}` : '';
  },
  get isWhitelabel() {
    return self.isWhitelabeled;
  },
  get getVendastaClientDetails() {
    return toJS(self.vendastaClientDetails);
  },
  get getRoles() {
    return toJS(self.roles);
  },
  get getMaxNumberOfSeats() {
    return self.seats;
  },
  get getMaxNumberOfOccupiedSeats() {
    return self.occupiedSeats;
  },
  get isLinkgraph() {
    return [439, 15774, 51914, 54712, 82276, 81962].includes(self.customerId);
  },
  get isLinkgraphSpecificIds() {
    return [439, 54712, 15774, 51914, 55443, 51750, 790].includes(self.customerId);
  },

  get isKRQuotaLimitReached() {
    const totalData = self.quotaUtilization?.ke?.maxKeywordLookups?.total;
    let consumedData = self.quotaUtilization?.ke?.maxKeywordLookups?.consumed;
    if ([null, undefined, 0].includes(totalData)) {
      return true;
    }
    if ([null, undefined].includes(consumedData)) {
      consumedData = 0;
    }
    return consumedData >= totalData;
  },
  get isFocusTermsQuotaLimitReached() {
    const totalData = self.quotaUtilization?.ca?.allowedFocusTerms?.total;
    let consumedData = self.quotaUtilization?.ca?.allowedFocusTerms?.consumed;
    if ([null, undefined, 0].includes(totalData)) {
      return true;
    }
    if ([null, undefined].includes(consumedData)) {
      consumedData = 0;
    }
    return consumedData >= totalData;
  },

  get focusTermsQuotaLimit() {
    let totalData = self.quotaUtilization?.ca?.allowedFocusTerms?.total;
    let consumedData = self.quotaUtilization?.ca?.allowedFocusTerms?.consumed;
    if ([null, undefined, 0].includes(totalData)) {
      totalData = 0;
    }
    if ([null, undefined].includes(consumedData)) {
      consumedData = 0;
    }
    return {totalData, consumedData};
  },

  get isAllowedPagesQuotaLimitReached() {
    const totalData = self.quotaUtilization?.ca?.allowedPages?.total;
    let consumedData = self.quotaUtilization?.ca?.allowedPages?.consumed;
    if ([null, undefined, 0].includes(totalData)) {
      return true;
    }
    if ([null, undefined].includes(consumedData)) {
      consumedData = 0;
    }
    return consumedData >= totalData;
  },

  get isAllowedAiContentGenerationQuotaLimitReached() {
    const totalData = self.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
    let consumedData = self.quotaUtilization?.ca?.allowedAiContentGeneration?.consumed;
    if ([null, undefined, 0].includes(totalData)) {
      return true;
    }
    if ([null, undefined].includes(consumedData)) {
      consumedData = 0;
    }
    return consumedData >= totalData;
  },

  get isAllowedSiteAuditorPagesQuotaLimitReached() {
    const totalData = self.quotaUtilization?.ca?.allowedSiteAuditorPages?.total;
    let consumedData = self.quotaUtilization?.ca?.allowedSiteAuditorPages?.consumed;
    if ([null, undefined, 0].includes(totalData)) {
      return true;
    }
    if ([null, undefined].includes(consumedData)) {
      consumedData = 0;
    }
    return consumedData >= totalData;
  },

  get GSCAllowActiveProjectsTotal() {
    return self.quotaUtilization?.gsc?.allowedActiveProjects?.total;
  },

  get GSCAllowActiveProjectsConsumed() {
    return self.quotaUtilization?.gsc?.allowedActiveProjects?.consumed;
  },

  get GSCAllowPagesTotal() {
    return self.quotaUtilization?.gsc?.allowedPages?.total;
  },

  get GSCAllowPagesConsumed() {
    return self.quotaUtilization?.gsc?.allowedPages?.total;
  },

  get GSCAllowSiteKeywordsTotal() {
    return self.quotaUtilization?.gsc?.allowedSiteKeywords?.total;
  },

  get GSCAllowSiteKeywordsConsumed() {
    return self.quotaUtilization?.gsc?.allowedSiteKeywords?.consumed;
  },

  get maxProjectsPerQuota() {
    return self.quotaUtilization?.bl?.maxNumberOfProjects?.total;
  },

  get consumedProjects() {
    return self.quotaUtilization?.bl?.maxNumberOfProjects?.consumed;
  },

  get getMaxCompetitorsPerProject() {
    return self.quotaUtilization?.bl?.maxCompetitorsPerProject?.total;
  },

  get getConsumedCompetitorsPerProject() {
    return self.quotaUtilization?.bl?.maxCompetitorsPerProject?.consumed;
  },

  get getQuotaUtilization() {
    return toJS(self.quotaUtilization);
  },

})).actions(self => {
  const setFieldValue = (attr: string, value: string) => {
    self[attr] = value;
  };

  const setLogoInStore = value => {
    self.logo = value;
  };

  const setSignInLogoInStore = value => {
    self.signInLogo = value;
  };

  const setSignUpLogoInStore = value => {
    self.signUpLogo = value;
  };

  const setTopupPrices = value => {
    self.topupPrices = value;
  };

  const togglePlan = (checked: boolean) => {
    if (checked) {
      self.plan = null;
      self.isSubscriber = false;
    } else {
      self.plan = 1;
      self.isSubscriber = true;
    }
  };

  const updatePlan = ( value: number) => {
    self.plan = value;
    self.isSubscriber = value != 0;
  };


  const setIsDaFlowCompleted = ( value: boolean) => {
    self.isDaFlowCompleted = value;
  };

  const updateEmail = ( value: string) => {
    self.emailAddress = value;
  };

  const updateName = ( value: string) => {
    self.name = value;
  };

  const setProductOverview = (value: boolean) => {
    self.productOverview = value;
  };

  const setGscProductOverview = (value: boolean) => {
    self.gscProductOverview = value;
  };

  // used for 429 status code to match consumed with total AI quota, to avoid calling /user/ endpoint
  const matchAiConsumedTotalQuota = () => {
    if (self.quotaUtilization?.ca?.allowedAiContentGeneration?.total) {
      const totalQuota = self.quotaUtilization?.ca?.allowedAiContentGeneration?.total;
      self.quotaUtilization.ca.allowedAiContentGeneration.consumed = totalQuota;
    }
  };
  const topUpAiQuota = flow(function* () {
    self.topUpAiQuotaLoading = true;
    try {
      const response = yield profileApi.topUpAiQuota();
      if (response.success) notify.success('Quota Successfully Topped Up', 'You now have 100 AI Quota points available');

      if (!response.success) notify.error('Quota Top Up failed', `${response.details}`);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.topUpAiQuotaLoading = false;
    }
  });

  const getPostOnboardingSuggestions = flow(function* (url: string, isOnboardingModal = true) {
    self.postOnboardingLoading = isOnboardingModal;
    try {
      const response = yield profileApi.getCompetitorKeywordSuggestions(url);
      self.showPostOnboardingModal=isOnboardingModal;
      if (response.isCancel) return;
      self.postOnboarding = response;
    } catch (e) {
      self.postOnboarding = cast({competitors: [], keywords: []});
      self.showPostOnboardingModal=isOnboardingModal;
      const errorMessage = apiError(e) as any;
      if (errorMessage?.url) {
        notify.error('Invalid URL', 'URL is not valid or is blocking our crawler');
      } else {
        notification.error('Failed to get keywords and competitors suggestions, Please add manually.');
      }
    } finally {
      self.postOnboardingLoading = false;
    }
  });

  const submitPostOnboardingData = flow(function* (domainUrl: string, keywords: string[], competitors: string[], countryCode: string, isOnBoarding?: boolean) {
    self.postOnboardingLoading = true;
    self.loadHomePageLoading = true;
    try {
      const response = yield profileApi.submitPostOnboardingData(domainUrl, keywords, competitors, countryCode);
      if (response.isCancel) return;
      if (isOnBoarding && response) {
        notify.success('Project successfully created', `11 main reports for ${domainUrl} will soon be ready.`, 'Go to Page', () => window.open(`/site-explorer/list`, '_blank'));
      }
      self.postOnboardingSubmitData = response;
      self.apiRecallCount = 0;
      // notify.success('Project successfully created', `11 main reports for ${domainUrl} will soon be ready.`);
      return response;
    } catch (e) {
      if (e?.response?.status === 520 && self.apiRecallCount < 4) {
        yield new Promise(r => setTimeout(r, 3000));
        self.apiRecallCount = self.apiRecallCount + 1;
        return submitPostOnboardingData(domainUrl, keywords, competitors, countryCode);
      } else if (e?.response?.status === 520 && self.apiRecallCount === 4) {
        notify.success('Project creation failed', 'Something went wrong, please try refresh the page and try again', 'Got it!');
      }
      return Promise.reject(e);
    } finally {
      self.postOnboardingLoading = false;
      self.loadHomePageLoading = false;
    }
  });

  const updateProjectCompetitor = flow(function* (projectId: Number, payload) {
    self.updateCompetitorLoading = true;
    try {
      const response = yield profileApi.updateProjectCompetitor(projectId, payload);
      return response;
    } catch (e) {
      notify.success('Competitors update failed', 'Something went wrong, please refresh the page and try again', 'Got it!');
      return Promise.reject(e);
    } finally {
      self.updateCompetitorLoading = false;
    }
  });
  const getStatusForHomePage = flow(function* (stopLoading?: boolean) {
    if (!stopLoading) {
      self.loadingStatus = true;
    }
    try {
      const response = yield profileApi.getStatusForHomePage(self.postOnboardingSubmitData?.id);
      if (response.isCancel) return;
      if (response.status == 'in_progress') {
        yield new Promise(r => setTimeout(r, 10000));
        return getStatusForHomePage();
      }
      self.loadingStatus = false;
      if (response?.status == 'completed') {
        self.getStatus = response.status;
        notify.success('Project created successfully', 'Please wait until the reports are generated.', 'Got it!');
      }
      if (response?.status == 'failed') {
        yield COMPETITOR_RESEARCHER_API.getCompetitorResearchlist({page: 1, page_size: 10, ordering: ''});
        self.getStatus = response.status;
        notify.success('Field', `${response?.status}`);
      }
    } catch (error) {
      self.loadingStatus = false;
      return Promise.reject(error);
    }
  });

  const postOnboardingData = flow(function* (data) {
    self.postOnboardingDataLoading = true;
    try {
      yield profileApi.postOnboardingData(self.customerId, data);
    } catch (error) {
      const errorMessage = apiError(error) as any;
      notification.error(errorMessage);
    } finally {
      self.postOnboardingDataLoading= false;
    }
  });

  const setLoadStatusLoading = value=>{
    self.loadingStatus = value;
  };
  const setLoadHomePage = value => {
    self.loadHomePageLoading = value;
  };
  const setShowPostOnboardingModal = (value: boolean) => self.showPostOnboardingModal = value;
  const setShowCreateProjectBanner = (value: boolean) => self.showCreateProjectBanner = value;
  const setCreditsforBuying= (value: number) => {
    self.creditsforBuying = value;
  };

  return {
    setCreditsforBuying,
    updateEmail,
    updateName,
    setLogoInStore,
    setLoadStatusLoading,
    setFieldValue,
    togglePlan,
    setLoadHomePage,
    updatePlan,
    setProductOverview,
    setGscProductOverview,
    matchAiConsumedTotalQuota,
    getStatusForHomePage,
    topUpAiQuota,
    getPostOnboardingSuggestions,
    submitPostOnboardingData,
    updateProjectCompetitor,
    setShowPostOnboardingModal,
    setShowCreateProjectBanner,
    setIsDaFlowCompleted,
    setSignInLogoInStore,
    setSignUpLogoInStore,
    postOnboardingData,
    setTopupPrices,
  };
});

export type ProfileType = Instance<typeof Profile>;


const stepTwoData = types.model({
  companyName: types.maybeNull(types.string),
  contactName: types.maybeNull(types.string),
  websiteUrl: types.maybeNull(types.string),
  companySize: types.maybeNull(types.string),
  role: types.maybeNull(types.string),
});
const stepThreeData = types.model({
  marketingBudget: types.maybeNull(types.string),
  goal: types.maybeNull(types.array(types.string)),

});
const customerData = types.model({
  id: types.maybeNull(types.number),
  emailAddress: types.maybeNull(types.string),
  plan: types.maybeNull(types.string),
  isPlanActive: types.maybeNull(types.boolean),
  created: types.maybeNull(types.string),
  serviceTier: types.maybeNull(types.string),
  whitelabelDomain: types.maybeNull(types.string),
  category: types.maybeNull(types.string),
});

const DataObjectModel = types.model({
  id: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  wysiwygContent: types.maybeNull(types.string),
  ctaLink: types.maybeNull(types.string),
  ctaLabel: types.maybeNull(types.string),
  bannerImage: types.maybeNull(types.string),
  releaseDate: types.maybeNull(types.string),
});

const ResultObjectModel = types.model({
  data: types.maybeNull(types.array(DataObjectModel)),
  mostRecentlyUpdatedAt: types.maybeNull(types.string),
});

const NotificationDataModel = types.model({
  results: types.maybeNull(ResultObjectModel),
  next: types.maybeNull(types.string),
});

const CustomerPlanOptionsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
});

export const Customer = types.model({
  profile: types.maybeNull(Profile),
  loading: types.optional(types.boolean, false),
  showPopover: types.optional(types.boolean, true),
  showAuthModal: types.optional(types.boolean, false),
  showModalWithLogin: types.optional(types.boolean, false),
  modalCustomRoute: types.maybeNull(types.string),
  isFreeTrail: types.maybeNull(types.boolean),
  trailPageData: types.maybeNull(types.number),
  stepTwoData: types.maybeNull(stepTwoData),
  stepThreeData: types.maybeNull(stepThreeData),
  phoneCode: types.maybeNull(types.string),
  serverErrors: types.array(types.string),
  alreadyCreated: types.maybeNull(types.string),
  topUpAmountInStore: types.maybeNull(types.union(types.string, types.number)),
  promoCode: types.maybeNull(types.boolean),
  promoCodeValue: types.maybeNull(types.string),
  inValidStatusPromoCode: types.maybeNull(types.boolean),
  validatingPromoCode: types.maybeNull(types.boolean),
  discount: types.maybeNull(types.number),
  trailLoading: types.maybeNull(types.boolean),
  useDiscountPrice: types.maybeNull(types.boolean),
  discountPrice: types.maybeNull(types.number),
  showPaymentModal: types.maybeNull(types.boolean),
  shouldDisableLivechat: types.maybeNull(types.boolean),
  newUserLoading: types.maybeNull(types.boolean),
  customerSwitcherLoading: types.maybeNull(types.boolean),
  customerSwitcherJwtLoading: types.maybeNull(types.boolean),
  customerData: types.maybeNull(types.array(customerData)),
  isInitialExtendedDetailsFetched: types.boolean,
  customerPlanOptions: types.maybeNull(types.array(CustomerPlanOptionsModel)),
  showTopUpConfirmationModal: types.boolean,
  whiteLabelOrderBuilder: types.maybeNull(WhiteLabelOrderBuilderModel),
  notificationsData: types.maybeNull(NotificationDataModel),
  notificationsLoading: types.optional(types.boolean, false),
  notificationsNextPage: types.optional(types.number, 1),
}).views(self => ({
  get getShowPopover() {
    return self.showPopover;
  },
  get getAlreadyCreated() {
    return self.alreadyCreated;
  },
  get getServerErrors() {
    return self.serverErrors.filter(x => typeof(x) === 'string');
  },
  get isLoading() {
    return self.trailLoading;
  },
  get getPromoCode() {
    return self.promoCode;
  },
  get getInValidStatusPromoCode() {
    return self.inValidStatusPromoCode;
  },
  get getDiscountPrice() {
    return self.discountPrice;
  },
  get getDiscount() {
    return self.discount;
  },

  get remainingExpiryDays() {
    const now = moment();
    const expire = moment(self.profile.trialExpiresAt);
    return expire.diff(now, 'days');
  },

  get customerNotifications() {
    return self.notificationsData;
  },

  get remainingDaysInNextPayment() {
    const now = moment();
    const expire = moment(self.profile.nextPaymentAt);
    return expire.diff(now, 'days');
  },

  get isFreeTrialExpire() {
    if (!self.profile.trialExpiresAt || self.profile.isSubscriber) {
      return false;
    }
    const now = moment();
    const expire = moment(self.profile.trialExpiresAt);
    return moment(now).isAfter(expire);
  },

  get getTotalPremiumAI() {
    const ca = self.profile.quotaUtilization.ca;
    // below lines is important for premium ai if change this please also modify isAllowedAiPremiumContentGenerationQuotaLimitReached
    return ca?.allowedAiPremiumContentGeneration?.total + ca?.purchasedPremiumAiQuota?.total;
  },

  get isAllowedAiPremiumContentGenerationQuotaLimitReached() {
    const ca = self.profile.quotaUtilization.ca;
    // below lines is important for premium ai if change this please also modify getTotalPremiumAI
    const totalData = ca?.allowedAiPremiumContentGeneration?.total + ca?.purchasedPremiumAiQuota?.total;
    let consumedData = ca?.allowedAiPremiumContentGeneration?.consumed;
    if ([null, undefined, 0].includes(totalData)) {
      return true;
    }
    if ([null, undefined].includes(consumedData)) {
      consumedData = 0;
    }
    return consumedData >= totalData;
  },

})).actions(self => {
  const setFromResponse = (profile: ProfileType) => {
    // self.profile.cardLast4=profile?.cardLast4;
    self.profile.id = profile?.id;
    self.profile.customerId = profile?.customerId;
    self.profile.firstName = profile?.firstName;
    self.profile.lastName = profile?.lastName;
    self.profile.emailAddress = profile?.emailAddress || ''; '';
    self.profile.phoneNumber = profile?.phoneNumber;
    self.profile.roles = profile?.roles;
    self.profile.trialExpiresAt = profile?.trialExpiresAt;
    self.profile.industry = profile?.industry;
    self.profile.isSubscriber = profile?.isSubscriber;
    self.profile.isTestAccount = profile?.isTestAccount;
    self.profile.isParentAccount = profile?.isParentAccount;
    self.profile.companyName = profile?.companyName;
    // isNil check will be removed after this key is added to /user/ endpoint
    // we use it now to prevent setting this key in mobx as null, while waiting for BE
    if (!isNil(profile?.isVendastaClient)) {
      self.profile.isVendastaClient = profile?.isVendastaClient;
    }
    self.profile.useTestStripeKey = profile?.useTestStripeKey;
    self.profile.plan = profile?.plan;
    self.profile.subscription = profile?.subscription;
    self.profile.contactName = profile?.firstName ? `${profile?.firstName} ${profile?.lastName}` : '';
    self.profile.isVerified = profile?.isVerified;
    self.profile.hasCompletedRegistration = profile?.hasCompletedRegistration;
    self.profile.firebaseKey = profile?.firebaseKey;
    self.profile.projectCount = profile?.projectCount;
    self.profile.customerId = profile?.customerId;
    self.profile.shouldShowBillingPage = profile?.shouldShowBillingPage;
    self.profile.shouldShowPricingPage = profile?.shouldShowPricingPage;
    self.profile.shouldShowOrdersPage = profile?.shouldShowOrdersPage;
    self.profile.shouldChildrenReceiveEmails = profile?.shouldChildrenReceiveEmails;
    self.profile.priceStarterPlan = profile?.priceStarterPlan;
    self.profile.priceGrowthPlan = profile?.priceGrowthPlan;
    self.profile.priceProPlan = profile?.priceProPlan;
    self.profile.isWhitelabeled = profile?.isWhitelabel;
    self.profile.shouldDisableLivechat = profile?.shouldDisableLivechat;
    self.profile.isParentAccount = profile?.isParentAccount;
    self.profile.address = profile?.address;
    if (profile?.logo) {
      self.profile.logo = profile?.logo;
    }
    if (profile?.hasCompletedRegistration) {
      localStorage.setItem('registerDate', Date.now().toString());
    }
  };

  const setTrialPagePlan = plan => {
    // if (plan == 10) {
    //   localStorage.setItem('promotionCode', `Starter50`);
    // } else if (plan == 1) {
    //   localStorage.setItem('promotionCode', `Growth50`);
    // }
    self.trailPageData = plan;
  };

  const setPhoneCode = phoneCode => {
    self.phoneCode = phoneCode;
  };
  const setStepThreeData = data => {
    self.stepThreeData=data;
  };

  const setStepTwoData = data => {
    self.stepTwoData = data;
  };

  const loadProfile = flow(function* (bulkDaChecker=false) {
    self.loading = true;
    try {
      if (getTokenFromCookies()) {
        const data = yield profileApi.getProfile();
        yield getCustomerQuota(data?.customerId);
        if (data?.emailAddress) {
          self.isInitialExtendedDetailsFetched = true;
          setFromResponse(data);
        }
        if (data && bulkDaChecker) {
          notify.success('Success', 'Data Generated Successfully!');
        }
        return data;
      }
    } catch (error) {
      notification.info('Failed to get updated profile data');
    } finally {
      self.loading = false;
    }
  });

  const loadCustomerNotifications = flow(function* () {
    self.notificationsLoading = true;
    try {
      if (getTokenFromCookies()) {
        const params = {
          page_size: 20,
          page: self.notificationsNextPage,
        };
        const data = yield profileApi.getNotifications(params);
        const previousNotifications = [...toJS(self.notificationsData.results.data)];
        self.notificationsData = cast({
          ...data,
          results: {
            ...data.results,
            data: [...previousNotifications, ...data.results.data],
          },
        });
        self.notificationsNextPage += 1;
      }
    } catch (error) {
      notification.info('Failed to load notifications.');
    } finally {
      self.notificationsLoading = false;
    }
  });

  type UpdateProfile = {
    phoneNumber: string;
    contactName: string;
    industry: string;
    logo?: any;
    selectedSignInImage?: any;
    selectedSignUpImage?: any;
    selectedFaviconImage?: any;
    starterPlanPrice?: number | string;
    growthPlanPrice?: number | string;
    proPlanPrice?: number | string;
    showOrdersPage?: boolean;
    showBillingPage?: boolean;
    showPricingPage?: boolean;
    shouldSendEmailsToAgencyCustomers?: boolean;
    defaultLocation?: string;
  };

  const updateProfile = flow(function* ({
    phoneNumber,
    contactName,
    industry,
    logo,
    selectedSignInImage,
    selectedSignUpImage,
    selectedFaviconImage,
    starterPlanPrice,
    growthPlanPrice,
    proPlanPrice,
    showOrdersPage,
    showBillingPage,
    showPricingPage,
    shouldSendEmailsToAgencyCustomers,
    defaultLocation,
  }: UpdateProfile) {
    self.loading = true;
    let data = null;
    try {
      data = yield profileApi.updateProfile(
        phoneNumber,
        contactName,
        industry,
        logo,
        selectedSignInImage,
        selectedSignUpImage,
        selectedFaviconImage,
        starterPlanPrice ?? self.profile.priceStarterPlan,
        growthPlanPrice ?? self.profile.priceGrowthPlan,
        proPlanPrice ?? self.profile.priceProPlan,
        showOrdersPage ?? self.profile.shouldShowOrdersPage,
        showBillingPage ?? self.profile.shouldShowBillingPage,
        showPricingPage ?? self.profile.shouldShowPricingPage,
        shouldSendEmailsToAgencyCustomers,
        defaultLocation,
      );
      self.profile.logo = data?.data?.logo;
    } catch (error) {
      notification.info('Data not saved. Please try again.');
    } finally {
      self.loading = false;
    }
    return data;
  });

  const setHasRegistrationCompleted = () => {
    self.profile.hasCompletedRegistration = true;
    localStorage.setItem('registerDate', Date.now().toString());
  };

  const deactivateSubscription = flow(function* (userExperience, encounteredIssues, additionFeedback) {
    yield profileApi.deactivateSubscription(userExperience, encounteredIssues, additionFeedback);
  });

  const activateProfile = flow(function* (activationCode: string | null = null) {
    self.loading = true;
    let data = null;
    try {
      data = yield profileApi.activateProfile(activationCode);
    } catch (error) {
      notification.info('Profile not activated. Please try again.');
      return Promise.reject(error);
    } finally {
      self.loading = false;
    }
    return data;
  });

  const getCustomersData = flow(function* (loading) {
    if (loading) {
      self.customerSwitcherLoading = true;
    }
    try {
      const data = yield planApi.getCustomers();
      if (data.isCancel) return;
      self.customerData=data;
      self.customerSwitcherLoading = false;
      return data;
    } catch (error) {
      notification.info('Failed to get profile data. Please try again.');
      self.customerSwitcherLoading = false;
      return null;
    }
  });
  const getCustomersPlanOptions = flow(function* () {
    let data = null;
    try {
      data = yield planApi.getCustomersPlanOptions();
      if (data.isCancel) return;
      self.customerPlanOptions = data;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      self.customerSwitcherLoading = false;
    }
    return data;
  });

  const deleteCustomer = flow(function* (id) {
    let data = null;
    try {
      data = yield planApi.deleteCustomer(id);
      if (data.isCancel) return;
      // self.customerData=data;
    } catch (error) {
      notification.info('Profile not deleted. Please try again.');
      return Promise.reject(error);
    }
    return data;
  });

  const downgradeCustomer = flow(function* (id) {
    let data = null;
    try {
      data = yield planApi.downgradeCustomer(id);
      if (data.isCancel) return;
      // self.customerData=data;
    } catch (error) {
      notification.info('Profile not downgraded. Please try again.');
      return Promise.reject(error);
    }
    return data;
  });

  const getCustomerJwt = flow(function* (id:number) {
    self.customerSwitcherJwtLoading = true;
    let data = null;
    try {
      data = yield planApi.getCustomerJwt(id);
      if (data.isCancel) return;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      self.customerSwitcherJwtLoading = false;
    }
    return data;
  });

  const setShowConfettiAnimation = (show: boolean) => {
    self.profile.showConfettiAnimation = show;
  };

  const getCustomerQuota = flow(function* (customerId = '') {
    try {
      if (customerId || self.profile.customerId) {
        const response = yield profileApi.getCustomerQuota(customerId || self.profile.customerId);
        if (response?.isCancel) return;
        self.profile.paymentFailed=response?.paymentFailed;
        self.profile.cardLast4=response?.cardLast4;
        self.profile.addons = response.addons;
        self.profile.quotaUtilization = response.resourceQuotaUsage;
        self.profile.seats = response.seats;
        self.profile.occupiedSeats = response.occupiedSeats;
        self.profile.registrationSource=response.registrationSource;
        self.profile.searchatlasApiKey=response.searchatlasApiKey;
        self.profile.planName = response.planName;
        self.profile.isVendastaClient = response?.isVendastaClient;
        self.profile.isWhitelabeled = response?.isWhitelabel;
        self.profile.vendastaClientDetails = response.vendastaClientDetails;
        self.profile.team = response?.team;
        self.profile.serviceTier = response?.serviceTier;
        self.profile.shouldDisableLivechat = response?.shouldDisableLivechat;
        self.profile.isAutoTopupEnabled=response?.isAutoTopupEnabled,
        self.profile.currentCredits = response?.currentCredits;
        self.profile.logo = response?.logo;
        self.profile.favicon = response?.favicon;
        self.profile.isParentAccount = response?.isParentAccount;
        self.profile.isShopifyCustomer = response?.isShopifyCustomer;
        self.profile.whitelabelDomain = response?.whitelabelDomain;
        self.profile.canAccessDiagonalGrowth = response?.canAccessDiagonalGrowth;
        self.profile.canUseFreeTrial = response?.canUseFreeTrial;
        self.profile.referralLink = response?.referralLink;
        self.profile.totalReferrals = response?.totalReferrals;
        self.profile.referralStatsLink = response?.referralStatsLink;
        self.profile.address = response?.address;
        self.profile.hasWhitelabelSettingsAccess = response?.hasWhitelabelSettingsAccess;
        self.profile.nextPaymentAt = response?.nextPaymentAt;
        self.profile.hasOnboardingCompleted = response?.hasOnboardingCompleted;
      }
    } catch (err) {
      const errorMessage = apiError(err) as string;
      notification.error('Failed to fetch updated profile', errorMessage);
    }
  });

  const closePopover = () => {
    self.showPopover = false;
  };

  const setAuthModal = (value, isLogin, customRoute?: string, isFreeTrail?: boolean) => {
    self.showAuthModal = value;
    self.showModalWithLogin = isLogin;
    self.modalCustomRoute = customRoute;
    self.isFreeTrail = isFreeTrail;
  };

  const skipFreeTrailSubscribePlan = flow(function* (data: any, skipFreeTrail?:Boolean) {
    const dataObj = {
      charge_type: 'customer_plan',
      skip_free_trial: skipFreeTrail,
      // To charge 1$ on free plan for card validtion
      validate_card_by_charging: true,
      plan_id: self.trailPageData,
      payment_method: {
        source: 'stripe',
        phone_number: data.phone,
        zipcode: data.zipCode,
        // stripe_payment_method: stripeCpm.paymentMethod,
      },
    };
    try {
      const updatedPayload = dataObj;
      if (!self.inValidStatusPromoCode && self.discount) {
        updatedPayload['promo_code'] = self.promoCodeValue;
      }
      return yield planApi.subscribePlan(updatedPayload);
    } catch (e) {
      const {response} = e;
      if (response.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          self.serverErrors.push(data[key][0]);
        });
      }
      if (response.status == 500) {
        notify.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
    } finally {
      localStorage.setItem('showConsultationModal', 'true');
      localStorage.setItem('registrationDate', JSON.stringify(getCurrentDate()));
      stopLoading();
    }
  });


  const buyCredits = flow(function* (amount:number, data: any, cardNumElement, stripe, id?: number) {
    const dataObj = {
      amount: amount,
    };

    if (!id) {
      const stripeCpm = yield stripe.createPaymentMethod({
        type: 'card',
        card: cardNumElement,
        billing_details: {
          address: {
            line2: null,
            postal_code: data.zipCode,
          },
          email: data.emailAddress,
          // phone: data.phone,
          name: data?.name,
        },
      });
      if (stripeCpm.error) {
        const errorHeading = formatStripeCpmErrorTypeString(stripeCpm.error?.type);
        notify.error(errorHeading, stripeCpm?.error?.message);
        stopLoading();
        return stripeCpm;
      }
      dataObj['payment_method'] = {
        source: 'stripe',
        phone_number: data.phone,
        zipcode: data.zipCode,
        stripe_payment_method: stripeCpm.paymentMethod,
      };
    } else {
      dataObj['payment_method_id'] = id;
    }
    try {
      const updatedPayload = dataObj;
      const response= yield planApi.buyCredits(updatedPayload);
      self.topUpAmountInStore = response.invoice.amount;
      localStorage.setItem('topUpAmount', response.invoice.amount);
      if (response?.invoice?.id) {
        getCustomerQuota();
        setShowPaymentModal(false);
        notify.success('Credits are Successfully added', `You now have ${response?.newCredits} Credits`);
      }
    } catch (e) {
      const {response} = e;
      if (response.status == 400) {
        const {data} = response;
        self.serverErrors.length = 0;
        Object.keys(data).forEach(key => {
          self.serverErrors.push(data[key][0]);
        });
      }
      if (response.status == 500) {
        notify.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
    } finally {
      stopLoading();
    }
  });

  const freeTrailSubscribePlan = flow(function* (data: any, cardNumElement, stripe, isFreeTrail: Boolean, skipFreeTrail?:Boolean, id?: number) {
    const dataObj = {
      charge_type: 'customer_plan',
      start_free_trial: isFreeTrail,
      skip_free_trial: skipFreeTrail,
      // To charge 1$ on free plan for card validtion
      validate_card_by_charging: true,
      plan_id: self.trailPageData,

    };
    if (!id) {
      const stripeCpm = yield stripe.createPaymentMethod({
        type: 'card',
        card: cardNumElement,
        billing_details: {
          address: {
            line2: null,
            postal_code: data.zipCode,
          },
          email: data.emailAddress,
          phone: data.phone,
          name: data?.name,
        },
      });
      if (stripeCpm.error) {
        const errorHeading = formatStripeCpmErrorTypeString(stripeCpm.error?.type);
        notify.error(errorHeading, stripeCpm?.error?.message);
        stopLoading();
        return stripeCpm;
      }
      dataObj['payment_method'] = {
        source: 'stripe',
        phone_number: data.phone,
        zipcode: data.zipCode,
        stripe_payment_method: stripeCpm.paymentMethod,
      };
    } else {
      dataObj['payment_method_id'] = id;
    }

    if (data?.payAnnualValue) {
      dataObj['payment_frequency'] = 'annually';
    }
    try {
      const updatedPayload = dataObj;
      if (!self.inValidStatusPromoCode && self.discount) {
        updatedPayload['promo_code'] = self.promoCodeValue;
      }
      return yield planApi.subscribePlan(updatedPayload);
    } catch (e) {
      if (e?.response.status == 400) {
        // eslint-disable-next-line
        const {data} = e?.response;
        if (data) {
          if (data?.length === 1 && (data[0]?.includes('[') && data[0]?.includes(']'))) {
            const stringWithoutBrackets = data[0].replace(/\[|\]/g, '');
            const parts = stringWithoutBrackets.split(' ');
            const heading = parts[0].split('_').map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word).join(' ');
            let message='';
            parts.forEach((word, index) => {
              if (index !== 0) {
                message +=' '+word;
              }
            });
            notify.error(heading, message.trim());
            return;
          }
        }
        self.serverErrors.length = 0;
        const apiErrorResp = apiError(e);
        let nonFieldError= null;
        if (apiErrorResp['nonFieldErrors']) {
          nonFieldError = apiError(e)['nonFieldErrors'][0];
        }
        if (nonFieldError) {
          self.serverErrors.push((apiError(e)?.['nonFieldErrors'][0]?.split('–')[1]) as any);
        } else {
          Object.keys(data).forEach(key => {
            self.serverErrors.push(data[key][0]);
          });
        }
      }
      if (e?.response.status == 500) {
        notify.error('Payment Error', 'Unable to process payment. Please contact support. Thanks');
      }
    } finally {
      stopLoading();
    }
  });
  const onValidatePromoCode = promoCode => {
    self.promoCodeValue = promoCode;
    const data = {promo_code: promoCode};
    validatePromoCode(data);
  };
  const validatePromoCode = flow(function* (data: object) {
    self.inValidStatusPromoCode = false;
    self.validatingPromoCode = true;
    try {
      const response = yield planApi.validatePromoCode(data);
      if (response?.isCancel) return;
      if (response?.success) {
        self.promoCode = response.success;
        if (response?.shouldUseDiscountPrice) {
          self.useDiscountPrice = response?.shouldUseDiscountPrice;
          self.discountPrice = response?.discountPrice;
        } else {
          self.discount = response.discount;
        }
        self.inValidStatusPromoCode = false;
      } else {
        self.discount = 0;
        const errorMsg = response?.details?.replace('_', ' ');
        notify.warning(errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1).toLowerCase(), '');
        self.inValidStatusPromoCode = true;
      }
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.validatingPromoCode = false;
    }
  });

  const creatNewUser = flow(function* (data: any) {
    const payload = {
      contact_name: data.firstName + ' ' + data.lastName,
      phone_number: data.phone_number,
      customer_user_email: data.userEmail,
      password: data.userPassword,
      additional_team_members: data.additionalTeamMembers,
      plan: data.plan,
    };
    self.newUserLoading = true;
    try {
      yield planApi.creatNewUser(payload);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.newUserLoading = false;
    }
  });

  const createWhiteLabel = flow(function* (data: any) {
    try {
      const response = yield planApi.createWhiteLabel(data);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  });


  const startLoading = () => {
    self.trailLoading = true;
  };
  const stopLoading = () => {
    self.trailLoading = false;
  };
  const resetPromoCode = () => {
    self.promoCode = false;
    self.discount = 0;
    self.promoCodeValue = '';
    self.inValidStatusPromoCode = false;
  };
  const resetServerErrors = ()=> {
    self.serverErrors = cast([]);
    self.alreadyCreated = null;
  };

  const setShowPaymentModal = value => {
    self.showPaymentModal = value;
  };

  const setShowScaTopUpModal = (value: boolean) => self.showTopUpConfirmationModal = value;

  const formatStripeCpmErrorTypeString = stripeCpmErrorType => {
    return stripeCpmErrorType.split('_').map((word, index) => index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word).join(' ');
  };

  const getSsoToken = flow(function* (ssoToken: string | null = null) {
    self.loading = true;
    let data = null;
    try {
      data = yield profileApi.getSsoToken(ssoToken);
    } catch (error) {
      notification.info('Something went wrong. Please try again.');
      return Promise.reject(error);
    } finally {
      self.loading = false;
    }
    return data;
  });

  return {
    getCustomerJwt,
    getCustomersData,
    deleteCustomer,
    buyCredits,
    loadProfile,
    setShowPaymentModal,
    skipFreeTrailSubscribePlan,
    freeTrailSubscribePlan,
    resetServerErrors,
    onValidatePromoCode,
    validatePromoCode,
    resetPromoCode,
    setPhoneCode,
    stopLoading,
    startLoading,
    setAuthModal,
    setTrialPagePlan,
    updateProfile,
    activateProfile,
    deactivateSubscription,
    setHasRegistrationCompleted,
    setShowConfettiAnimation,
    getCustomerQuota,
    closePopover,
    setStepThreeData,
    setStepTwoData,
    creatNewUser,
    downgradeCustomer,
    getCustomersPlanOptions,
    setShowScaTopUpModal,
    getSsoToken,
    createWhiteLabel,
    loadCustomerNotifications,
  };
});

export function initCustomer() {
  return Customer.create({
    profile: Profile.create({
      emailAddress: '',
      creditsforBuying: 0,
      name: '',
      firstName: null,
      isVendastaClient: false,
      isWhitelabeled: true,
      logo: '',
      vendastaClientDetails: {
        dataUrl: '',
        dataAccountId: '',
        dataAppId: '',
      },
      isDaFlowCompleted: false,
      productOverview: true,
      gscProductOverview: false,
      topUpAiQuotaLoading: false,
      lastName: null,
      phoneNumber: '',
      industry: '',
      isSubscriber: false,
      searchatlasApiKey: '',
      registrationSource: 'domain_analyzer',
      isAutoTopupEnabled: false,
      isTestAccount: false,
      useTestStripeKey: false,
      plan: 0,
      subscription: {
        activatedAt: '',
        plan: {
          name: '',
        },
      },
      team: [],
      contactName: '',
      cardLast4: '',
      hasCompletedRegistration: false,
      quotaUtilization: {
        bl: null,
        ca: null,
        gsc: null,
        ke: null,
        seats: null,
      },
      showConfettiAnimation: false,
      postOnboarding: {
        competitors: null,
        keywords: null,
      },
      postOnboardingLoading: false,
      updateCompetitorLoading: false,
      postOnboardingSubmitData: null,
      showPostOnboardingModal: false,
      showCreateProjectBanner: true,
      loadHomePageLoading: false,
      getStatus: '',
      loadingStatus: false,
      postOnboardingDataLoading: false,
      hasOnboardingCompleted: null,
    }),

    trailLoading: false,
    phoneCode: '1',
    alreadyCreated: null,
    serverErrors: [],
    promoCode: false,
    promoCodeValue: '',
    inValidStatusPromoCode: false,
    validatingPromoCode: false,
    discount: 0,
    showPaymentModal: false,
    isInitialExtendedDetailsFetched: false,
    showTopUpConfirmationModal: false,
    whiteLabelOrderBuilder: WhiteLabelOrderBuilderModel.create({
      loadingStripeKeys: false,
      loadingCategories: false,
      loadingCategoriesList: false,
      loadingProductsList: false,
      loadingProducts: false,

    }),
    notificationsData: {
      results: {
        mostRecentlyUpdatedAt: null,
        data: [],
      },
    },
  });
}
