import {useState, useCallback, useEffect} from 'react';
import {Drawer, Input, Spin} from 'antd';
import {observer} from 'mobx-react-lite';
import {useStore} from '@/store/root-store';
import {useRouter} from 'next/router';
import {CloseOutlined, LoadingOutlined} from '@ant-design/icons';
import {useTranslation} from 'next-i18next';
import styles from '@/components/dashboard/gsc/styles.module.scss';
import {Button, Table} from '@/components/common-components/components/index';
import {Search} from 'react-feather';
import {toJS} from 'mobx';
// import {isEqual} from 'lodash';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import {GSC_PROPERTIES_PAGE_SIZE} from '@/constants';
import {ProgressBar} from '@/components/common-components/v2/progresBar';
import {notification} from '@/utils/notification-v2';
import LocationSelector from './LocationSelector';
import StatusSwitch from './StatusSwitch';
import classNames from 'classnames';
import stylesTable from './styles.module.scss';
import {getDomain} from '@/utils/url';


export const SitePropertiesDrawer = observer(() => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [initialPayload, setInitialPayload] = useState(null);
  // const [popoverVisible, setPopoverVisible] = useState(false);
  const {t} = useTranslation('common');
  const router = useRouter();
  const currentCustomerId = localStorage.getItem('currentCustomerId');

  const {
    countries: {getSpecificCountryByCode},
    navBar,
    home: {
      isChild,
    },
    gsc: {criteria},
    settings: {
      customer: {
        getCustomerQuota,
        // getShowPopover,
        // closePopover,
        profile,
      },
    },
    reportBuilder: {
      setManageDomainName,
      manageDomainName,
      selectedCountry,
      details: {loadReportBuilderGSCList},
    },
  } = useStore('');
  const [dataOptions, setDataOptions] = useState([]);
  const [searchSite, setSearchSite] = useState('');
  const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />;
  const paramsCheck = !location.pathname.includes('report-builder') && !location.pathname.includes('otto-page');

  const {drawer} = router.query;
  useEffect(() => {
    async function load() {
      navBar.setIsActivatePropertiesDrawerOpen();
      await navBar.loadSearchPerformanceStatus();
      notification.destroy();
      notification.success('Your GA Account is successfully connected', '');
    }
    if (drawer && drawer == 'manage_projects' && criteria.property) {
      load();
    }
  }, [
    criteria.property,
    criteria.previousPeriodStart, criteria.previousPeriodEnd,
    criteria.currentPeriodStart, criteria.currentPeriodEnd,
  ]);

  // availableCountryCodes={option?.trafficValue}
  // siteId={option.id}
  // siteUrl={option.url}
  // activeCountryCodes={option.activeCountryCodes}


  useEffect(() => {
    if (navBar.getSiteProperties.length && navBar.activatePropertiesDrawerOpen) {
      const tmpSiteProperties = [];
      navBar.getSiteProperties.forEach(record => {
        const trafficValue = record?.trafficValueV2.
          filter(trafficValue => trafficValue.countryCode !== null && trafficValue.countryCode !== undefined)
          .map(item => ({...item, name: getSpecificCountryByCode(item?.countryCode)?.name}));
        const availableCountryCodes = toJS(trafficValue);
        tmpSiteProperties.push({
          id: record.id,
          projectName: getProjectName(record?.url),
          projectKwCount: record?.saTotalKwCount?.find(item => item?.countryCode == criteria?.countryCode)?.value || 0,
          pages: record?.totalPages?.find(item => item?.countryCode == criteria?.countryCode)?.value || 0,
          url: record.url,
          activeForCurrentCustomer: record.activeForCurrentCustomer,
          availableCountryCodes: availableCountryCodes,
          activeCountryCodes: record.activeCountryCodes,
        });
      });
      setDataOptions(tmpSiteProperties);
      setSearchSite(manageDomainName);
    } else {
      if (!navBar.loading) {
        setManageDomainName({manageDomainName: '', countryCode: ''});
      }
    }
  }, [navBar.getSiteProperties, navBar.activatePropertiesDrawerOpen]);


  const handleAfterVisibilityChange = () => {
    if (navBar.activatePropertiesDrawerOpen) {
      navBar.setDetailApiCall(true);
      navBar.setDetailApiCall(false);
      paramsCheck && router.push(`${location.pathname}?drawer=manage_projects${isChild ? `&customerId=${currentCustomerId ? currentCustomerId: ''}` : ''}`, undefined, {shallow: true});
    } else {
      navBar.setDetailApiCall(false);
      const {style} = document.body;
      style.cssText = `width: calc(100% - 0px); overflow: visible;`;
      if (paramsCheck) {
        if (!location.pathname.includes('/gsc/sites-list')) {
          router.push(`${location.pathname}?sp=${criteria.property}&country_code=${criteria.countryCode}`, undefined, {shallow: true});
        } else {
          router.push(`${location.pathname}`, undefined, {shallow: true});
        }
      }
    }
  };

  const equalsCheck = (a, b) => {
    if (a.length != b.length) {
      return false;
    } else {
      let result = false;
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false;
        } else {
          result = true;
        }
      }
      return result;
    }
  };

  const formatPayload = () => {
    const payload = [];
    dataOptions.forEach(record => {
      const properties = navBar?.getSiteProperties.find(properties => properties.id == record.id);
      if (properties && (properties.activeCountryCodes.length != 0 || record.activeCountryCodes.length != 0)) {
        if (!equalsCheck(properties.activeCountryCodes.sort((a, b) => b.value - a.value), record.activeCountryCodes.sort((a, b) => b.value - a.value))) {
          payload.push({
            id: record.id,
            country_codes: record.activeCountryCodes,
            site_url: record.url,
          });
        }
      }
    });
    return payload;
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);

    try {
      await navBar.activateSiteV2(formatPayload());
      getCustomerQuota();

      loadReportBuilderGSCList(selectedCountry);
      setManageDomainName({manageDomainName: '', countryCode: ''});
      await navBar.loadActiveGscProperties(true, GSC_PROPERTIES_PAGE_SIZE, 1);
      await navBar.loadInActiveGscProperties(true, GSC_PROPERTIES_PAGE_SIZE, 1);

      await navBar.loadSiteProperties();

      // its important to trigger this notification here and not after drawer is closed
      notification.success('GSC sites updated successfully, now refreshing data please wait...', '');
      // navBar.setIsActivatePropertiesDrawerClose();
      setIsSubmitting(false);

      setTimeout(() => {
        if (paramsCheck) {
          if (!location.pathname.includes('/gsc/sites-list')) {
            router.push(`${location.pathname}?sp=${criteria.property}&country_code=${criteria.countryCode}`, undefined, {shallow: true});
          } else {
            router.push(`${location.pathname}`, undefined, {shallow: true});
          }
        }
      }, 3000);
    } catch (e) {
      setIsSubmitting(false);
      notification.error('', 'Failed to update sites data');
    }
  };


  const onSearchHandler = e => {
    setSearchSite(e.target.value);
  };

  const totalLoactionsQuota = profile?.quotaUtilization?.gsc?.allowedActiveProjects?.total;
  const consumedLoactionsQuota = profile?.quotaUtilization?.gsc?.allowedActiveProjects?.consumed;


  const getProjectName = (property: string) => {
    return property ? (property.startsWith('sc-domain:') ? property.replace('sc-domain', 'Domain') : property) : '';
  };

  const numberWithCommas = useCallback(
    x => {
      return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    [],
  );

  const addDefaultSrc = e => {
    e.target.src = '/img/world-wide-web.svg';
  };

  const locationCountryChange = (id, value, check) => {
    const tmpDataOptions = JSON.parse(JSON.stringify(dataOptions));
    const tmpOption = tmpDataOptions.find(option => option.id == id);
    if (!tmpOption) {
      return;
    }
    if (check) {
      tmpOption.activeCountryCodes.push(value);
    } else {
      const index = tmpOption.activeCountryCodes.indexOf(value);
      if (index !== -1) {
        tmpOption.activeCountryCodes.splice(index, 1);
      }
    }
    tmpOption.activeForCurrentCustomer = tmpOption.activeCountryCodes.length > 0 ? true : false;
    setDataOptions(tmpDataOptions);
  };

  const locationStatusChange = (id, checked) => {
    const tmpDataOptions = JSON.parse(JSON.stringify(dataOptions));
    const tmpOption = tmpDataOptions.find(option => option.id == id);
    if (checked) {
      tmpOption.activeCountryCodes = tmpOption.availableCountryCodes.slice(0, 2).map(option => option.countryCode);
    } else {
      tmpOption.activeCountryCodes = [];
    }
    tmpOption.activeForCurrentCustomer = checked;
    setDataOptions(tmpDataOptions);
  };

  const columns = () => [
    {
      title: <span className={stylesTable.columnTitle} style={{marginLeft: 5}}>SITE</span>,
      dataIndex: 'site',
      key: 'site',
      width: 330,
      render: (_, record) => {
        return (<>
          <div className={stylesTable.imgDivWrapper}>
            <div className={stylesTable.dataWrapper}>
              <img className={stylesTable.icon} style={{width: '26px', height: '26px'}} src={`https://www.google.com/s2/favicons?sz=64&domain_url=${getDomain(record.url)}`} onError={e => addDefaultSrc(e)}/>
              <div style={{display: 'block'}} >
                <div className={stylesTable.projectName}><a>{record.projectName}</a></div>
                <div style={{display: 'flex'}}>
                  <div className={stylesTable.projectKwDiv}>{`${record.projectKwCount} keywords`}<span className={stylesTable.dot}></span></div>
                  <div>
                    <span className={stylesTable.pagesDiv}>{`${record.pages ? numberWithCommas(record.pages) : 0} pages`}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>);
      },
    },
    {
      title: () => <div style={{marginLeft: 5}}>LOCATION</div>,
      dataIndex: 'location',
      key: 'location',
      width: 280,
      render: (_, record) => {
        return <LocationSelector
          id={record.id}
          availableCountryCodes={record.availableCountryCodes}
          activeCountryCodes={record.activeCountryCodes}
          locationCountryChange={locationCountryChange}
        />;
      },
    },
    {
      title: ()=> <div style={{marginLeft: 5}}>STATUS</div>,
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        return <StatusSwitch
          id={record.id}
          activeForCurrentCustomer={record.activeForCurrentCustomer}
          locationStatusChange={locationStatusChange}/>;
      },
    },
  ];

  const activeSites = () => {
    const newActiveCount = dataOptions.filter(record => record.activeForCurrentCustomer).length;
    return newActiveCount;
  };

  let height = 550;
  if (window.screen.height < 1000) {
    height = 420;
  } else {
    height = 530;
  }

  useEffect(() => {
    if (!navBar.getSiteProperties?.length && navBar.activatePropertiesDrawerOpen) {
      navBar.loadSiteProperties();
    }
  }, [navBar.activatePropertiesDrawerOpen]);

  return (
    <Drawer
      onClose={() => navBar.setIsActivatePropertiesDrawerClose()}
      placement='right'
      width='850px'
      zIndex={11}
      visible={navBar.activatePropertiesDrawerOpen}
      destroyOnClose={true}
      bodyStyle={{padding: 0}}
      afterVisibleChange={() => handleAfterVisibilityChange()}
      className={styles.detailsDrawer}
      closeIcon={<CloseOutlined className={styles.closeIcons}
        style={navBar.activatePropertiesDrawerOpen ? {display: 'block'} : {display: 'none'}}/>}
    >
      <ProgressBar trigger={navBar.loadingSearchPerformance}/>
      <div className={styles.drawerWrapper} style={{padding: 24}}>
        <div className={styles.topPart}>
          <div> <img src='/img/google-modern-icon.svg' style={{width: 30, height: 30, marginRight: 14}}/> </div>
          <div className={styles.textPart}>
            <span className={styles.title}>
              {t('choose-projects-manage')}
            </span>
            <span className={styles.paragraph}>
              {t('valuable-insight-projects')}
            </span>
          </div>
        </div>
        {!navBar.loadingSearchPerformance && !navBar?.loading ?
          <div style={{background: '#fff', padding: 10}}>
            <div className={styles.inputSearchDiv} style={{marginBottom: '10px', width: 190}}>
              <Input
                value={searchSite}
                placeholder={'Site URL'}
                onChange={onSearchHandler}
                prefix={<Search width={15}/>}
                bordered={false}
                style={{background: '#fff', borderRadius: 6}}
              />
            </div>
            <div className={classNames(stylesTable.columns)}>
              <Table
                className={styles.middlePart}
                pagination={false}
                columns={columns()}
                dataSource={searchSite == '' ? toJS(dataOptions) : toJS(dataOptions)?.filter(option => option.url.includes(searchSite))}
                scroll={{y: height}}
              />
            </div>

          </div> :
          <div style={{display: 'flex'}}><Spin indicator={antIcon} style={{margin: '50px auto'}}/></div>}
        <div className={styles.bottomPart}>
          <div className={styles.activeProjects}>
            <div>{`Active sites: ${activeSites()}`}</div>
            <span>
              <QuotaInfoDetail heading={'Location quota'}
                consumedData={consumedLoactionsQuota}
                totalData={totalLoactionsQuota}
                description={'Total location quota'}
                hideTopup
              />
            </span>
            {/* <span style={{fontWeight: 500}} className={styles.activeProjectTitle}>
              {t('active-projects')}: <Popover trigger='hover'
                visible={getShowPopover ? getShowPopover : popoverVisible}
                onVisibleChange={() => getShowPopover ? getShowPopover : setPopoverVisible(!popoverVisible)}
                getPopupContainer={handlePopupContainer}
                content={popoverContent}><img src='img/dashboard/backlinks/info-circle-gray.svg' alt='' /></Popover>
            </span> */}
            {/* <span style={{fontWeight: 500}} className={styles.count}>{toJS(navBar.getSiteProperties).filter(item => item.activeForCurrentCustomer).length} / {profile?.quotaUtilization?.gsc?.allowedActiveProjects?.total}</span>
            <span style={{display: 'block', marginTop: '10px'}}>Activation quota: {profile?.quotaUtilization?.gsc?.allowedActiveProjects?.consumed}/{profile?.quotaUtilization?.gsc?.allowedActiveProjects?.total}</span> */}
            {/* <span style={{display: 'block', marginTop: '10px'}}> Deactivation quota: {profile?.quotaUtilization?.gsc?.projectDeactivationsAllowedPerMonth?.consumed}/{profile?.quotaUtilization?.gsc?.projectDeactivationsAllowedPerMonth?.total}</span> */}
          </div>
          <div className={styles.bottomWrapper}>
            {
              <Button disabled={isSubmitting || formatPayload().length == 0}
                className={styles.bottomButton} buttonType='purple' onClick={handleConfirm}><>
                  {isSubmitting ? <Spin indicator={antIcon} /> : null}{' '}{t('confirm-continue')}</>
              </Button>
            }

            <div className={styles.bottomParagraph}>
              {t('activ-deactiv-quota')}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
});
