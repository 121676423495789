import {types, flow, cast} from 'mobx-state-tree';
import {LOCAL_SEO_SCAN_API} from '@/api/local-seo';
import {toJS} from 'mobx';
import {initLocalSeoScanDetailStore, LocalSeoScanDetailStore} from './seo-scan-detail';
import {apiError} from '@/utils/api';
import {useErrorNotification, notification as notify, notification} from '@/utils/notification-v2';
import {LOCALSEO_FILTER} from './data';
import {getFilter} from '@/utils/filters';

const LocationModel = types.model({
  type: types.maybeNull(types.string),
  coordinates: types.maybeNull(types.array(types.maybeNull(types.number))),
});

const latestSnapshotModel = types.model({
  date: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  gmbAveragePosition: types.maybeNull(types.union(types.string, types.number)),
  gmbBestPosition: types.maybeNull(types.union(types.string, types.number)),
  gmbCenterPosition: types.maybeNull(types.union(types.string, types.number)),
  gmbWorstPosition: types.maybeNull(types.union(types.string, types.number)),
});

const ResultsModel = types.model({
  businessName: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  center: types.maybeNull(LocationModel),
  dataCid: types.maybeNull(types.string),
  googleUrl: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  gridSize: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  updateFrequencyDays: types.maybeNull(types.number),
  radiusMiles: types.maybeNull(types.number),
  location: types.maybeNull(LocationModel),
  latestSnapshot: types.maybeNull(latestSnapshotModel),
});

const localSeoHistoryDataModel = types.model({
  id: types.maybeNull(types.number),
  keyword: types.maybeNull(types.string),
  updateFrequencyDays: types.maybeNull(types.number),
  gmbAveragePosition: types.maybeNull(types.number),
  lastProcessedAt: types.maybeNull(types.string),
  gridDimensions: types.maybeNull(types.number),
  center: types.maybeNull(types.model({
    coordinates: types.maybeNull(types.array(types.union(types.number, types.string))),
  })),
  pinCount: types.maybeNull(types.number),
  radiusMiles: types.maybeNull(types.number),
  gridShape: types.maybeNull(types.string),
  businessId: types.maybeNull(types.number),
  businessName: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  recrawlTime: types.maybeNull(types.string),
  businessTimezone: types.maybeNull(types.string),
});
const feedbackModel = types.model({
  rating: types.maybeNull(types.number),
  reviews: types.maybeNull(types.number),
});

const parsedModel = types.model({
  open: types.maybeNull(types.string),
  close: types.maybeNull(types.string),
});

const openingHoursPerDayModel = types.model({
  date: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  dayNumber: types.maybeNull(types.number),
  parsed: types.maybeNull(types.array(parsedModel)),
  value: types.maybeNull(types.string),
});

const perDayModel = types.model({
  perDay: types.maybeNull(types.array(openingHoursPerDayModel)),
});
const addressModel = types.model({
  city: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  zipcode: types.maybeNull(types.string),
  address1: types.maybeNull(types.string),
});

const businessMetadataModel = types.model({
  address: types.maybeNull(addressModel),
  category: types.maybeNull(types.array(types.string)),
  description: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  openingHours: types.maybeNull(perDayModel),
});

const projectsResultsModel = types.model({
  businessName: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
  keyword: types.maybeNull(types.string),
  publicShareHash: types.maybeNull(types.string),
  center: types.maybeNull(LocationModel),
  dataCid: types.maybeNull(types.string),
  googleUrl: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  gridSize: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  currentAveragePosition: types.maybeNull(types.number),
  latestGird: types.maybeNull(types.number),
  radiusMiles: types.maybeNull(types.number),
  location: types.maybeNull(LocationModel),
  latestSnapshot: types.maybeNull(latestSnapshotModel),
  keywordMetrics: types.maybeNull(types.array(latestSnapshotModel)),
  keywordBreakdown: types.maybeNull(types.array(latestSnapshotModel)),
  feedback: types.maybeNull(feedbackModel),
  historicalSnapshot: types.maybeNull(types.array(latestSnapshotModel)),
  gridsAvgPosition: types.maybeNull(types.number),
  gridsLocationLegends: types.maybeNull(types.frozen({})),
  businessMetadata: types.maybeNull(businessMetadataModel),
});
const localSeoprojectListsModel = types.model({
  count: types.maybeNull(types.number),
  next: types.maybeNull(types.string),
  pageSize: types.maybeNull(types.number),
  totalPages: types.maybeNull(types.number),
  results: types.maybeNull(types.array(projectsResultsModel)),
});

export const paramsModel = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
});
export const paramsModelV3 = types.model({
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  business_ids: types.maybeNull(types.frozen()),
});
export const projectListParamsModel = types.model({
  ordering: types.maybeNull(types.string),
  search: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  page_size: types.maybeNull(types.number),
  page: types.maybeNull(types.number),
});

const countriesModel = types.model({
  formattedAddress: types.maybeNull(types.string),
  geometry: types.maybeNull(types.model({
    location: types.maybeNull(types.model({
      lat: types.maybeNull(types.number),
      lng: types.maybeNull(types.number),
    })),
  })),
  name: types.maybeNull(types.string),
});

const editHeatmapModalDataModel = types.model({
  editHeatmapModalVisible: types.maybeNull(types.boolean),
  gridIdForEditModal: types.maybeNull(types.number),
  businessNameForEditModal: types.maybeNull(types.string),
  keywordForEditModal: types.maybeNull(types.string),
  businessIdForEditModal: types.maybeNull(types.number),
  gridShapeForEditModal: types.maybeNull(types.string),
  gridDimensionsForEditModal: types.maybeNull(types.number),
  radiusMilesForEditModal: types.maybeNull(types.number),
  updateFrequencyDaysForEditModal: types.maybeNull(types.number),
  typeOfEditModal: types.maybeNull(types.string),
  recrawlTimeForEditModal: types.maybeNull(types.string),
  gridIdsListForEditModal: types.maybeNull(types.array(types.number)),
  newCenterForEditModal: types.maybeNull(types.model({
    lat: types.maybeNull(types.number),
    lon: types.maybeNull(types.number),
  })),
});

const filterListModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  isDecimals: types.maybeNull(types.boolean),
  maxLimit: types.maybeNull(types.number),
  filterField: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  equal: types.maybeNull(types.union(types.string, types.number)),
  customCategoryType: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  category: types.maybeNull(types.array(types.string)),
  filterTypes: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    value: types.maybeNull(types.string),
  }))),
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
    equal: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customFields: types.maybeNull(types.array(types.model({
    label: types.maybeNull(types.string),
    operator: types.maybeNull(types.string),
  }))),
});

const AggregatorNetworkModel = types.model({
  businessName: types.maybeNull(types.string),
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  aggregators: types.maybeNull(types.array(types.string)),
  amountPaid: types.maybeNull(types.number),
  createdAt: types.maybeNull(types.string),
  websiteAddress: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  briefDescription: types.maybeNull(types.string),
  businessCategoryId: types.maybeNull(types.number),
  campaignCity: types.maybeNull(types.string),
  campaignCountry: types.maybeNull(types.string),
  campaignName: types.maybeNull(types.string),
  campaignState: types.maybeNull(types.string),
  contactEmail: types.maybeNull(types.string),
  contactFirstname: types.maybeNull(types.string),
  contactName: types.maybeNull(types.string),
  contactTelephone: types.maybeNull(types.string),
  formationDate: types.maybeNull(types.string),
  isSuccessfullySubmitted: types.maybeNull(types.boolean),
  postcode: types.maybeNull(types.string),
  campaignStatus: types.maybeNull(types.string),
});
const CitationBusinessDetailModel = types.model({
  campaignId: types.maybeNull(types.number),
  citations: types.maybeNull(types.frozen()),
  error: types.maybeNull(types.boolean),
});

const aggregatorNetworkDetailsModel = types.model({
  address1: types.maybeNull(types.string),
  address2: types.maybeNull(types.string),
  aggregators: types.maybeNull(types.array(types.string)),
  brief_description: types.maybeNull(types.string),
  business_category_id: types.maybeNull(types.number),
  business_name: types.maybeNull(types.string),
  campaign_city: types.maybeNull(types.string),
  campaign_country: types.maybeNull(types.string),
  campaign_id: types.maybeNull(types.number),
  campaign_name: types.maybeNull(types.string),
  campaign_notes: types.maybeNull(types.string),
  campaign_state: types.maybeNull(types.string),
  campaign_status: types.maybeNull(types.string),
  contact_email: types.maybeNull(types.string),
  contact_firstname: types.maybeNull(types.string),
  contact_lastname: types.maybeNull(types.string),
  contact_name: types.maybeNull(types.string),
  contact_telephone: types.maybeNull(types.string),
  created_at: types.maybeNull(types.string),
  customer_id: types.maybeNull(types.number),
  employees_number: types.maybeNull(types.number),
  fax_number: types.maybeNull(types.string), // Updated to types.string to match snapshot
  formation_date: types.maybeNull(types.string),
  full_description: types.maybeNull(types.string),
  gb_location_id: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  location_id: types.maybeNull(types.number),
  mobile_number: types.maybeNull(types.string),
  old_business_name: types.maybeNull(types.string),
  opening_hours: types.maybeNull(types.frozen({})), // Ensure correct structure or consider more specific model if needed
  postcode: types.maybeNull(types.string),
  social_profile_links: types.maybeNull(types.model({
    facebook: types.maybeNull(types.string),
    instagram: types.maybeNull(types.string),
    twitter: types.maybeNull(types.string),
    linkedin: types.maybeNull(types.string),
    pinterest: types.maybeNull(types.string),
  })),
  website_address: types.maybeNull(types.string),
  add_additional_category: types.maybeNull(types.array(types.string)),
  business_email: types.maybeNull(types.string),
  extra_business_categories_ids: types.maybeNull(types.frozen()), // Ensure correct structure or consider more specific model if needed
  special_offer: types.maybeNull(types.string), // Added based on the snapshot
  special_offer_description: types.maybeNull(types.string), // Added based on the snapshot
  special_offer_expiry_date: types.maybeNull(types.string), // Added based on the snapshot
  payment_methods: types.maybeNull(types.string), // Added based on the snapshot
  logo: types.maybeNull(types.string),
  image1: types.maybeNull(types.string),
  image2: types.maybeNull(types.string),
  image3: types.maybeNull(types.string),
});

export const LocalSeoScanStore = types.model({
  localSeoScanDetail: LocalSeoScanDetailStore,
  id: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  noRepolling: types.boolean,
  loadingList: types.boolean,
  loadingListV3: types.boolean,
  results: types.maybeNull(types.array(ResultsModel)),
  localSeoHistoryData: types.maybeNull(types.array(localSeoHistoryDataModel)),
  localSeoprojectsList: types.maybeNull(localSeoprojectListsModel),
  localSeoprojectsListV3: types.maybeNull(localSeoprojectListsModel),
  params: types.maybeNull(paramsModel),
  paramsV3: types.maybeNull(paramsModelV3),
  projectListParams: types.maybeNull(projectListParamsModel),
  projectListParamsV3: types.maybeNull(projectListParamsModel),
  total: types.maybeNull(types.number),
  totalHistoryKws: types.maybeNull(types.number),
  loadingSeoprojectsList: types.maybeNull(types.boolean),
  loadingSeoprojectsListV3: types.maybeNull(types.boolean),
  localSeoProjectId: types.maybeNull(types.number),
  creatingNewRecord: types.boolean,
  listApiRepolling: types.boolean,
  listApiRepollingV3: types.boolean,
  showDeleteModal: types.boolean,
  showDeleteModalV3: types.boolean,
  isDeleting: types.boolean,
  isDeletingV3: types.boolean,
  addingAggregatorNetwork: types.boolean,
  businessDeleteId: types.maybeNull(types.union(types.string, types.number)),
  keywordDeleteId: types.maybeNull(types.union(types.string, types.number)),
  countries: types.optional(types.array(countriesModel), []),
  urlValue: types.string,
  dataFilter: types.array(filterListModel),
  projectList: types.maybeNull(types.frozen()),
  keywordsList: types.maybeNull(types.frozen()),
  addMore: types.boolean,
  updatingData: types.boolean,
  bulkUpdatingData: types.boolean,
  editHeatmapModalData: types.maybeNull(editHeatmapModalDataModel),
  aggregatorNetwork: types.maybeNull(types.array(AggregatorNetworkModel)),
  loadingAggregatorNetwork: types.boolean,
  deletingAggregatorNetwork: types.boolean,
  citationBusinessDetail: types.maybeNull(CitationBusinessDetailModel),
  gettingCitationBusinessDetail: types.boolean,
  loadingExportCitation: types.boolean,
  aggregatorNetworkDetails: types.maybeNull(aggregatorNetworkDetailsModel),
  loadingAggregatorNetworkDetails: types.boolean,
}).views( self => ({
  get getLocalSeoResults() {
    return toJS(self.results);
  },
  get getCountries() {
    return toJS(self.countries);
  },
  get filter() {
    return toJS(self.dataFilter);
  },
  get getLocalSeoprojectsList() {
    return toJS(self.localSeoprojectsList);
  },
  get getLocalSeoprojectsListV3() {
    return toJS(self.localSeoprojectsListV3);
  },
})).actions(self => {
  const loadCustomLocation = flow(function* (queryParam: string) {
    try {
      const resp = yield LOCAL_SEO_SCAN_API.searchLocation(queryParam);
      if (resp && !resp.isCancel) self.countries = cast(resp?.results);
      return resp?.results || [];
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const clearCountries = () => {
    self.countries = cast([]);
  };
  const loadLocalSeoList = flow(function* (id, noLoading?: boolean) {
    if (!noLoading) {
      self.loadingList = true;
    }
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadLocalSeoListData(id, self.params);
      self.total = response?.count;
      self.results = cast(response?.results || []);
      const metricsStatus = response?.results?.filter(item => item?.status == 'PENDING');
      if (metricsStatus?.length && self.listApiRepolling) {
        yield new Promise(r => setTimeout(r, 2000));
        return loadLocalSeoList(id, true);
      }
      self.loadingList = false;
    } catch (e) {
      self.loadingList = false;
      return Promise.reject(e);
    }
  });

  const loadUpdateGridDataV3 = flow(function* (id, payload) {
    self.updatingData = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.updateGridDataV3(id, payload);
      return response;
    } catch (e) {
      self.updatingData = false;
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.updatingData = false;
    }
  });

  const loadBulkUpdateGridDataV3 = flow(function* (id, payload) {
    self.bulkUpdatingData = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.bulkUpdateGridDataV3(id, payload);
      return response;
    } catch (e) {
      self.bulkUpdatingData = false;
      return Promise.reject(e);
    } finally {
      self.bulkUpdatingData = false;
    }
  });

  const loadLocalSeoListV3 = flow(function* (noLoading?: boolean, addMore?: boolean) {
    if (!noLoading) {
      self.loadingListV3 = true;
    }
    try {
      const addFilter = (filters, filterType, paramName) => {
        const filter = getFilter(filters, filterType);
        return filter && filter.from && {[paramName + '_from']: filter.from, [paramName + '_to']: filter.to};
      };
      const activeFilters = self.dataFilter?.filter(z => z?.active);
      const params = {
        ...self.paramsV3,
        business_ids: self.paramsV3?.business_ids?.join(','),
        ...(getFilter(activeFilters, 'exclude')?.type == 'contains' && {'keyword_contains': getFilter(activeFilters, 'exclude')?.query}),
        ...(getFilter(activeFilters, 'exclude')?.type == 'does_not_contain' && {'keyword_excludes': getFilter(activeFilters, 'exclude')?.query}),
        ...(getFilter(activeFilters, 'exclude')?.type == 'starts_with' && {'keyword_startswith': getFilter(activeFilters, 'exclude')?.query}),
        ...(getFilter(activeFilters, 'exclude')?.type == 'ends_with' && {'keyword_endswith': getFilter(activeFilters, 'exclude')?.query}),
        ...addFilter(activeFilters, 'avg_score', 'avg_score'),
      };
      const response = yield LOCAL_SEO_SCAN_API.loadLocalSeoListDataV3(params);
      if (response?.isCancel) return;
      self.totalHistoryKws = response?.count;
      self.localSeoHistoryData = cast(response?.results || []);
      if (addMore) {
        self.keywordsList = [...self.keywordsList, response?.results];
      }
      const metricsStatus = response?.results?.filter(item => item?.status == 'PENDING');
      if (metricsStatus?.length && self.listApiRepollingV3) {
        yield new Promise(r => setTimeout(r, 2000));
        return loadLocalSeoListV3([], true);
      }
      if (response) {
        self.loadingListV3 = false;
      }
    } catch (e) {
      self.localSeoHistoryData = cast([]);
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      self.loadingListV3 = false;
    }
  });
  const loadSeoProjectsList = flow(function* (noLoading?: boolean) {
    if (!noLoading) {
      self.loadingSeoprojectsList = true;
    }
    const payload = {...self.projectListParams};
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadSeoProjectsList(payload);
      self.localSeoprojectsList = cast(response);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingSeoprojectsList = false;
    }
  });
  const loadSeoProjectsListV3 = flow(function* (noLoading?: boolean, setProjects?: boolean, searchValue?: string, pageNumber?: number) {
    if (!noLoading) {
      self.loadingSeoprojectsListV3 = true;
    }
    let payload = null;
    if (searchValue) {
      payload = {...self.projectListParamsV3, search: searchValue};
    } else {
      payload = {...self.projectListParamsV3};
    }
    if (pageNumber) {
      payload = {...self.projectListParamsV3, page: pageNumber};
    }
    try {
      const response = yield LOCAL_SEO_SCAN_API.loadSeoProjectsListV3(payload);
      self.localSeoprojectsListV3 = cast(response);
      if (setProjects) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        self.projectList = [...self.projectList, ...response?.results];
      }
      self.loadingSeoprojectsListV3 = false;
      if (pageNumber) {
        return {success: true};
      }
    } catch (e) {
      self.loadingSeoprojectsListV3 = false;
      const errorMessage = apiError(e) as string;
      notification.error('', errorMessage, false, 'OK');
    }
  });
  const deleteSeoProject = flow(function* (id, isLastRecord?: boolean) {
    if (isLastRecord) {
      setProjectListParamsV3({
        ...self.projectListParamsV3,
        page: self.projectListParamsV3.page !== 1 ? self.projectListParamsV3.page - 1 : self.projectListParamsV3.page,
      }, true);
    }
    try {
      const response = yield LOCAL_SEO_SCAN_API.deleteSeoProject(id);
      if (response) {
        notify.success('Success', 'Business deleted successfully');
      }
      if (!response?.isCancel) {
        loadSeoProjectsListV3(true);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  });
  const addBusiness = flow(function* (id, payload) {
    self.creatingNewRecord = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.postNewBusiness(id, payload);
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.creatingNewRecord = false;
    }
  });

  const addBusinessV3 = flow(function* (id, payload) {
    self.creatingNewRecord = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.postNewBusinessV3(id, payload);
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.creatingNewRecord = false;
    }
  });

  const addBusinessV2 = flow(function* (payload) {
    self.creatingNewRecord = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.postNewBusinessV2(payload);
      if (response?.id) {
        notify.success('Success', 'Project successfully created');
      } else if (response?.message) {
        notify.warning('Warning', response?.message);
      }
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.creatingNewRecord = false;
    }
  });

  const getBusinessToAdd = flow(function* (id) {
    self.creatingNewRecord = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.postBusinessV3ToAdd(id);
      return response;
    } catch (e) {
      const errorMessage = apiError(e) as string;
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.creatingNewRecord = false;
    }
  });

  const addNewBusinessV3 = flow(function* (payload, isOnBoarding?: boolean) {
    self.creatingNewRecord = true;
    try {
      const response = yield LOCAL_SEO_SCAN_API.postBusinessV3(payload);
      if (response?.isCancel) return;
      if (response?.id) {
        self.urlValue = '';
        if (isOnBoarding) {
          notify.success('Business created', 'Local SEO business created successfully', 'Go to Page', () => window?.open('/research/seo-projects-list', '_blank'));
        } else {
          notify.success('Business created', 'Local SEO business created successfully');
        }
      } else if (response?.message) {
        notify.warning('Warning', response?.message);
      }
      return response;
    } catch (e) {
      let errorMessage = apiError(e) as string;
      if (payload?.business_url && e?.response?.status == 400) {
        errorMessage = Array.isArray(e?.response?.data) ? e?.response?.data[0] : e?.response?.data?.message;
      }
      useErrorNotification({
        e,
        msg: errorMessage,
      });
      return Promise.reject(e);
    } finally {
      self.creatingNewRecord = false;
    }
  });
  const setupGrids = flow(function* (id, payload) {
    try {
      const response = yield LOCAL_SEO_SCAN_API.postSetupGrids(id, payload);
      return response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.creatingNewRecord = false;
    }
  });
  const setParams = (value, id, noApiCall?: boolean) => {
    if (value?.search !== undefined) {
      self.params.search = value?.search;
    }
    self.params.ordering = value?.ordering;

    if (value?.type) {
      self.params.type = value?.type;
    }
    if (value?.page) {
      self.params.page = value?.page;
    }
    if (value?.pageSize) {
      self.params.page_size = value?.pageSize;
    }
    if (!noApiCall) {
      loadLocalSeoList(id);
    }
  };

  const setParamsV3 = (value, noApiCall?: boolean, addMore?: boolean) => {
    if (value?.search !== undefined) {
      self.paramsV3.search = value?.search;
    }
    self.paramsV3.ordering = value?.ordering;

    if (value?.type) {
      self.paramsV3.type = value?.type;
    }
    if (value?.page) {
      self.paramsV3.page = value?.page;
    }
    if (value?.pageSize) {
      self.paramsV3.page_size = value?.pageSize;
    }
    if (value?.business_ids) {
      self.paramsV3.business_ids = value?.business_ids;
    }
    if (!noApiCall) {
      loadLocalSeoListV3(false, addMore);
    }
  };

  const setListApiRepolling = value => {
    self.listApiRepolling = value;
    self.loadingList = true;
  };

  const setListApiRepollingV3 = value => {
    self.listApiRepollingV3 = value;
    self.loadingList = true;
  };

  const setShowDeleteModal = (value, recordId?: any) => {
    self.showDeleteModal = value;
    if (recordId) {
      self.businessDeleteId =recordId;
    }
  };
  const setShowDeleteModalV3 = (value, recordId?: any, businessId?: any) => {
    self.showDeleteModalV3 = value;
    if (recordId) {
      self.keywordDeleteId = recordId;
      self.businessDeleteId = businessId;
    }
  };
  const setHistoryOrdering = (data: any) => {
    self.paramsV3 = data;
    loadLocalSeoListV3();
  };
  const setLocalSeoProjectId = (id: number) => {
    self.localSeoProjectId = id;
    localStorage.setItem('localSeoProjectId', `${id}`);
  };
  const setProjectListParams = (params: any, noApiCall = false) => {
    self.projectListParams = params;
    if (!noApiCall) {
      loadSeoProjectsList(false);
    }
  };

  const setProjectListParamsV3 = (params: any, setProjects?: boolean, noApiCall = false) => {
    self.projectListParamsV3 = params;
    if (!noApiCall) {
      loadSeoProjectsListV3(false, setProjects);
    }
  };

  const setProjectsPagination = (page: number, pageSize: number) => {
    self.projectListParams = {...self.projectListParams, page: page, page_size: pageSize};
    loadSeoProjectsList();
  };

  const setProjectsPaginationV3 = (page: number, pageSize: number) => {
    self.projectListParamsV3 = {...self.projectListParamsV3, page: page, page_size: pageSize};
    loadSeoProjectsListV3();
  };

  const setPagination = (page: number, pageSize: number) => {
    self.params = {...self.params, page: page, page_size: pageSize};
    loadLocalSeoListV3();
  };

  const setPaginationV3 = (page: number, pageSize: number) => {
    self.paramsV3 = {...self.paramsV3, page: page, page_size: pageSize};
    loadLocalSeoListV3();
  };
  const updateHistoryFilter = filters => {
    self.paramsV3 = {...self.paramsV3, page: 1};
    self.dataFilter = cast(filters);
    loadLocalSeoListV3();
  };
  const updateBusinessFilterStatus = filters => {
    self.dataFilter = cast(filters);
  };

  const deletedBusinessListData = flow(function* (id, isHistory, isLastRecord?: boolean) {
    // self.loadingKeywordList = true;
    const mainId = localStorage.getItem('localSeoProjectId') || self.localSeoProjectId;
    self.isDeleting = true;
    if (isLastRecord) {
      setParams({
        ...self.params,
        page: self.params.page !== 1 ? self.params.page - 1 : self.params.page,
      }, true);
    }
    try {
      yield LOCAL_SEO_SCAN_API.deleteBusiness(mainId, id);
      return loadLocalSeoList(mainId);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.isDeleting = false;
    }
  });

  const deleteKeywordData = flow(function* (mainId, id, isLastRecord?: boolean) {
    self.isDeletingV3 = true;
    if (isLastRecord) {
      setParamsV3({
        ...self.paramsV3,
        page: self.paramsV3.page !== 1 ? self.paramsV3.page - 1 : self.paramsV3.page,
      }, true);
    }
    try {
      yield LOCAL_SEO_SCAN_API.deleteKeyword(mainId, id);
      return loadLocalSeoListV3();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      self.isDeletingV3 = false;
    }
  });

  const addAggregatorNetwork = flow(function* (payload: any) {
    try {
      self.addingAggregatorNetwork = true;
      const response = yield LOCAL_SEO_SCAN_API.addAggregatorNetwork(payload);
      return response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.addingAggregatorNetwork = false;
    }
  });

  const updateAggregatorNetwork = flow(function* (id, payload: any) {
    try {
      self.addingAggregatorNetwork = true;
      yield LOCAL_SEO_SCAN_API.updateAggregatorNetwork(id, payload);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.addingAggregatorNetwork = false;
    }
  });


  const loadAggregatorNetwork = flow(function* () {
    try {
      self.loadingAggregatorNetwork = true;
      const data = yield LOCAL_SEO_SCAN_API.getAggregatorNetwork();
      self.aggregatorNetwork = cast(data);
    } catch (e) {
      notification.error('Failed', 'Failed to load aggregator networks');
    } finally {
      self.loadingAggregatorNetwork = false;
    }
  });

  const loadAggregatorNetworkDetails = flow(function* (id) {
    self.loadingAggregatorNetworkDetails = true;
    try {
      const data = yield LOCAL_SEO_SCAN_API.loadAggregatorNetworkDetails(id);
      self.aggregatorNetworkDetails = cast(data);
      return data;
    } catch (e) {
      const errorMessage = apiError(e);
      notification.error('', errorMessage);
    } finally {
      self.loadingAggregatorNetworkDetails = false;
    }
  });

  const deleteAggregatorNetwork = flow(function* (id: string | number) {
    try {
      self.deletingAggregatorNetwork = true;
      yield LOCAL_SEO_SCAN_API.deleteAggregatorNetwork(id);
      notification.success('Success', 'Deleted business successfully ');
      loadAggregatorNetwork();
      return {success: true};
    } catch (e) {
      notification.error('Failed', 'Failed to delete business');
    } finally {
      self.deletingAggregatorNetwork = false;
    }
  });

  const exportCitationTableDetail = flow(function* (id: string | number) {
    try {
      self.loadingExportCitation = true;
      const response = yield LOCAL_SEO_SCAN_API.exportCitationDetail(id);
      if (response.isCancel) return;
      return response;
    } catch (e) {
      self.loadingExportCitation = false;
      notification.error('Failed', 'Failed to export citation data');
    } finally {
      self.loadingExportCitation = false;
    }
  });

  const loadCitationBusinessDetail = flow(function* (id: string | number) {
    try {
      self.gettingCitationBusinessDetail = true;
      const response = yield LOCAL_SEO_SCAN_API.citationBusinessDetail(id);
      if (response.isCancel) return;
      self.citationBusinessDetail = cast(response);
      self.gettingCitationBusinessDetail = false;
      return {success: true};
    } catch (e) {
      self.gettingCitationBusinessDetail = false;
      notification.error('Failed', 'Failed to get citation data');
    } finally {
      self.gettingCitationBusinessDetail = false;
    }
  });

  const setProjectList = (value: any, appendSelectedProject = true) => {
    const selectedProjectData = JSON.parse(localStorage.getItem('selectedProjectData'));
    if (selectedProjectData && !value.find(v => v?.id == selectedProjectData?.id) && appendSelectedProject) {
      value.push(selectedProjectData);
    }

    self.projectList = selectedProjectData ? value.filter((value, index, array) => array?.findIndex(v2 => (v2?.id === value?.id))===index) : value;
  };

  const setAddMore = (value: boolean) => {
    self.addMore = value;
  };

  const setUrlValue = value => self.urlValue = value;

  const setEditHeatmapModalData = (data, method?: string, type?: string) => {
    self.editHeatmapModalData.editHeatmapModalVisible = data?.value;
    if (method !== 'onClose') {
      self.editHeatmapModalData.gridIdForEditModal = data?.gridId;
      self.editHeatmapModalData.businessIdForEditModal = data?.businessId;
    }
    if (type == 'bulk') {
      self.editHeatmapModalData.gridIdsListForEditModal = data?.gridIdsList;
    }
    self.editHeatmapModalData.businessNameForEditModal = data?.businessName;
    self.editHeatmapModalData.keywordForEditModal = data?.keyword;
    self.editHeatmapModalData.gridShapeForEditModal = data?.gridShape;
    self.editHeatmapModalData.gridDimensionsForEditModal = data?.gridDimensions;
    self.editHeatmapModalData.radiusMilesForEditModal = data?.radiusMiles;
    self.editHeatmapModalData.updateFrequencyDaysForEditModal = data?.updateFrequencyDays;
    self.editHeatmapModalData.typeOfEditModal = type;
    self.editHeatmapModalData.recrawlTimeForEditModal = data?.recrawlTime;
    self.editHeatmapModalData.newCenterForEditModal = data?.newCenter;
  };

  return {
    loadLocalSeoList,
    setListApiRepolling,
    addBusiness,
    deleteSeoProject,
    loadCustomLocation,
    clearCountries,
    setParams,
    setParamsV3,
    setPaginationV3,
    deletedBusinessListData,
    deleteKeywordData,
    setShowDeleteModal,
    setShowDeleteModalV3,
    setupGrids,
    loadSeoProjectsList,
    addBusinessV2,
    addBusinessV3,
    getBusinessToAdd,
    addNewBusinessV3,
    loadSeoProjectsListV3,
    setLocalSeoProjectId,
    setProjectListParams,
    setProjectListParamsV3,
    setProjectList,
    setProjectsPagination,
    setProjectsPaginationV3,
    setPagination,
    loadLocalSeoListV3,
    setHistoryOrdering,
    setListApiRepollingV3,
    setUrlValue,
    updateHistoryFilter,
    updateBusinessFilterStatus,
    setAddMore,
    addAggregatorNetwork,
    setEditHeatmapModalData,
    loadUpdateGridDataV3,
    loadBulkUpdateGridDataV3,
    loadAggregatorNetwork,
    deleteAggregatorNetwork,
    loadCitationBusinessDetail,
    exportCitationTableDetail,
    updateAggregatorNetwork,
    loadAggregatorNetworkDetails,
  };
});

export const initLocalSeoScanStore = () => {
  return LocalSeoScanStore.create({
    localSeoScanDetail: initLocalSeoScanDetailStore(),
    id: null,
    status: null,
    noRepolling: false,
    listApiRepolling: false,
    listApiRepollingV3: false,
    loadingSeoprojectsList: false,
    loadingSeoprojectsListV3: false,
    isDeleting: false,
    isDeletingV3: false,
    loadingList: true,
    loadingListV3: true,
    creatingNewRecord: false,
    results: null,
    showDeleteModal: false,
    showDeleteModalV3: false,
    params: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
    },
    paramsV3: {
      page_size: 10,
      page: 1,
      ordering: '',
      search: '',
      type: '',
      business_ids: [],
    },
    projectListParams: {
      ordering: '',
      search: '',
      page_size: 10,
      page: 1,
    },
    projectListParamsV3: {
      ordering: '',
      search: '',
      page_size: 10,
      page: 1,
    },
    urlValue: '',
    dataFilter: LOCALSEO_FILTER,
    projectList: [],
    keywordsList: [],
    addMore: true,
    addingAggregatorNetwork: false,
    editHeatmapModalData: {
      editHeatmapModalVisible: false,
      gridIdForEditModal: null,
      businessNameForEditModal: null,
      keywordForEditModal: null,
      businessIdForEditModal: null,
    },
    updatingData: false,
    bulkUpdatingData: false,
    loadingAggregatorNetwork: false,
    deletingAggregatorNetwork: false,
    aggregatorNetwork: [],
    gettingCitationBusinessDetail: false,
    loadingExportCitation: false,
    loadingAggregatorNetworkDetails: false,
  });
};
