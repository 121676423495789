export const isOnboardingSupportTime = () => {
  const options = {
    timeZone: 'America/New_York',
    hour12: false,
  };

  const currentDateTime = new Date().toLocaleString('en-US', options);
  const currentDay = new Date(currentDateTime).getDay();
  const currentHour = new Date(currentDateTime).getHours();
  const currentMinutes = new Date(currentDateTime).getMinutes();
  const isWeekday = currentDay >= 1 && currentDay <= 5;
  // Visible from 12:30 AM to 9 PM
  const isWithinTimeSpan = (currentHour === 0 && currentMinutes >= 30) || (currentHour >= 1 && currentHour <= 20);
  return isWeekday && isWithinTimeSpan;
};
