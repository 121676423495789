import {types, Instance, flow, cast} from 'mobx-state-tree';
import {reportsApi} from '@/api/gsc/index';
import {getTokenFromCookies} from '@/api/common-utils';
import {useErrorNotification} from '@/utils/notification-v2';

export const SpCountryPerformance = types.model(
  {
    clicks: types.maybeNull(types.number),
    country: types.string,
    impressions: types.maybeNull(types.number),
  },
);

export const SpPerformanceByAppearance = types.model(
  {
    impressions: types.maybeNull(types.number),
    clicks: types.maybeNull(types.number),
    searchappearance: types.string,
    canonicalName: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
  },
);

export type SpPerformanceByAppearanceType = Instance<typeof SpPerformanceByAppearance>;

export const SpPerformanceByDevice = types.model(
  {
    impressions: types.maybeNull(types.number),
    clicks: types.maybeNull(types.number),
    device: types.string,
  },
);

export type SpPerformanceByDeviceType = Instance<typeof SpPerformanceByDevice>;

export const ContentModel = types.model({
  type: types.maybeNull(types.string),
  indexed: types.maybeNull(types.union(types.number, types.string)),
  submitted: types.maybeNull(types.union(types.number, types.string)),
});

export type SiteMapContentType = Instance<typeof ContentModel>;

export const SpSiteMap = types.model(
  {
    path: types.string,
    lastDownloadedDt: types.maybeNull(types.number),
    warningsCount: types.maybeNull(types.number),
    errorsCount: types.maybeNull(types.number),
    submittedCount: types.maybeNull(types.number),
    indexedCount: types.maybeNull(types.number),
    type: types.string,
    lastDownloadedAt: types.string,
    contents: types.array(ContentModel),
  },
);

export type SpSiteMapType = Instance<typeof SpSiteMap>;

export type SpCountryPerformanceType = Instance<typeof SpCountryPerformance>;

export const SpPerformanceReport = types.model({
  performanceByCountry: types.optional(types.array(SpCountryPerformance), []),
  performanceByAppearance: types.optional(types.array(SpPerformanceByAppearance), []),
  performanceByDevice: types.optional(types.array(SpPerformanceByDevice), []),
  sitemap: types.optional(types.array(SpSiteMap), []),
  loading: types.boolean,
}).actions(self => {
  const setFromResponse = (data: any) => {
    self.performanceByCountry = cast(data?.performanceByCountry);
    self.performanceByAppearance = cast(data?.performanceByAppearance);
    self.performanceByDevice = cast(data?.performanceByDevice);
    self.sitemap = cast(data?.sitemaps);
  };

  const doLoadPerformanceReport = flow(function* (selectedProperty: string, countryCode: string) {
    const token = getTokenFromCookies();
    let data = null;
    if (token && selectedProperty) {
      self.loading = true;
      try {
        data = yield reportsApi.getSpPerformanceData(selectedProperty, 40, countryCode);
      } catch (e) {
        useErrorNotification({e, msg: 'Performance Report not actualized', desc: 'The Performance Report data did not load properly', permanent: false});
      } finally {
        self.loading = false;
      }
    }
    return data;
  });

  const loadPerformanceReport = flow(function* (selectedProperty: string, countryCode: string) {
    setFromResponse(yield doLoadPerformanceReport(selectedProperty, countryCode));
  });

  return {
    setFromResponse,
    loadPerformanceReport,
  };
});

export function initSpPerformanceReport() {
  return SpPerformanceReport.create({
    performanceByCountry: [],
    loading: false,
  });
}
