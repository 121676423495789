import styles from '@/components/layoutV2/header/style.module.scss';
import {observer} from 'mobx-react-lite';
import React, {useRef} from 'react';

import {CaretDownOutlined} from '@ant-design/icons';
import {useEffect, useState} from 'react';
import {useTranslation} from 'next-i18next';
import {useStore} from '@/store/root-store';
import {SelectWithSearchKrt} from '@/components/common-components';
import {getDomain} from '@/utils/url';
import {useRouter} from 'next/router';
import styled from 'styled-components';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImageSlash} from '@fortawesome/pro-solid-svg-icons';
import {routes} from '@/utils/const';
import moment from 'moment';

interface Props {
  isDockedSider?: boolean;
  setDropDownSa?: (value)=>void;
  isDropDownSa?: boolean;
}
export const RenderKrtProjectSelect: React.FC<Props> = observer(({isDockedSider, isDropDownSa, setDropDownSa}) => {
  const {t} = useTranslation('common');
  const router = useRouter();
  const selectRef = useRef(null);

  const {
    keywordResearcherV2: {
      keywordRankTracker: {
        rankTrackerList,
        stopRepollingDetails,
        details,
        getListWithoutRepoll,
        setCurrentListPage,
        getRankTrackerCounts,
        currentListPage,
      },
    },
  } = useStore('');
  const antIcon = <LoadingOutlined style={{fontSize: 24, color: '#F1AA3E'}} spin />;
  const popupRef = useRef(null);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && (!popupRef.current.contains(event.target) )) {
        setDropDownSa(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popupRef]);

  const onPropertyChange = (value: string) => {
    stopRepollingDetails(true);
    if (value) {
      const postProcessingUpdatedAt = rankTrackerList?.results?.find(item => item.id == +value)?.postProcessingUpdatedAt;
      const periodEnd = postProcessingUpdatedAt ?
        moment( new Date(postProcessingUpdatedAt).toLocaleString('en-US', {timeZone: 'America/Los_Angeles'}))?.format('YYYY-MM-DD') :
        moment().format('YYYY-MM-DD');
      router.push({pathname: `${routes.keywordRankTracker}/${value}`,
        query: {periodStart: moment(rankTrackerList?.results?.find(item => item.id == +value)?.created)?.format('YYYY-MM-DD'), periodEnd}});

      // stopRepollingDetails(false);
      // getDetails(+value);
      // if (krtCriteria?.currentPeriodStart) {
      //   setLoadingInitKeywordsDetails(true);
      //   getDetailsCoreReports(+value, krtCriteria);
      //   getKeywordsDetails(+value, krtCriteria);
      // }
    }
  };


  const getLastUpdated = (lastUpdatedDate: string) => {
    let label;
    let difference;
    const now = new Date().getTime();
    const lastUpdatedAt = new Date(lastUpdatedDate).getTime();
    difference = (now - lastUpdatedAt) / 1000;

    if (difference < 60) {
      label = 'seconds ago';
    }

    if (difference >= 60 && difference < 3600) {
      difference = Math.round(difference / 60);
      if (difference < 2) {
        label = 'minute ago';
      } else {
        label = 'minutes ago';
      }
    }

    if (difference >= 3600 && difference < 86400) {
      difference = Math.round(difference / 3600);
      if (difference < 2) {
        label = 'hour ago';
      } else {
        label = 'hours ago';
      }
    }

    if (difference >= 86400) {
      difference = Math.round(difference / 86400);
      if (difference < 2) {
        label = 'day ago';
      } else {
        label = 'days ago';
      }
    }

    return `${difference} ${label}`;
  };

  const handlePopupScroll = async (event:any) => {
    // Check if the user has scrolled to the bottom
    const isScrolledToBottom =
      event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (isScrolledToBottom) {
      // getListWithoutRepoll();
      const currentCount = currentListPage*10;
      if (currentCount < getRankTrackerCounts) {
        setLoader(true);
        await setCurrentListPage(currentListPage+1);
        await getListWithoutRepoll();
        setLoader(false);
      }
    }
  };

  const optionRenderKrt = (option: any) => {
    return (
      <div style={{display: 'flex', width: '100%'}}>

        <div style={{marginRight: 8, marginTop: 4}}>
          {option?.hostname ? <img style={{height: 35, width: 35, borderRadius: 5}}
            onError={e => addDefaultSrc(e)}
            src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${option?.hostname && getDomain((!/^(https?:)?\/\//i.test(option?.hostname)) ? 'https:' + option?.hostname : option?.hostname)}&size=64`}/> :
            <div style={{width: '35px', height: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <FontAwesomeIcon icon={faImageSlash} style={{fontSize: '20px', color: '#4E5156'}}/>
            </div>
          }
        </div>
        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
          <div style={{width: '100%'}}>
            <div style={{fontWeight: 500, fontSize: '14px', color: '#FFFFFF'}}>{(option?.hostname)}</div>
            <div style={{fontSize: '12px', color: '#E8E8E8'}}>{option.shouldRepoll ?
              `Processing can take up to 10 minutes.` : `Last crawled: ${option.postProcessingUpdatedAt === null ? 'No keywords added' : getLastUpdated(option.postProcessingUpdatedAt)}`}</div>
          </div>
          {option.shouldRepoll ?
            <span style={{paddingTop: '5px'}}> <Spin indicator={antIcon} /></span> : null}
        </div>

      </div>
    );
  };

  const addDefaultSrc = e => {
    e.target.src = '/icons/image-slash-solid.svg';
  };

  const [timer, setTimer] = useState(null);

  function onSearchHandler() {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        // setParamsBasic({search: value, page_size: 100});
      }, 600),
    );
  }

  const projectToShow = details?.id ?? '';
  const projectToShowHostname = details?.hostname ?? '';

  return (
    <>
      <Wrapper isDropDownSa={isDropDownSa} style={{background: `${isDropDownSa ? 'rgba(84, 86, 90, 0.17)': ''}`, borderRadius: `${isDropDownSa ? '5px': ''}`}} ref={popupRef} onClick={()=>{
        setDropDownSa(!isDropDownSa);
      }} className={styles['header__project-menu']}>
        <AbsoluteDiv>
          <img onError={e => addDefaultSrc(e)} src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${getDomain(projectToShowHostname ? (!/^(https?:)?\/\//i.test(projectToShowHostname)) ?'https:' + projectToShowHostname : projectToShowHostname : '')}&size=64`} />
        </AbsoluteDiv>
        {isDockedSider || isDropDownSa ? <div>
          <HeadingSpan className={!isDockedSider ? styles.close : styles.open}>
            {'KRT Project'}
          </HeadingSpan>
          <SelectedProjectHeading className={!isDockedSider ? styles.close : styles.open} style={{marginBottom: -25}}>
            {projectToShowHostname ?? ''}
          </SelectedProjectHeading>
          <SelectWrapper className={`${styles['header__project-menu-close']} ${!isDockedSider ? styles.opened : styles.closed}`}>
            <SelectWithSearchKrt
              ref={selectRef}
              search={true}
              open={isDropDownSa}
              onPopupScroll={handlePopupScroll}
              onOptionChange={onPropertyChange}
              data={rankTrackerList?.results}
              selectedValue={projectToShow}
              selectedValueHost={projectToShowHostname}
              optionRender={optionRenderKrt}
              optionLabelProp='display'
              placeholder={projectToShow ? projectToShow : t('select-project')}
              handleSearch={onSearchHandler}
              isLoading={loader}
            />
          </SelectWrapper>
          <ArrowIcon className={!isDockedSider ? styles.close : styles.open}>
            <CaretDownOutlined />
          </ArrowIcon></div>:''}
      </Wrapper>
    </>
  );
});

const Wrapper = styled.div<{isDropDownSa: boolean}>`
  position: relative;
  height: 50px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: ${p => p.isDropDownSa ? '1px solid #4e5156 !important' : ''};
  background: rgba(84, 86, 90, 0.17);
  &:hover {
    border: 1px solid #4E5156 !important;
    border-radius: 5px;
  }
  @media screen and (max-width: 860px) {
    margin-right: 0 !important;
  }
`;

const AbsoluteDiv = styled.div`
  width: 40px;
  padding-right: 10px;
  img {
    height: 28px; 
    width: 28px;
  }
`;

const HeadingSpan = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A3A4A4;
  margin-top: 5px;
`;
const SelectedProjectHeading = styled.span`
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectWrapper = styled.div`
  border: 1px solid transparent;
  margin-top: -4px;
`;

const ArrowIcon = styled.div`
  position: absolute;
  right: 8px;
  bottom: 3px;
`;
