import styles from '@/components/layoutV2/header/style.module.scss';
import {
  DatePicker, Dropdown, Menu, Radio,
  Tooltip,
} from 'antd';
import {ChevronDown, ChevronUp} from 'react-feather';
import moment from 'moment';
import {observer} from 'mobx-react-lite';
import {GSC_INSIGHTS_UPDATE_DATE_RANGE} from '@/constants/events';
import {CaretDownOutlined} from '@ant-design/icons';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'next-i18next';

import {useStore} from '@/store/root-store';

import {MixPanel} from '@/utils/mixpanel';
import {ButtonLink} from '@/components/common-components';
import {getFullAccessLink} from '@/utils/legacy';

const {RangePicker} = DatePicker;

export const RenderSiteEventsDateRangePicker = observer(() => {
  const {t} = useTranslation('common');
  const [siteEventDays, setSiteEventDays] = useState('1 Week before - 1 Week after');
  const [relativeDays, setRelativeDays] = useState('oneWeek');
  const [defaultDate, setDefaultDate] = useState([
    moment().subtract(1, 'years').subtract(1, 'days'),
    moment().subtract(1, 'days'),
  ]);
  const [showDropdown, setShowDropdown]=useState(false);
  const popupRef = useRef(null);
  const {gsc,
    navBar: {isDockedSider},
    settings: {customer: {profile}}} = useStore('');

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && (!popupRef.current.contains(event.target) )) {
        setShowDropdown(false);
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popupRef]);
  useEffect(() => {
    if (gsc.sitePropertyEvents.siteEventCriteria.currentPeriodStart) {
      setDefaultDate([
        moment(gsc.sitePropertyEvents.siteEventCriteria.currentPeriodStart),
        moment(gsc.sitePropertyEvents.siteEventCriteria.currentPeriodEnd),
      ]);
    }
  }, [gsc.sitePropertyEvents.siteEventCriteria.currentPeriodStart, gsc.sitePropertyEvents.siteEventCriteria.currentPeriodEnd]);

  const {plan} = profile;
  const ActionsRangePicker = useRef(null);
  const [dateRangeTooltip, setDateRangeTooltip] = useState(false);
  const [dateRangeVisible, setDateRangeVisible] = useState(false);

  useEffect(() => {
    gsc.setSiteEventsRange(siteEventDays);
  }, [siteEventDays]);

  useEffect(() => {
    gsc.setSiteEventsRelativeDays('7');
    window.addEventListener('click', clickHandler);
    return () => {
      window.removeEventListener('click', clickHandler);
    };
  }, []);

  const clickHandler = function(e) {
    const path = e?.path || (e?.composedPath && e?.composedPath());
    const isInPath = path?.includes(ActionsRangePicker.current);
    if (!isInPath) {
      setDateRangeVisible(false);
      setDateRangeTooltip(false);
    }
  };

  const renderTooltip = () =>{
    return (
      <>
        {!gsc?.isBannerVisible ?
          <>
            <p> {t('select-custom-dates')}</p>
            <ButtonLink
              className={styles.upgradeButton}
              href={`/${getFullAccessLink()}`}
              target='_blank'
            >
              {t('upgrade-to-pro')}
            </ButtonLink>
          </> :
          <span>Please wait until data import is completed</span>
        }
      </>
    );
  };

  const dateRangePickerClickHandler = event => {
    event.stopPropagation();
    if (plan) {
      setDateRangeVisible(true);
    } else {
      setDateRangeTooltip(true);
    }
  };

  const handleSEDateChange = (start, end) => {
    const dateDiff = moment(end).diff(moment(start), 'days') + 1;
    const previousPeriodStart = moment(start).subtract(1, 'days').subtract(dateDiff, 'days');
    const previousPeriodEnd = moment(start).subtract(1, 'days');
    gsc.sitePropertyEvents.siteEventCriteriaUpdate({
      ...gsc.sitePropertyEvents.siteEventCriteria,
      previousPeriodStart: previousPeriodStart.format('YYYY-MM-DD'),
      previousPeriodEnd: previousPeriodEnd.format('YYYY-MM-DD'),
      currentPeriodStart: moment(start).format('YYYY-MM-DD'),
      currentPeriodEnd: moment(end).format('YYYY-MM-DD'),
    });
  };

  const setEventRelativeDays = e => {
    setRelativeDays(e.target.value);
    switch (e.target.value) {
      case 'oneWeek': {
        setSiteEventDays('1 Week before - 1 Week after');
        gsc.setSiteEventsRelativeDays('7');
        // const startDate = moment().subtract(2, 'weeks').format('DD MMM YYYY');
        // const endDate = moment().format('DD MMM YYYY');
        // handleSEDateChange(startDate, endDate);
        break;
      }
      case 'twoWeeks': {
        setSiteEventDays('2 Weeks before - 2 Weeks after');
        gsc.setSiteEventsRelativeDays('14');
        // const startDate = moment().subtract(4, 'weeks').format('DD MMM YYYY');
        // const endDate = moment().format('DD MMM YYYY');
        // handleSEDateChange(startDate, endDate);
        break;
      }
      case 'oneMonth': {
        setSiteEventDays('1 Month before - 1 Month after');
        gsc.setSiteEventsRelativeDays('30');
        // const startDate = moment().subtract(2, 'months').format('DD MMM YYYY');
        // const endDate = moment().format('DD MMM YYYY');
        // handleSEDateChange(startDate, endDate);
        break;
      }
      case 'CustomRange': {
        setSiteEventDays(`${moment(defaultDate[0]).format('DD MMM YYYY')} - ${moment(defaultDate[1]).format('DD MMM YYYY')}`);
        gsc.setSiteEventsRelativeDays('');
        handleSEDateChange(defaultDate[0], defaultDate[1]);
        break;
      }
      default:
        setSiteEventDays('');
        break;
    }
  };

  const menu = (
    <Menu>
      <div>Comparison graph range:</div>
      <Radio.Group onChange={setEventRelativeDays} value={relativeDays}>
        <Radio value={'oneWeek'}>{'1 Week before - 1 Week after'}</Radio>
        <Radio value={'twoWeeks'}>{'2 Weeks before - 2 Weeks after'}</Radio>
        <Radio value={'oneMonth'}>{'1 Month before - 1 Month after'}</Radio>
        <Radio value={'CustomRange'}>{'Custom range'}</Radio>
      </Radio.Group>
      <div onClick={e => dateRangePickerClickHandler(e)}>
        <RangePicker
          picker={'date'}
          // open={dateRangeVisible}
          className={styles.pickerHeader}
          style={{background: '#121212', border: 'none', right: '6px', bottom: '1px', position: 'absolute', width: '100%', justifyContent: 'flex-end'}}
          dropdownClassName={isDockedSider ? styles.siteeventdatepickersiteevent : styles.siteeventdatepickersiteeventclose}
          format={'DD MMM YYYY'}
          suffixIcon={dateRangeVisible ? <ChevronUp/>:<ChevronDown/>}
          allowClear={false}
          value={[
            defaultDate[0],
            defaultDate[1],
          ]}
          separator={'-'}
          allowEmpty={[false, false]}
          onCalendarChange={(dates: Array<moment.Moment>, [currentPeriodStart, currentPeriodEnd]: [string, string]) => {
            if (currentPeriodStart && currentPeriodEnd) {
              setSiteEventDays(`${currentPeriodStart} - ${currentPeriodEnd}`);
              setRelativeDays('CustomRange');
              gsc.setSiteEventsRelativeDays('');
              const diff = moment(currentPeriodEnd).diff(moment(currentPeriodStart), 'days') + 1;
              const previousPeriodStart = moment(currentPeriodStart).subtract(1, 'days').subtract(diff, 'days');
              const previousPeriodEnd = moment(currentPeriodStart).subtract(1, 'days');
              gsc.sitePropertyEvents.siteEventCriteriaUpdate({
                ...gsc.sitePropertyEvents.siteEventCriteria,
                previousPeriodStart: previousPeriodStart.format('YYYY-MM-DD'),
                previousPeriodEnd: previousPeriodEnd.format('YYYY-MM-DD'),
                currentPeriodStart: moment(currentPeriodStart).format('YYYY-MM-DD'),
                currentPeriodEnd: moment(currentPeriodEnd).format('YYYY-MM-DD'),
              });

              MixPanel.track(GSC_INSIGHTS_UPDATE_DATE_RANGE);
            }
          }}
          // disabledDate={currentDate => currentDate >= moment().subtract(1, 'days')}
          onOpenChange={(open: boolean) => {
            setDateRangeVisible(open);
          }}
        />
        <span className={styles.rangerCaretNew} style={{position: 'absolute', right: '25px', top: '188px'}}>
          <CaretDownOutlined />
        </span>
      </div>
    </Menu>
  );

  return (
    <div className={styles.rangernew}>
      <div className={`${styles.rangernewButton}`}
        style={dateRangeVisible || showDropdown ? {border: '1px solid #4e5156', background: 'rgba(84, 86, 90, 0.17)', borderRadius: '5px'} : {}}
        ref={ActionsRangePicker}
      >
        <div ref={popupRef} onClick={()=>{
          if (plan) {
            setShowDropdown(!showDropdown);
          } else {
            setDateRangeTooltip(!dateRangeTooltip);
          }
        }} style={{display: 'flex'}}><span style={{marginTop: '7px', marginRight: '13px'}}><img src='/icons/calendarline.svg' width={'25px'}/></span>
          {isDockedSider || showDropdown ?
            <Dropdown visible={plan && showDropdown} trigger={['click']} overlayClassName={isDockedSider ? styles.groupDropdown : styles.groupDropdownclose} placement='bottomLeft' overlay={menu}>
              <div >
                <Tooltip placement='bottom' title={renderTooltip} trigger='click'
                  visible={!plan && dateRangeTooltip}
                  overlayClassName={styles.upgradeTooltip}
                >
                  <span style={{padding: '0px !important', fontSize: '14px', lineHeight: '22px'}} className={`${styles.pickerHeading} ${!isDockedSider ? styles.close : styles.open}`} >
                    {t('comparison-range')}
                  </span>

                  <div className={`${styles.eventsDateValue} ${!isDockedSider ? styles.close : styles.open}`}>
                    <span style={{wordBreak: 'break-all', whiteSpace: 'break-spaces'}}>{siteEventDays}</span>
                  </div>
                </Tooltip>
                <span className={`${styles.rangerCaretNew} ${!isDockedSider ? styles.close: styles.open}`} style={{position: 'absolute', right: '15px', top: '8px'}}>
                  <CaretDownOutlined />
                </span>
              </div>
            </Dropdown> : ''}
        </div>
      </div>
    </div>
  );
});
