import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import pick from 'lodash/pick';
import {NextRouter, useRouter} from 'next/router';

export async function replaceRouterQueryParam(router: NextRouter, params: object) {
  let questionIdx = router.asPath.indexOf('?');
  if (questionIdx === -1) {
    questionIdx = router.asPath.length;
  }
  const pathname = router.asPath.slice(0, questionIdx);
  const query = router.asPath.slice(questionIdx + 1);
  const queryParams = new URLSearchParams(query);
  for (const [key, value] of Object.entries(params)) {
    queryParams.set(key, value);
  }
  return router.push({
    pathname,
    query: queryParams.toString(),
  });
}

export function useSingleQueryParam(name: string): string | undefined {
  const {query} = useRouter();
  const value = query[name];
  if (isString(value)) {
    return value as string;
  }
  if (isArray(value) && value.length > 0) {
    return value[0];
  }
  return undefined;
}

export function useDomainQuery(): string {
  return useSingleQueryParam('q') || '';
}

export async function pushAndKeepQueryParams(router: NextRouter, pathname: string) {
  const paramsToKeep = ['q'];
  const newQuery = pick(router.query, paramsToKeep);
  return router.push({
    pathname,
    query: newQuery,
  });
}

export const openUrl = (url, blank = '') => {
  const link = document.createElement('a');
  link.href = url;
  link.target = blank;
  link.click();
};

export const linkgraphDomains = ['dashboard.linkgraph.com', 'dashboard.linkgraph.io', 'dashboard.searchatlas.com', 'dashboard.searchatlas.io', 'localhost'];
