import styles from './styles.module.scss';
import {useTranslation} from 'next-i18next';
import {getHubSpotLink} from '@/utils/legacy';
import React from 'react';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
interface Props {
  msgText: string | React.ReactNode;
  showGetHelpLink?: boolean;
  getHelpLinkHref?: string;
  getHelpLinkText?: string;
  showContactSupportLink?: boolean;
  contactSupportLinkText?: string;
}

export const NoDataBanner = observer(({
  msgText,
  showGetHelpLink = false,
  getHelpLinkHref = getHubSpotLink(),
  getHelpLinkText = 'Schedule a free consultation',
  showContactSupportLink = false,
  contactSupportLinkText = ' contact our support team',
}: Props) => {
  const {settings: {customer: {profile: {isWhitelabel}}}} = useStore('');
  const openLiveChat = () => {
    const chatBtn: HTMLElement = document.querySelector('.cc-unoo') as HTMLElement;
    if (chatBtn) {
      chatBtn.click();
    }
  };
  const {t} = useTranslation('common');

  return (
    <div className={styles.noDataBanner}>
      <span>
        <img src='/icons/faq1.svg' />
      </span>
      <span>
        <span><>{msgText}</></span>
        {showGetHelpLink && !isWhitelabel &&
          <a href={getHelpLinkHref} target='_blank' rel='noreferrer'>{getHelpLinkText}</a>
        }
        {showContactSupportLink && !isWhitelabel && <span className={styles.supportLink} onClick={() => openLiveChat()}>{contactSupportLinkText === ' contact our support team' ? t('contact-our-support-team'): contactSupportLinkText }</span>}
      </span>
    </div>
  );
});
