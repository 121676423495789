import styles from '@/components/layoutV2/header/style.module.scss';
import {Col, Row, Spin, Tag, Tooltip} from 'antd';
import React from 'react';
import {observer} from 'mobx-react-lite';
import {Fragment, useEffect, useRef} from 'react';
import {useTranslation} from 'next-i18next';

import {useStore} from '@/store/root-store';
import Flag from '@/components/common-components/components/flag';
import styled from 'styled-components';

import {MixPanel} from '@/utils/mixpanel';
import {
  setCountryCodeToLocalStorage,
  setLastPropertyIntoCookies,
} from '@/api/common-utils';
import {useRouter} from 'next/router';
import {GSC_PROJECT_CHANGED} from '@/constants/events';
import {toJS} from 'mobx';
import {getDomain, getSingleUrlParam} from '@/utils/url';
import {Button, SelectWithSearch} from '@/components/common-components';
import {CaretDownOutlined, LoadingOutlined} from '@ant-design/icons';
import {PropertyType} from '@/store/nav-bar';
import numeral from 'numeral';
import classnames from 'classnames';
import {numberWithCommas} from '@/utils/number';
import {Globe} from 'react-feather';
import {tagsRegex} from '@/constants/regex';
import {ManageGoogleAnalyticsData} from './manageGoogleAnalyticsData';

 interface Props{
  isDockeSideBar?:boolean;
  setDropDownSa?: (value)=>void;
  isDropDownSa?: boolean;
 }

export const RenderSitePropertySelect:React.FC<Props> = observer(({isDockeSideBar, isDropDownSa, setDropDownSa}) => {
  // const {settings: {customer: {profile: {isLinkgraph}}}} = useStore('');
  const {countries: {getSpecificCountryByCode}, gsc, gsc: {googleAnalytics: {setGaDataModal, gaDataModal}, criteria, coreReports: {hideShowBanner}}, siteAuditorV2: {setLoadProjectDetails}, siteAuditor: {customerSiteAudit}, navBar} = useStore('');
  const {t} = useTranslation('common');

  const router = useRouter();
  // const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && (!popupRef.current.contains(event.target) )) {
        if (typeof setDropDownSa !== 'undefined') {
          setDropDownSa(false);
        }
      }
    }
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [popupRef]);

  const appendParams = (value, countryCode?: string) => {
    let params = '';
    const checkTable = getSingleUrlParam('table');
    if ('sp' in router.query) {
      delete router.query['sp'];
    }
    if ('country_code' in router.query) {
      delete router.query['country_code'];
    }
    if ('table' in router.query) {
      delete router.query['table'];
    }
    router.query['sp'] = value;
    if (countryCode || countryCode !== undefined) {
      router.query['country_code'] = countryCode;
    }
    if (checkTable) {
      router.query['table'] = checkTable;
    }
    Object.keys(router.query).forEach(param => {
      if (param !== 'slug') {
        if (!params) {
          params = `?&${param}=${router.query[param]}`;
        } else {
          params += `?&${param}=${router.query[param]}`;
        }
      }
    });
    return params;
  };


  const getProjectName = (property: string) => {
    return property ? (property.startsWith('sc-domain:') ? property.split('sc-domain:')[1] : property) : '';
  };

  const onPropertyChange = async (value: string) => {
    const index = inActiveUrls.findIndex(item => item.url === value);
    const newCountryCode = navBar.getSiteProperty(value);
    setLoadProjectDetails(false);
    if (newCountryCode.activeForCurrentCustomer) {
      const matchPreviousCountryCode = newCountryCode?.activeCountryCodes?.slice()?.length ? newCountryCode?.activeCountryCodes?.slice()?.find(item => item == criteria?.countryCode) : '';
      const countryCodeToApply = matchPreviousCountryCode ? matchPreviousCountryCode : (newCountryCode?.activeCountryCodes?.slice()?.length ? newCountryCode?.activeCountryCodes[0] : '');
      // if (index > -1) {
      //   inActiveUrls.splice(index, 1);
      // }
      if (value) {
        if (index == -1) {
          hideShowBanner();
        }
        if (customerSiteAudit?.isNotComplete) {
          // window.location.replace(`${location}${appendParams(value)}`);
          router.push(
            `${location.pathname}${appendParams(value)}`,
            undefined,
            {shallow: true},
          );
        } else {
          router.push(
            `${location.pathname}${appendParams(value, countryCodeToApply)}`,
            undefined,
            {shallow: true},
          );
        }


        gsc.sitePropertyEvents.siteEventCriteriaUpdate({
          ...gsc.sitePropertyEvents.siteEventCriteria,
          property: value,
          countryCode: countryCodeToApply,
        });

        criteria.updateCriteria({
          ...criteria,
          property: value,
          countryCode: countryCodeToApply,
        });
        setLastPropertyIntoCookies(value);
        setCountryCodeToLocalStorage(countryCodeToApply);

        MixPanel.track(GSC_PROJECT_CHANGED, {
          project: value,
        });
      }
    } else {
      navBar?.setIsActivatePropertiesDrawerOpen();
    }
  };

  const projectName = getProjectName(criteria.property);
  const inActiveUrls = [];
  const data = toJS(navBar.propertiesData)?.map(sp => {
    if (navBar.inactiveSiteproperties.includes(sp.id)) {
      inActiveUrls.push({id: sp.id, url: sp.url});
    }
    return {
      name: getDomain(sp.url),
      value: sp.url,
      raw: sp,
    };
  });

  const parseProject = (url: string, keywords: number, topPages: number, traffic) => {
    const match = url.match(tagsRegex);
    return {
      url: url.replace(tagsRegex, '').replace(/\/$/, ''),
      tag: match?.[0] ?? 'Domain',
      keywords: numberWithCommas(keywords),
      topPages: topPages,
      traffic: traffic,
    };
  };
  const addDefaultSrc = e => {
    e.target.src = '/img/world-wide-web-white.svg';
  };

  const openGoogleAnalyticsModalHandler = (e, url: string, isGaConnected: boolean, settingId: number, settingsEnabled: boolean, hasSiteGaCredentials: boolean) => {
    e.stopPropagation();
    setGaDataModal({
      ...gaDataModal,
      visible: true,
      siteUrl: url,
      gaConnected: isGaConnected,
      settingId: settingId,
      settingsEnabled: settingsEnabled,
      hasSiteGaCredentials: hasSiteGaCredentials,
    });
    if (typeof setDropDownSa !== 'undefined') {
      setDropDownSa(false);
    }
  };

  const optionRender = (option: PropertyType) => {
    const isGSCForPropertyConnected = option.activeCountryCodes.includes(criteria?.countryCode);
    const complete = isGSCForPropertyConnected ?
      option.saPctProgress?.find(item => item?.countryCode == criteria?.countryCode)?.value > 1 ? 100 : parseFloat(numeral(option.saPctProgress?.find(item => item?.countryCode == criteria?.countryCode)?.value).format('0%').replace('%', '')) : 100;
    const getProgressStatus = () => {
      return complete > 0 ? complete === 100 ? t('ready') : `${t('loading')} ${complete}%` : t('none');
    };

    const projectName = getProjectName(option.url);
    const firstActivecountryCode = option.activeCountryCodes[0];
    const projectKwCount = option.saTotalKwCount?.find(item => item?.countryCode == firstActivecountryCode)?.value;
    // const trafficValue = option?.trafficValue?.find(item => item?.countryCode == store?.gsc?.criteria?.countryCode)?.value;
    const pages = option?.totalPages?.find(item => item?.countryCode == firstActivecountryCode)?.value;
    const {url, tag, keywords, topPages, traffic} = parseProject(projectName, projectKwCount as number, pages as number, option?.trafficValue);
    const countriesTrafficData = traffic?.map(item => ({
      code: item?.countryCode,
      value: item?.value,
      pctProgress: option.saPctProgress?.find(x => x?.countryCode == item?.countryCode)?.value,
      ...getSpecificCountryByCode(item?.countryCode),
    }))?.sort((a, b) => b.value - a.value);
    // collect countries with complete data
    const countriesTrafficDataOne = countriesTrafficData.filter(item => item.pctProgress == 1);
    // collect countries with importing data
    const countriesTrafficDataZero = countriesTrafficData.filter(item => item.pctProgress != 1 && option.activeCountryCodes.includes(item.code));

    const tooltipContent = tag !== 'Domain' ? (
      <ul>
        <li>{t('includes')} <span>{t('under-entered-address')}</span></li>
        <li>{t('includes')} <span>{t('under-specified-protocol')}</span> </li>
        <li>{t('allows')} <span>{t('multiple-verification-methods')}</span></li>
      </ul>
    ) : (
      <ul>
        <li>{t('includes')} <span>{t('urls-across-subdomains')}</span></li>
        <li>{t('includes')} <span>{t('url-across-http')}</span> </li>
        <li>{t('requires')} <span>{t('dns-verification')}</span></li>
      </ul>
    );

    // const trim = (text: string, size: number):string => {
    //   return text && text.length > size ? `${text.slice(0, size)}...` : text;
    // };

    const antIcon = <LoadingOutlined style={{fontSize: 16, marginLeft: '5px', color: '#F1AA3E'}} spin />;
    const classes = [styles.Tooltip, styles.TagToolTip];
    return (
      <Fragment>
        <Row gutter={23} style={{alignItems: 'center'}} className={classnames(styles.options, !option.activeForCurrentCustomer ? styles.optionDisable : '')}>
          <Col span={17} className={styles.TagContainer}>
            <div>
              <div className={styles.projectBox}>
                <img onError={e => addDefaultSrc(e)}
                  src={option.favicon ? option.favicon : `https://www.google.com/s2/favicons?sz=64&domain_url=${getDomain(option.url)}`}
                  className={navBar.propertiesData.filter(item => item.activeForCurrentCustomer).map(item => item.id).includes(option?.id) ? '' : styles.disableImg}/>
                <div>
                  <div className={styles.topPropertyInfoContainer}>
                    <Tooltip placement='bottom' trigger='hover' title={tooltipContent} overlayClassName={classes.join(' ')}>
                      {
                        option.activeForCurrentCustomer &&
                        !option?.activeCountryCodes.includes(criteria?.countryCode) ?
                          complete == 100 ? <Tag className={styles.tag}>{tag}</Tag> : null : <Tag className={styles.disableTag}>{tag}</Tag>
                      }
                    </Tooltip>
                    {/* wrap all with isLinkgraph ? ....  : null to hide from users*/}
                    <>
                      {isGSCForPropertyConnected ? <div className={styles.gscLabel}><span className={styles.labelDot}>&#x2022;</span> GSC</div> : null}
                      {option.isGaConnected ? <div className={styles.gaLabel}>&#x2022; GA</div> :
                        <div className={styles.gaDisconnected}><span className={styles.labelDot}>&#x2022;</span> <span onClick={e => openGoogleAnalyticsModalHandler(e, option.url, option.isGaConnected, option.activatedAnalyticsSettingsId, option.isGaSettingsActive, option?.hasSiteGaCredentials)}>Enable GA</span></div>}
                    </>
                    {/* end wrap here */}

                  </div>


                  {option.activeForCurrentCustomer ? <div className={styles.url}>{url}</div> : <div className={styles.disableUrl}>{url}</div>}
                  {option.activeForCurrentCustomer?
                    <>
                      {/* Display data for Active Site property */}

                      <span className={styles.tag} style={{paddingRight: '3px', paddingLeft: '0px'}}>{`${keywords ?? '-'} ${t('keywords')}`}</span><span style={{marginLeft: '3px'}} className={styles.dot} />
                      <span className={styles.tag} style={{paddingRight: '3px', paddingLeft: '0px'}}>{`${topPages ? numberWithCommas(topPages) : 0} ${t('pages')}`}</span>
                      <div style={{marginTop: '-5px'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          {countriesTrafficDataOne?.map((item, index) => {
                            return <span key={index} className={item?.code == '' ? styles.globalIcon : ''} style={{opacity: option.activeCountryCodes.includes(item?.code) ? '100%' : '40%'}}>
                              {item?.code == '' ? <Globe style={{height: '14px', width: '14px', color: '#fff', opacity: option.activeCountryCodes.includes(item?.code) ? '100%' : '40%'}}/> :
                                <Flag style={{width: '16px', height: '16px', opacity: option.activeCountryCodes.includes(item?.code) ? '100%' : '40%'}} code={item?.code}/>}
                            </span>;
                          })}
                          { countriesTrafficDataZero.length > 0 && <span style={{color: '#A3A4A4', marginRight: 10, marginTop: 10}}>Importing</span> }
                          { countriesTrafficDataZero?.map((item, index) => {
                            return <span key={index} className={item?.code == '' ? styles.globalIcon : ''} style={{filter: 'grayscale(100%)'}}>
                              {item?.code == '' ? <Globe style={{height: '14px', width: '14px', color: '#fff', filter: 'grayscale(100%)'}}/>: <Flag style={{width: '16px', height: '16px', filter: 'grayscale(100%)'}} code={item?.code}/>}
                            </span>;
                          })}
                          {countriesTrafficDataZero.length > 0 && <Spin style={{marginTop: 10}} indicator={antIcon} />}
                        </div>
                      </div></> :
                    <>
                      {/* Display keywords and Pages data for Inactive Site property */}
                      <div style={{marginTop: '-5px'}}>
                        <span className={styles.disableTag} style={{paddingRight: '3px', paddingLeft: '0px'}}><span className={styles.dot} />{`${keywords ?? '-'} ${t('keywords')}`}</span>
                        <span className={styles.disableTag} style={{paddingRight: '3px', paddingLeft: '0px'}}><span className={styles.dot} />{`${topPages ? numberWithCommas(topPages) : 0} ${t('pages')}`}</span>
                      </div>
                    </>
                  }
                  {option.activeForCurrentCustomer ? null : <span className={styles.disableTag}>{t('inactive')}</span>}
                </div>
              </div>
            </div>
          </Col>
          {!option.activeForCurrentCustomer && complete == 100 ? (
            <Button onClick={event => {
              event.stopPropagation();
              navBar?.setIsActivatePropertiesDrawerOpen();
            }} className={styles.activate}>{t('activate')}</Button>
          ): (
            <Col span={5} style={{display: 'flex'}}>
              <Tooltip placement='right' title={`${t('gsc-import')}: ${getProgressStatus()}`} overlayClassName={styles.Tooltip}>
                <div className={styles.ProgressIconContainer}>
                  <div className={styles.ComplitedIcon} />
                  <div className={styles.InProgressIcon} style={{height: `${100 - complete}%`}} />
                </div>
              </Tooltip>
              {/* wrap all with isLinkgraph ? ....  : null to hide from users*/}
              {option.isGaConnected ?
                <Tooltip placement='right' title={option.isGaSettingsActive ? `Google Analytics connected and enabled.` : `Google Analytics connected, but not enabled.`} overlayClassName={styles.Tooltip}>
                  <div className={styles.gaIconContainer} onClick={e => openGoogleAnalyticsModalHandler(e, option.url, option.isGaConnected, option.activatedAnalyticsSettingsId, option.isGaSettingsActive, option?.hasSiteGaCredentials)}>
                    {option.isGaSettingsActive ? <img className={styles.gaIconColor} src='/img/icon/analytics_icon.svg' /> : <img className={styles.gaIcon } src='/img/icon/analytics_icon_white.svg' /> }
                  </div>
                </Tooltip> :
                <Tooltip placement='right' title={`Google Analytics not connected.`} overlayClassName={styles.Tooltip}>
                  <div className={styles.gaIconContainer} onClick={e => openGoogleAnalyticsModalHandler(e, option.url, option.isGaConnected, option.activatedAnalyticsSettingsId, option.isGaSettingsActive, option?.hasSiteGaCredentials)}>
                    <img className={classnames(styles.gaIcon, styles.gaIconGray)} src='/img/icon/analytics_icon_white.svg' />
                  </div>
                </Tooltip>}
            </Col>
          )}
        </Row>
      </Fragment>
    );
  };

  return (
    <>
      <ManageGoogleAnalyticsData />
      <WrapperStyled style={{border: `${isDropDownSa ? '1px solid #4e5156': ''}`, background: `${isDropDownSa ? 'rgba(84, 86, 90, 0.17)': ''}`, borderRadius: `${isDropDownSa ? '5px': ''}`}}
        ref={popupRef} onClick={()=>{
          if (typeof setDropDownSa !== 'undefined') {
            setDropDownSa(!isDropDownSa);
          }
        }}>
        <AbsoluteDiv >
          <img onError={e => addDefaultSrc(e)} src={`https://www.google.com/s2/favicons?sz=64&domain_url=${getDomain(projectName)}`} />
        </AbsoluteDiv>
        <div>
          <Heading className={!isDockeSideBar ? styles.close : styles.open}>{t('gsc-project')}</Heading>
          <div className={`${styles['header__project-menu-close']} ${!isDockeSideBar ? styles.opened : styles.closed}`}>
            <SelectWithSearch
              open={isDropDownSa}
              onOptionChange={onPropertyChange}
              data={data}
              optionRender={optionRender}
              optionLabelProp='display'
              // onDropdownVisibleChange={(open: boolean) => setIsOpen(open)}
              placeholder={navBar?.hasSiteProperties ? (getDomain(projectName) || 'Select Project') : null}
              listHeight={450}
            />
          </div>
          <ArrowIcon className={!isDockeSideBar ? styles.close : styles.open}>
            <CaretDownOutlined />
          </ArrowIcon>
        </div>
      </WrapperStyled>
    </>
  );
});

const WrapperStyled = styled.div`
  position: relative;
  height: 50px;
  padding-left: 7px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  cursor: pointer;
  background: rgba(84, 86, 90, 0.17);
  &:hover {
    border: 1px solid #4E5156 !important;
    border-radius: 5px;
  }
`;

const AbsoluteDiv = styled.div`
  width: 40px;
  padding-right: 10px;
  img {
    height: 28px; 
    width: 28px;
  }
`;

const Heading = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A3A4A4;
  margin-top: 5px;
`;

const ArrowIcon = styled.div`
  position: absolute;
  right: 8px;
  bottom: 3px;
  color: #fff;
`;
