import {useCallback, useEffect, useRef, useState} from 'react';
import {Divider, Form} from 'antd';
import {observer} from 'mobx-react';
import {useStore} from '@/store/root-store';
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {toJS} from 'mobx';
import {isContentGeneratorPublic} from '@/utils/url';
import {MainSection} from './DraggableStructure/MainSection';
import {debounce} from 'lodash';
import {ContentState, EditorState, Modifier, SelectionState} from 'draft-js';

interface Props {
  htmlToDraft: any;
  pageId: any;
  drawerVisible: boolean;
  selectedContentLength: 'short' | 'medium' | 'long';
  selectedToneOfVoice: string;
  selectedLanguage: string;
  selectedPointOfView: string;
  setSelectedContentLength: (value: 'short' | 'medium' | 'long') => void;
  setSelectedToneOfVoice: (value: string) => void;
  setSelectedLanguage: (value: string) => void;
  setSelectedPointOfView: (value: string) => void;
  setIsContentOverwritten: (value: boolean) => void;
}

export const CompleteSeoArticleForm = observer(({
  htmlToDraft,
  pageId,
  drawerVisible,
  selectedContentLength,
  selectedToneOfVoice,
  selectedLanguage,
  selectedPointOfView,
  setSelectedContentLength,
  setSelectedToneOfVoice,
  setSelectedLanguage,
  setSelectedPointOfView,
  setIsContentOverwritten,
}: Props) => {
  const {contentOptimizer: {currentPage, aiOutline}} = useStore('');

  const [usedTerms, setUsedTerms] = useState('');
  const [termsToInclude, setTermsToInclude] = useState([]);
  const [tableCheck, setTableCheck] = useState(false);
  const [listCheck, setListCheck] = useState(false);
  const [teaseCheck, setTeaseCheck] = useState(false);
  const [imagesCheck, setImagesCheck] = useState(false);
  const [conclusionCheck, setConclusionCheck] = useState(false);
  const [boostDettectionCheck, setBoostDettectionCheck] = useState(false);
  const [addGlobalBackgroundCheck, setAddGlobalBackgroundCheck] = useState(false);
  const [literaryDevicesCheck, setLiteraryDevicesCheck] = useState(false);
  const [keyTakawaysCheck, setKeyTakeawaysCheck] = useState(false);
  const [disableSkinnyParagraphCheck, setDisableSkinnyParagraphCheck] = useState(false);
  const [customToneOfVoice, setCustomToneOfVoice] = useState('');
  const [shouldOverwriteContent, setShouldOverwriteContent] = useState(true);
  const [globalBackgroundDesc, setGlobalBackgroundDesc] = useState('');

  const termsRef = useRef(null);


  const [form] = Form.useForm();
  useEffect(() => {
    if (!drawerVisible) form.resetFields();
  }, [drawerVisible]);

  useEffect(() => {
    if (!isContentGeneratorPublic()) {
      // methods for standalone tool and regular tool are different
      // in regular editor drawer we send page uuid, not the case with stanalone tool
      if (aiOutline?.aiWriterController?.isStandaloneTool) aiOutline.loadAiOutlineHistoryStandalone();

      if (!aiOutline?.aiWriterController?.isStandaloneTool) aiOutline.loadAiOutlineHistory(currentPage?.content?.uuid);
    }
  }, []);


  // chekcs if focus terms input is in focus or not
  const myFunction = () => {
    if (document.activeElement === termsRef?.current?.input) {
      if (!usedTerms || termsToInclude?.includes(usedTerms.trim())) return;
      const updatedTerms = [usedTerms.trim(), ...termsToInclude];
      setTermsToInclude(updatedTerms);
      setUsedTerms('');
      form.setFieldsValue({
        'termsToInclude': '',
      });
    }
  };

  // adds event hendler on enter key press
  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();

        myFunction();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [usedTerms]);

  const debouncedChange = debounce((state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations = false) => {
    // we are then calling the edit content method which will fire the API call and send new state to the BE
    currentPage.editContent(state, isNewDecorations);
  }, 2000);


  const debouncedUpdate = useCallback(
    (state: EditorState, focusTerms, focusTermsClassNames, isNewDecorations) => debouncedChange(state, focusTerms, focusTermsClassNames, isNewDecorations),
    // eslint-disable-next-line
        []
  );

  const insertText = async (outlineParam: any, id: number, shouldReplace?: boolean) => {
    // NEW FLOW start
    const {contentBlocks, entityMap} = htmlToDraft(outlineParam);

    const selection = currentPage.currentEditorState.getSelection();


    const newSelection = !shouldReplace ?
      new SelectionState({
        anchorKey: selection.getAnchorKey(),
        anchorOffset: selection.getEndOffset(),
        focusKey: selection.getAnchorKey(),
        focusOffset: selection.getEndOffset(),
      }) :
      selection.merge({
        anchorKey: currentPage.currentEditorState.getCurrentContent().getFirstBlock().getKey(),
        anchorOffset: 0,
        focusOffset: currentPage.currentEditorState.getCurrentContent().getLastBlock().getText().length,
        focusKey: currentPage.currentEditorState.getCurrentContent().getLastBlock().getKey(),
      });

    const fragment = ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap();

    const nonStyledState = Modifier.replaceWithFragment(
      currentPage.currentEditorState.getCurrentContent(),
      newSelection,
      fragment,
    );

    // WE are calculating nodes to toggle blue background on new Ai generated nodes
    let firstNewBlockId: number;
    const currentBlock = selection.getAnchorKey();
    const nextBlock = currentPage.currentEditorState.getCurrentContent().getBlockAfter(selection.getAnchorKey())?.getKey();

    const existingNodes = document.querySelectorAll('[data-block="true"]');
    existingNodes?.forEach((elem, id) => {
      if (elem.getAttribute('data-offset-key') && elem.getAttribute('data-offset-key') == `${currentBlock}-0-0`) {
        firstNewBlockId = id + 1;
      }
    });


    const updatedState = shouldReplace ?
      EditorState.createWithContent(nonStyledState) :
      EditorState.push(currentPage.currentEditorState, nonStyledState, 'insert-fragment');

    currentPage.setCurrentEditorState(updatedState);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('[data-block="true"]');
      if (newTextElems.length) {
        for (let i = firstNewBlockId; i <= newTextElems.length; i++) {
          if (newTextElems[i]?.getAttribute('data-offset-key') && newTextElems[i]?.getAttribute('data-offset-key') != `${nextBlock}-0-0` && newTextElems[i]?.getAttribute('data-offset-key') != `${currentBlock}-0-0`) {
            newTextElems[i].classList.add('newTextBlock');
          } else {
            return;
          }
        }
      }
    }, 500);

    debouncedUpdate(updatedState, currentPage.analytics?.focusTerms, currentPage.focusTermsClassNames, false);

    setTimeout(() => {
      const newTextElems = document.querySelectorAll('.newTextBlock');
      if (newTextElems.length) {
        newTextElems.forEach(elem => {
          elem.classList.remove('newTextBlock');
        });
      }
    }, 3500);
    // NEW FLOW END
    aiOutline?.updateSingleOutline(true, id);
  };


  return (
    <>
      <Header>
        <HeaderTop>
          <BackArrowContainer onClick={() => {
            aiOutline?.setAiWriter({
              ...toJS(aiOutline?.aiWriterController ?? []),
              showForm: false,
              templateIcon: '',
              templateDesc: '',
              templateName: '',
              isStandaloneTool: false,
            });
            aiOutline?.clearOneClickOutlines();
          }}>
            <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 10}}/>
            Back to templates
          </BackArrowContainer>


        </HeaderTop>
        <DescContainer>
          <ImgContainer>
            <img src={`/img/icon/${aiOutline.aiWriterController.templateIcon}`} style={{height: 25, margin: 'auto'}}/>
          </ImgContainer>
          <div>
            <TemplateName>{aiOutline.aiWriterController?.templateName}</TemplateName>
            <TemplateDesc>{aiOutline.aiWriterController?.templateDesc}</TemplateDesc>
          </div>

        </DescContainer>
      </Header>
      <Divider style={{width: 50, minWidth: 50, marginTop: 20, marginBottom: 20}}/>

      <MainSection
        pageId={pageId}
        tableCheck={tableCheck}
        setTableCheck={setTableCheck}
        listCheck={listCheck}
        setListCheck={setListCheck}
        teaseCheck={teaseCheck}
        setTeaseCheck={setTeaseCheck}
        boostDettectionCheck={boostDettectionCheck}
        setBoostDettectionCheck={setBoostDettectionCheck}
        addGlobalBackgroundCheck={addGlobalBackgroundCheck}
        setAddGlobalBackgroundCheck={setAddGlobalBackgroundCheck}
        literaryDevicesCheck={literaryDevicesCheck}
        setLiteraryDevicesCheck={setLiteraryDevicesCheck}
        keyTakawaysCheck={keyTakawaysCheck}
        setKeyTakeawaysCheck={setKeyTakeawaysCheck}
        disableSkinnyParagraphCheck={disableSkinnyParagraphCheck}
        setDisableSkinnyParagraphCheck={setDisableSkinnyParagraphCheck}
        selectedToneOfVoice={selectedToneOfVoice}
        setSelectedToneOfVoice={setSelectedToneOfVoice}
        selectedPointOfView={selectedPointOfView}
        setSelectedPointOfView={setSelectedPointOfView}
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
        selectedContentLength={selectedContentLength}
        setSelectedContentLength={setSelectedContentLength}
        customToneOfVoice={customToneOfVoice}
        setCustomToneOfVoice={setCustomToneOfVoice}
        replaceTextInEditor={insertText}
        shouldOverwriteContent={shouldOverwriteContent}
        setShouldOverwriteContent={setShouldOverwriteContent}
        globalBackgroundDesc={globalBackgroundDesc}
        setGlobalBackgroundDesc={setGlobalBackgroundDesc}
        setIsContentOverwritten={setIsContentOverwritten}
        conclusionCheck={conclusionCheck}
        setConclusionCheck={setConclusionCheck}
        imagesCheck={imagesCheck}
        setImagesCheck={setImagesCheck}
      />
    </>
  );
});

const Header = styled.div`

`;
const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ImgContainer = styled.div`
  display: flex;
  width: 38px;
  height: 38px;
  margin-right: 13px;
`;
const DescContainer = styled.div`
  max-width: 80%
`;
const TemplateName = styled.div`
  font-weight: 600;
  font-size: 20px;
  color: #121212;
  margin-bottom: 4px;
`;
const TemplateDesc = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: #4E5156;
`;
const BackArrowContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

