import React from 'react';
import {EChartOption} from 'echarts';
import ReactEcharts from 'echarts-for-react';
import {observer} from 'mobx-react-lite';
import {TooltipContent, TooltipItem, TooltipSimpleText} from '../../../site-explorer-page/detail-page/StyledComponent';
import {numberWithCommas} from '@/utils/number';
import {renderToString} from 'react-dom/server';
// import {renderToString} from 'react-dom/server';
// import moment from 'moment';

interface Props {
  color?: string;
  data?: any;
  xAxisData?: any[];
  loading?: boolean;
  seriesName?: string;
  inverted?: boolean;
  chartWidth?: string;
  tooltipName?: string;
  chartHeight?: string;
  gridBottom?: number;
  tooltipText?: string;
  tooltipPositionTop?: string;
  boundaryGap?: boolean;
}
export const LineChart: React.FC<Props> = observer(({inverted=false, color, tooltipName='', tooltipPositionTop='110%', tooltipText, gridBottom=30, data, xAxisData, loading, seriesName='', chartWidth='70%', chartHeight='60px', boundaryGap=true}) => {
  const toolTipFormatterLineChart = data => {
    const element = (tooltipText ? <TooltipContent>
      <TooltipSimpleText>{tooltipText}</TooltipSimpleText>
    </TooltipContent> : <TooltipContent>
      <TooltipItem>
        {data?.map((item, idx) => {
          return <TooltipItem key={idx} style={{display: 'block'}}>
            <span style={{marginLeft: 0, color: '#121212'}}>{data[0]?.data?.date}</span>
            <div style={{display: 'flex'}}>
              <div dangerouslySetInnerHTML={{__html: item?.marker}} />
              {tooltipName || item?.seriesName}:
              <span style={{marginLeft: 5, color: '#121212'}}>{numberWithCommas(item?.data.value)}</span>
            </div>
          </TooltipItem>;
        })}
      </TooltipItem>
    </TooltipContent>
    );
    return renderToString(element);
  };


  const dummyData = () => {
    const array = [];
    for (let i = 0; i <= 70; i++) {
      if (i % 8 === 0) {
        array.push(3500);
      } else {
        if (i % 2 === 0) {
          array.push(3000);
        } else array.push(3250);
      }
    }
    return array;
  };

  const chartData: EChartOption = {
    tooltip: {
      show: true,
      appendToBody: true,
      trigger: 'axis',
      formatter: toolTipFormatterLineChart,
      position: function(point) {
        return [Number(point[0])-100, tooltipPositionTop];
      },
    },

    xAxis: {
      data: loading ? dummyData() : xAxisData,
      type: 'category',
      show: true,
      boundaryGap: boundaryGap,
    },

    yAxis: {
      type: 'value',
      show: true,
      inverse: inverted,
    },
    legend: {
      show: false,
    },
    grid: {
      bottom: gridBottom,
      top: 0,
      left: 0,
      right: 0,
    },
    series: [
      {
        name: seriesName,
        symbol: 'none',
        data: loading ? dummyData() :data,
        type: 'line',
        itemStyle: {
          color: loading ? '#424549' : color ? color : '#F1AA3E',
        },
        lineStyle: {color: loading ? '#424549' : color ? color : '#F1AA3E'},
      },
    ],
  };

  return (
    <div>
      <ReactEcharts
        style={{width: chartWidth, height: chartHeight, marginTop: '0px'}}
        option={chartData}
      />
    </div>
  );
});
