import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {COLORS} from '@/components/common-components/v2/colors';
import {formatNumber, getPercentage, numberWithCommas} from '@/utils/number';
import {faSquareQuestion, faXmark, faLockKeyhole, faCoins} from '@fortawesome/pro-duotone-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip, Progress, Divider, Popover, Input} from 'antd';
import styles from '../style.module.scss';
import styled from 'styled-components';
// import {ButtonColor} from './Button';
import {getInversePercentageColor} from '@/utils/colors';
import {premiumAiQuota} from '@/utils/icons';
import {useStore} from '@/store/root-store';
import {Button} from '@/components/common-components/v2';
import {PurchasedQuota} from '../components/badge';

interface Guide {
  title: string;
  value: number | string;
}


interface Props {
  consumedData: number;
  totalData: number;
  heading?: string;
  description?: string;
  isCenter?: boolean;
  showGuide?: boolean;
  guideData?: Guide[];
  isConsemed?:string;
  isRemaining?:string;
  isTotal?:string;
  isNoProgressBar?:boolean;
  isKrPublic?:boolean;
  showTopupButton?:boolean;
  showPurchasedQuota?: number | boolean;
}

export const AiQuotaInfoDetail: React.FC<Props> = observer(({
  consumedData,
  totalData,
  heading,
  isCenter,
  description,
  showGuide,
  isConsemed,
  isRemaining,
  isTotal,
  guideData,
  isNoProgressBar,
  isKrPublic,
  showTopupButton,
  showPurchasedQuota,
}) => {
  const difference = totalData - consumedData;
  const {
    contentOptimizer: {
      aiOutline: {
        addTopUpQuote,
        loadingAiQuota,
        topUpVisibility,
        setTopUpVisibility,
        setTopUpBulkVisibility,
        setShowDesc,
      },
    },
  } = useStore('');

  const {
    settings: {customer: {getCustomerQuota}},
  } = useStore('');


  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addQuota, setAddQuota] = useState(1);
  const [isTooltip, setIsToolTip] = useState(false);

  const popoverContent = (<div style={{position: 'relative', padding: '0 15px 0 15px'}}>
    <IconWrapper>
      {premiumAiQuota()}
    </IconWrapper>
    <PopoverTitle>Top-up Full Article Generation Quota</PopoverTitle>
    <PopoverDesc>Keep AI generating undetectable, SEO optimized articles.</PopoverDesc>
    <InputWrapper>
      <Popover
        content={'Specify the amount of Full Article Generation Quota points you wish to add.'}
        trigger='click'
        visible={isTooltip}
        onVisibleChange={e=> isTooltip && setIsToolTip(e)}
      >
        <InputQuota
          type='number'
          minLength={0}
          placeholder='10 Points'
          value={addQuota}
          addonBefore={'Points'}
          onChange={(e: any) => {
            const inputValue = Number(e.target.value);
            if (inputValue < 0 || inputValue % 1 !== 0) {
              return false;
            }
            setAddQuota(Math.floor(inputValue));
            setIsToolTip(false);
          }}
          onMouseDown={e => e.stopPropagation()}
        />
      </Popover>
      <Tooltip title={`${addQuota} USD`}>
        <span style={{whiteSpace: 'normal'}} className='amount'>{` = ${addQuota} USD`}</span>
      </Tooltip>
    </InputWrapper>
    <PopoverFooterContainer>
      <ButtonWrapper color='green'
        loading={loadingAiQuota}
        onClick={async () => {
          if (addQuota <= 0) {
            setIsToolTip(true);
            return false;
          }
          await addTopUpQuote({units: addQuota});
          await getCustomerQuota();
          setTopUpVisibility(false);
          setAddQuota(1);
        //   getCustomerQuota();
        }}
      >
        <FontAwesomeIcon icon={faLockKeyhole} style={{height: '20px', color: 'white', cursor: 'pointer', marginRight: '10px'}} />
        <span> Get {addQuota} point{addQuota > 1 ? 's' : ''} for ${addQuota}{' '}</span> </ButtonWrapper>
    </PopoverFooterContainer>
    <div style={{position: 'absolute', top: -20, right: 0}}>
      <FontAwesomeIcon
        icon={faXmark}
        style={{height: '20px', color: '#A3A4A4', cursor: 'pointer', marginTop: '10px'}}
        onClick={() => {
          setTopUpVisibility(false);
          setAddQuota(1);
        }}
      />
    </div>
  </div>);

  return (
    <div>
      <QuotaContainer isCenter={isCenter}><QuotaDivSA>
        {isKrPublic ? <div>Quota: 10 requests per day</div> :<>
          <span style={{display: 'flex'}} >{heading}:
            <QuotaValues>{consumedData ? `${formatNumber(consumedData, 1)}`.replace('.0', '') : 0} </QuotaValues>
            <QuotaSubValue>/{totalData ? `${formatNumber(totalData, 1)}`.replace('.0', '') : 100}</QuotaSubValue>
            {showPurchasedQuota ? <PurchasedQuota total={`+${showPurchasedQuota}`} /> : ''}
            <Tooltip
              trigger={'hover'}
              overlayInnerStyle={{
                width: showGuide ? 200 : 157,
                padding: '10px',
                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #2D2F34',
                boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
                borderRadius: 8,
              }}
              placement='bottom'
              title={<>
                <TooltipTitle showGuide={showGuide}>{description}</TooltipTitle>
                {!showGuide ? <Divider style={{background: '#4E5156', margin: '10px 0px'}}/> : null}
                <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                  <> <span>{isConsemed ?? 'Used'}:</span><span>{numberWithCommas(consumedData)}</span></>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                  <> <span>{isRemaining ?? 'Remaining'}:</span><span style={{color: difference > 0 ? '#15ac47' : '#f44343'}}>{difference > 0 ? numberWithCommas(difference) : 0}</span></>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                  <> <span> {isTotal ?? 'Total'}:</span><span>{totalData ? numberWithCommas(totalData) : 100}</span></>
                </div>
                {showTopupButton && <TopupButton style={{cursor: consumedData == 0 ? 'not-allowed' : ''}} onClick={() => {
                  if (consumedData !== 0) {
                    setShowDesc(false);
                    setTopUpBulkVisibility(true);
                  }
                }}>
                  <FontAwesomeIcon icon={faCoins} color='#2AC155' />
                  <TopupText>Top up</TopupText>
                </TopupButton>}
                {showGuide ? <>
                  <Divider style={{background: '#4E5156', margin: '10px 0px'}} />
                  <TooltipTitle showGuide={showGuide}>Quota consumption guide</TooltipTitle>
                  {guideData.map((item: Guide, key: number) => (
                    <div key={key} style={{display: 'flex', justifyContent: 'space-between', color: '#fff', fontSize: 12}}>
                      <> <span>{`${item.title}:`}</span><span>{numberWithCommas(item.value as number)}</span></>
                    </div>
                  ))}
                </> : null
                }

              </>} >
              <FontAwesomeIcon icon={faSquareQuestion} color='#A3A4A4' fontSize={14} style={{marginLeft: 6, marginTop: 4, cursor: 'pointer'}} />
            </Tooltip>
            <PopoverWrapper
              overlayClassName={styles.TopUpPopupOverlay}
              color='#313337'
              overlayInnerStyle={{
                backgroundColor: '#313337',
                width: 331,
                borderRadius: 5,
              }}
              content={popoverContent}
              trigger='click'
              placement='bottomLeft'
              onVisibleChange={visible => {
                setTopUpVisibility(visible);
                setAddQuota(1);
              }}
              visible={topUpVisibility}
              autoAdjustOverflow={true}
            >
              <>
                { consumedData >= totalData && <TopUp onClick={() => setTopUpVisibility(true)}>Top Up</TopUp>}
              </>
            </PopoverWrapper>
          </span>
          {!isNoProgressBar && <ProgressStyled
            trailColor={'#4E5156'}
            strokeColor={getInversePercentageColor(getPercentage(Number(consumedData), 0, Number(totalData)))}
            percent={getPercentage(Number(consumedData), 0, Number(totalData))}
            showInfo={false} /> }</>}
      </QuotaDivSA> </QuotaContainer>
    </div>
  );
});

const QuotaValues = styled.div`
  margin-left:5px;
`;
const ProgressStyled = styled(Progress)`
margin-top:-7px;
  .ant-progress-inner {
    height: 2px;
  }
`;
const QuotaSubValue = styled.div`
  font-size:12px;
  display: flex;
  align-items: center;
`;
const QuotaDivSA = styled.div`
  display:flex;
  flex-direction:column;
  font-size:14px;
  font-weight:400;
  color: ${COLORS.gray};
  `;
const QuotaContainer = styled.div<{isCenter}>`
display:flex;
gap: 25px;
justify-content:${p=>p.isCenter ? '' : 'center'};
`;
const TooltipTitle = styled.div<{showGuide?: boolean; calledFrom?:string}>`
font-family: 'Inter';
font-style: normal;
font-weight: ${p => p.showGuide ? 600 : 400};
font-size: ${p => p.showGuide ? 13 : '12px'};
line-height: ${p => p.showGuide ? 15.73 : 14}px;
color: '#FFFFFF';
margin-bottom: ${p => p.showGuide ? 10 : 0}px;
`;

const IconWrapper = styled.div`
width: 56px;
    height: 42px;
    border-radius: 100%;
    margin-right: auto;
    margin-top: 10px;
`;
const PopoverTitle = styled.div`
 color: #fff;
 font-weight: 600;
 font-size: 21px;
 margin-top: 8px;
 margin-bottom: 6px;
 max-width: 280px;
`;
const PopoverDesc = styled.div`
 color: #E8E8E8;
 font-weight: 400;
 font-size: 15px;
 max-width: 280px;
`;
const PopoverFooterContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  .amountCount{
    // width: 50px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    display: block;
  }
`;
const TopUp = styled.span`
 color: #2d6cca;
 text-decoration:  underline;
 cursor: pointer;
 margin-left:5px;
`;

const PopoverWrapper = styled(Popover)`
.ant-popover-arrow-content::before {
background: #313337 !important;
top: 56px !important;
}
`;

const InputQuota = styled(Input)`
height: 38px;
width: 58%;
margin: 13px 10px 10px 0;
border: none;
color:gray;
&:hover{
  border:none;
}
`;

const InputWrapper = styled.div`
display:flex;
flex-wrap: wrap;
p{
  margin-bottom: 0;
  color: white;
  font-weight: 600;
  font-size: 16px;
  display:flex;
}
align-items: center;
.ant-input{
  border-radius: 8px;
}
.amount{
    display: block;
    color: white;
    font-size: 14px;
    font-weight: 500;
}
.ant-input:focus, .ant-input-focused{
  border-color: #40a9ff00 !important;
}
`;

const ButtonWrapper = styled(Button)`
white-space: normal;
display:flex;
background-color: #1FAC47;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 7px 20px;
    color: #fff;
    font-size: 14px;
    width: 100%;
    height: auto;
    font-weight: 600;
    display: flex;
    justify-content: center;
    border:none;
    &:hover{
      background-color: #1FAC47;
      color: white;
      border:none;
    }
    .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
      background-color: #1fac47b0 !important;
      color: white  !important;
      border:none !important;
    }
`;

const TopupButton = styled.button`
  background-color: rgba(42, 193, 85, 0.15);
  width: 96px;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px;
  border: none;
  margin-top: 12px;
  cursor: pointer;
`;

const TopupText = styled.span`
margin-left: 8px;
color: var(--White, #FFF);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 22px;
`;
