
export enum SORT_DIRECTION {
  ascend = 'ascend',
  descend = 'descend'
}

export function filterDataInMemory<T>(
  data: T[],
  filterKeywordTerm: string,
  filterKeywordColumns: string[],
  filters: {
    name: string;
    header: string;
    from: string;
    to: string;
    operator:string;
    query:string;
  }[],
  pageNumber: number,
  pageSize: number,
  sortField: string,
  sortDirection: SORT_DIRECTION,
): {dataFiltered: T[]; dataFilteredSize: number} {
  if (!data || !data.length) return {dataFiltered: [], dataFilteredSize: 0};
  // filtering data using filters in header.
  let dataFiltered = data.filter( d => d);
  // sorting data if defined
  if (sortField && sortDirection) {
    dataFiltered.sort( (a, b) => {
      const _dir = sortDirection === SORT_DIRECTION.ascend ? 1 : -1;
      if (a[sortField] > b[sortField]) return 1 * _dir;
      if (a[sortField] < b[sortField] * _dir) return -1 * _dir;
      return 0;
    });
  }
  // filtering by input term
  if (filterKeywordTerm) {
    dataFiltered = dataFiltered?.filter( k => {
      const includeInResult = filterKeywordColumns?.filter( col => {
        return k[col] && k[col]?.toLowerCase()?.includes(filterKeywordTerm?.toLowerCase());
      }).length > 0;
      return includeInResult;
    });
  }
  if (filters && filters.length) {
    dataFiltered = dataFiltered.filter( k => {
      const shouldBeExcluded = filters.filter( f => {
        const itemValue = k[f.name];
        if (f.from === '' && f.to === '') return false;
        if (isNaN(itemValue)) return false;
        let isValid = true;
        if (f.from !== '') {
          const from = parseFloat(f.from);
          if (itemValue < from) isValid = false;
        }
        if (f.to !== '') {
          const to = parseFloat(f.to);
          if (itemValue > to) isValid = false;
        }
        return !isValid;
      }).length > 0;
      return !shouldBeExcluded;
    });
  }
  // Slice using pagination
  const startPaginationIndex = (pageNumber * pageSize) - pageSize;
  const endPaginationIndex = startPaginationIndex + pageSize;
  const dataFilteredSize = dataFiltered.length;
  dataFiltered = dataFiltered.slice(startPaginationIndex, endPaginationIndex);
  return {dataFiltered, dataFilteredSize};
}

export const formatDataForAddBusiness = data => {
  const dataFormat = data.map(value =>{
    return {
      address: value?.formattedAddress || value?.formatted_address,
      category: '',
      dataCid: value?.placeId || value?.place_id,
      extensions: null,
      gpsCoordinates: {latitude: value?.geometry?.location?.lat, longitude: value?.geometry?.location?.lng},
      position: null,
      rating: value.rating,
      reviews: value?.userRatingsTotal || value?.user_ratings_total,
      sponsored: false,
      title: value.name,
      webResultsLink: null,
    };
  });
  return dataFormat;
};
