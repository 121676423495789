import {getApiUrl, getAuthHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {formatDataForAddBusiness} from '@/utils/filter-data';
import axios from 'axios';

export class LocalSeoScanApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');
  private static readonly countriesUrl: string = 'https://countriesnow.space/api/v0.1/countries';

  public async postSetupGrids(id, payload) {
    try {
      const response = await this.axios.post(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/setup-grid/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteBusiness(mainId, id) {
    try {
      const response = await this.axios.delete(`${LocalSeoScanApi.baseUrl}/v3/google-business/${mainId}/remove-grid/?grid_id=${id}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteKeyword(mainId, id) {
    try {
      const response = await this.axios.delete(`${LocalSeoScanApi.baseUrl}/v3/google-business/${mainId}/remove-grid/?grid_id=${id}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async postNewBusiness(id, payload) {
    try {
      const response = await this.axios.post(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/setup-grids/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async postNewBusinessV3(id, payload) {
    try {
      const response = await this.axios.post(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/setup-grids/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async postNewBusinessV2(payload) {
    try {
      const response = await this.axios.post(`${LocalSeoScanApi.baseUrl}/v3/google-business/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getHeatmapKeywordData(businessId, gridId, publicHash?: string, selectedDate?: string) {
    const params = {date: selectedDate};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/${businessId}/grid-details/?grid_id=${gridId}`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async postBusinessV3ToAdd(id) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/place-detail/?place_id=${id}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async postBusinessV3(payload) {
    try {
      const response = await this.axios.post(`${LocalSeoScanApi.baseUrl}/v3/google-business/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async searchLocation(params) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/location-suggestions/`, {
        // params: {
        //   siteproperty: siteProperty,
        // },
        params: {query: params},
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadLocalSeoListData(id, params) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/grids/`, {
        // params: {
        //   siteproperty: siteProperty,
        // },
        params: params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadLocalSeoListDataV3(params) {
    try {
      // const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/grids/`, {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/grids-history/`, {
        params: params,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateGridDataV3(id, payload) {
    try {
      const response = await this.axios.put(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/update-grid/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async bulkUpdateGridDataV3(id, payload) {
    try {
      const response = await this.axios.put(`${LocalSeoScanApi.baseUrl}/v3/google-business/bulk-update-grids-v2/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadGMBDetailData(id, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/`, {
        params: params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteDateGrid(id, date, gridId) {
    try {
      const response = await this.axios.delete(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/remove-snapshot/?grid_id=${gridId}&date=${date}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadCompareData(businessId, gridId, dataCid, publicHash, selectedDate) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/${businessId}/competitor-report/?grid_id=${gridId}&competitor_business_date=${selectedDate}&business_date=${selectedDate}&data_cid=${dataCid}`, {
        params: params,
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadBusinessSuggestionData(data) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/text-search/`, {
        params: data,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return {results: formatDataForAddBusiness(response.data.results)};
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadPlaceIDData(data) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/place-detail/`, {
        params: data,
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return {results: formatDataForAddBusiness(response.data.results)};
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadAvailableDates(id, gridId, publicHash?: any) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/available-dates/?grid_id=${gridId}${publicHash ? `&hash=${publicHash}` : ''}`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadLocationsReport(id, gridId, publicHash, date?: string) {
    const params = {
      grid_id: gridId || '',
    };
    if (publicHash) params['hash'] = publicHash;
    if (date) params['date'] = date;
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/location-report/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadGridData(id) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/grids/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadSeoProjectsList(params) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async loadSeoProjectsListV3(params) {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v3/google-business/`, {
        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async deleteSeoProject(id) {
    try {
      const response = await this.axios.delete(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/delete/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async refreshNowApi(id, gridId) {
    try {
      const response = await this.axios.post(`${LocalSeoScanApi.baseUrl}/v3/google-business/${id}/refresh-grid/`, {grid_id: gridId}, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async createBusinessFromGBP(payload) {
    try {
      const response = await this.axios.post(`${LocalSeoScanApi.baseUrl}/v3/google-business/bulk-create/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getBusinessCategories() {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v1/aggregator-network/business-categories/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async addAggregatorNetwork(payload) {
    try {
      const response = await this.axios.post(`${LocalSeoScanApi.baseUrl}/v1/aggregator-network/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAggregatorNetwork() {
    try {
      const response = await this.axios.get(`${LocalSeoScanApi.baseUrl}/v1/aggregator-network/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async deleteAggregatorNetwork(id: string | number) {
    try {
      const response = await this.axios.delete(`${LocalSeoScanApi.baseUrl}/v1/aggregator-network/${id}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async citationBusinessDetail(id: string | number) {
    try {
      const response = await axios.get(`${LocalSeoScanApi.baseUrl}/v1/aggregator-network/${id}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async exportCitationDetail(id: string | number) {
    try {
      const response = await axios.get(`${LocalSeoScanApi.baseUrl}/v1/aggregator-network/${id}/?export=1`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async updateAggregatorNetwork(id: string | number, payload) {
    try {
      const response = await axios.put(`${LocalSeoScanApi.baseUrl}/v1/aggregator-network/${id}/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async loadAggregatorNetworkDetails(id: string | number) {
    try {
      const response = await axios.get(`${LocalSeoScanApi.baseUrl}/v1/aggregator-network/${id}/campaign-details/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export const LOCAL_SEO_SCAN_API = new LocalSeoScanApi();
