import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {AxiosResponse} from 'axios';

export interface CampaignPaginationParams {
  pageNumber: number;
  pageSize: number;
  id: string | number;
  ordering?: string;
}

export interface campaignContactDelete {
  contactId: string;
  opportunityId: string;
}

class CampaignOpportunityApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api/v1/customer-outreach');

  public async getCampaignsOpportunity(params, id) {
    try {
      const response: AxiosResponse<any> = await this.axios.get(`${CampaignOpportunityApi.baseUrl}/opportunities/?campaign_id=${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        params: params,
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCampaignsContacts({id}: CampaignPaginationParams) {
    try {
      const response: AxiosResponse<any> = await this.axios.get(`${CampaignOpportunityApi.baseUrl}/contacts/?opportunity_id=${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getCampaignsContactsDelete({contactId, opportunityId}: campaignContactDelete) {
    try {
      const response: AxiosResponse<any> = await this.axios.delete(`${CampaignOpportunityApi.baseUrl}/contacts/${contactId}/?opportunity_id=${opportunityId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async campaignsContactBulkDelete(contactIds, opportunityId) {
    try {
      const response: AxiosResponse<any> = await this.axios.post(`${CampaignOpportunityApi.baseUrl}/contacts/bulk-delete-contacts/?opportunity_id=${opportunityId}`, {contact_ids: contactIds}, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async postOpportunityConfig(data:any) {
    try {
      const response: AxiosResponse<any> = await this.axios.put(`${CampaignOpportunityApi.baseUrl}/campaigns/${data.campaign}/update-settings/`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async updateOpportunityStatus(opportunityId: number, campaignId: number) {
    try {
      const response: AxiosResponse<any> = await this.axios.get(`${CampaignOpportunityApi.baseUrl}/opportunities/${opportunityId}/toggle-opportunity/?campaign_id=${campaignId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async postEmail(data:any, opportunity:number, campaign:number) {
    try {
      const response: AxiosResponse<any> = await this.axios.post(`${CampaignOpportunityApi.baseUrl}/opportunities/${opportunity}/send-outreach-emails/?campaign_id=${campaign}`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async saveContact(data:any, opportunity:number) {
    try {
      const response: AxiosResponse<any> = await this.axios.post(`${CampaignOpportunityApi.baseUrl}/contacts/?opportunity_id=${opportunity}`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async sendEmailInBulk(data:any, campaign:number) {
    try {
      const response: AxiosResponse<any> = await this.axios.post(`${CampaignOpportunityApi.baseUrl}/campaigns/${campaign}/send-outreach-emails/`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async addToOutreachList(data:any, campaign:number) {
    try {
      const response: AxiosResponse<any> = await this.axios.post(`${CampaignOpportunityApi.baseUrl}/campaigns/${campaign}/schedule-opportunities/`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  public async deleteOpportunity(opportunityId, campaignId) {
    try {
      const response: AxiosResponse<any> = await this.axios.delete(`${CampaignOpportunityApi.baseUrl}/opportunities/${opportunityId}/?campaign_id=${campaignId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export const CAMPAIGN_OPPORTUNITY_API = new CampaignOpportunityApi();
