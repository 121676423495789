import React from 'react';
import {IFocusTermToClassNameMap} from '@/store/content-optimizer/currentPage';
import {Popover} from 'antd';
import {ContentBlock, ContentState, DraftDecorator} from 'draft-js';
import {StrategyCallbackType} from './types';

type LinkComponentProps = {
  contentState: ContentState;
  entityKey: string;
  children: React.ReactNode;
  decoratedText: any;
  highlights: IFocusTermToClassNameMap;
};

export const ExternalLinkPopupContent = ({url}: {url: string}) => {
  return (
    <a href={url} target='_blank' rel='noopener noreferrer'>
      {/* this check is added because in Anchor tool we add // to links that dont have protocols */}
      Open <span style={{textDecoration: 'underline'}}>{url?.startsWith('http://') ? url?.slice(7) : url}</span>
    </a>
  );
};

export const LinkComponent = (props: LinkComponentProps) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  const searchKey = props.decoratedText.toLocaleLowerCase();

  return (url ? <Popover content={<ExternalLinkPopupContent url={url} />}>
    <a href={url}
      className={props.highlights?.[searchKey]}
      style={{textDecoration: 'underline'}}>{props.children}</a>
  </Popover> : props.children
  );
};

function linkStrategy(
  contentBlock: ContentBlock,
  callback: StrategyCallbackType,
  contentState: ContentState,
) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
}

export const createLinkDecorator = (focusClasNames: IFocusTermToClassNameMap): DraftDecorator => {
  return {
    strategy: linkStrategy,
    component: LinkComponent,
    props: {
      highlights: focusClasNames,
    },
  };
};
