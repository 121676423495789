import {gmbAdd, layoutDataFullReport} from '@/components/dashboard/pages/report-builder/componentsDrag/layoutData';
import {filterNullValues, heatmapHeightLogic} from '@/components/dashboard/pages/report-builder/utils';


// payloadObj.layout = gmbAdd(addConfig, Number(item?.value), 'seo')

export const getDefaultLayoutWithCheckedSections = (singleProjectData, layoutType='custom') => {
  let formatLayout = [];
  const disableNames = singleProjectData?.reportsData && Object.keys(filterNullValues(singleProjectData?.reportsData))?.map(itemName => {
    return itemName == 'backlinks' ? 'se' : itemName == 'localSeo' ? 'seo' : itemName == 'rankTracker' ? 'krt' : itemName == 'facebookAds' ? 'fb' : itemName == 'googleAds' ? 'gl' : itemName;
  });
  disableNames?.push('otto');
  layoutType == 'default' && disableNames?.push('summary');
  const gmbIds = singleProjectData?.reportsData?.gmb?.gmbIds;
  const localSeoIds = singleProjectData?.reportsData?.localSeo?.localSeoIds;

  layoutDataFullReport?.forEach( item => {
    const layoutData = layoutDataFullReport.find(data => (data.name == item?.name || (item.tag == 'gl' && data.name.startsWith(item.name) || (item.tag == 'fb' && data.name == `${item.name} FB`))));
    if (disableNames?.includes(item?.tag || layoutData?.tag) || item?.tag == 'title' || item?.name == 'customText' || (layoutType != 'custom' && item?.tag == 'tabs')) {
      if (item.name == 'Map') {
        if (item.seoData?.length) {
          formatLayout.push({...item, tag: layoutData?.tag, h: layoutData.h + heatmapHeightLogic(item.seoData), component: layoutData?.component, index: item.name == 'Map' ? item['index'] : null});
        } else {
          formatLayout.push({...item, tag: layoutData?.tag, component: layoutData?.component, index: item.tag == 'gmb' ? gmbIds[0] : (item.tag == 'seo' ? localSeoIds[0] : null)});
        }
      } else if (item.name == 'customText') {
        formatLayout.push({...item, tag: layoutData?.tag, component: <h2 className='customText' style={{fontSize: '18px', fontWeight: '500', padding: '10px', color: 'rgba(0, 0, 0, 0.85)'}}>{('value' in item) ? (item['value'] ?? '') : ''}</h2>});
      } else {
        formatLayout.push({...item, tag: layoutData?.tag, component: layoutData?.component, index: item.tag == 'gmb' ? gmbIds[0] : (item.tag == 'seo' ? localSeoIds[0] : null)});
      }
    }
  });
  if (layoutType == 'default') {
    if (disableNames.includes('seo')) {
      const seoDataList = singleProjectData?.layout?.filter(item=> item.tag == 'seo');
      formatLayout = formatLayout.filter(item=> item.tag != 'seo').map(item=> ({...item, w: item.w, h: item.h, x: item.x, y: item.y, i: item.i, minW: item.minW, minH: item.minH, tag: item?.tag, name: item.name}));
      singleProjectData?.reportsData?.localSeo?.localSeoIds?.map(item=> {
        formatLayout = gmbAdd(formatLayout, Number(item), 'seo');
      });
      formatLayout = formatLayout.map(item=> {
        if (item.tag == 'seo' && item.name == 'Map') {
          const seoData = seoDataList.find(value=> value.index == item.index && value.name == 'Map')?.seoData;
          return {...item, seoData: seoData};
        } else return item;
      });
    }
    if (disableNames.includes('gmb')) {
      formatLayout = formatLayout.filter(item=> item.tag != 'gmb').map(item=> ({...item, w: item.w, h: item.h, x: item.x, y: item.y, i: item.i, minW: item.minW, minH: item.minH, tag: item?.tag, name: item.name}));
      singleProjectData?.reportsData?.gmb?.gmbIds?.map(item=> {
        formatLayout = gmbAdd(formatLayout, Number(item));
      });
    }
  }

  return formatLayout;
};
