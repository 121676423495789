
/*
* Use this util to format country code to our flag library requirements
*/
export const formatCountryCode = (country: string, code?: string) => {
  switch (country) {
    case 'Arabic':
      return 'SA';
    case 'Japanese':
      return 'JP';
    case 'Albanian':
      return 'AL';
    case 'Armenian':
      return 'AM';
    case 'Belarusian':
      return 'BY';
    case 'Czech':
      return 'CZ';
    case 'Danish':
      return 'DK';
    case 'Estonian':
      return 'ET';
    case 'Chinese':
      return 'CN';
    case 'Taiwan':
      return 'TW';
    case 'Georgian':
      return 'GE';
    case 'Hindi':
      return 'IN';
    case 'Irish':
      return 'IE';
    case 'Kannada':
      return 'KN';
    case 'Slovenian':
      return 'SI';
    case 'Serbian':
      return 'RS';
    case 'Swedish':
      return 'SE';
    case 'Ukrainian':
      return 'UA';
    case 'English': case 'English (US)':
      return 'US';
    case 'English (UK)':
      return 'GB';
    case 'Kazakh':
      return 'KZ';
    case 'English (CA)':
      return 'CA';
    default:
      return code;
  }
};
