import React from 'react';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {observer} from 'mobx-react-lite';
import {ColumnsType} from 'antd/lib/table';
import {faImageSlash} from '@fortawesome/pro-solid-svg-icons';
import {Table} from '@/components/common-components/components';
// import {faCheck} from '@fortawesome/pro-regular-svg-icons';
// import {faXmark} from '@fortawesome/pro-duotone-svg-icons';
import {getDomain} from '@/utils/url';
import {toJS} from 'mobx';
import {tagsRegex} from '@/constants/regex';
import {routes} from '@/utils/const';
import {useRouter} from 'next/router';
import {Row, Col} from 'antd';

interface Props {
  setGaDetail?: (value) => void;
  gscDetailData?: any;
  onDeleteAccount?: (email) => void;
}
export const GSCSettingsDetail: React.FC<Props> = observer(({setGaDetail, gscDetailData, onDeleteAccount}) => {
  const router = useRouter();
  const parseProject = (url: string) => {
    const match = url.match(tagsRegex);
    return {
      url: url.replace(tagsRegex, '').replace('/', '').replace('sc-domain:', ''),
      tag: match?.[0] ?? 'Domain',
    };
  };
  const columns: ColumnsType = [
    {
      title: () => <span>TYPE</span>,
      key: 'url',
      dataIndex: 'url',
      align: 'left',
      width: 10,
      render: (_, data: any) => <Type>
        {data?.url ? parseProject(data.url)?.tag : '-'}
      </Type>,
    },
    {
      title: () => <span style={{marginLeft: '-22px'}}>GSC SITE PROPERTY</span>,
      key: 'url',
      dataIndex: 'url',
      align: 'left',
      width: 400,
      render: (_, data: any) => <FlexWrapper style={{gap: '6px'}} onClick={()=>{
        if (parseProject(data.url)?.url) router.push(`/${routes.gsc}/overview?&sp=${parseProject(data.url)?.url}&country_code=`);
      }}>
        <div>
          {data?.url ? <img style={{height: '30px', width: '30px'}} src={`https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${getDomain((!/^(https?:)?\/\//i.test(data.url)) ? 'https:' + data.url : data.url)}&size=64`}/> :
            <FontAwesomeIcon style={{fontSize: 25, color: '#A3A4A4'}} icon={faImageSlash} /> }
        </div>
        <ProjectName>{data?.url ? parseProject(data.url)?.url : '-'}</ProjectName>
      </FlexWrapper>,
    },
    // {
    //   title: () => <span>SITE AUDIT PROJECT</span>,
    //   key: 'selectedGaViewId',
    //   dataIndex: 'selectedGaViewId',
    //   width: 60,
    //   render: (_, data: any) => <div>
    //     {data?.selectedGaViewId ? <>
    //       <GscProject>
    //         <FontAwesomeIcon icon={faCheck} color={'#1FAC47'}/>
    //         <ViewProject>View Project</ViewProject>
    //       </GscProject>
    //     </> : <GscProject>
    //       <FontAwesomeIcon icon={faXmark} color={'#A3A4A4'}/>
    //       <MissingWrapper>Missing</MissingWrapper>
    //     </GscProject>}
    //   </div>,
    // },
  ];

  return (
    <MainWrapper>
      <Row gutter={24} style={{display: 'flex', flexWrap: 'wrap'}}>
        <Col xl={18} md={16} xs={24}>
          <div onClick={()=>setGaDetail(false)} style={{display: 'flex', gap: '18px', cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faArrowLeft} style={{cursor: 'pointer'}}/>
            <Heading>Back to list of GSC Connected Accounts</Heading>
          </div>
          <Email>{gscDetailData?.email}</Email>
        </Col>
        <Col xl={6} md={8} xs={24}>
          <DisconnectEmail onClick={() => onDeleteAccount(gscDetailData?.email)}>Disconnect this Email</DisconnectEmail>
        </Col>
      </Row>
      <TableContainer>
        <Table
          dataSource={toJS(gscDetailData)?.siteproperties || []}
          columns={columns}
          pagination={false}
        />
      </TableContainer>
    </MainWrapper>
  );
});
// const GscProject = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 6px;
// `;
// const MissingWrapper = styled.div`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 17px;
//   color: #A3A4A4;
// `;
const Type = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4E5156;
`;
const ProjectName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;
// const ViewProject = styled.div`
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 17px;
//   color: #2D6CCA;
// `;
const DisconnectEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #F44343;
  cursor: pointer;
  @media only screen and (max-width: 767px){
    padding-left: 30px;
  }
`;
const Email = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
  padding-left: 30px;
`;

const FlexWrapper= styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const MainWrapper = styled.div`
  padding-top: 15px;
`;
const Heading = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
`;

const TableContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 10px;
  margin-top: 10px;
  padding: 0 5px 5px 5px;

 .ant-table-body {
  &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: rgba(138,138,138,0.32);
    }
  
    &::-webkit-scrollbar-track {
      background: rgba(138,138,138,0.12);
    }
}

table tr th {
  background-color: #fff;
}

.ant-table-content {
  border-radius: 0 !important;
}

.ant-table-column-sorters-with-tooltip:not(:first-child) {
  text-align: center;
}

table {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;

  th::before {
    width: 0 !important;
  }

  .ant-table-column-sorter {
    display: flex;
    padding-left: 3px !important;
  }

  .ant-table-column-sorters {
    display: inline-flex !important;
    padding: 5px !important;
  }

  tr td {
    &:first-child {
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }
  }

  .ant-table-tbody {
    tr:nth-child(even) {
      td {
        background: #fff !important;
        border-bottom: 1px solid #f4f4f4 !important;
        box-shadow: none;
        font-weight: 400;
        white-space: nowrap;
      }

      &:hover {
        td {
          background: #eef4fe !important;
        }

        button {
          background: #eef4fe !important;
          border: 0 !important;
          color: #2d6cca;
          font-weight: 500;
        }
      }
    }

    tr:nth-child(odd) {
      td {
        background: #fff;
        border-bottom: 1px solid #f4f4f4 !important;
        box-shadow: none;
        font-weight: 400;
        white-space: nowrap;
      }

      &:hover {
        td {
          background: #eef4fe !important;
        }

        button {
          background: #eef4fe !important;
          border: 0 !important;
          color: #2d6cca;
          font-weight: 500;
        }
      }
    }
  }

  .ant-table-thead {
    background: #f7f7f7 !important;

    th {
      background: #f7f7f7 !important;
      color: #121212 !important;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      padding: 0 !important;
    }

    .ant-table-cell-fix-left {
      text-align: left !important;

      &:first-child {
        border-bottom-left-radius: 8px !important;
        border-top-left-radius: 8px !important;
      }
    }

    th:first-child {
      background: #f7f7f7 !important;
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
      color: #121212;
      font-size: 12px;
      font-weight: 600;
    }

    th:nth-child(2) {
      background: #f7f7f7 !important;
      color: #121212;
      font-size: 12px;
      font-weight: 600;
    }

    th:last-child {
      background-color: #f7f7f7 !important;
      border-bottom-right-radius: 8px !important;
      border-top-right-radius: 8px !important;
      padding-left: 25px !important;
    }
  }

  .ant-table-cell-fix-left-last {
    text-align: left !important;
  }

  tr:first-child td {
    background: #fff !important;
  }

  tr:nth-child(even) td {
    background: #f9f9fb !important;
  }

  tr:nth-child(odd) td {
    background-color: #fff !important;
  }

  tr th {
    height: 50px !important;
    line-height: 22px;
    white-space: nowrap;
  }

  .ant-column-sorters {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  tr td {
    padding-bottom: 15px;
    padding-top: 15px;
    text-align: center;
  }

  tr th:first-child,
  tr td:first-child {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
}
`;

