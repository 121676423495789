import {Button, Modal} from '@/components/common-components';
import styles from '@/components/common-components/components/authorized-modal/style.module.scss';
import {useTranslation} from 'next-i18next';
import {Checkbox} from 'antd';
import {integrationApi} from '@/api/gsc';

import {MixPanel} from '@/utils/mixpanel';
import React, {useEffect, useState} from 'react';


interface Props {
  onClose: (...args: any) => any;
  isOpen: boolean;
  trackingId: string;
}

export const AuthorizedModal: React.FC<Props> = ({
  onClose,
  isOpen,
  trackingId,
}) => {
  const {t} = useTranslation('common');

  const [checkBoxValue, setCheckBoxValue] = useState(false);

  useEffect(() => {
    setCheckBoxValue(false);
    setTimeout(() => {
      setCheckBoxValue(true);
    }, 1000);
  }, []);

  return (
    <>
      <Modal
        className={styles.searchConsolePopup}
        visible={isOpen}
        onClose={onClose}
        closable={true}
        forceRender={true}>
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.consoleCheckbox}>
              <img src='/img/dashboard/gsc/google-search-console.svg' />
              <div className={styles.blurSection}></div>
              <Checkbox autoFocus={true} defaultChecked={false} checked={checkBoxValue}>{t('search-console-paragraph-part-2')}</Checkbox>
            </div>

            <h2>{t('enable-search-console-data')}</h2>
            <p>{t('search-console-paragraph-part-1')} <a onClick={()=>{
              if (trackingId) {
                MixPanel.track(trackingId);
              }
              location.href=integrationApi.getGoogleConnectUrl();
            }}>{t('search-console-paragraph-part-2')}</a> {t('search-console-paragraph-part-3')}</p>
          </div>
          <div className={styles.footer}>
            <Button
              onClick={() => {
                if (trackingId) {
                  MixPanel.track(trackingId);
                }
                location.href=integrationApi.getGoogleConnectUrl();
              }}
            >
              {t('connect-search-console')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
