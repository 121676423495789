import {observer} from 'mobx-react';
import styled from 'styled-components';
import ReactEcharts from 'echarts-for-react';
import {useEffect, useState} from 'react';
import {useStore} from '@/store/root-store';
import {Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowTrendUp, faBug, faPage} from '@fortawesome/pro-regular-svg-icons';
import {numberWithCommas} from '@/utils/number';
import {faArrowRight} from '@fortawesome/pro-duotone-svg-icons';

export const After = observer(() => {
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const {
    ottoV2Store: {getOttoV2Project},
  } = useStore('');
  const [ottoStats, setOttoStats] = useState({
    optimization: 0,
    pages: 0,
    issues: 0,
    isCompleted: false,
  });

  const getChartColor = (value: number, type: string) => {
    const completeColors = {
      optimization: '#2A8745',
      pages: '#2AC155',
      issues: '#70F596',
    };
    if (value <= 30 || !value) {
      return '#F44343';
    } else if (value >= 31 && value <= 80) {
      return '#F1AA3E';
    } else {
      return completeColors[type] || '#70F596';
    }
  };

  const optimizationScore = getOttoV2Project && getOttoV2Project.afterSummary['seoOptimizationScore'];
  const healthyPages = getOttoV2Project && (getOttoV2Project.afterSummary['healthyPages'] / getOttoV2Project.afterSummary['totalPages']) * 100;
  const issues = getOttoV2Project && (getOttoV2Project.afterSummary['deployedFixes'] / getOttoV2Project.afterSummary['foundIssues']) * 100;

  const isCompletedV2 = optimizationScore == 100 || healthyPages == 100 || issues == 100;

  useEffect(() => {
    const optimizationScore = getOttoV2Project?.afterSummary['seoOptimizationScore'];
    const healthyPages = (getOttoV2Project?.afterSummary['healthyPages'] / getOttoV2Project?.afterSummary['totalPages']) * 100;
    const issues = (getOttoV2Project?.afterSummary['deployedFixes'] / getOttoV2Project?.afterSummary['foundIssues']) * 100;
    const statsArr = [
      {value: issues, name: 'issues', label: `Issues fixed: ${getOttoV2Project?.afterSummary['deployedFixes'] || 0}`},
      {value: healthyPages, name: 'pages', label: `Healthy pages: ${getOttoV2Project?.afterSummary['healthyPages'] || 0}`},
      {value: optimizationScore, name: 'optimization', label: `Optimization Score: ${getOttoV2Project?.afterSummary['seoOptimizationScore'] || 0}%`},
    ];
    const isCompleted = statsArr.filter(stat => stat.value === 100).length === statsArr.length;
    let series: any[] = statsArr.map((stat, index) => {
      return [1, 2].map(n => (
        {
          name: stat.label,
          type: 'pie',
          radius: [25 + (20 * index), 37 + (20 * index)],
          hoverOffset: 0,
          labelLine: {
            show: false,
          },
          clockwise: false,
          silent: n === 2 ? true : false,
          z: n === 2 ? 0 : 1,
          animation: n === 2 ? false : true,
          data: n === 2 ? [
            {value: 100, name: '', itemStyle: {color: '#D6DEE4'}, label: {show: false}},
          ] : [
            {value: Math.round(stat.value), name: '', itemStyle: {color: getChartColor(stat.value, stat.name), borderRadius: 12}},
            {value: Math.round(100 - stat.value), name: '', itemStyle: {color: 'transparent'}, label: {show: false}},
          ],
        }
      ));
    });
    series = series?.flatMap(el => el);
    setSeriesData(series);
    setOttoStats({
      optimization: optimizationScore,
      pages: healthyPages,
      issues,
      isCompleted,
    });
  }, [getOttoV2Project]);

  return <StatsWrapper>
    <div style={{minWidth: 160, height: 'fit-content', position: 'relative'}}>
      <div style={{color: 'black', marginTop: 16, fontWeight: 400, fontSize: 12, lineHeight: '14.52px', textAlign: 'center', opacity: 1}}>After</div>
      <br />
      <ReactEcharts
        style={{width: '160px', height: '160px', alignSelf: 'center', transform: 'rotate(5deg)'}}
        opts={{renderer: 'svg'}}
        option={{
          title: [{
            text: '{titleIcon|}',
            top: '-2px',
            left: '40%',
            textAlign: 'center',
            textStyle: {
              rich: {
                titleIcon: {
                  height: 14,
                  width: 14,
                  backgroundColor: {
                    image: '/icons/arrow-trend.svg',
                  },
                  transform: 'rotate(-30deg)',
                  opacity: 0.7,
                },
              },
            },
          }, {
            text: '{titleIcon|}',
            top: '20px',
            left: '43%',
            textAlign: 'center',
            textStyle: {
              rich: {
                titleIcon: {
                  height: 10,
                  width: 10,
                  backgroundColor: {
                    image: '/icons/page-icon.svg',
                  },
                  rotate: 30,
                  opacity: 0.7,
                },
              },
            },
          }, {
            text: '{titleIcon|}',
            top: '40px',
            left: '44%',
            textAlign: 'center',
            textStyle: {
              rich: {
                titleIcon: {
                  height: 10,
                  width: 10,
                  backgroundColor: {
                    image: '/icons/bug-icon.svg',
                  },
                  transform: 'rotate(-30deg)',
                  opacity: 0.7,
                },
              },
            },
          }],
          tooltip: {
            trigger: 'item',
            formatter: '{a}',
            confine: true,
            appendToBody: true,
          },
          series: seriesData,
        }}
      />
      {isCompletedV2 && <ImgStyled src={'/icons/white-check-mark.svg'}/>}
    </div>
    <div className='scores-wrapper'>
      <div className='score'>
        <Tooltip title='A metric that indicates how well-optimized and issue-free your website is. A higher score is better.'>
          <FontAwesomeIcon
            icon={faArrowTrendUp}
            color={getChartColor(ottoStats?.optimization, 'optimization')}
            fontSize={20}
          />
        </Tooltip>
        <div>
          <Tooltip title='A metric that indicates how well-optimized and issue-free your website is. A higher score is better.'>
            <p className='title'>Optimization Score</p>
          </Tooltip>
          <div>
            <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.afterSummary['seoOptimizationScore'] || 0) ?? 'N/A'}</span>
            <span className='fixed-total'>/ 100</span>
          </div>
        </div>
      </div>
      <div className='score'>
        <Tooltip overlayInnerStyle={{minWidth: '260px'}} title={(
          <div>
          Pages that had no issue during the initial crawl or pages that were fixed with OTTO.
            <div>{`Note: pages must have ALL issues fixed or they won't show as healthy.`}</div>
          </div>
        )}>
          <FontAwesomeIcon
            icon={faPage}
            color={getChartColor(ottoStats?.pages, 'pages')}
            fontSize={20}
          />
        </Tooltip>
        <div>
          <Tooltip overlayInnerStyle={{minWidth: '260px'}} title={(
            <div>
            Pages that had no issue during the initial crawl or pages that were fixed with OTTO.
              <div>{`Note: pages must have ALL issues fixed or they won't show as healthy.`}</div>
            </div>
          )}>
            <p className='title'>Healthy pages</p>
          </Tooltip>
          <div>
            <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.afterSummary['healthyPages'] || 0) ?? 'N/A'}</span>
            <span className='fixed-total'>/ {numberWithCommas(getOttoV2Project?.afterSummary['totalPages'] || 0) ?? 'N/A'}</span>
          </div>
        </div>
      </div>
      <div className='score'>
        <Tooltip title='Only the issues fixed with OTTO (excluding issues fixed by other means).'>
          <FontAwesomeIcon
            icon={faBug}
            color={getChartColor(ottoStats?.issues, 'issues')}
            fontSize={20}
          />
        </Tooltip>
        <div>
          <Tooltip title='Only the issues fixed with OTTO (excluding issues fixed by other means).'>
            <p className='title'>Issues fixed with OTTO</p>
          </Tooltip>
          <div>
            <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.afterSummary['deployedFixes'] || 0) ?? 'N/A'}</span>
            <span className='fixed-total'>/ {numberWithCommas(getOttoV2Project?.afterSummary['foundIssues'] || 0) ?? 'N/A'}</span>
          </div>
        </div>
      </div>
    </div>
  </StatsWrapper>;
});

export const Before = observer(() => {
  const [seriesData, setSeriesData] = useState<any[]>([]);
  const {
    ottoV2Store: {getOttoV2Project},
  } = useStore('');
  const [ottoStats, setOttoStats] = useState({
    optimization: 0,
    pages: 0,
    issues: 0,
    isCompleted: false,
  });

  const getChartColor = (value: number, type: string) => {
    const completeColors = {
      optimization: '#2A8745',
      pages: '#2AC155',
      issues: '#70F596',
    };
    if (value <= 30 || !value) {
      return '#F44343';
    } else if (value >= 31 && value <= 80) {
      return '#F1AA3E';
    } else {
      return completeColors[type] || '#70F596';
    }
  };

  const optimizationScore = getOttoV2Project && getOttoV2Project.beforeSummary['seoOptimizationScore'];
  const healthyPages = getOttoV2Project && (getOttoV2Project.beforeSummary['healthyPages'] / getOttoV2Project.beforeSummary['totalPages']) * 100;
  const issues = getOttoV2Project && (getOttoV2Project.beforeSummary['deployedFixes'] / getOttoV2Project.beforeSummary['foundIssues']) * 100;

  const isCompletedV2 = optimizationScore == 100 || healthyPages == 100 || issues == 100;

  useEffect(() => {
    const optimizationScore = getOttoV2Project?.beforeSummary['seoOptimizationScore'];
    const healthyPages = (getOttoV2Project?.beforeSummary['healthyPages'] / getOttoV2Project?.beforeSummary['totalPages']) * 100;
    const issues = (getOttoV2Project?.beforeSummary['deployedFixes'] / getOttoV2Project?.beforeSummary['foundIssues']) * 100;
    const statsArr = [
      {value: issues, name: 'issues', label: `Issues fixed: ${getOttoV2Project?.beforeSummary['deployedFixes'] || 0}`},
      {value: healthyPages, name: 'pages', label: `Healthy pages: ${getOttoV2Project?.beforeSummary['healthyPages'] || 0}`},
      {value: optimizationScore, name: 'optimization', label: `Optimization Score: ${getOttoV2Project?.beforeSummary['seoOptimizationScore'] || 0}%`},
    ];
    const isCompleted = statsArr?.filter(stat => stat.value === 100).length === statsArr.length;
    let series: any[] = statsArr?.map((stat, index) => {
      return [1, 2].map(n => (
        {
          name: stat.label,
          type: 'pie',
          radius: [25 + (20 * index), 37 + (20 * index)],
          hoverOffset: 0,
          labelLine: {
            show: false,
          },
          clockwise: false,
          silent: n === 2 ? true : false,
          z: n === 2 ? 0 : 1,
          animation: n === 2 ? false : true,
          data: n === 2 ? [
            {value: 100, name: '', itemStyle: {color: '#D6DEE4'}, label: {show: false}},
          ] : [
            {value: Math.round(stat.value), name: '', itemStyle: {color: getChartColor(stat.value, stat.name), borderRadius: 12}},
            {value: Math.round(100 - stat.value), name: '', itemStyle: {color: 'transparent'}, label: {show: false}},
          ],
        }
      ));
    });
    series = series?.flatMap(el => el);
    setSeriesData(series);
    setOttoStats({
      optimization: optimizationScore,
      pages: healthyPages,
      issues,
      isCompleted,
    });
  }, [getOttoV2Project]);

  return <StatsWrapper>
    <div style={{minWidth: 160, height: 'fit-content', position: 'relative'}}>
      <div style={{color: 'black', marginTop: 16, fontWeight: 400, fontSize: 12, lineHeight: '14.52px', textAlign: 'center', opacity: 1}}>Before</div>
      <br />
      <ReactEcharts
        style={{width: '160px', height: '160px', alignSelf: 'center', transform: 'rotate(5deg)'}}
        opts={{renderer: 'svg'}}
        option={{
          title: [{
            text: '{titleIcon|}',
            top: '-2px',
            left: '40%',
            textAlign: 'center',
            textStyle: {
              rich: {
                titleIcon: {
                  height: 14,
                  width: 14,
                  backgroundColor: {
                    image: '/icons/arrow-trend.svg',
                  },
                  transform: 'rotate(-30deg)',
                  opacity: 0.7,
                },
              },
            },
          }, {
            text: '{titleIcon|}',
            top: '20px',
            left: '43%',
            textAlign: 'center',
            textStyle: {
              rich: {
                titleIcon: {
                  height: 10,
                  width: 10,
                  backgroundColor: {
                    image: '/icons/page-icon.svg',
                  },
                  rotate: 30,
                  opacity: 0.7,
                },
              },
            },
          }, {
            text: '{titleIcon|}',
            top: '40px',
            left: '44%',
            textAlign: 'center',
            textStyle: {
              rich: {
                titleIcon: {
                  height: 10,
                  width: 10,
                  backgroundColor: {
                    image: '/icons/bug-icon.svg',
                  },
                  transform: 'rotate(-30deg)',
                  opacity: 0.7,
                },
              },
            },
          }],
          tooltip: {
            trigger: 'item',
            formatter: '{a}',
            confine: true,
            appendToBody: true,
          },
          series: seriesData,
        }}
      />
      {isCompletedV2 && <ImgStyled src={'/icons/white-check-mark.svg'}/>}
    </div>
    <div className='scores-wrapper'>
      <div className='score'>
        <Tooltip title='A metric that indicates how well-optimized and issue-free your website is. A higher score is better.'>
          <FontAwesomeIcon
            icon={faArrowTrendUp}
            color={getChartColor(ottoStats?.optimization, 'optimization')}
            fontSize={20}
          />
        </Tooltip>
        <div>
          <Tooltip title='A metric that indicates how well-optimized and issue-free your website is. A higher score is better.'>
            <p className='title'>Optimization Score</p>
          </Tooltip>
          <div>
            <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.beforeSummary['seoOptimizationScore'] || 0) ?? 'N/A'}</span>
            <span className='fixed-total'>/ 100</span>
          </div>
        </div>
      </div>
      <div className='score'>
        <Tooltip overlayInnerStyle={{minWidth: '260px'}} title={(
          <div>
          Pages that had no issue during the initial crawl or pages that were fixed with OTTO.
            <div>{`Note: pages must have ALL issues fixed or they won't show as healthy.`}</div>
          </div>
        )}>
          <FontAwesomeIcon
            icon={faPage}
            color={getChartColor(ottoStats?.pages, 'pages')}
            fontSize={20}
          />
        </Tooltip>
        <div>
          <Tooltip overlayInnerStyle={{minWidth: '260px'}} title={(
            <div>
            Pages that had no issue during the initial crawl or pages that were fixed with OTTO.
              <div>{`Note: pages must have ALL issues fixed or they won't show as healthy.`}</div>
            </div>
          )}>
            <p className='title'>Healthy pages</p>
          </Tooltip>
          <div>
            <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.beforeSummary['healthyPages'] || 0) ?? 'N/A'}</span>
            <span className='fixed-total'>/ {numberWithCommas(getOttoV2Project?.beforeSummary['totalPages'] || 0) ?? 'N/A'}</span>
          </div>
        </div>
      </div>
      <div className='score'>
        <Tooltip title='Only the issues fixed with OTTO (excluding issues fixed by other means).'>
          <FontAwesomeIcon
            icon={faBug}
            color={getChartColor(ottoStats?.issues, 'issues')}
            fontSize={20}
          />
        </Tooltip>
        <div>
          <Tooltip title='Only the issues fixed with OTTO (excluding issues fixed by other means).'>
            <p className='title'>Issues fixed with OTTO</p>
          </Tooltip>
          <div>
            <span className='fixed-count'>{numberWithCommas(getOttoV2Project?.beforeSummary['deployedFixes'] || 0) ?? 'N/A'}</span>
            <span className='fixed-total'>/ {numberWithCommas(getOttoV2Project?.beforeSummary['foundIssues'] || 0) ?? 'N/A'}</span>
          </div>
        </div>
      </div>
    </div>
  </StatsWrapper>;
});

export const OttoSeo = observer(()=> {
  return <Wrapper id='ottoSection'>
    <Before />
    <FontAwesomeIcon className='arrow' icon={faArrowRight} />
    <After />
  </Wrapper>;
});

const Wrapper = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 266px;
  .arrow{
    font-size: 33px;
    color: gray;
  }
`;

const StatsWrapper = styled.div`
  height: 100%;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1400px) {
    justify-content: center;
  }
    .title{
    color: black;
    }
  p {
    margin-top: 3px !important;
    margin-bottom: 0 !important;
  }
  .scores-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #fff;
    svg {
      min-width: 20px;
    }
    .score {
      display: flex;
      gap: 10px;
      font-size: 14px;
      line-height: 16.94px;
      font-weight: 500;
      .fixed-count {
        font-size: 24px;
        font-weight: 600;
        line-height: 29.05px;
        margin-right: 5px;
        color: black;
      }
      .fixed-total {
        color: #A3A4A4;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.52px;
      }
    }
  }
`;

const ImgStyled = styled.img`
  position: absolute;
  top: 39%;
  left: 44%;
`;
