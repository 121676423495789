import {getApiUrl, getAuthorizationHeader} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';

class TopPagesApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.GSC_ENDPOINT, '/search-console/api/v2');

  public async getOrganicSearchReports(property, countryCode, periodStart, periodEnd) {
    try {
      const response = await this.axios.get(`${TopPagesApi.baseUrl}/top-pages-overview/`, {
        params: {
          property: property,
          period_start: periodStart,
          period_end: periodEnd,
          country_code: countryCode,
        },
        headers: {
          Authorization: getAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default TopPagesApi;
