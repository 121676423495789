import {CommonInput} from '@/components/common-components';
import {Divider} from 'antd';
import styled from 'styled-components';
import {useState} from 'react';

interface CompanyInfoProps {
  setCompanyName?: (value: string) => void;
  companyName?: string;
  setWebsiteUrl?: (value: string) => void;
  websiteUrl?: string;
  setTargetUrl?: (value: string) => void;
  targetUrl?: string;
  setBusinessAddress?: (value: string) => void;
  businessAddress?: string;
  setGbpUrl?: (value: string) => void;
  gbpUrl?: string;
  setEmail?: (value: string) => void;
  email?: string;
  setPhone?: (value: string) => void;
  phone?: string;
  setMediaContactName?: (value: string) => void;
  mediaContactName?: string;
  setMediaContactEmail?: (value: string) => void;
  mediaContactEmail?: string;
  setMediaContactPhone?: (value: string) => void;
  mediaContactPhone?: string;
  defaultCompanyName?: string;
  companyNameError?: string;
  setCompanyNameError?: (value: string) => void;
}

export const CompanyInfo = ({
  setCompanyName,
  companyName,
  setWebsiteUrl,
  websiteUrl,
  setTargetUrl,
  targetUrl,
  setBusinessAddress,
  businessAddress,
  setGbpUrl,
  gbpUrl,
  setEmail,
  email,
  setPhone,
  phone,
  setMediaContactName,
  mediaContactName,
  setMediaContactEmail,
  mediaContactEmail,
  setMediaContactPhone,
  mediaContactPhone,
  companyNameError,
  setCompanyNameError,
}: CompanyInfoProps) => {
  const [errors, setErrors] = useState({});
  const urlPrefixes = ['https://', 'http://www.', 'http://', 'https://www.', 'www.'];
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const telPattern = /^\+?[1-9]\d{1,14}$/;
  const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  const urlRegexWithWww = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

  const validateUrl = value => {
    if (urlPrefixes.some(url => value?.startsWith(url))) {
      return !value || urlRegexWithWww.test(value);
    } else {
      return !value || urlRegex.test(value);
    }
  };

  const validateEmail = value => {
    return !value || emailPattern.test(value);
  };

  const validateTel = value => {
    return !value || telPattern.test(value);
  };

  const handleChange = (e, setter, type, index) => {
    const value = e.target.value;
    setter(value);

    let isValid = true;
    let errorMsg = '';

    switch (type) {
      case 'url':
        isValid = validateUrl(value);
        errorMsg = isValid ? '' : 'URL must be valid';
        break;
      case 'email':
        isValid = validateEmail(value);
        errorMsg = isValid ? '' : 'Email must be valid';
        break;
      case 'tel':
        isValid = validateTel(value);
        errorMsg = isValid ? '' : 'Phone number must be valid';
        break;
      default:
        break;
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [index]: {
        isValid,
        errorMsg,
      },
    }));
  };

  const companyInfoFields = [
    {
      label: 'Company Name',
      key: 'company_name',
      value: companyName,
      onChange: handleChange,
      setter: setCompanyName,
      placeholder: 'Enter company name',
      type: 'text',
    },
    {
      label: 'Website',
      key: 'website_url',
      value: websiteUrl,
      onChange: handleChange,
      setter: setWebsiteUrl,
      placeholder: 'Enter website url',
      type: 'url',
    },
    {
      label: 'Target URL',
      key: 'target_url',
      value: targetUrl,
      onChange: handleChange,
      setter: setTargetUrl,
      placeholder: 'Enter target url',
      type: 'url',
    },
    {
      label: 'GBP URL',
      key: 'gbp_url',
      value: gbpUrl,
      onChange: handleChange,
      setter: setGbpUrl,
      placeholder: 'Enter gbp url',
      type: 'url',
    },
    {
      label: 'Address',
      key: 'business_address',
      value: businessAddress,
      onChange: handleChange,
      setter: setBusinessAddress,
      placeholder: 'Enter business address',
      type: 'text',
    },
    {
      label: 'Company Email',
      key: 'email',
      value: email,
      onChange: handleChange,
      setter: setEmail,
      placeholder: 'Enter company email',
      type: 'email',
    },
    {
      label: 'Company Phone',
      key: 'phone',
      value: phone,
      onChange: handleChange,
      setter: setPhone,
      placeholder: 'Enter company phone',
      type: 'tel',
    },
    {
      label: 'Contact Name',
      key: 'media_contact_name',
      value: mediaContactName,
      onChange: handleChange,
      setter: setMediaContactName,
      placeholder: 'Enter contact name',
      type: 'text',
    },
    {
      label: 'Contact Email',
      key: 'media_contact_email',
      value: mediaContactEmail,
      onChange: handleChange,
      setter: setMediaContactEmail,
      placeholder: 'Enter contact email',
      type: 'email',
    },
    {
      label: 'Contact Phone',
      key: 'media_contact_phone',
      value: mediaContactPhone,
      onChange: handleChange,
      setter: setMediaContactPhone,
      placeholder: 'Enter contact phone',
      type: 'tel',
    },
  ];
  const validateInput = value => {
    const trimmedValue = value.trim();
    if (!trimmedValue) {
      setCompanyNameError('Company Name is required');
    } else {
      setCompanyNameError('');
    }
  };

  return (
    <CompanyInfoContainer>
      {companyInfoFields?.map((companyInfoField, index) => (
        <>
          <CompanyFieldContainer key={index}>
            <StyledLabel>{companyInfoField?.label}</StyledLabel>
            <Divider style={{height: 32}} type='vertical' />
            <div>
              <CommonInputStyled
                value={companyInfoField?.value}
                // disabled={(companyInfoField?.key=='company_name' && !!defaultCompanyName)}
                onChange={e => {
                  companyInfoField?.onChange(e, companyInfoField?.setter, companyInfoField?.type, index);
                  if (companyInfoField?.key=='company_name') {
                    validateInput(e.target.value);
                  }
                }}
                type={companyInfoField?.type}
                variant='white'
                placeholder={companyInfoField?.placeholder} />
              {errors[index] && !errors[index]?.isValid && <ErrorMessage>{errors[index]?.errorMsg}</ErrorMessage>}
              {companyInfoField?.key === 'company_name' && companyNameError && (
                <ErrorMessage>{companyNameError}</ErrorMessage>
              )}
            </div>
          </CompanyFieldContainer>
          {index !== companyInfoFields?.length-1 && <Divider style={{margin: '0px'}} />}
        </>
      ))}
    </CompanyInfoContainer>
  );
};

const CommonInputStyled = styled(CommonInput)<{disabled?: boolean;border?: boolean; boxShadow?: boolean}>`
  color: ${p => p.disabled ? '#a3a4a4' : '#121212 !important'};
  border-radius: 6px !important;
  ${p => p.boxShadow && 'box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.02)'};
  ${p => !p.border && 'border: 1px solid transparent !important'};
  padding-right: 8px !important;
  height: 32px !important;
  width: 180px !important;
  
  &:focus {
    box-shadow: none !important;
  }
 
`;

const CompanyInfoContainer = styled.div`
  border: 1px solid #E8E8E8;
  border-radius: 10px;
  width: 330px;
  padding: 5px;
`;

const CompanyFieldContainer = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
`;

const StyledLabel = styled.div`
  width: 180px;
  color: #a3a4a4;
`;

const ErrorMessage = styled.span`
  color: red;
  font-size: 10px;
  margin-left: 8px !important;
`;
