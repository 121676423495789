import React, {useState, Fragment} from 'react';
import {Row, Col, Form} from 'antd';
import {
  Typography,
  CommonInput,
} from '@/components/common-components/components/index';
import ButtonComponent from '@/components/common-components/components/button-component';
import {profileApi} from '@/api/account/index';
import {notification} from '@/utils/notification-v2';
import {Eye, EyeOff} from 'react-feather';

import {useTranslation} from 'next-i18next';
import styles from '@/components/dashboard/settings/styles.module.scss';

import {MixPanel} from '@/utils/mixpanel';
import {SETTINGS_PASSWORD_UPDATE} from '@/constants/events';
import {useStore} from '@/store/root-store';

export const UpdatePasswordSection: React.FC = () => {
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation('common');
  const [currentPasswordValue, setCurrentPasswordValue] = useState('');
  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [newPasswordLengthError, setNewPasswordLengthError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [form] = Form.useForm();

  const {settings: {customer: {profile: {isVendastaClient}}}} = useStore('');

  const onUpdatePassword = async ({currentPassword, newPassword}: {currentPassword: string; newPassword: string}) => {
    setLoading(true);
    const details = {
      currentPassword: currentPassword,
      newPassword: newPassword,
    };

    MixPanel.track(SETTINGS_PASSWORD_UPDATE, {
      'onAccountPasswordUpdate': `${details}`,
    });


    if (currentPassword && newPassword) {
      if (newPassword.length >= 8) {
        const response = await profileApi.updatePassword(
          currentPassword,
          newPassword,
        );
        if (response && response.status == 200) {
          notification.success(t('done'), t('password-update-success'));
        } else {
          setPassError(true);
        }
      } else {
        setNewPasswordLengthError(true);
      }
    }
    form.setFieldsValue({
      currentPassword: '',
      newPassword: '',
    });
    setCurrentPasswordValue('');
    setNewPasswordValue('');
    setLoading(false);
  };

  return (
    <Fragment>
      {isVendastaClient ? '' :<>
        <div className={styles.content} style={{padding: 25}}>
          <Typography tag='h4' className={styles.title}>{t('Update password')}</Typography>
          <Form onFinish={onUpdatePassword} form={form}>
            <Row gutter={20} style={{marginTop: '27px'}}>
              <Col xl={12} md={12} xs={24}>
                <Form.Item name='currentPassword' label={'Current password'} labelAlign={'left'} requiredMark={false} required style={{flexDirection: 'column'}}>
                  <CommonInput
                    className={styles.Place}
                    inputType={showCurrentPass ? 'text' : 'password'}
                    onChange={e => {
                      setCurrentPasswordValue(e.target.value);
                      setPassError(false);
                    }}
                    placeholder={passError ? t('password-invalid') : null}
                  />
                </Form.Item>
                <span className={styles.passIcon} onClick={() => setShowCurrentPass(!showCurrentPass)}>
                  {showCurrentPass ? <EyeOff width={20} /> : <Eye width={20} />}
                </span>
              </Col>
              <Col xl={12} md={12} xs={24}>
                <Form.Item name='newPassword' label={'New password'} labelAlign={'left'} requiredMark={false} required style={{flexDirection: 'column'}}>
                  <CommonInput
                    inputType={showNewPass ? 'text' : 'password'}
                    onChange={e => {
                      setNewPasswordValue(e.target.value); setNewPasswordLengthError(false);
                    }}
                  />
                </Form.Item>
                <span className={styles.passIcon} onClick={() => setShowNewPass(!showNewPass)}>
                  {showNewPass ? <EyeOff width={20} /> : <Eye width={20} />}
                </span>
                <span className={styles.errMsg}>{newPasswordLengthError && t('new-password-8-long')}</span>
              </Col>
            </Row>
            <div className={styles.flexContainer}>
              <Form.Item>
                <ButtonComponent
                  color='primary'
                  style='solid'
                  type='submit'
                  /*       className={styles['header__buttons-btn']} */
                  disabled={loading || currentPasswordValue === '' || newPasswordValue === ''}
                >
                  {t('update-password')}
                </ButtonComponent>
              </Form.Item>
            </div>
          </Form>
        </div></>}
    </Fragment>
  );
};
