import moment from 'moment';
import * as momentTimezone from 'moment-timezone';

export const getCurrentDate=()=>{
  return moment().format('DD-MM-YYYY');
};

// Using this func convert date like this "Mon, 11 Dec 2023 02:08:14 -0800" into this "Dec 11, 2023 at 8:05 PM"
export const formatDateWithTimeZone = inputDate => {
  const date = new Date(inputDate);

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  const formattedDate = `${month} ${day}, ${year} at ${hours}:${minutes} ${ampm}`;

  return formattedDate;
};

// Using this func convert date like this "2023-12-12T06:26:01.620788-08:00" into this "Dec 7 at 3:20AM"
export const formatDate = (timestamp: string) => {
  const date = new Date(timestamp);

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  const day = date.getDate();
  const month = monthNames[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  const formattedDate = `${month} ${day} at ${formattedHours}:${minutes}${ampm}`;

  return formattedDate;
};

export const getTimeAgo = (timestamp: string) => {
  const currentDate = new Date();
  const repliedAtDate = new Date(timestamp);

  const timeDifferenceInSeconds = Math.floor((currentDate.getTime() - repliedAtDate.getTime()) / 1000);

  const minutes = Math.floor(timeDifferenceInSeconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30.44); // Assuming an average month length of 30.44 days
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? '1 year ago' : `${years} years ago`;
  } else if (months > 0) {
    return months === 1 ? '1 month ago' : `${months} months ago`;
  } else if (days > 0) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
  } else {
    return minutes <= 1 ? 'just now' : `${minutes} minutes ago`;
  }
};

export const getFormatedDate = (key, date) => {
  switch (key) {
    case 'oneMonth':
      return moment(date).subtract(1, 'months').format('MMMM DD, YYYY');
    case 'threeMonth':
      return moment(date).subtract(3, 'months').format('MMMM DD, YYYY');
    case 'sixMonth':
      return moment(date).subtract(6, 'months').format('MMMM DD, YYYY');
    case 'oneYear':
      return moment(date).subtract(1, 'years').format('MMMM DD, YYYY');
    default:
      return moment(date).subtract(1, 'years').format('MMMM DD, YYYY');
  }
};


export const createDateWithTimeZoneAndCurrentTime = (dateString, timeZone) => {
  const m = momentTimezone.utc(dateString);
  if (timeZone) {
    return m.clone().tz(timeZone).format('MMM DD, YYYY');
  } else {
    return m.clone().format('MMM DD, YYYY');
  }
};

export const getFormattedPreviousDate = (startDate, endDate) => {
  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  const daysDifference = momentEndDate.diff(momentStartDate, 'days');

  const previousPeriodEndDate = momentStartDate.clone().subtract(1, 'day').format('YYYY-MM-DD');

  const previousPeriodStartDate = moment(previousPeriodEndDate).subtract(daysDifference, 'days').format('YYYY-MM-DD');

  return `${moment(previousPeriodStartDate).format('ll')} - ${moment(previousPeriodEndDate).format('ll')}`;
};

export const convertMinutesIntoReadAbleTime = (minutes, json=false) => {
  if ((typeof minutes) != 'number') {
    return minutes;
  }
  const days = Math.floor(minutes / 1440); // 1 day = 1440 minutes
  minutes %= 1440;

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (json) {
    return {
      days: days,
      hours: hours,
      minutes: remainingMinutes,
    };
  } else {
    let result = '';

    if (days > 0) {
      result += `${days} day${days > 1 ? 's' : ''} `;
    }

    if (hours > 0) {
      result += `${hours} hour${hours > 1 ? 's' : ''} `;
    }

    if (remainingMinutes > 0) {
      result += `${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''} `;
    }

    if (result == '') {
      result = '-';
    }
    return result.trim();
  }
};
