import styles from './style.module.scss';
import {MainSection} from './DraggableStructure/MainSection';
import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';
import {Divider} from 'antd';
import {toJS} from 'mobx';

export const AiFirstDraftGenerator = observer(() => {
  const {contentOptimizer: {aiOutline: {setAiTemplate, setIsOpenAiContentToolsDrawer, aiTemplatesController, v2AiOutline, showOutlinesInDrawer, setShowOutlinesInDrawer}}} = useStore('');

  return <>
    <Header>
      <Left>
        <BackArrowContainer onClick={() => {
          if (aiTemplatesController?.isStandaloneTool) {
            setIsOpenAiContentToolsDrawer(false);
          }
          setAiTemplate({
            ...toJS(aiTemplatesController ?? []),
            showForm: false,
            templateType: '',
            templateIcon: '',
            templateDesc: '',
            templateName: '',
            fieldsSetup: [],
            defaultForm: false,
            defaultFormType: null,
            isStandaloneTool: false,
          });
        }}>
          <FontAwesomeIcon icon={faArrowLeft} style={{marginRight: 10}}/>
        </BackArrowContainer>
        <ImgContainer>
          <img src={aiTemplatesController?.templateIcon} style={{height: 25, margin: 'auto'}}/>
        </ImgContainer>


      </Left>
      <DescContainer>
        <TemplateName>{aiTemplatesController?.templateName}</TemplateName>
        <TemplateDesc>{aiTemplatesController?.templateDesc}</TemplateDesc>
      </DescContainer>
    </Header>
    <Divider />
    {/* <div className={styles.contentGeneratorTitle}>
              {showOutlinesInDrawer ? `${v2AiOutline?.length} Outlines Generated` : 'AI Outline Generator'}
            </div> */}
    <p style={{marginTop: 10, marginBottom: 10, color: '#4E5156', maxWidth: 530}}>
              Provide an Article Title, 2 Focus Topics, and a few Topical Terms to generate a SEO Optimized First Draft with our innovative AI Content Generator
    </p>
    {(!showOutlinesInDrawer && v2AiOutline?.length) ?
      <div style={{display: 'flex'}}>
        <p style={{marginRight: 10, minWidth: 120}}>Previous Outline:</p>
        <span style={{color: '#2e6cca', cursor: 'pointer'}} onClick={() => setShowOutlinesInDrawer(true)}>
          {/* eslint-disable-next-line */}
                  {v2AiOutline[0]?.match('(?<=<h1>)(.*)(?=<\/h1>)')[0]}
        </span>
      </div> :
      null}

    <div className={styles.contentIGeneratorDrawerContent}>
      <MainSection />
      {/* {(v2AiOutline?.length && showOutlinesInDrawer) ? <AiOutlineList outlines={v2AiOutline} /> : <MainSection />
      } */}
    </div>
  </>;
});


const Header = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;

const ImgContainer = styled.div`
  background: #F2F2F5;
  border-radius: 10px;
  display: flex;
  width: 38px;
  height: 38px;
  margin-right: 13px;
`;
const DescContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%
`;
const TemplateName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #121212;
  margin-bottom: 4px;
`;
const TemplateDesc = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #4E5156;
`;
const BackArrowContainer = styled.div`
  width: 30px;
  cursor: pointer;
`;
