import {useStore} from '@/store/root-store';
import {observer} from 'mobx-react-lite';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {
  CommonInput,
} from '@/components/common-components/components';
import {Divider, Dropdown, Select as AntdSelect, Spin, Tooltip} from 'antd';
import styles from './style.module.scss';
import {languages} from '../languages';
import Flag from '@/components/common-components/components/flag';
import {formatCountryCode} from '@/components/dashboard/pages/content-ideas/drawer/languageUtil';
import {Button, GrayButton} from '@/components/common-components/v2';
import {LoadingOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {faSquareQuestion} from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  onCancelClick: () => void;
}

export const SettingsPopup = observer(({onCancelClick}: Props) => {
  const antIcon = <LoadingOutlined style={{fontSize: 16}} spin />;

  const {
    contentOptimizer: {
      aiOutline: {
        userAiSettings,
        getUserAiSettings,
        loadingUserAiSettings,
        updateUserAiSettings,
      },
    },
  } = useStore('');

  const {Option} = AntdSelect;

  const pointOfViewSetup = [{
    label: 'First person',
    value: 'first person',
  }, {
    label: 'Second person',
    value: 'second person',
  },
  {
    label: 'Third person',
    value: 'third person',
  },
  ];

  const [toneOfVoice, setToneOfVoice] = useState<string>('');
  const [language, setLanguage] = useState<{language: string; code: string}>(userAiSettings?.defaultLanguage ? {language: languages.find(item => item.code == userAiSettings?.defaultLanguage)?.language ?? '', code: userAiSettings?.defaultLanguage} : {language: 'English', code: 'en'});
  const [pointOfView, setPointOfView] = useState<string>(pointOfViewSetup[0].value);


  useEffect(() => {
    getUserAiSettings();
  }, []);

  useEffect(() => {
    if (userAiSettings?.toneOfVoice) {
      setToneOfVoice(userAiSettings.toneOfVoice);
    }
    if (userAiSettings?.pointOfView) {
      setPointOfView(userAiSettings.pointOfView);
    }
    if (userAiSettings?.defaultLanguage) {
      setLanguage({language: languages.find(item => item.code == userAiSettings?.defaultLanguage)?.language ?? '', code: userAiSettings?.defaultLanguage});
    }
  }, [userAiSettings]);


  const onLanguageChangeHandler = (value, option) => {
    setLanguage({language: option.country, code: option.value});
  };

  const updateSettingsHandler = async () => {
    try {
      await updateUserAiSettings({language: language.code, toneOfVoice, pointOfView});
      onCancelClick();
    } catch (e) {
      return Promise.reject(e);
    }
  };


  const standardTovs = ['Casual', 'Professional', 'Funny', 'Angry'];
  const dropdownTovs = ['Excited', 'Witty', 'Sarcastic', 'Feminine', 'Masculine', 'Bold', 'Dramatic', 'Grumpy', 'Secretive'];
  const advancedTovs = [{
    label: 'Yoda',
    color: '#499636',
    icon: <img src={'/img/logo/yoda-logo.svg'} style={{marginRight: 5}}/>,
  },
  {
    label: 'Tony Stark',
    color: '#d13234',
    icon: <img src={'/img/logo/avengers-logo.svg'} style={{marginRight: 5}}/>,
  },
  {
    label: 'Shakespeare',
    color: '#46609C',
    icon: <img src={'/img/logo/shakespear-logo.svg'} style={{marginRight: 5}}/>,
  }];

  const tovsDropdownRender = () => {
    return <DropdownMenuContainer>
      {
        dropdownTovs.map((tov, idx) => {
          return <>
            <SingleDropdownValue key={idx} onClick={() => setToneOfVoice(tov)}>{tov}</SingleDropdownValue>
            {idx < dropdownTovs.length - 1 ? <Divider style={{marginTop: 0, marginBottom: 0}}/> : null}
          </>;
        })
      }
    </DropdownMenuContainer>;
  };


  return <Wrapper>
    <TopSection>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
        <p style={{marginBottom: 5, color: '#4E5156'}}>
          Tone of Voice
          <Tooltip
            overlayInnerStyle={{width: 400, backgroundColor: '#121212', borderRadius: 8}}
            placement='top'
            title='There is no pre-defined list, you can try anything you want, the AI Writter can understand almost anything you throw at it.'>
            <FontAwesomeIcon icon={faSquareQuestion} style={{width: 12, marginLeft: 5}} color={'#A3A4A4'}/>
          </Tooltip>
        </p>
        {loadingUserAiSettings ? <Spin indicator={antIcon} /> : null}
      </div>

      <CommonInput
        type='text'
        variant='lightGrey'
        value={toneOfVoice}
        allowClear={{clearIcon: <FontAwesomeIcon icon={faXmark}/>}}
        onChange={e => setToneOfVoice(e.target.value)}
        placeholder='Professional'/>
      <SuggestionsContainer>
        <div style={{display: 'flex'}}>
          {advancedTovs.map((tov, idx) => {
            return <AdvancedTov key={idx} color={tov.color} onClick={() => setToneOfVoice(tov.label)}>
              {tov.icon}
              <span style={{color: '#4E5156'}}>{tov.label}</span>
            </AdvancedTov>;
          })}
        </div>
        <div style={{display: 'flex', marginTop: 8}}>
          {standardTovs.map((tov, idx) => {
            return <StandardTov key={idx} onClick={() => setToneOfVoice(tov)}>{tov}</StandardTov>;
          })}
          <DropdownStyled overlay={tovsDropdownRender()} placement='bottomLeft' trigger={['click']}>
            <Button>{`+ ${dropdownTovs.length}`}</Button>
          </DropdownStyled>
        </div>
      </SuggestionsContainer>

    </TopSection>
    <BottomSection>
      <Left>
        <p style={{marginBottom: 5, color: '#4E5156'}}>Language</p>
        <StyledSelect
          variant={'grey'}
          showSearch
          className={styles.selectInput}
          value={language.language}
          onChange={onLanguageChangeHandler}
          filterOption={(input, option) =>
            option.country.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {
            languages.map((lang, index) => {
              return (
                <Option disabled={false} key={index} country={lang.language} value={lang.code}>
                  <Flag width={12} code={formatCountryCode(lang.language, lang.code)} style={{marginRight: '5px'}}></Flag>
                  {lang.language}
                </Option>
              );
            })
          }
        </StyledSelect>
      </Left>
      <Right>
        <p style={{marginBottom: 5, color: '#4E5156'}}>Point of view</p>
        <StyledSelect
          variant={'grey'}
          showSearch
          className={styles.selectInput}
          value={pointOfView}
          width={'150px'}
          onChange={value => {
            setPointOfView(value as string);
          }}
        >
          {
            pointOfViewSetup.map((pov, index) => {
              return (
                <Option disabled={false} key={index} value={pov.value}>
                  {pov.label}
                </Option>
              );
            })
          }
        </StyledSelect>
      </Right>
    </BottomSection>

    <ButtonContainer>
      <GrayButton onClick={() => onCancelClick()}>Cancel</GrayButton>
      <Button onClick={() => updateSettingsHandler()} disabled={loadingUserAiSettings}>Update</Button>
    </ButtonContainer>
  </Wrapper>;
});

const dropdownColors = {
  grey: {
    color: '#333',
    backgroundColor: '#F9F9FB',
    listItemsBackgroundColor: '#F9F9FB',
    borderColor: '#F9F9FB',
  }};

const Wrapper = styled.div`
  width: 350px;
`;

const TopSection = styled.div`
  .ant-input {
    background-color: transparent;
  }

`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

`;

const Left = styled.div`
  width: 45%;
`;

const Right = styled.div`

`;


const StyledSelect = styled(AntdSelect)<{variant?: string; borderRadius?: string; width?: string; height?: string; marginLeft?: string}>`
  color:${ p => dropdownColors[p.variant].color};
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-radius: ${ p => p.borderRadius ? p.borderRadius : '5px'} !important;
  .ant-select-selector {
    max-width:180px !important;
    min-width: ${p => p.width ? p.width : '168px'} !important;
    width: ${p => p.width ? p.width : '168px'} !important;
    height: ${p => p.height ? p.height : '38px'} !important;
    margin-left: ${p => p.marginLeft ? p.marginLeft : '6px'} !important;
    border-radius: ${ p => p.borderRadius ? p.borderRadius : '5px'} !important;
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-color: ${ p => dropdownColors[p.variant].borderColor} !important;
    padding: 6px 15px !important;
    cursor: pointer !important;
    line-height: unset !important;
  }
  .ant-select-selection-search-input {
    margin-top: -5px !important;
  }
  .ant-select-selection-search {
    padding: 6px 0;
  }
 .ant-select-arrow {
  color:${ p => dropdownColors[p.variant].color};
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: ${ p => dropdownColors[p.variant].backgroundColor} !important;
    border-color: ${ p => dropdownColors[p.variant].borderColor} !important
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;
const SuggestionsContainer = styled.div`
    margin-top: 10px;
`;
const StandardTov = styled.div`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 38px;
  padding: 3px 10px;
  margin-right: 5px;
  color: #4E5156;
  cursor: pointer;
  font-size: 12px;
`;


const AdvancedTov = styled.div<{color: string}>`
  padding: 3px 10px;
  background: ${p => `${p.color}1F`};
  border: ${p => `1px solid ${p.color}0A`};
  border-radius: 38px;
  margin-right: 5px;
  display: flex;
  cursor: pointer;
  font-size: 12px;
`;

const DropdownStyled = styled(Dropdown)`
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 38px;
  padding: 3px 10px;
  margin-left: 0;
  color: #4E5156;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background: #FFFFFF !important;
    color: #4E5156;
    border: 1px solid #E8E8E8;
  }
  &:focus {
    background: #FFFFFF !important;
    color: #4E5156;
    border: 1px solid #E8E8E8;
  }
`;

const DropdownMenuContainer = styled.div`
  background: #FFFFFF;
  box-shadow:  0px 3px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 160px;
  padding-top: 2px;
  padding-bottom: 2px;
`;
const SingleDropdownValue = styled.div`
  margin: 5px 14px;
  cursor: pointer;
`;
