import {useState, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {
  Tabs,
  TabPane,
} from '@/components/common-components/components/index';
import {RootStoreType} from '@/store/root-store';
import {useRouter} from 'next/router';

import {YourProfile} from './settings/YourProfile';
// import {GSCIntegration} from './settings/GSCIntegration';
import {TabPanel, TabPanelProps} from './settings/TabPanel';
import TeamMembers from './settings/TeamMembers';
import {useTranslation} from 'next-i18next';
import styles from './styles.module.scss';
import {useStore} from '@/store/root-store';
// import {routes} from '@/utils/const';

import {MixPanel} from '@/utils/mixpanel';
import {SETTINGS_PROFILE_TAB, SETTINGS_GSC_INTEGRATIONS_TAB, SETTINGS_EMAIL_UPDATES_TAB, SETTINGS_API_KEY_TAB,
  SETTINGS_TEAM_MEMBERS_TAB} from '@/constants/events';
// import {EmailUpdates} from './settings/EmailUpdates';
import {EmailUpdatesV2} from './settings/EmailUpdatesV2';
import {ApiKey} from './settings/ApiKey';
import GASettings from './settings/GaSettings';
import {getSingleUrlParam} from '@/utils/url';


enum TABS {
  TAB_PROFILE = 'profile',
  TAB_GSCINTEGRATION = 'gsc',
  TAB_EMAIL_UPDATES = 'email-updates',
  TAB_EMAIL_UPDATES_V2 = 'email-updates-v2',
  TAB_TEAM_MEMBERS = 'team-members',
  TAB_API_KEY = 'api-key',
  TAB_GA_SETTINGS = 'GSC-GA-Settings',

}

export const SettingsPage = observer(({store}: {
    store: RootStoreType;
}) => {
  const {settings} = store;
  const {integration} = settings;
  const router = useRouter();
  const {t} = useTranslation('common');
  const {gsc: {isDrawerVisible}, settings: {teamMembers: {setFromResponse}}} = useStore('');
  const [activeTab, setActiveTab] = useState('profile');
  const {settings: {customer: {profile: {isVendastaClient}}}} = useStore('');

  useEffect(() => {
    setFromResponse();
  }, []);

  useEffect(() => {
    if (router?.query) {
      if (router.query['active_tab']==='team-members') {
        MixPanel.track(SETTINGS_TEAM_MEMBERS_TAB, {
          'tab': 'team members',
        });
        setActiveTab(TABS.TAB_TEAM_MEMBERS);
      }
      if (router.query['active_tab']==='GSC-GA-Settings') {
        MixPanel.track(SETTINGS_TEAM_MEMBERS_TAB, {
          'tab': ' GSC-GA-Settings',
        });
        setActiveTab(TABS.TAB_GA_SETTINGS);
      }
      if (router.query['active_tab']==='email-updates') {
        MixPanel.track(SETTINGS_EMAIL_UPDATES_TAB, {
          'tab': 'email updates',
        });
        setActiveTab(TABS.TAB_EMAIL_UPDATES);
      }
      if (router.query['active_tab']==='gsc') {
        MixPanel.track(SETTINGS_GSC_INTEGRATIONS_TAB, {
          'tab': 'gsc integrations',
        });
        setActiveTab(TABS.TAB_GSCINTEGRATION);
      }
      if (router.query['active_tab'] == 'api-key') {
        MixPanel.track(SETTINGS_API_KEY_TAB, {
          'tab': 'api key',
        });
        setActiveTab(TABS.TAB_API_KEY);
      }
    } else if (!router.query['active_tab'] && isDrawerVisible) {
      MixPanel.track(SETTINGS_PROFILE_TAB, {
        'tab': 'profile',
      });
      setActiveTab(TABS.TAB_PROFILE);
    }
  }, [router.query]);

  const onTabChange = (key: string) => {
    setActiveTab(key);

    if (key == 'team-members') {
      MixPanel.track(SETTINGS_TEAM_MEMBERS_TAB, {
        'tab': 'team members',
      });
    }
    if (key == 'email-updates') {
      MixPanel.track(SETTINGS_EMAIL_UPDATES_TAB, {
        'tab': 'email updates',
      });
    }
    if (key == 'email-updates-v2') {
      MixPanel.track(SETTINGS_EMAIL_UPDATES_TAB, {
        'tab': 'email updates v2',
      });
    }
    if (key == 'gsc') {
      MixPanel.track(SETTINGS_GSC_INTEGRATIONS_TAB, {
        'tab': 'gsc integrations',
      });
    }
    if (key == 'profile') {
      MixPanel.track(SETTINGS_PROFILE_TAB, {
        'tab': 'profile',
      });
    }
    if (key == 'api-key') {
      MixPanel.track(SETTINGS_API_KEY_TAB, {
        'tab': 'profile',
      });
    }
  };

  const profilePanel: TabPanelProps = {
    heading: t('profile'),
    selected: activeTab == TABS.TAB_PROFILE,
    selectionClass: styles.selectedIcon,
  };

  // const gscPanel: TabPanelProps = {
  //   heading: t('gsc-settings'),
  //   selected: activeTab == TABS.TAB_GSCINTEGRATION,
  //   selectionClass: styles.selectedGscIcon,
  // };

  // const emailUpdatesPanel: TabPanelProps = {
  //   heading: t('email-updates'),
  //   selected: activeTab == TABS.TAB_EMAIL_UPDATES,
  //   selectionClass: styles.selectedEmailIcon,
  // };

  const emailUpdatesPanelv2: TabPanelProps = {
    heading: 'Email Settings',
    selected: activeTab == TABS.TAB_EMAIL_UPDATES_V2,
    selectionClass: styles.selectedEmailIcon,
  };

  const teamMembersPanel: TabPanelProps = {
    heading: t('team-members'),
    selected: activeTab == TABS.TAB_TEAM_MEMBERS,
    selectionClass: styles.selectedEmailIcon,
  };
  const gaSettings: any = {
    heading: 'Google Integrations',
    selected: activeTab == TABS.TAB_GA_SETTINGS,
    selectionClass: styles.selectedEmailIcon,
  };

  const apiKeyPanel: TabPanelProps = {
    heading: ('API Key'),
    selected: activeTab == TABS.TAB_API_KEY,
    selectionClass: styles.selectedEmailIcon,
  };

  useEffect(() => {
    if (getSingleUrlParam('google-search-console') || getSingleUrlParam('google-analytics') || getSingleUrlParam('google-business')) {
      setActiveTab('GSC-GA-Settings');
    }
  }, []);

  return (
    <div className={styles.container}>
      <Tabs tabPosition={'top'} activeKey={activeTab} className={styles.Tabs} onChange={key => onTabChange(key)}>
        <TabPane tab={<TabPanel {...profilePanel}/>} key={'profile'}>
          <YourProfile />
        </TabPane>
        {/* <TabPane tab={<TabPanel {...gscPanel}/>} key={'gsc'}>
          <GSCIntegration profiles={integration.profiles}/>
        </TabPane> */}
        {/* <TabPane tab={<TabPanel {...emailUpdatesPanel}/>} key={'email-updates'}>
          <EmailUpdates />
        </TabPane> */}
        { <TabPane tab={<TabPanel {...emailUpdatesPanelv2}/>} key={'email-updates-v2'}>
          <EmailUpdatesV2 />
        </TabPane>}
        {isVendastaClient ? '':
          <TabPane tab={<TabPanel {...teamMembersPanel}/>} key={'team-members'}>
            <TeamMembers />
          </TabPane>}
        <TabPane tab={<TabPanel {...gaSettings}/>} key={'GSC-GA-Settings'}>
          <GASettings profile={integration.profiles}/>
        </TabPane>
        <TabPane tab={<TabPanel {...apiKeyPanel}/>} key={'api-key'}>
          <ApiKey />
        </TabPane>
      </Tabs>
    </div>
  );
});
