import React, {useEffect, useRef, useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  CommonInput,
  GoogleLoginButtonRedirect,
} from '@/components/common-components/components/index';
import {Form, Row, Col, Input, Alert} from 'antd';
import {useRouter} from 'next/router';
import Link from 'next/link';
import {
  MailOutlined,
  LockOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import styles from './styles.module.scss';
import {authApi} from '@/api/account';
import {setTokenIntoCookies} from '@/api/common-utils';
import {useTranslation} from 'next-i18next';
import {languages} from '@/components/layoutV2';
import {useLeadCapture} from '@/utils/hooks';
import {AccountHeader} from '../components';
import {ValidateStatus} from 'antd/lib/form/FormItem';
import ButtonComponent from '@/components/common-components/components/button-component';
import {getSingleUrlParam} from '@/utils/url';
import Cookies from 'js-cookie';

interface RegistrationForm {
  email: string;
  contactName: string;
  phoneNumber: string;
  companyName: string;
  website: string;
  category: string;
  budget: string;
  password: string;
}

interface Props {
  isCheckoutPage?: boolean;
  isFreeTrail?: boolean;
  openRegisterPage?: (value) => void;
  varificationModal?: () => void;
}

export const RegisterSection: React.FC<Props> = ({openRegisterPage, varificationModal, isCheckoutPage, isFreeTrail}) => {
  const router = useRouter();
  const [showPass] = useState(false);
  const [form] = Form.useForm<RegistrationForm>();
  const [focus, setFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation('common');
  const leadCaptureData = useLeadCapture();
  const registerSource = getSingleUrlParam('source'); ``;
  const referral = getSingleUrlParam('referral') || getSingleUrlParam('fpr') || Cookies.get('_fprom_ref');
  const invitationToken = getSingleUrlParam('invitation_token');
  const inputRef = useRef(null);
  const onlyLettersReg = /[A-Za-z]/;
  const onlySpacesReg = /\s/;

  useEffect(() => {
    focus && inputRef.current.focus();
    setTimeout(() => {
      setFocus(false);
    }, 1000);
  }, [focus]);

  const [errorMessages, setErrorMessages] = useState([]);
  const [errorFieldMessages, setErrorFieldMessages] = useState([]);

  const resetErrorMessages = () => {
    setErrorMessages([]);
    setErrorFieldMessages([]);
  };

  const onBadRequestError = (error: any) => {
    resetErrorMessages();
    const errorData = error.response.data;
    const errorKeys = [
      'email',
      'password',
      'contactName',
      'phoneNumber',
      'nonFieldErrors',
      'detail',
    ];
    const fieldErrorMessages = [];
    const errorMessageList = [];
    errorKeys.forEach(errorKey => {
      const errors = errorData[errorKey];
      if (errors) {
        const errorMessage = Array.isArray(errors) ? errors[0] : errors;
        if (!['nonFieldErrors', 'detail'].includes(errorKey)) {
          const obj = {};
          obj[errorKey] = {
            validateStatus: 'error',
            errorMsg: errorMessage,
          };
          fieldErrorMessages.push(obj);
        } else {
          errorMessageList.push(errorMessage);
        }
      }
    });
    setErrorFieldMessages(fieldErrorMessages);
    setErrorMessages(errorMessageList);
  };

  const onRegister = async ({email, contactName, phoneNumber, password}) => {
    setLoading(true);
    resetErrorMessages();
    const data = {
      contact_name: contactName,
      phone_number: `+${phoneNumber}`,
      email: email,
      is_code_verification: true,
      password: password,
      registration_source: registerSource ?? 'dashboard_main',
      ...leadCaptureData,
    };
    if (referral) {
      data['referral'] = referral;
    }
    if (invitationToken) {
      data['invitation_token'] = invitationToken;
    }
    try {
      const response = await authApi.register(data);
      if (response.status === 201) {
        setTokenIntoCookies(response.data.jwtToken);
        setLoading(false);
        // router.push('/home');
        if ((referral) && (typeof window != 'undefined')) {
          if (window['fpr']) {
            window['fpr']('referral', {email: email});
          }
        }
        varificationModal();
      }
    } catch (error) {
      const status = error.response?.status || 200;
      if ( status === 406) {
        setErrorMessages(['ProtonMail emails are not allowed for registration.']);
        setLoading(false);
        return;
      }
      if (status === 400 || status === 409) {
        onBadRequestError(error);
      } else {
        setErrorMessages([t('went-wrong')]);
      }
    }
    setLoading(false);
  };

  const errors = error => {
    return (
      <ul>
        {error.map((err, index) => (<li key={index}>{err}</li>))}
      </ul>
    );
  };

  const onFinishFailed = errorInfo => {
    resetErrorMessages();
    const errorMessage = [];
    errorInfo.errorFields.forEach(value => {
      const obj = {};
      obj[value.name[0]] = {
        validateStatus: 'error',
        errorMsg: value.errors[0],
      };
      errorMessage.push(obj);
    });
    setErrorFieldMessages(errorMessage);
  };

  // LEAVING THIS COMMENTED OUT IN THE CASE WE WANNA GO BACK TO USING THIRD-PARTY COOKIE BASED GOOGLE LOGIN
  // const onGoogleOAuthSuccess = async (
  //   payload: GoogleLoginResponse | GoogleLoginResponseOffline,
  // ) => {
  //   resetErrorMessages();
  //   const response = await authApi.validateGoogleOAuthResponseAndLogin({
  //     ...payload,
  //     lead: leadCaptureData,
  //   });
  //   setTokenIntoCookies(response.data.token);
  //   router.push('/');
  // };

  // const onGoogleOAuthFailure = (error: any) => {
  //   setErrorMessages(error);
  // };

  const fetchErrorMessage = key => {
    const error = errorFieldMessages.find(value => {
      if (value[key]) {
        return true;
      }
    });

    return error ? error[key] : null;
  };
  const isSiteAuditPages = location.pathname?.includes('/site-audit/');

  return (
    <>
      <AccountHeader
        isRegisterPage
        className={styles.header}
        titleText={isFreeTrail ? 'Get more with a 7 Day Free Trial' : isSiteAuditPages ? 'Audit your own site in minutes' : t('create-your-account')}
        caption={invitationToken ? '' : t('or')}
        subtitleText={isFreeTrail ? 'Open new possibilities and features with higher quotas. You’re going to love it!' : isSiteAuditPages ? 'Register lightning-fast and simplify account set-up by logging in with Google.' : ''}
      >
        {invitationToken ? '' : <div onClick={() => isCheckoutPage ? localStorage.setItem('verifyingFromCheckoutPage', 'TRUE') : ''}>
          <GoogleLoginButtonRedirect buttonText={t('sign-in-google')} />
        </div>}
      </AccountHeader>
      <div
        style={{height: '22px'}}
      />
      <Form className={styles.form} form={form} onFinish={onRegister} onFinishFailed={onFinishFailed}>
        {errorMessages.length > 0 && <Alert
          message={'Error'}
          description={errors(errorMessages)}
          closable
          className={styles.errorMessages}
          type='error'/>}

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name='contactName'
              rules={[
                {
                  required: true,
                  message: t('input-full-name'),
                },
              ]}
            >
              <CommonInput
                cyId='yourName'
                inputType='text'
                onInput={e=>{
                  const chars = e.target.value.split('');
                  const char = chars.pop();
                  if (!onlyLettersReg.test(char) && !onlySpacesReg.test(char)) {
                    e.target.value = chars.join('');
                  }
                }}
                placeholder={t('your-name')}
                /*  isRequired={true} */
                disabled={loading}
                iconClasses={styles.inputIcon}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <span style={{color: '#a3a4a4', fontSize: '12px'}}>
              {t('used-technical-help')}
            </span>
          </Col>
          <Col span={24}>
            <Form.Item
              className={styles.phoneInput}
              name='phoneNumber'
              rules={[
                {
                  required: true,
                  message: t('input-phone-number'),
                },
              ]}
              validateStatus={fetchErrorMessage('phoneNumber')?.validateStatus as ValidateStatus}
              help={fetchErrorMessage('phoneNumber')?.errorMsg}
            >
              <PhoneInput
                onKeyDown={e=> e.key ==='Tab' && setFocus(true)}
                country={'US'}
                inputStyle={{width: '100%'}}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className={styles.emailInput} >
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                message: t('input-valid-email'),
              },
            ]}
            validateStatus={fetchErrorMessage('email')?.validateStatus as ValidateStatus}
            help={fetchErrorMessage('email')?.errorMsg}
          >
            <Input
              data-cy='emailInput'
              ref={inputRef}
              type='email'
              placeholder={t('email')}
              className={styles.inputOverrides}
            />
          </Form.Item>

          <span className={styles.emailIcon}>
            <MailOutlined />
          </span>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: `${t('password')} ${t('must-contain-eight')}`,
                },
              ]}
              validateStatus={fetchErrorMessage('password')?.validateStatus as ValidateStatus}
              help={fetchErrorMessage('password')?.errorMsg}
            >
              <CommonInput
                cyId='passwordInput'
                inputType={showPass ? 'text' : 'password'}
                placeholder={t('password')}
                disabled={loading}
                iconClasses={styles.inputIcon}
                className={styles.inputOverrides}
                icon={<LockOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24}>
            <div className={styles.loginButton}>
              <ButtonComponent
                data-cy='createButton'
                type='submit'
                size='lg'
                className={styles.loginBtn}
                disabled={loading}
                isFullWidth={true}
              >
                {t('start-free-trial')}
                <LoadingOutlined
                  className={styles.loading}
                  hidden={!loading}
                />
              </ButtonComponent>
            </div>
          </Col>
          <Col span={24}>
            <div style={{marginTop: '10px'}}>
              <p className={styles.logIn}>
                {t('already-have-account')}{' '}
                <span style={{color: '#1890ff', cursor: 'pointer'}} onClick={() => openRegisterPage(false)}>{t('login')}</span>
              </p>
            </div>
          </Col>
        </Row>
      </Form>

      {!loading && (
        <ul className={styles.languagesSwitcher}>
          {router.locales.map(locale => (
            <li key={locale}>
              <Link href={router.asPath} locale={locale}>
                <a>{languages(locale)}</a>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
