import {getApiUrl, getAuthHeader, getExportUrl, getExportUrlV2, getMessagesFromErrorResponse, getTokenFromCookies} from '@/api/common-utils';
import {BaseApi} from '@/api/base-api';
import {notification} from '@/utils/notifications';
import {notification as notificationV2} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';


interface KeywordsDetailProps {
  id: number;
  criteria: any;
  page: number;
  pageSize: number;
  sortBy?: string;
  search?: string;
  filters?: any;
  hash?: string;
}
interface ProjectCompetitorsProps {
  id: number;
  criteria: any;
  page: number;
  pageSize: number;
  sortBy?: string;
  hash?: string;
}
interface KeywordDetailProps {
  id: number;
  keyword: string;
  sortBy?: string;
  hash?: string;
  location?: string;
}

interface KWObject {
  keyword: string;
  location: string;
}

export class KeywordResearchApi extends BaseApi {
  private static readonly baseUrl: string = getApiUrl(BaseApi.KEYWORD_ENDPOINT, '/api');

  public async getKeywordMainDetails(listId, keyword, publicHash) {
    const params = {
      'keyword': keyword,
    };
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${listId}/keyword-details/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getKeywordOrganicPaidHistoryDetails(listId, keyword, publicHash) {
    const params = {
      'keyword': keyword,
    };
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${listId}/keyword-ranking-history/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateKeyword(payload, id) {
    try {
      const response = await this.axios.put(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${id}/`, payload, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      const errorMessage = apiError(error) as string;
      notificationV2.error('Error', errorMessage);
      return Promise.reject(error);
    }
  }
  public async deleteKeyword(id) {
    try {
      const response = await this.axios.delete(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${id}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async postNewKeyword(payload) {
    try {
      const response = await this.axios.post(`${KeywordResearchApi.baseUrl}/v2/keyword-research/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      const keywordsErr = error?.response?.data?.keywords?.keywords;
      const keywordsMsg = typeof keywordsErr === 'string' ? keywordsErr : '';
      notification.error(keywordsMsg || errorMessage);
      return {success: false, response: error};
    }
  }

  public async postNewHistory(payload) {
    try {
      const response = await this.axios.post(`${KeywordResearchApi.baseUrl}/v1/keyword-magic-tool-history/`, payload, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      const errorMessage = getMessagesFromErrorResponse(error, true) as string;
      notification.error(errorMessage);
      return {success: false, response: errorMessage};
    }
  }

  public async getKeywordDetail(data, search?: any) {
    const params = {
      page: data?.page,
      page_size: data?.pageSize,
    };
    if (data?.search) {
      params['search'] = data.search;
    }
    if (data?.ordering) {
      params['ordering'] = data?.ordering;
    }
    if (search) {
      params['search'] = search;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getKeywordHistory(data, search?: any) {
    const params = {
      page: data?.page,
      page_size: data?.pageSize,
    };
    if (data?.search) {
      params['search'] = data.search;
    }
    if (data?.ordering) {
      params['ordering'] = data?.ordering;
    }
    if (search) {
      params['search'] = search;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/keyword-magic-tool-history/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }


  public async deleteMagicToolKeyword(id) {
    try {
      const response = await this.axios.delete(`${KeywordResearchApi.baseUrl}/v1/keyword-magic-tool-history/${id}/`, {
        headers: getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getKeywordListDetail(data) {
    const params = {
      history: true,
      page: data?.page,
      page_size: data?.pageSize,
    };
    if (data?.search) {
      params['search'] = data.search;
    }
    const headers = {
      headers: getAuthHeader(),
      params: params,
    };
    if (!params.history) {
      headers['cancelToken'] = this.cancelToken;
    }

    try {
      const response = await this.axios.get(
        `${KeywordResearchApi.baseUrl}/v2/keyword-research/`,
        headers,
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getKeywordSerpOverviewDetails(listId, keyword, publicHash) {
    const params = {
      'keyword': keyword,
    };
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${listId}/serp-overview/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getKeywordSvCountriesDetails(listId, keyword, publicHash) {
    const params = {
      'keyword': keyword,
    };
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${listId}/top-countries-search-volume/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTermsMatchDetails(keyword, locationId, countryCode, publicHash) {
    const params = {
      term: keyword,
      page_size: 10,
    };
    if (locationId) {
      params['location_id'] = locationId;
    } else {
      params['country_code'] = countryCode ? countryCode : '';
    }
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v3/keyword-research/terms-match/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getRefetchDetails(id, keyword) {
    const params = {
      keyword: keyword,
    };
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${id}/refetch/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async refetchSearchIntentList(keyword, locationId, countryCode) {
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/search-intent-refetch/?keyword=${keyword}&country_code=${countryCode || ''}&location_id=${locationId || ''}`, {
        headers: getAuthHeader(),
      });
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async refetchSearchIntentListV2(keyword, locationId, countryCode) {
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/kmt/refetch-search-intent/?keyword=${keyword}&country_code=${countryCode || ''}&location_id=${locationId || ''}`, {
        headers: getAuthHeader(),
      });
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getAndUpdateSearchIntentList(payload) {
    try {
      const response = await this.axios.post(`${KeywordResearchApi.baseUrl}/v2/keyword-research/bulk-search-intent-refetch/`, payload, {
        headers: getAuthHeader(),
      });
      return response?.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async refetchKeyword(data) {
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/keyword-data-refetch/`, {
        headers: getAuthHeader(),
        params: data,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTermsMatchMagicList(data, payload, includeTerm, tableName, publicHash?: string) {
    const params = {
      ...payload,
      term: data?.keyword,
      id: data?.listId,
      search: data?.search,
      // country_code: !['NULL', 'UNDEFINED', null, undefined].includes(data?.countryCode) ? data?.countryCode : '',
    };
    if (data?.locationId) {
      params['location_id'] = data?.locationId;
    } else {
      params['country_code'] = !['NULL', 'UNDEFINED', null, undefined].includes(data?.countryCode) ? data?.countryCode : '';
    }
    // if (data?.search) {
    //   params['term'] = data?.keyword;
    // }
    if (includeTerm) {
      params['include_term'] = includeTerm;
    }
    if (tableName == 'questions') {
      params['with_question'] = 1;
    }
    if (publicHash) {
      params['hash'] = publicHash;
    }
    let makeEndpoint = '';
    if (tableName) {
      if (tableName == 'related_keywords') {
        makeEndpoint = 'terms-match-related-keywords';
      } else {
        makeEndpoint = 'terms-match';
      }
    } else if (data?.listId) {
      makeEndpoint = 'terms-match-keyword-list';
    } else {
      makeEndpoint = 'terms-match';
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v3/keyword-research/${makeEndpoint}/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return {
        'response': response.data,
        'exportLink': `${KeywordResearchApi.baseUrl}/v3/keyword-research/${makeEndpoint}/?${getExportUrl(params, response.data?.count)}`,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTermSearchMagicList(data, payload, tableName, publicHash?: string, filters?: string[]) {
    const params = new URLSearchParams({
      ...payload,
      ...data,
      page_size: 100,
    });
    if (publicHash) {
      params.append('hash', publicHash);
    }
    if (filters?.length) {
      filters?.map(z => {
        const arr = z?.split(',');
        if (arr[0] == 'includes' || arr[0] == 'excludes') {
          const newArr = arr?.slice(2).join();

          (arr[0] == 'includes') ? params.append('include_words', newArr) : params.append('exclude_words', newArr);
          params.append('operator', arr[1]);
        } else {
          arr[1] !== 'targetPage' ? params.append('filter', z) : params.append('url', arr[2]);
        }
      });
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/kmt/term-search/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return {
        'response': response.data,
        'exportLink': `${KeywordResearchApi.baseUrl}/v1/kmt/term-search/?${getExportUrlV2(params)}`,
      };
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTermAggregationKeywordsDetail(data, payload, includeParameter, tableName, publicHash?: string) {
    const params = {
      keyword: includeParameter ? `${data?.keyword} ${includeParameter?.replace(/,/g, ' ')}` : data?.keyword,
      ...payload,
    };
    if (data?.country_code) {
      params['country_code'] = !['NULL', 'UNDEFINED', null, undefined].includes(data?.country_code) ? data?.country_code : '';
    }
    if (tableName == 'questions') {
      params['with_question'] = 1;
    }
    if (publicHash) {
      params['hash'] = publicHash;
    }
    let makeEndpoint = '';
    if (tableName) {
      if (tableName == 'related_keywords') {
        makeEndpoint = 'token-aggregation-related-keywords';
      } else {
        makeEndpoint = 'term-aggregation';
      }
    } else if (data?.listId) {
      makeEndpoint = 'term-aggregation';
    } else {
      makeEndpoint = 'term-aggregation';
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/kmt/${makeEndpoint}/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTermSearchQuestionsDetails(keyword, countryCode, publicHash) {
    const params = {
      keyword: keyword,
      question_search: true,
      page_size: 10,
    };
    if (countryCode) {
      params['country_code'] = countryCode;
    }
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/kmt/term-search/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTermsMatchDetailsV2(keyword, countryCode, publicHash) {
    const params = {
      keyword: keyword,
      page_size: 10,
    };
    if (countryCode) {
      params['country_code'] = countryCode;
    }
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/kmt/term-search/`, {
        headers: getAuthHeader(),
        params: params,
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getMagicKeywordsDetail(data, payload, includeParameter, tableName, publicHash?: string) {
    const params = {
      term: data?.keyword,
      // country_code: !['NULL', 'UNDEFINED', null, undefined].includes(data?.countryCode) ? data?.countryCode : '',
      id: data?.listId,
      ...payload,
    };
    if (data?.locationId) {
      params['location_id'] = data?.locationId;
    } else {
      params['country_code'] = !['NULL', 'UNDEFINED', null, undefined].includes(data?.countryCode) ? data?.countryCode : '';
    }
    if (includeParameter) {
      params['include_term'] = includeParameter;
    }
    if (tableName == 'questions') {
      params['with_question'] = 1;
    }
    if (publicHash) {
      params['hash'] = publicHash;
    }
    let makeEndpoint = '';
    if (tableName) {
      if (tableName == 'related_keywords') {
        makeEndpoint = 'token-aggregation-related-keywords';
      } else {
        makeEndpoint = 'token-aggregation';
      }
    } else if (data?.listId) {
      makeEndpoint = 'token-aggregation-keyword-list';
    } else {
      makeEndpoint = 'token-aggregation';
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v3/keyword-research/${makeEndpoint}/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getTermsMatchQuestionsDetails(keyword, locationId, countryCode, publicHash) {
    const params = {
      term: keyword,
      with_question: 1,
      page_size: 10,
      // country_code: countryCode ? countryCode : '',
    };
    if (locationId) {
      params['location_id'] = locationId;
    } else {
      params['country_code'] = countryCode ? countryCode : '';
    }
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v3/keyword-research/terms-match/`, {
        headers: getAuthHeader(),
        params: params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getKeywordDetailsList(listId, publicHash) {
    const params = {};
    if (publicHash) {
      params['hash'] = publicHash;
    }
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${listId}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async exportSerpOverview(listId, keyword) {
    const params = {
      'keyword': keyword,
    };

    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/keyword-research/${listId}/export-serp-overview/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // KRT API START
  public async getKwRankTrackerList(page: number, isSimple?: false, isLoadMore = false, hash?: string, sortBy?: string, search?: string, filters?: any) {
    const checkToken = getTokenFromCookies();
    const params = {
      page_size: 10,
      ...(sortBy && {sort_by: sortBy}),
      ...(search && {search}),
      ...filters,
    };

    if (isSimple) params.status_only = 1;
    if (page) params.page = page;
    if (hash) params.hash = hash;
    try {
      if (checkToken) {
        const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/`, {

          headers: getAuthHeader(),
          ...(!isLoadMore && {cancelToken: this.cancelToken}),
          params: params,
        });
        return response.data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getKwRankTrackerListWithoutRepoll(page: number, pageSize: number, hash?: string) {
    const params = {};
    params['page_size'] = pageSize ?? 10;
    if (page) params['page'] = page;
    if (hash) params['hash'] = hash;
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/`, {

        headers: getAuthHeader(),
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getKwRankTargetKw(projectId: number, hash?: string) {
    const params = {};
    if (hash) params['hash'] = hash;
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${projectId}/target-keywords/`, {
        headers: getAuthHeader(),
        params,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getKwRankTrackedKw(projectId: number, hash?: string) {
    const params = {};
    if (hash) params['hash'] = hash;
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${projectId}/tracked-keywords/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async deleteKrtTrackedKwV2(projectId: number, payload?: Array<KWObject>) {
    try {
      const response = await this.axios.delete(`${KeywordResearchApi.baseUrl}/v2/rank-tracker/${projectId}/tracked-keywords/`, {
        headers: getAuthHeader(),
        data: payload,
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async createTrackedProject(payload) {
    try {
      const response = await this.axios.post(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/`, payload, {
        headers: getAuthHeader(),
        // cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async updateTrackedProject(projectId, payload) {
    try {
      const response = await this.axios.patch(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${projectId}/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async deleteTrackedProject(projectId) {
    try {
      const response = await this.axios.delete(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${projectId}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async addTrackedKeywords(payload: string[], projectId: number) {
    try {
      const response = await this.axios.put(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${projectId}/tracked-keywords/`, {keywords: payload}, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async addTrackedKeywordsV2(payload: any, projectId: number) {
    try {
      const response = await this.axios.put(`${KeywordResearchApi.baseUrl}/v2/rank-tracker/${projectId}/tracked-keywords/`, payload, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getDetails(id: number, isSimple?: boolean, hash?: string) {
    const params = {};
    if (isSimple) params['status_only'] = 1;
    if (hash) params['hash'] = hash;
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${id}/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getDetailsWithoutRepoll(id: number, hash?: string) {
    const params = {};
    if (hash) params['hash'] = hash;
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${id}/`, {
        headers: getAuthHeader(),
        params,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getCoreReports(id: number, criteria: any, hash?: string) {
    const payload = {
      'period2_start': criteria.currentPeriodStart,
      'period2_end': criteria.currentPeriodEnd,
      ...(hash && {hash}),
    };
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${id}/core-reports/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: payload,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async getKeywordsDetails({id, criteria, page, pageSize, sortBy, search, filters, hash}: KeywordsDetailProps) {
    const payload = {
      'period2_start': criteria.currentPeriodStart,
      'period2_end': criteria.currentPeriodEnd,
      'page': page,
      'page_size': pageSize,
      ...(sortBy && {'sort_by': sortBy}),
      ...(search && {'search': search}),
      ...filters,
      ...(hash && {hash}),
    };
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${id}/keywords-details/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: payload,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getKeywordDetailsV2({id, keyword, sortBy, hash, location}: KeywordDetailProps) {
    try {
      const payload = {
        keyword,
        ...((location && location !== 'null') && {location}),
        ...(sortBy && {'sort_by': sortBy}),
        ...(hash && {hash}),
      };
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v2/rank-tracker/${id}/keyword-historical-performance/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: payload,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getProjectCompetitors({id, criteria, page, pageSize, sortBy, hash}: ProjectCompetitorsProps) {
    const payload = {
      'period2_start': criteria.currentPeriodStart,
      'period2_end': criteria.currentPeriodEnd,
      'page': page,
      'page_size': pageSize,
      ...(sortBy && {'sort_by': sortBy}),
      ...(hash && {hash}),
    };
    try {
      const response = await this.axios.get(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${id}/competitors-by-visibility/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
        params: payload,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  public async refreshProjectDetails(projectId: number) {
    try {
      const response = await this.axios.post(`${KeywordResearchApi.baseUrl}/v1/rank-tracker/${projectId}/refresh/`, {
        headers: getAuthHeader(),
        cancelToken: this.cancelToken,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // KRT API END
}

export const KEYWORD_RESEARCH_API_V2 = new KeywordResearchApi();
