import React, {ReactNode, HTMLAttributes} from 'react';
import classnames from 'classnames';
import styles from './style.module.scss';
import {
  Typography,
} from '../typography';
import {Button} from '../../v2';
import classNames from 'classnames';
import {Empty} from 'antd';

export interface CardWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  theme?: 'themeDark' | 'themeLight';
  className?: string;
  bgColor?:string;
}

export const CardWrapper: React.FC<CardWrapperProps> = ({
  className,
  theme = 'themeLight',
  children,
  bgColor,
  ...props
}) => {
  return (
    <div style={{background: bgColor}} className={classnames(styles.wrapper, styles[theme], className)} {...props}>
      {children}
    </div>
  );
};

export interface EmptyStateCardProps {
  icon?: ReactNode;
  title: string;
  subTitle?: ReactNode;
}
export const EmptyStateCard: React.FC<EmptyStateCardProps> = ({icon, title, subTitle}) => {
  return (
    <div className={styles.noKeywordsContainer}>
      {icon}
      <Typography tag='p'>
        {title}
      </Typography>
      {subTitle}
    </div>
  );
};

export default CardWrapper;

export interface ReportsCardProps {
  icon?: any;
  heading?: string;
  paragraph?: string;
  onSubmitHandler?:()=>void;
  width: number;
  height: number;
  estimatedTime?: any;
  processingStatus?: any;
  estimatedTimeleft?: any;
}

export const ReportsCard: React.FC<ReportsCardProps> = ({icon, width, height, heading, paragraph, ...props}) => {
  const onSubmit = () =>{
    props.onSubmitHandler();
  };
  return (
    <div style={{width: `${width} !important`, height: `${height} !important`}} className={styles.reportCard}>
      <div className={styles.reportCardInner}>
        <div style={{height: '40px', width: '40px'}}><img src={icon} alt='' /></div>
        <div className={styles.text}>
          <div style={{fontWeight: 600, fontSize: '16px', color: '#121212', marginTop: '10px'}}>
            {heading}
          </div>
          <div className={styles.paragraph}>
            {paragraph}
          </div>
          {props?.processingStatus == 'processing' && <p className={styles.estimatedTimeText}>{props?.estimatedTimeleft && props?.estimatedTimeleft !== undefined ? props?.estimatedTime?.replace('up to ', '') : 'Report ready in: a moment'}</p>}
        </div>
        <div className={styles.BtnStyle}>
          <Button onClick={onSubmit} variant='ghost' disabled={props?.processingStatus == 'processing'} color='blue' textColor='#4E5156' >{'View Reports'}</Button>
        </div>
      </div>
    </div>
  );
};
export interface LargeReportsCardProps {
  icon?: any;
  heading?: string;
  paragraph?: string;
  onSubmitHandler?:()=>void;
  width: number;
  height: number;
}

export const LargeReportsCard: React.FC<LargeReportsCardProps> = ({icon, width, height, heading, paragraph, ...props}) => {
  const onSubmit = () =>{
    props.onSubmitHandler();
  };
  return (
    <div style={{width: `${width} !important`, height: `${height} !important`}} className={classNames(styles.reportCard, styles.large)}>
      <div className={styles.reportCardInner}>
        <div style={{height: '40px', width: '40px'}}><img src={icon} alt='' /></div>
        <div className={classNames(styles.text, styles.allData)}>
          <div style={{fontWeight: 600, fontSize: '16px', color: '#121212', marginTop: '10px'}}>
            {heading}
          </div>
          <div className={styles.paragraph}>
            {paragraph}
          </div>
        </div>
        <div className={styles.BtnStyle}>
          <Button onClick={onSubmit} variant='ghost' color='blue' textColor='#4E5156' >{'View Reports'}</Button>
        </div>
      </div>
    </div>
  );
};

export const EmptyReportCard = () => {
  return (
    <div style={{padding: '10px 15px'}}>
      <div className={styles.emptyReoprtCard}>
        <div>
          <span style={{display: 'flex', justifyContent: 'center'}}><Empty description={'No Searched Report Found'} style={{color: '#a3a4a4'}}/></span>
        </div>
      </div>
    </div>
  );
};
