import {types, flow, cast, Instance} from 'mobx-state-tree';
import {sitePropertyEventsApi, reportsApi} from '@/api/gsc/index';
import {notification} from '@/utils/notification-v2';
import {toJS} from 'mobx';
import {getFilter, formatSorters} from '@/utils/filters';
import {setSiteEventDateIntoLocalStorage} from '@/api/common-utils';
import {getSingleUrlParam} from '@/utils/url';

export const RESULTS_DETAIL_DEFAULT = {
  id: 0,
  name: '',
  page: '',
  date: '',
  desc: '',
  isSitewide: false,
  icon: '',
  pageGroup: 0,
  pageGroupDetails: {
    id: 0,
    name: '',
    pages: []},
  evolutionOverTime: [
    {
      date: '',
      totalImpressions: 0,
      totalTraffic: 0,
      averagePosition: 0,
      totalKeywords: 0,
    },
  ],
  headers: {
    impressions: {
      old: null,
      new: null,
      changeLabel: '',
    },
    keywords: {
      old: null,
      new: null,
      changeLabel: '',
    },
    position: {
      old: null,
      new: null,
      changeLabel: '',
    },
    traffic: {
      old: null,
      new: null,
      changeLabel: '',
    }},
  overallRankChange: [
    {
      date: '',
      positionChangeCumulative: 0,
      positionsFallen: 0,
      positionsNet: 0,
      positionsRisen: 0,
    },
  ],
  pagePerformance: [
    {
      date: '',
      visibility: 0,
      volatility: 0,
    },
  ],
};

export const SITE_EVENT_CRITERIA_DEFAULT = {
  property: '',
  previousPeriodEnd: '',
  previousPeriodStart: '',
  currentPeriodEnd: '',
  currentPeriodStart: '',
  periodStatus: '',
  countryCode: '',
};

export const siteEventCriteriaModel = types.model({
  property: types.string,
  previousPeriodStart: types.string,
  previousPeriodEnd: types.string,
  currentPeriodStart: types.string,
  currentPeriodEnd: types.string,
  periodStatus: types.string,
  countryCode: types.string,
});

export const evolutionOverTimeModel = types.model({
  date: types.maybeNull(types.string),
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  averagePosition: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  impressionsR7: types.maybeNull(types.number),
  keywordsR7: types.maybeNull(types.number),
  positionR7: types.maybeNull(types.number),
  trafficR7: types.maybeNull(types.number),
});

export const evolutionOverTimeDetailModel = types.model({
  date: types.maybeNull(types.string),
  totalImpressions: types.maybeNull(types.number),
  totalTraffic: types.maybeNull(types.number),
  averagePosition: types.maybeNull(types.number),
  totalKeywords: types.maybeNull(types.number),
  value: types.maybeNull(types.number),
  impressionsR7: types.maybeNull(types.number),
  keywordsR7: types.maybeNull(types.number),
  positionR7: types.maybeNull(types.number),
  trafficR7: types.maybeNull(types.number),
  valueR7: types.maybeNull(types.number),
});

export const oldAndNew = types.model({
  old: types.maybeNull(types.union(types.string, types.number)),
  new: types.maybeNull(types.union(types.string, types.number)),
  changeLabel: types.maybeNull(types.string),
});

export const headersModel = types.model({
  impressions: types.maybeNull(oldAndNew),
  keywords: types.maybeNull(oldAndNew),
  position: types.maybeNull(oldAndNew),
  traffic: types.maybeNull(oldAndNew),
});

export const groupDetailsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  pages: types.maybeNull(types.array(types.string)),
});

export const resultsModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  desc: types.maybeNull(types.string),
  isSitewide: types.maybeNull(types.boolean),
  icon: types.maybeNull(types.string),
  pageGroup: types.maybeNull(types.number),
  pageGroupDetails: types.maybeNull(groupDetailsModel),
  evolutionOverTime: types.maybeNull(types.array(evolutionOverTimeModel)),
  headers: types.maybeNull(headersModel),
});

export const rankChangeChartModel = types.model({
  date: types.maybeNull(types.string),
  positionChangeCumulative: types.maybeNull(types.number),
  positionsFallen: types.maybeNull(types.number),
  positionsNet: types.maybeNull(types.number),
  positionsRisen: types.maybeNull(types.number),
});

export const pagePerformanceChartModel = types.model({
  date: types.maybeNull(types.string),
  visibility: types.maybeNull(types.number),
  volatility: types.maybeNull(types.number),
});


export const resultsDetailModel = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  page: types.maybeNull(types.string),
  date: types.maybeNull(types.string),
  desc: types.maybeNull(types.string),
  isSitewide: types.maybeNull(types.boolean),
  icon: types.maybeNull(types.string),
  pageGroup: types.maybeNull(types.number),
  pageGroupDetails: types.maybeNull(groupDetailsModel),
  evolutionOverTime: types.maybeNull(types.array(evolutionOverTimeDetailModel)),
  headers: types.maybeNull(headersModel),
  overallRankChange: types.maybeNull(types.array(rankChangeChartModel)),
  pagePerformance: types.maybeNull(types.array(pagePerformanceChartModel)),
});

const KEYWORD_FILTER_LIST = [
  {id: 4, name: 'exclude', header: 'Keyword', text: '', type: undefined, active: false, isSearch: true},

  {id: 5, name: 'posCur', header: 'Position', customFilterValue: '', from: undefined, to: undefined, type: undefined, active: false,
    customOptionsTop: [
      {name: 'Top 3', min: '1', max: '3'},
      {name: 'Top 10', min: '1', max: '10'},
      {name: 'Top 20', min: '1', max: '20'},
      {name: 'Top 50', min: '1', max: '50'},
    ], customOptions: [
      {name: '#1', min: '0', max: '1'},
      {name: '#4-10', min: '4', max: '10'},
      {name: '#11-20', min: '11', max: '20'},
      {name: '#21-50', min: '21', max: '50'},
      {name: '#51-100', min: '51', max: '100'},
    ]},
  {id: 6, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '100,001+', min: '100001', max: ''},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '11 - 100', min: '11', max: '100'},
    {name: '1 - 10', min: '1', max: '10'},
  ]},
  {id: 8, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false, customOptions: [
    {name: '1,000,001+', min: '1000001', max: ''},
    {name: '100,001 - 1,000,000', min: '100001', max: '1000000'},
    {name: '10,001 - 100,000', min: '10001', max: '100000'},
    {name: '1,001 - 10,000', min: '1001', max: '10000'},
    {name: '101 - 1,000', min: '101', max: '1000'},
    {name: '0 - 100', min: '0', max: '100'},
  ]},
  {id: 7, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false},
  {id: 9, name: 'vol', header: 'Search Volume', from: undefined, to: undefined, type: undefined, active: false},
  {id: 10, name: 'val', header: 'Traffic Value', from: undefined, to: undefined, type: undefined, active: false},
  // {id: 11, name: 'potentialTrafficValueCur', header: 'Potential Traffic Value', from: undefined, to: undefined, type: undefined, active: false},
  // {id: 12, name: 'potentialTrafficCur', header: 'Potential Traffic', from: undefined, to: undefined, type: undefined, active: false},
];


export const filterList = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  customFilterValue: types.optional(types.string, ''),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  query: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  isSearch: types.maybeNull(types.boolean),
  active: types.boolean,
  customOptions: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
  customOptionsTop: types.maybeNull(types.array(types.model({
    name: types.maybeNull(types.string),
    info: types.optional(types.string, ''),
    showPercent: types.optional(types.boolean, false),
    min: types.maybeNull(types.union(types.string, types.number)),
    max: types.maybeNull(types.union(types.string, types.number)),
  }))),
});


export const keywordsData = types.model({
  kw: types.maybeNull(types.string),
  clicksCur: types.maybeNull(types.number),
  clicksPrev: types.maybeNull(types.number),
  cpc: types.maybeNull(types.number),
  impCur: types.maybeNull(types.number),
  impPrev: types.maybeNull(types.number),
  keywordsCount: types.maybeNull(types.number),
  opportunityScore: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
  posCur: types.maybeNull(types.number),
  posPrev: types.maybeNull(types.number),
  vol: types.maybeNull(types.number),
  topKeyword: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  value: types.maybeNull(types.number),
  potentialTrafficCur: types.maybeNull(types.number),
  potentialTrafficPrev: types.maybeNull(types.number),
  potentialTrafficValueCur: types.maybeNull(types.number),
  potentialTrafficValuePrev: types.maybeNull(types.number),
  searchIntent: types.maybeNull(types.string),
});


export const filterListOld = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  header: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
  from: types.maybeNull(types.string),
  to: types.maybeNull(types.string),
  active: types.boolean,
});

export type EventsType = Instance<typeof resultsModel>;

export const SitePropertyEvents = types.model({
  count: types.maybeNull(types.number),
  events: types.maybeNull(types.array(resultsModel)),
  siteEventCriteria: types.maybeNull(siteEventCriteriaModel),
  eventsList: types.maybeNull(types.array(resultsModel)),
  eventDetail: types.maybeNull(resultsDetailModel),
  loading: types.boolean,
  detailLoading: types.boolean,
  pageNumber: types.maybeNull(types.number),
  nextLoading: types.maybeNull(types.boolean),
  nextRecord: types.maybeNull(types.boolean),
  pageSize: types.maybeNull(types.number),
  relativeDays: types.maybeNull(types.number),
  periodStart: types.maybeNull(types.number),
  periodEnd: types.maybeNull(types.number),
  loadingNewChart: types.maybeNull(types.boolean),
  newEventModal: types.maybeNull(types.boolean),
  creatingEvent: types.maybeNull(types.boolean),
  averageData: types.maybeNull(types.boolean),
  isAddNewEventFormOpen: types.boolean,
  isOpenEventsMenu: types.boolean,
  selectedDateFromChart: types.string,
  isEditModeActive: types.boolean,
  dataId: types.maybeNull(types.number),
  siteEventsDrawer: types.boolean,
  deleteEventModal: types.boolean,
  deleteLoading: types.boolean,

  keywords: types.array(keywordsData),
  keywordsCount: types.maybeNull(types.number),
  keywordsLoading: types.boolean,
  isKeywordInit: types.boolean,
  keywordPageNumber: types.number,
  keywordPageSize: types.number,
  keywordFilterList: types.array(filterList),
  keywordFilterListOld: types.array(filterListOld),
  keywordSortField: types.maybeNull(types.string),
  keywordSortDirection: types.maybeNull(types.string),
  keywordSearchTerm: types.maybeNull(types.string),
  notKeywordTerm: types.maybeNull(types.string),
  isEdit: types.maybeNull(types.boolean),
  eventId: types.maybeNull(types.number),
}).views(self => ({
  get getAllEvents() {
    return toJS(self.eventsList);
  },
  get getEventDetail() {
    return toJS(self.eventDetail);
  },
  get getSiteEventCriteria() {
    return toJS(self.siteEventCriteria);
  },
  get keywordsData() {
    return self.keywords;
  },
  get siteEventsRelativeDays() {
    return self.relativeDays;
  },
  get getPageNumber() {
    return self.pageNumber;
  },
})).actions(self => {
  const siteEventCriteriaUpdate = ({property, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, periodStatus, countryCode}: {
    property: string;
    previousPeriodStart: string;
    previousPeriodEnd: string;
    currentPeriodStart: string;
    currentPeriodEnd: string;
    periodStatus: string;
    countryCode: string;
  }) => {
    self.siteEventCriteria.property = property;
    self.siteEventCriteria.previousPeriodStart = previousPeriodStart;
    self.siteEventCriteria.previousPeriodEnd = previousPeriodEnd;
    self.siteEventCriteria.currentPeriodStart = currentPeriodStart;
    self.siteEventCriteria.currentPeriodEnd = currentPeriodEnd;
    self.siteEventCriteria.periodStatus = periodStatus == 'Invalid date' ? '' : periodStatus;
    self.siteEventCriteria.countryCode = countryCode ? countryCode : '';

    setSiteEventDateIntoLocalStorage(previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, periodStatus == 'Invalid date' ? '' : periodStatus);
  };

  const siteEventCriteriaClear = () => {
    self.siteEventCriteria.property = '';
    self.siteEventCriteria.previousPeriodStart = '';
    self.siteEventCriteria.previousPeriodEnd = '';
    self.siteEventCriteria.currentPeriodStart = '';
    self.siteEventCriteria.currentPeriodEnd = '';
  };
  const loadEvents = flow(function* (params) {
    const publicHash = getSingleUrlParam('public_hash');
    if (publicHash) return;
    self.loading = true;
    try {
      const response = yield sitePropertyEventsApi.getEvents(params);
      if (response) {
        self.events = response;
      }
    } catch (e) {
      self.loading = true;
    } finally {
      self.loading = false;
    }
  });

  const loadEventDetail = flow(function* ({property, periodStart, periodEnd, relativeDays='', id, countryCode}) {
    self.detailLoading = true;
    try {
      const response = yield sitePropertyEventsApi.getEventDetail({property, periodStart, periodEnd, relativeDays, id, countryCode});
      if (response) {
        self.eventDetail = response;
      }
      self.detailLoading = false;
    } catch (e) {
      self.detailLoading = true;
    }
  });

  const loadSiteEvents = flow(function* ({property, periodStart, periodEnd, pageSize, pageNumber, relativeDays='', countryCode}) {
    self.loading = true;
    const params = {
      site_property: property,
      page_size: pageSize,
      page_number: pageNumber,
      country_code: countryCode,
    };
    if (relativeDays) {
      params['relative_days'] = relativeDays;
    } else {
      params['period_start'] = periodStart;
      params['period_end'] = periodEnd;
    }

    try {
      const response = yield sitePropertyEventsApi.getSiteEvents(params);
      if (response) {
        self.eventsList = cast(response.results);
        self.count = response?.count;
        self.nextRecord = self.eventsList?.length < self.count;
      }
    } catch (e) {
      notification.error('An error has occurred', 'There was an error fetching site event data. Please try again later.', true);
    } finally {
      self.loading = false;
    }
  });

  const loadMoreSiteEvents = flow(function* ({property, periodStart, periodEnd, pageSize, relativeDays='', countryCode}) {
    self.nextLoading = true;
    const params = {
      site_property: property,
      page_size: pageSize,
      page_number: self.pageNumber,
      country_code: countryCode,
    };
    if (relativeDays) {
      params['relative_days'] = relativeDays;
    } else {
      params['period_start'] = periodStart;
      params['period_end'] = periodEnd;
    }

    try {
      const response = yield sitePropertyEventsApi.getSiteEvents(params);
      if (response) {
        if (response) {
          const updatedData = [...self.eventsList, ...response.results];
          self.eventsList = cast(updatedData);
          self.count = response?.count;
          self.nextRecord = self.eventsList?.length < self.count;
        }
      }
    } catch (e) {
      notification.error('An error has occurred', 'There was an error fetching site event data. Please try again later.', true);
    } finally {
      self.nextLoading = false;
    }
  });


  const createEvent = flow(function* (property: string, name: string, date: string, desc: string, isSitewide: boolean, icon: string, pageGroup: number, countryCode?:string, page?: string, isLoadEvents?:boolean) {
    self.creatingEvent = true;
    const payload = {property: property, name: name, date: date, desc: desc, isSitewide: isSitewide, icon: icon, pageGroup: pageGroup, countryCode: countryCode};
    if (page?.length > 0) {
      payload['page'] = page;
    }
    try {
      const response = yield sitePropertyEventsApi.createEvent(payload as never);
      if (response) {
        notification.success('Site Event Created Successfully.', '');
        if (isLoadEvents) {
          loadEvents({country_code: countryCode, site_property: property});
        }
      }
      self.creatingEvent = false;
    } catch (e) {
      self.creatingEvent = true;
    }
  });
  const deleteEvent = flow(function* (id: number) {
    try {
      self.loading = true;
      yield sitePropertyEventsApi.deleteEvent({id: id});
      self.events = cast(self.events.filter((_events: EventsType) => _events.id !== id));
      self.loading = false;
    } catch (e) {
      self.loading = true;
    }
  });
  const editEvent = flow(function* (countryCode:string, id: number, name: string, date: string, desc: string, isSitewide: boolean, icon: string, pageGroup: number, siteProperty: string, page?: string) {
    try {
      self.loading = true;
      const response = yield sitePropertyEventsApi.editEvent({countryCode: countryCode, id: id, name: name, date: date, desc: desc, isSitewide: isSitewide, icon: icon, pageGroup: pageGroup, page: page, siteProperty: siteProperty});
      self.events.map((event, index) => {
        if (event.id === id) {
          self.events[index] = response;
        }
      });
      self.loading = false;
    } catch (e) {
      self.loading = true;
    }
  });

  const getNewEventChart = flow(function* (criteria, url) {
    const params = {
      property: criteria.property,
      period1Start: criteria.previousPeriodStart,
      period1End: criteria.previousPeriodEnd,
      periodStart: criteria.currentPeriodStart,
      periodEnd: criteria.currentPeriodEnd,
      countryCode: criteria.countryCode,
      page: url,
    };

    self.loadingNewChart = true;
    try {
      const response = yield reportsApi.getPageHistoricalPerformance(params);
      return response;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      self.loadingNewChart = false;
    }
  });

  const deleteSiteEvent = flow(function* (id) {
    self.deleteLoading = true;
    try {
      const response = yield sitePropertyEventsApi.deleteEvent({id});
      self.eventsList = cast(self.eventsList.filter((_events: EventsType) => _events.id !== id));
      self.count = self.count - 1;
      return response;
    } catch (e) {
      notification.error('An error has occurred', 'Failed to delete site. Please try again later.');
    } finally {
      self.deleteLoading = false;
    }
  });

  const selectDateFromChart = date => {
    self.selectedDateFromChart = date;
  };
  const clearDateFromChart = () => {
    self.selectedDateFromChart = '';
  };
  const openAddNewEventForm = () => {
    self.isAddNewEventFormOpen = !self.isAddNewEventFormOpen;
  };
  const setAddNewEventFormToOpen = () => {
    self.isAddNewEventFormOpen = true;
  };
  const openEventsMenu = () => {
    self.isOpenEventsMenu = !self.isOpenEventsMenu;
  };

  const setEventsMenuToOpen = () => {
    self.isOpenEventsMenu = true;
  };

  const closeEventsMenu = () => {
    self.isOpenEventsMenu = false;
  };
  const startEditMode = () => {
    self.isEditModeActive = true;
  };
  const exitEditMode = () => {
    self.isEditModeActive = false;
  };

  const setAverageData = value => {
    self.averageData = value;
  };

  const openSiteEventsDrawer = id => {
    self.dataId = id;
    self.siteEventsDrawer = true;
  };
  const closeSiteEventsDrawer = () => {
    self.siteEventsDrawer = false;
    self.eventDetail.id = RESULTS_DETAIL_DEFAULT.id;
    self.eventDetail.name= RESULTS_DETAIL_DEFAULT.name;
    self.eventDetail.page= RESULTS_DETAIL_DEFAULT.page;
    self.eventDetail.date= RESULTS_DETAIL_DEFAULT.date;
    self.eventDetail.desc= RESULTS_DETAIL_DEFAULT.desc;
    self.eventDetail.isSitewide= RESULTS_DETAIL_DEFAULT.isSitewide;
    self.eventDetail.icon= RESULTS_DETAIL_DEFAULT.icon;
    self.eventDetail.pageGroup= RESULTS_DETAIL_DEFAULT.pageGroup;
    self.eventDetail.pageGroupDetails= cast(RESULTS_DETAIL_DEFAULT.pageGroupDetails);
    self.eventDetail.evolutionOverTime= cast(RESULTS_DETAIL_DEFAULT.evolutionOverTime);
    self.eventDetail.headers= RESULTS_DETAIL_DEFAULT.headers;
    self.eventDetail.overallRankChange= cast(RESULTS_DETAIL_DEFAULT.overallRankChange);
    self.eventDetail.pagePerformance= cast(RESULTS_DETAIL_DEFAULT.pagePerformance);
  };

  const setNewEventModal = value => {
    self.newEventModal = value;
  };

  const setDeleteEventModal = value => {
    self.deleteEventModal = value;
  };

  const onKeywordPaginationChange = (pageNumber: number, pageSize: number) => {
    self.keywordPageNumber = pageNumber;
    self.keywordPageSize = pageSize;
  };

  const onKeywordFilterChange = (filters: any) => {
    self.keywordPageNumber = 1;
    self.keywordFilterList = cast(filters);
  };

  const onKeywordTableChange = (sortFields: string, order: string) => {
    self.keywordSortField = sortFields;
    self.keywordSortDirection = order;
  };

  const onKeywordUpdateSearchTerm = (searchTerm: string) => {
    self.keywordSearchTerm = searchTerm;
  };
  const onUpdateNotKeywordTerm = (notKeywordTerm: string) => {
    self.notKeywordTerm = notKeywordTerm;
  };

  const clearFilterList = () => {
    self.keywordPageNumber = 1;
    self.keywordFilterList = cast(KEYWORD_FILTER_LIST);
  };


  const loadKeywords = flow(function* ({property, countryCode, previousPeriodStart, previousPeriodEnd, currentPeriodStart, currentPeriodEnd, pageNumber, pageSize, filters, notKeywordTerm, pageUrl, pageGroupId}) {
    const sorters = {
      posCur: 'current_position',
      clicksCur: 'current_clicks',
      impCur: 'current_impressions',
      cpc: 'cpc',
      vol: 'volume',
      page: 'page',
      opportunity_score: 'opportunity_score',
      value: 'value',
      watchlist: 'watchlist',
      potentialTrafficCur: 'potential_traffic_cur',
      potentialTrafficValueCur: 'potential_traffic_value_cur',
    };

    const formtatedSorters = formatSorters(sorters, self.keywordSortField, self.keywordSortDirection);

    const params = {
      selected_property: property,
      period1_start: previousPeriodStart,
      period1_end: previousPeriodEnd,
      period2_start: currentPeriodStart,
      period2_end: currentPeriodEnd,
      keyword: getFilter(filters, 'exclude')?.query,
      keyword_operator: getFilter(filters, 'exclude')?.type,
      country_code: getFilter(filters, 'countryCode')?.customFilterValue || countryCode,
      page_size: pageSize,
      not_keyword: notKeywordTerm,
      page_number: pageNumber,
      position_from: getFilter(filters, 'posCur')?.from,
      position_to: getFilter(filters, 'posCur')?.to,
      clicks_from: getFilter(filters, 'clicksCur')?.from,
      clicks_to: getFilter(filters, 'clicksCur')?.to,
      impressions_from: getFilter(filters, 'impCur')?.from,
      impressions_to: getFilter(filters, 'impCur')?.to,
      cpc_from: getFilter(filters, 'cpc')?.from,
      cpc_to: getFilter(filters, 'cpc')?.to,
      volume_from: getFilter(filters, 'vol')?.from,
      volume_to: getFilter(filters, 'vol')?.to,
      opportunity_score_from: getFilter(filters, 'opportunityScore')?.from,
      opportunity_score_to: getFilter(filters, 'opportunityScore')?.to,
      potential_traffic_cur_form: getFilter(filters, 'potentialTrafficCur')?.from,
      potential_traffic_cur_to: getFilter(filters, 'potentialTrafficCur')?.to,
      potential_traffic_value_cur_form: getFilter(filters, 'potentialTrafficValueCur')?.from,
      potential_traffic_value_cur_to: getFilter(filters, 'potentialTrafficValueCur')?.to,
      sort_by: formtatedSorters,
    };
    if (pageUrl) {
      // params['page'] = pageUrl;
    } else {
      params['page_group_id'] = pageGroupId;
    }

    try {
      self.keywordsLoading = true;
      const response = yield reportsApi.getKeywordDataTableReports(params);
      if (response.keywordsTable) {
        self.keywords = cast(response.keywordsTable.results);
        self.isKeywordInit = false;
        self.keywordsCount = response.keywordsTable.count;
        // return response?.keywordsTable;
      }
      self.keywordsLoading = false;
    } catch (e) {
      self.keywordsLoading = true;
      throw e;
    }
  });

  const setLoading = () => {
    self.keywordsLoading = true;
  };

  const updatePageNumber = number => {
    self.pageNumber = number;
  };

  const setIsEdit = name => {
    self.isEdit = name;
  };

  const setEventId = id => {
    self.eventId = id;
  };

  return {
    loadSiteEvents, createEvent, deleteEvent, editEvent, openAddNewEventForm, selectDateFromChart, setAddNewEventFormToOpen, openEventsMenu, setEventsMenuToOpen, clearDateFromChart, closeEventsMenu,
    startEditMode, exitEditMode, loadEvents, getNewEventChart,
    openSiteEventsDrawer,
    closeSiteEventsDrawer,
    loadKeywords,
    onKeywordPaginationChange,
    onKeywordFilterChange,
    onKeywordTableChange,
    onKeywordUpdateSearchTerm,
    setLoading,
    setAverageData,
    deleteSiteEvent,
    onUpdateNotKeywordTerm,
    clearFilterList,
    loadEventDetail,
    loadMoreSiteEvents,
    updatePageNumber,
    setNewEventModal,
    setDeleteEventModal,
    setIsEdit,
    setEventId,
    siteEventCriteriaUpdate,
    siteEventCriteriaClear,
  };
});

export function initSitePropertyEvents() {
  return SitePropertyEvents.create({
    events: [],
    loading: true,
    detailLoading: false,
    pageNumber: 1,
    nextLoading: false,
    nextRecord: false,
    isAddNewEventFormOpen: false,
    selectedDateFromChart: '',
    isOpenEventsMenu: false,
    isEditModeActive: false,
    siteEventsDrawer: false,
    keywords: [],
    keywordsLoading: true,
    isKeywordInit: true,
    keywordPageNumber: 1,
    keywordPageSize: 20,
    keywordFilterList: KEYWORD_FILTER_LIST,
    keywordFilterListOld: [
      {id: 1, name: 'exclude', header: 'Exclude text', text: '', type: undefined, active: false},
      {id: 2, name: 'opportunityScore', header: 'SEO Potential', from: undefined, to: undefined, type: undefined, active: false},
      {id: 3, name: 'posCur', header: 'Position', from: undefined, to: undefined, type: undefined, active: false},
      {id: 4, name: 'clicksCur', header: 'Traffic', from: undefined, to: undefined, type: undefined, active: false},
      {id: 5, name: 'impCur', header: 'Impressions', from: undefined, to: undefined, type: undefined, active: false},
      {id: 6, name: 'cpc', header: 'CPC', from: undefined, to: undefined, type: undefined, active: false},
      {id: 7, name: 'vol', header: 'Volume', from: undefined, to: undefined, type: undefined, active: false},
      // {id: 8, name: 'potentialTrafficCur', header: 'Potential Traffic', from: undefined, to: undefined, type: undefined, active: false},
      // {id: 9, name: 'potentialTrafficValueCur', header: 'Potential Traffic Value', from: undefined, to: undefined, type: undefined, active: false},
    ],
    keywordSortField: '',
    keywordSortDirection: '',
    keywordSearchTerm: '',
    newEventModal: false,
    loadingNewChart: false,
    creatingEvent: false,
    deleteEventModal: false,
    deleteLoading: false,
    eventDetail: RESULTS_DETAIL_DEFAULT,
    isEdit: false,
    siteEventCriteria: SITE_EVENT_CRITERIA_DEFAULT,
  });
}
