import {TFunction} from 'next-i18next';
import {faBolt} from '@fortawesome/pro-solid-svg-icons';
import {
  PRO_PLAN_ID,
  BUSINESS_PLAN_ID,
  GROWTH_PLAN_ID,
  STARTER_PLAN_ID,
  CUSTOM_PLAN_ID,
} from '@/constants';

export const pricingFeaturesArray = (t: TFunction) => ( [

  {id: 0,
    title: '',
    items: [{
      name: t('chrome-extension'),
      // free: t('none'),
      starter: t('install'),
      growth: t('install'),
      pro: t('install'),
      custom: t('install'),
    },
    {
      name: 'User seats',
      // free: '2',
      starter: '2',
      growth: '3',
      pro: '5',
      custom: '5+',
    },
    ],
  },
  {id: 1,
    title: 'OTTO SEO (Promo until June 31, 2024)',
    items: [{
      name: 'Sites',
      starter: '1',
      growth: '1',
      pro: '3',
    },
    {
      name: 'Hyperdrive Credits (Consumable)',
      starter: '0',
      growth: '50',
      pro: '100',
    },
    {
      blueTxtBtn: true,
      text: 'Explore OTTO Packages',
    },
    ],
  },
  {id: 2,
    title: 'Content Strategy Suite',
    items: [{
      name: 'Pages / Articles Created',
      // free: 'Demo',
      starter: '40',
      growth: '100',
      pro: '200',
      custom: '200+',
    },
    {
      name: 'Premium AI Quota',
      // free: '0',
      starter: '100',
      growth: '100',
      pro: '100',
      custom: '100',
    },
    {
      name: 'Regular AI Quota',
      // free: '0',
      starter: '100',
      growth: '100',
      pro: '100',
      custom: '100',
    },
    {
      icon: {faBolt},
      alert: 'Need more AI Quota? Access 100 more AI content outputs per month for just $25.',
    },
    {
      name: 'Content Assistant Keywords',
      // free: '0',
      starter: '200',
      growth: '200',
      pro: '1,000',
      custom: '1,000+',
    },
    {
      name: 'Onpage In-Depth Audits',
      // free: '0',
      starter: '100',
      growth: '500',
      pro: '2,000',
      custom: '2,000+',
    },
    {
      name: 'Content Plans',
      // free: '0',
      starter: '100',
      growth: '500',
      pro: '1,000',
      custom: '1,000+',
    },
    ],
  },
  {id: 3,
    title: 'GSC Insights (Rank Tracking)',
    items: [{
      name: 'GSC Projects',
      // free: 'Demo',
      starter: '5',
      growth: '15',
      pro: '30',
      custom: '30+',
    },
    {
      name: 'Keywords per Site',
      // free: '0',
      starter: 'Unlimited',
      growth: 'Unlimited',
      pro: 'Unlimited',
      custom: 'Unlimited',
    },
    {
      name: 'GSC Pages',
      // free: '0',
      starter: 'Top 200',
      growth: 'Top 500',
      pro: 'Top 2,000',
      custom: 'Top 2,000+',
    },
    {
      name: 'Historical Data Limit',
      // free: '0',
      starter: '6 months',
      growth: '3 years',
      pro: '3 years',
      custom: '3 years',
    },
    ],
  },
  {id: 4,
    title: 'Research Tools',
    items: [
      {
        name: 'Backlink Research Credit',
        // free: '1',
        starter: '10,000',
        growth: '50,000',
        pro: '300,000',
        custom: '300,000+',

      },
      {
        name: 'Competitor Research Credit',
        // free: '10',
        starter: '10,000',
        growth: '50,000',
        pro: '300,000',
        custom: '300,000+',
      },
      {
        name: 'Keyword Research Lookups',
        // free: '10',
        starter: '500',
        growth: '2,000',
        pro: '5,000',
        custom: '5,000+',
      },
      // {
      //   name: 'Content Research Lookups',
      //   // free: '10',
      //   starter: '500',
      //   growth: '2,000',
      //   pro: '5,000',
      //   custom: '5,000+',
      // },
    ],
  },
  {id: 5,
    title: 'Keyword Rank Tracking',
    items: [{
      name: 'Projects',
      // free: 'Top 10',
      starter: '10',
      growth: '25',
      pro: '20',
      custom: '25+',
    },
    {
      name: 'Keywords per Project',
      // free: t('none'),
      starter: '1000',
      growth: '2500',
      pro: '5000',
      custom: '2500+',
    },
    ],
  },
  {id: 6,
    title: 'Site Audit',
    items: [{
      name: 'Site Auditor Projects',
      // free: t('none'),
      starter: '3',
      growth: '10',
      pro: '25',
      custom: '25+',
    },
    {
      name: 'Total Pages Crawled per Month',
      // free: 'Top 10',
      starter: '5,000',
      growth: '25,000',
      pro: '100,000',
      custom: '100,000',
    },
    ],
  },
  {id: 7,
    title: 'Local SEO',
    items: [{
      name: 'Search points',
      // free: t('none'),
      starter: '3',
      growth: '10',
      pro: '25',
      custom: '25+',
    },
    ],
  },
  {id: 8,
    title: 'Digital PR',
    items: [{
      name: 'HARO Replies',
      // free: t('none'),
      starter: '3',
      growth: '10',
      pro: '25',
      custom: '25+',
    },
    ],
  },
  {id: 9,
    title: 'Backlink Analyzer',
    items: [{
      name: 'Backlink Analyzer Projects',
      // free: '1',
      starter: '2',
      growth: '5',
      pro: '5',
      custom: '5+',
    },
    {
      name: 'Referring Domains Processed',
      // free: 'Top 10',
      starter: 'Top 500',
      growth: 'Top 1,000',
      pro: 'Top 5,000',
      custom: 'Top 5,000+',
    },
    {
      name: 'Competitors per Project',
      // free: '0',
      starter: '1',
      growth: '2',
      pro: '4',
      custom: '4',
    },
    ],
  },
  {id: 10,
    title: 'Bulk DA Checker',
    items: [{
      name: 'Lookups per Day',
      // free: '5',
      starter: '50',
      growth: '100',
      pro: '200',
      custom: '200+',
    }],
  },
  {id: 11,
    title: 'Bulk URL Analyzer',
    items: [{
      name: 'Batch size limit',
      starter: '200',
      growth: '200',
      pro: '500',
    },
    {
      name: 'Analyzed URLs',
      starter: '0',
      growth: '2,000',
      pro: '5,000',
    },
    ],
  },
]);


export const getPricingArray = (t: TFunction, isWhiteLabel = false) => ([
  {id: STARTER_PLAN_ID,
    icon: 'faRocket',
    buttonOverride: false,
    disabled: true,
    buttonType: 'transparent-with-border',
    bestValueTag: false,
    darker: true,
    allowedProject: 0,
    shortDescription: '',
    tooltip: false,
    main: false,
    name: 'Starter',
    buttonColor: '#FFFFFF',
    buttonTextColor: '#4E5156',
    // oldPrice: '$99',
    iconsTooltipText: 'Download our Chrome Extension by searching for SearchAtlas in the store.',
    price: '$99',
    description: 'For freelancers and small teams',
    buttonText: t('sidebar-plan-0-buttonText'),
    summary: t('plan-0-description'),
    features: [
      // {str: 'X AI Written articles', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of AI Written articles.'},
      // {str: 'X Site reports', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of Site reports.'},
      {str: 'X GSC Sites ', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of GSC Verified sites you can import and track.'},
      {str: 'X User Seats', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Maximum number of users who can use an account.'},
      {str: 'Content Optimization Tools with AI', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {str: 'Site Explorer', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {str: 'Local SEO Heatmaps/Geogrids', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {str: 'Keyword Explorer, Keyword Magic, Keyword Gap Analysis', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {str: 'GSC Keyword Rank Tracking', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Keyword Tracking for GSC Projects.'},
      {str: 'Keyword, Competitor & Backlink Research Tools', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Find winning keywords, track competitors and analyze backlinks with award winning tools.'},
      {str: 'Chrome Extension Access', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      // {str: 'OTTO SEO AI', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Weekly data-driven SEO insights and tips for busy, fast-growing companies.'},
      // {str: 'Report builder', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Report builder'},
    ],
  },
  {id: GROWTH_PLAN_ID,
    icon: 'faTrophyStar',
    buttonOverride: true,
    disabled: false,
    buttonType: 'purple',
    darker: true,
    bestValueTag: false,
    allowedProject: 15,
    shortDescription: 'plan-2-summary',
    main: true,
    tooltip: true,
    name: 'Growth',
    buttonColor: '#FFFFFF',
    buttonTextColor: '#7F4EAD',
    // oldPrice: '$299',
    iconsTooltipText: 'Download our Chrome Extension by searching for SearchAtlas in the store.',
    price: '$199',
    description: 'For agencies and mid-size businesses',
    buttonText: t('sidebar-plan-1-buttonText'),
    summary: t('plan-2-description'),
    features: [
      // {str: 'X AI Written articles', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of AI Written articles.'},
      // {str: 'X Site reports', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of Site reports.'},
      {str: 'X GSC Sites ', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of GSC Verified sites you can import and track.'},
      {str: 'X User Seats', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Maximum number of users who can use an account.'},
      {str: 'All Starter features plus:', iconName: 'faCheck', iconColor: '#1FAC47', infoIcon: '', infoTooltip: ''},
      {str: 'Site Auditor with Realtime Site Monitoring', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Automatically scan any site for SEO issues and learn how to fix them.'},
      {str: 'HARO AI & Digital PR', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Get authoritative backlinks and improve your Digital PR.'},
      {str: 'Journalist Outreach', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      {str: 'Expanded quotas', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Generous quotas for easy and unrestrictive daily usage of all tools.'},
      {str: 'Batch Analysis', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      {str: 'AI SEO Recommendation Engine', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
    ],
  },
  {id: PRO_PLAN_ID,
    icon: 'faStar',
    buttonOverride: false,
    disabled: false,
    buttonType: 'primary',
    darker: false,
    allowedProject: 5,
    bestValueTag: true,
    buttonColor: '#F1AA3E',
    buttonTextColor: '#FFFFFF',
    shortDescription: 'plan-1-summary',
    main: false,
    tooltip: true,
    name: 'Pro',
    // oldPrice: '$499',
    price: '$399',
    description: 'Agencies ready to scale and Enterprise',
    buttonText: t('sidebar-plan-2-buttonText'),
    iconsTooltipText: 'Download our Chrome Extension by searching for SearchAtlas in the store.',
    summary: t('plan-2-description'),
    features: [
      // {str: 'X AI Written articles', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of AI Written articles.'},
      // {str: 'X Site reports', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of Site reports.'},
      {str: 'X GSC Sites ', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of GSC Verified sites you can import and track.'},
      {str: 'X User Seats', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Maximum number of users who can use an account.'},
      {str: 'All Growth features plus:', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {str: 'Unlimited GSC Keyword Rank Tracking', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      {str: 'Unlimited home page projects', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      {str: 'Unlimited AI Quota', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      {str: 'Unlimited Site Crawls up to 1 million pages', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Analyze and track unlimited sites in the Site Audit tool.'},
      // {str: 'API access', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Unlock the full potential of our data with the SearchAtlas API.'},
      ...(isWhiteLabel ? [] : [{
        str: 'Full Dashboard Whitelabel', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Use your own branding on the dashboard and exported reports.',
      }]),
      {str: 'Bulk Workflows for AI content creation, Site Explorer, Page Audits, Site Audits, and URL Analysis', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: '', background: true},
      ...(isWhiteLabel ? [] : [
        {str: 'Site Analysis Lead Capture Widget', iconName: 'faPlus', iconColor: '#936BDA',
          infoTooltip: `Maximize Lead Generation with Our Site Analysis Widget. Designed for agencies, this 
        intuitive widget captures visitor interest by offering a comprehensive site analysis. As users engage 
        with the analysis flow, they are seamlessly guided towards a contact form. Capture essential details 
        effortlessly and turn your website traffic into valuable leads.`},
      ]),
      {str: 'Integrations', iconName: 'faPlus', iconColor: '#936BDA',
        infoTooltip: `Gain access to powerful complementary tools through our partnerships with leading digital marketing software platforms.`},
      {str: 'Link Building / Sales Outreach (coming soon)', iconName: 'faPlus', iconColor: '#936BDA',
        infoTooltip: `Our Link Building Outreach tool is a game-changer for agencies seeking quality backlinks. 
        Harness the power of Google's rankings to identify and connect with top publishers. Target sites by keywords 
        in Google Search and Maps, making it an indispensable sales and SEO tool. Streamline your outreach and build 
        valuable connections effortlessly.`},
    ],
  },

  {id: CUSTOM_PLAN_ID,
    icon: 'faGem',
    buttonOverride: true,
    disabled: false,
    buttonType: 'purple',
    darker: true,
    allowedProject: 30,
    bestValueTag: false,
    shortDescription: 'plan-3-summary',
    main: false,
    tooltip: true,
    buttonColor: '#2D6CCA',
    buttonTextColor: '#FFFFFF',
    name: 'Enterprise',
    iconsTooltipText: '',
    price: 'Call Us ',
    yearlyCost: 'Call Us',
    description: 'Custom functionality & tailored quotas',
    buttonText: t('sidebar-plan-3-buttonText'),
    summary: t('plan-2-description'),
    features: [
      {str: 'Tailored Quota Possibilities for All Tools', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Call us to get personalized quotas for your business.'},
      ...(isWhiteLabel ? [] : [
        {str: 'API access', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Unlock the full potential of our data with the SearchAtlas API.'},
      ]),
    ],
  },
]);

export const getPricingSidebarArray = (t: TFunction, isWhiteLabel = false) => ([
  {
    id: STARTER_PLAN_ID,
    buttonType: 'transparent-with-border',
    name: t('sidebar-plan-0-name'),
    // oldPrice: '$99',
    price: '$99',
    mostPopular: false,
    activeButton: false,
    customPlan: false,
    description: 'For freelancers and small teams',
    icon: 'faRocket',
    buttonText: t('sidebar-plan-0-buttonText'),
    previousButtonText: t('sidebar-plan-0-previousButtonText'),
    currentButtonText: t('sidebar-plan-0-currentButtonText'),
    checkmark: '/icons/gray-check.svg',
    iconsTooltipText: 'Download our Chrome Extension by searching for SearchAtlas in the store.',
    checkList: [
      {name: '5 GSC Site Projects', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of GSC Verified sites you can import and track.'},
      {name: '2 User Seats', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Maximum number of users who can use an account.'},
      {name: 'Content Optimization Tools with AI', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Analyze and Improve page SEO with our Advanced AI.'},
      {name: 'Site Explorer', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {name: 'Local SEO Heatmaps/Geogrids', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {name: 'Keyword Explorer, Keyword Magic, Keyword Gap Analysis', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {name: 'GSC Keyword Rank Tracking', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Keyword Tracking for GSC Projects.'},
      {name: 'Keyword, Competitor & Backlink Research Tools', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Find winning keywords, track competitors and analyze backlinks with award winning tools.'},
      {name: 'Chrome Extension Access', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Download our Chrome Extension by searching for SearchAtlas in the store.'},
    ],
  },
  {
    id: GROWTH_PLAN_ID,
    buttonType: 'transparent-with-border',
    name: t('sidebar-plan-1-name'),
    // oldPrice: '$299',
    price: '$199',
    mostPopular: true,
    activeButton: true,
    customPlan: false,
    icon: 'faTrophyStar',
    description: 'For agencies and mid-size businesses',
    buttonText: t('sidebar-plan-1-buttonText'),
    previousButtonText: t('sidebar-plan-1-previousButtonText'),
    currentButtonText: t('sidebar-plan-1-currentButtonText'),
    iconsTooltipText: 'Download our Chrome Extension by searching for SearchAtlas in the store.',
    checkmark: '/icons/gray-check.svg',
    checkList: [
      {name: '15 GSC Site Projects', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of GSC Verified sites you can import and track.'},
      {name: '3 User Seats', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Maximum number of users who can use an account.'},
      {name: 'All Starter features plus:', iconName: 'faCheck', iconColor: '#1FAC47', infoIcon: '', infoTooltip: ''},
      {name: 'Site Auditor with Realtime Site Monitoring', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Automatically scan any site for SEO issues and learn how to fix them.'},
      {name: 'HARO AI & Digital PR', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Get authoritative backlinks and improve your Digital PR.'},
      {name: 'Journalist Outreach', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      {name: 'Expanded quotas', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Generous quotas for easy and unrestrictive daily usage of all tools.'},
      {name: 'Batch Analysis', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Pull SearchAtlas SEO metrics for hundreds of URLs.'},
      {name: 'AI SEO Recommendation Engine', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      // {name: 'OTTO SEO AI', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Weekly data-driven SEO insights and tips for busy, fast-growing companies.'},
    ],
  },
  {
    id: PRO_PLAN_ID,
    buttonType: 'transparent-with-border',
    name: t('sidebar-plan-2-name'),
    // oldPrice: '$449',
    price: '$399',
    mostPopular: false,
    activeButton: false,
    customPlan: false,
    icon: 'faStar',
    description: 'Agencies ready to scale and Enterprise',
    buttonText: t('sidebar-plan-2-buttonText'),
    previousButtonText: t('sidebar-plan-2-previousButtonText'),
    iconsTooltipText: 'Download our Chrome Extension by searching for SearchAtlas in the store.',
    currentButtonText: t('sidebar-plan-2-currentButtonText'),
    checkmark: '/icons/gray-check.svg',
    checkList: [
      {name: '100 GSC Site Projects', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Number of GSC Verified sites you can import and track.'},
      {name: '5 User Seats', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: 'Maximum number of users who can use an account.'},
      {name: 'All Growth features plus:', iconName: 'faCheck', iconColor: '#1FAC47', infoTooltip: ''},
      {name: 'Unlimited GSC Keyword Rank Tracking', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      {name: 'Unlimited home page projects', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: ''},
      {name: 'Unlimited AI Quota', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Default AI quota is 100 uses per month.'},
      {name: 'Unlimited Site Crawls up to 1 million pages', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Analyze and track unlimited sites in the Site Audit tool.'},
      // {name: 'API access', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Unlock the full potential of our data with the SearchAtlas API.'},
      ...(isWhiteLabel ? [] : [
        {name: 'Full Dashboard Whitelabel', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: 'Use your own branding on the dashboard and exported reports.'},
      ]),
      {name: 'Bulk Workflows for AI content creation, Site Explorer, Page Audits, Site Audits, and URL Analysis', iconName: 'faPlus', iconColor: '#936BDA', infoTooltip: '', background: true},
      ...(isWhiteLabel ? [] : [
        {name: 'Site Analysis Lead Capture Widget', iconName: 'faPlus', iconColor: '#936BDA',
          infoTooltip: `Maximize Lead Generation with Our Site Analysis Widget. Designed for agencies, this 
        intuitive widget captures visitor interest by offering a comprehensive site analysis. As users engage 
        with the analysis flow, they are seamlessly guided towards a contact form. Capture essential details 
        effortlessly and turn your website traffic into valuable leads.`},
      ]),
      {name: 'Integrations', iconName: 'faPlus', iconColor: '#936BDA',
        infoTooltip: `Gain access to powerful complementary tools through our partnerships with leading digital marketing software platforms.`},
      {name: 'Link Building / Sales Outreach (coming soon)', iconName: 'faPlus', iconColor: '#936BDA',
        infoTooltip: `Our Link Building Outreach tool is a game-changer for agencies seeking quality backlinks. 
        Harness the power of Google's rankings to identify and connect with top publishers. Target sites by keywords 
        in Google Search and Maps, making it an indispensable sales and SEO tool. Streamline your outreach and build 
        valuable connections effortlessly.`},
    ],
  },
  {
    id: BUSINESS_PLAN_ID,
    buttonType: 'transparent-with-border',
    name: 'Enterprise',
    price: 'Custom',
    mostPopular: false,
    activeButton: false,
    customPlan: true,
    icon: 'faGem',
    description: 'Custom functionality & quotas tailored for you',
    buttonText: t('sidebar-plan-3-buttonText'),
    previousButtonText: t('sidebar-plan-0-previousButtonText'),
    currentButtonText: t('sidebar-plan-0-currentButtonText'),
    checkmark: '/icons/gray-check.svg',
    checkList: [
      {name: 'Tailored Quota Posibilities for All Tools', iconName: 'faCheck', iconColor: '#936BDA', infoTooltip: 'Call us to get personalized quotas for your business.'},
      ...(isWhiteLabel ? [] : [
        {name: 'API access', iconName: 'faCheck', iconColor: '#936BDA', infoTooltip: 'Unlock the full potential of our data with the SearchAtlas API.'},
      ]),
    ],
  },

]);

export const getSupportCards = (t: TFunction) => [
  {id: 1,
    title: t('suppor_1_title'),
    description: t('suppor_1_description'),
  }, {id: 2,
    title: t('suppor_2_title'),
    description: t('suppor_2_description'),
  }, {id: 3,
    title: t('suppor_3_title'),
    description: t('suppor_3_description'),
  },
];

export const getFQAOptions = (t: TFunction) => ([
  {id: 1, isOpen: false, title: t('faq_1_title'), description: t('faq_1_description')},
  {id: 2, isOpen: false, title: t('faq_2_title'), description: t('faq_2_description')},
  {id: 3, isOpen: false, title: t('faq_3_title'), description: t('faq_3_description')},
  {id: 4, isOpen: false, title: t('faq_4_title'), description: t('faq_4_description')},
]);

